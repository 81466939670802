import React, { useState } from "react";
import styled from "styled-components";
import { ReactComponent as QuantityMinus } from "../../../../assets/images/quantity-minus.svg";
import { ReactComponent as QuantityPlus } from "../../../../assets/images/quantity-plus.svg";

const StyledPage = styled.div`
    &.frame-313515_4,
    &.frame-313515_4 * {
        box-sizing: border-box;
    }
    &.frame-313515_4 {
        display: flex;
        flex-direction: column;
        gap: 12px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313500_4 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313499_4 {
        display: flex;
        flex-direction: column;
        gap: 21px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313324_4 {
        padding: 0px 15px 0px 15px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        height: 20px;
        position: relative;
    }
    .frame-3135152_4 {
        padding: 0px 15px 0px 15px;
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .color-circle_4 {
        flex-shrink: 0;
        width: 56px;
        height: 56px;
        position: relative;
        overflow: visible;
    }
    .frame-313516 {
        padding: 4px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    ._100 {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-size: 30px;
        font-weight: 600;
        position: relative;
        width: 52px;
    }
    .color-circle2_4 {
        flex-shrink: 0;
        width: 56px;
        height: 56px;
        position: relative;
        overflow: visible;
    }
    .this-store-offers-up-to-5-items-need-more-we-ll-find-additional-stores {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: center;
        font-size: 10px;
        line-height: 14px;
        font-weight: 400;
        opacity: 0;
        position: relative;
        align-self: stretch;
    }
    .this-store-offers-up-to-5-items-need-more-we-ll-find-additional-stores-span {
        color: var(--text-text-secondary, #8c8c8c);
        font-size: 10px;
        line-height: 14px;
        font-weight: 400;
    }
    .this-store-offers-up-to-5-items-need-more-we-ll-find-additional-stores-span2 {
        color: var(--text-text-secondary, #8c8c8c);
        font-size: 10px;
        line-height: 14px;
        font-weight: 600;
    }
    .this-store-offers-up-to-5-items-need-more-we-ll-find-additional-stores-span3 {
        color: var(--text-text-secondary, #8c8c8c);
        font-size: 10px;
        line-height: 14px;
        font-weight: 400;
    }
    .frame-313501 {
        padding: 0px 14px 0px 14px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-3135034 {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .btn-fw-botag_4 {
        background: var(--buttons-button-secondary, #212121);
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex: 1;
        height: 33px;
        position: relative;
        box-shadow: var(--bt-drop-shadow-box-shadow, 0px 0px 4px 0px rgba(30, 38, 49, 0.35));
    }
    .frame-313497 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313349 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .button {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-size: var(--button-text-small-font-size, 12px);
        line-height: var(--button-text-small-line-height, 16px);
        font-weight: var(--button-text-small-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .btn-fw-botag2_4 {
        background: var(--buttons-button-primary, #f7665f);
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex: 1;
        height: 33px;
        position: relative;
        box-shadow: var(--bt-drop-shadow-box-shadow, 0px 0px 4px 0px rgba(30, 38, 49, 0.35));
    }
    .button2 {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

export default function BOTagBodySelectQuantity({
    selectedQuantity,
    setSelectedQuantity,
    setShowQuantitySelection,
    setShowDeliveryMethodSelection,
    selectedNewQuantity,
    setSelectedNewQuantity,
    setSelectedColor,
    selectedNewColor,
    setSelectedSize,
    selectedNewSize,
    botagData,
    stockData,
    setHeaderMessage,
    setOverQuantity,
    setOverQuantityNumber
}) {
    const limit = 100;

    const changeQuantity = isIncrease => {
        if (isIncrease) {
            if (selectedNewQuantity < limit) setSelectedNewQuantity(selectedNewQuantity + 1);
        } else {
            if (selectedNewQuantity > 1) setSelectedNewQuantity(selectedNewQuantity - 1);
        }
    };
    function checkSelectedProductQty(arr, selectedSize, selecetedColor) {
        for (let i = 0; i < arr.length; i++) {
            if (arr[i].size === selectedSize && arr[i].colorCode === selecetedColor.replace("#", "")) {
                return arr[i].totalStock;
            }
        }
        return 0;
    }
    return (
        <StyledPage className="frame-313515_4">
            <div className="frame-313500_4">
                <div className="frame-313499_4">
                    <div className="frame-313324_4"></div>
                    <div className="frame-3135152_4">
                        <QuantityMinus
                            className="color-circle_4 cursor-pointer"
                            onClick={() => changeQuantity(false)}
                            alt="Decrease Quantity"
                            style={{ color: selectedNewQuantity === 1 ? "#8c8c8c" : "#ffffff" }}
                        />
                        <div className="frame-313516">
                            <div className="_100">{selectedNewQuantity}</div>
                        </div>
                        <QuantityPlus
                            className="color-circle2_4 cursor-pointer"
                            onClick={() => changeQuantity(true)}
                            alt="Increase Quantity"
                            style={{ color: selectedNewQuantity === limit ? "#8c8c8c" : "#ffffff" }}
                        />
                    </div>
                    <div className="this-store-offers-up-to-5-items-need-more-we-ll-find-additional-stores">
                        <span>
                            <span className="this-store-offers-up-to-5-items-need-more-we-ll-find-additional-stores-span">
                                This store offers up to
                            </span>
                            <span className="this-store-offers-up-to-5-items-need-more-we-ll-find-additional-stores-span2">5 items</span>
                            <span className="this-store-offers-up-to-5-items-need-more-we-ll-find-additional-stores-span3">
                                .
                                <br />
                                Need more? We&#039;ll find additional stores.
                            </span>
                        </span>
                    </div>
                </div>
            </div>
            <div className="frame-313501">
                <div className="frame-3135034">
                    <div
                        className="btn-fw-botag_4 cursor-pointer"
                        onClick={() => {
                            setSelectedNewQuantity(1);
                            setSelectedQuantity(1);
                            setShowQuantitySelection(false);
                            setShowDeliveryMethodSelection(true);
                            setHeaderMessage(false);
                            setOverQuantity(false);
                        }}>
                        <div className="frame-313497">
                            <div className="frame-313349">
                                <div className="button">Clear</div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="btn-fw-botag2_4 cursor-pointer"
                        onClick={() => {
                            const stockQty = checkSelectedProductQty(stockData, selectedNewSize, selectedNewColor);
                            if (stockQty >= selectedNewQuantity) {
                                setSelectedQuantity(selectedNewQuantity);
                                setSelectedColor(selectedNewColor.replace("#", ""));
                                setSelectedSize(selectedNewSize);
                                setShowQuantitySelection(false);
                                setShowDeliveryMethodSelection(true);
                                setOverQuantity(false);
                                setHeaderMessage(false);
                            } else {
                                setHeaderMessage(true);
                                setShowQuantitySelection(false);
                                setShowDeliveryMethodSelection(true);
                                if (selectedNewQuantity === 1) {
                                    setOverQuantity(false);
                                } else {
                                    setOverQuantity(true);
                                }

                                setOverQuantityNumber(stockQty);
                                console.log("Out of stock");
                            }
                        }}>
                        <div className="frame-313497">
                            <div className="frame-313349">
                                <div className="button2">Select</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </StyledPage>
    );
}
