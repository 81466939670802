import Skeleton from "@mui/material/Skeleton";

const ProductInfoSkeleton = () => {
    return (
        <div style={{ width: "100%" }}>
            <div
                style={{
                    display: "flex",
                    padding: "16px",
                    height: "87px",
                    border: "1px solid #212121",
                    borderRadius: "5px",
                    marginBottom: "16px"
                }}>
                <div style={{ marginRight: "20px" }}>
                    <Skeleton
                        height={"53px"}
                        width={"40px"}
                        borderRadius={"5px"}
                        sx={{ bgcolor: "grey.900" }}
                        variant="rectangular"
                        className="rect"
                    />
                </div>
                <div>
                    <Skeleton
                        width={"147px"}
                        height={"12px"}
                        borderRadius={"20px"}
                        sx={{ bgcolor: "grey.900" }}
                        variant="rectangular"
                        className="rect"
                    />
                    <br />
                    <Skeleton
                        width={"177px"}
                        height={"12px"}
                        borderRadius={"20px"}
                        sx={{ bgcolor: "grey.900" }}
                        variant="rectangular"
                        className="rect"
                    />
                </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                <div
                    style={{
                        display: "flex",
                        height: "59px",
                        border: "1px solid #212121",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "16px",
                        borderRadius: "5px"
                    }}>
                    <Skeleton
                        width={"147px"}
                        height={"12px"}
                        borderRadius={"20px"}
                        sx={{ bgcolor: "grey.900" }}
                        variant="rectangular"
                        className="rect"
                    />
                    <Skeleton width={"20px"} height={"20px"} borderRadius={"50%"} sx={{ bgcolor: "grey.900" }} variant="circular" />
                </div>
                <div
                    style={{
                        display: "flex",
                        height: "59px",
                        border: "1px solid #212121",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "16px",
                        borderRadius: "5px"
                    }}>
                    <Skeleton
                        width={"147px"}
                        height={"12px"}
                        borderRadius={"20px"}
                        sx={{ bgcolor: "grey.900" }}
                        variant="rectangular"
                        className="rect"
                    />
                    <Skeleton width={"20px"} height={"20px"} borderRadius={"50%"} sx={{ bgcolor: "grey.900" }} variant="circular" />
                </div>
                <div
                    style={{
                        display: "flex",
                        height: "59px",
                        border: "1px solid #212121",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "16px",
                        borderRadius: "5px"
                    }}>
                    <Skeleton
                        width={"147px"}
                        height={"12px"}
                        borderRadius={"20px"}
                        sx={{ bgcolor: "grey.900" }}
                        variant="rectangular"
                        className="rect"
                    />
                    <Skeleton width={"20px"} height={"20px"} borderRadius={"50%"} sx={{ bgcolor: "grey.900" }} variant="circular" />
                </div>
            </div>
        </div>
    );
};

export default ProductInfoSkeleton;
