import React, { createRef, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import LabelIcon from "../../../assets/images/label-icon.svg";
import ArrowNext from "../../../assets/images/arrow-next.svg";
import LayoutContext from "../../../contexts/LayoutContext";
import Skeleton from "../../common/skeletons/Skeleton";
import * as Library from "../../../utils/Library";
import botagCloseBtn from "../../../assets/images/botagCloseBtn.png";
import { useNavigate } from "react-router-dom";

/* #region CSS */
const StyledPage = styled.div`
    &.frame-313731,
    &.frame-313731 * {
        box-sizing: border-box;
    }
    &.frame-313731 {
        display: flex;
        flex-direction: column;
        gap: 32px;
        align-items: flex-start;
        -webkit-box-pack: start;
        justify-content: flex-start;
        position: relative;
    }
    .frame-313730 {
        display: flex;
        flex-direction: row;
        gap: 32px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313706 {
        display: flex;
        flex-direction: row;
        gap: 13px;
        align-items: flex-start;
        justify-content: flex-start;
        flex: 1;
        position: relative;
        padding: 0px 25px;
    }
    .popular-now {
        color: #ffffff;
        text-align: center;
        font-family: var(--heading-1-font-family, "OpenSans-Bold", sans-serif);
        font-size: var(--heading-1-font-size, 24px);
        line-height: var(--heading-1-line-height, 24px);
        font-weight: var(--heading-1-font-weight, 700);
        position: relative;
    }
    .frame-313729 {
        display: flex;
        flex-direction: row;
        gap: 13px;
        align-items: flex-start;
        justify-content: flex-start;
        flex-shrink: 0;
        width: 63px;
        height: 24px;
        position: relative;
    }
    .frame-313705 {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        height: 662px;
        position: relative;
        // width: 100%;
    }
    .frame-313690 {
        background: linear-gradient(166.11deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 50%);
        border-radius: 32px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        position: relative !important;
        max-width: 380px;
        flex-shrink: 0;
    }
    .frame-295922389 {
        padding: 8px 16px 8px 16px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: flex-end;
        justify-content: center;
        flex-shrink: 0;
        width: 117px;
        height: 48px;
        position: absolute;
        right: 0px;
        bottom: 10px;
    }
    .samsoe-samsoe-logo-white-3 {
        align-self: stretch;
        flex: 1;
        position: relative;
    }
    .info-note {
        background: rgba(0, 0, 0, 0.6);
        border-radius: 30px;
        padding: 5px 15px;
        display: flex;
        flex-direction: row;
        gap: 19.2px;
        align-items: flex-start;
        justify-content: flex-start;
        flex-shrink: 0;
        position: absolute;
        left: 16px;
        bottom: 16px;
        backdrop-filter: blur(5px);
    }
    .frame-29614 {
        display: flex;
        flex-direction: row;
        gap: 4.8px;
        align-items: center;
        justify-content: flex-start;
        flex-shrink: 0;
        position: relative;
    }
    .tag-01 {
        flex-shrink: 0;
        width: 14.4px;
        height: 14.4px;
        position: relative;
        overflow: visible;
    }
    ._2-products {
        color: var(--text-text-primary, #ffffff);
        text-align: left;
        font-family: "OpenSans-Regular", sans-serif;
        font-size: 14.40000057220459px;
        line-height: 24px;
        font-weight: 400;
        position: relative;
    }
    .frame-313701 {
        background: linear-gradient(166.11deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 50%);
        border-radius: 32px;
        padding: 32px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex: 1;
        position: relative;
        width: 25%;
    }

    .tag-012 {
        flex-shrink: 0;
        width: 14.4px;
        height: 14.4px;
        position: relative;
        overflow: visible;
    }
    .frame-313703 {
        background: linear-gradient(166.11deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 50%);
        border-radius: 32px;
        padding: 32px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex: 1;
        position: relative;
        width: 25%;
    }
    .tag-013 {
        flex-shrink: 0;
        width: 14.4px;
        height: 14.4px;
        position: relative;
        overflow: visible;
    }
    .frame-313702 {
        background: linear-gradient(166.11deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 50%);
        border-radius: 32px;
        padding: 32px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex: 1;
        position: relative;
        width: 25%;
    }
    .tag-014 {
        flex-shrink: 0;
        width: 14.4px;
        height: 14.4px;
        position: relative;
        overflow: visible;
    }
    .frame-295922570 {
        border-radius: 0px 32px 32px 0px;
        padding: 0px 16px 0px 16px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: flex-end;
        flex-shrink: 0;
        position: absolute;
        right: 15px;
    }
    .frame-295922571 {
        border-radius: 0px 32px 32px 0px;
        padding: 0px 16px 0px 16px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: flex-end;
        flex-shrink: 0;
        position: absolute;
    }
    .frame-295922394 {
        border-radius: 50px;
        border-style: solid;
        border-color: var(--border-border-white, #ffffff);
        border-width: 1px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 70px;
        height: 70px;
        position: relative;
        cursor: pointer;
        z-index: 5;
    }
    .arrow-narrow-right {
        flex-shrink: 0;
        width: 32px;
        height: 32px;
        position: relative;
        overflow: visible;
    }
    .arrow-narrow-left {
        flex-shrink: 0;
        width: 32px;
        height: 32px;
        position: relative;
        overflow: visible;
        transform: rotate(180deg);
        z-index: 2;
    }
    .main-containe-popular {
        display: flex;
        flex-direction: column;
        gap: 32px;
        align-items: flex-start;
        -webkit-box-pack: start;
        justify-content: flex-start;
        flex-shrink: 0;
        width: 100%;
        position: relative;
        overflow: hidden;
    }
    .main-popular-list {
        display: flex;
        flex-direction: row;
        height: 100%;
        width: 100%;
        gap: 16px;
        overflow-x: scroll;
        flex-wrap: nowrap;
        scroll-behavior: smooth;
    }
    .frame-295922390 {
        padding: 8px 16px 8px 16px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: flex-end;
        justify-content: center;
        width: 175px;
        height: 71px;
        position: absolute;
        right: 0.33px;
        bottom: 0.29px;
    }
    @media only screen and (max-width: 992px) {
        .info-note {
            top: 16px;
            bottom: unset;
        }
    }
    @keyframes pulse-white {
        0% {
            transform: scale(1.2);
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.3);
        }

        100% {
            transform: scale(0.9);
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
        }
    }
`;
/* #endregion CSS */
export default function PopularBrands() {
    /* #region CONTEXT */
    const { isMobile } = React.useContext(LayoutContext);
    const imageRefs = useRef([]);

    /* #region STATES */
    const [isLoading, setIsLoading] = useState(true);
    const images = [1, 2, 3, 4];

    const [data, setData] = useState([]);
    const [beacon, setBeacon] = useState({ state: false, id: "" });
    const [onHover, setOnHover] = useState({
        state: false,
        id: ""
    });
    const [isShowCloseBtn, setIsShowCloseBtn] = useState({ state: false, id: "" });

    /* #endregion STATES */
    /* #region Methods */
    const containerRef = useRef(null);
    const handleScrollLeft = () => {
        if (containerRef.current) {
            containerRef.current.scrollBy({
                left: isMobile ? 475 : 950,
                behavior: "smooth"
            });
        }
    };
    const handleScrollRight = () => {
        if (containerRef.current) {
            containerRef.current.scrollBy({
                left: isMobile ? -475 : -950,
                behavior: "smooth"
            });
        }
    };

    const getImages = () => {
        Library.makePostRequest("getHomePopularBOimages", "-", false, setIsLoading)
            .then(res => {
                const { data } = res;

                if (res?.status === 200) {
                    setData(data?.data);
                }
            })
            .catch(e => {
                console.log(e);
            });
    };

    /* #endregion Methods */

    /* #region Effects */

    useEffect(() => {
        getImages();
    }, []);
    /* #endregion Effects */

    return (
        <StyledPage className="frame-313731">
            <div className="main-containe-popular">
                <div className="frame-313730">
                    <div className="frame-313706" style={{ padding: isMobile ? "0px 25px" : "0px 15px" }}>
                        <div className="popular-now">Popular now</div>
                    </div>
                    <div className="frame-313729"></div>
                </div>
                {isLoading ? (
                    <div
                        style={{
                            // width: "100%",
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr 1fr 1fr",
                            gridTemplateRows: "none",
                            overflowX: "hidden",
                            gap: "16px",
                            padding: "0px 15px"
                        }}>
                        {images.map(i => (
                            <Skeleton width={isMobile ? 280 : 450} height={isMobile ? 405 : 600} style={{ borderRadius: "20px" }} />
                        ))}
                    </div>
                ) : (
                    <div className="frame-313705">
                        {!isMobile && (
                            <div className="frame-295922571" onClick={handleScrollRight}>
                                <div className="frame-295922394">
                                    <img className="arrow-narrow-left" src={ArrowNext} alt="" />
                                </div>
                            </div>
                        )}

                        <div className="main-popular-list" ref={containerRef} style={{ padding: " 0px 15px" }}>
                            {data?.map((i, index) => {
                                if (!imageRefs.current[index]) {
                                    imageRefs.current[index] = React.createRef();
                                }
                                return (
                                    <div
                                        onClick={() => {
                                            if (isMobile) {
                                                setBeacon({
                                                    state: true,
                                                    id: i?._id
                                                });
                                                setOnHover({
                                                    state: true,
                                                    id: i?._id
                                                });
                                                setIsShowCloseBtn({ state: true, id: i?._id });
                                            }
                                        }}
                                        onMouseEnter={() => {
                                            !isMobile &&
                                                setBeacon({
                                                    state: true,
                                                    id: i?._id
                                                });
                                            setOnHover({
                                                state: true,
                                                id: i?._id
                                            });
                                        }}
                                        onMouseLeave={() => {
                                            setBeacon({
                                                state: false,
                                                id: i?._id
                                            });
                                            setOnHover({
                                                state: false,
                                                id: i?._id
                                            });
                                        }}
                                        className="frame-313690"
                                        style={{
                                            opacity: onHover?.state && onHover?.id === i?._id ? 1 : onHover?.state && 0.3
                                        }}>
                                        <img
                                            src={i?.imageURL}
                                            alt=""
                                            ref={imageRefs.current[index]}
                                            style={{
                                                width: "100%",
                                                height: "100%",
                                                borderRadius: "32px",
                                                objectFit: "cover"
                                            }}
                                        />
                                        {beacon?.state &&
                                            beacon?.id === i?._id &&
                                            i?.BOtags?.map(item => {
                                                return <Beacon item={item} i={i} targetImageRef={imageRefs.current[index]} />;
                                            })}
                                        {isMobile && isShowCloseBtn?.state && isShowCloseBtn?.id === i?._id && (
                                            <div
                                                style={{
                                                    position: "absolute",
                                                    zIndex: 5,
                                                    top: "25px",
                                                    left: "25px"
                                                }}
                                                onClick={e => {
                                                    e.stopPropagation();
                                                    setBeacon({
                                                        state: false,
                                                        id: ""
                                                    });
                                                    setOnHover({
                                                        state: false,
                                                        id: ""
                                                    });

                                                    setIsShowCloseBtn(false);
                                                }}>
                                                <img src={botagCloseBtn} alt="" height={"50px"} width={"50px"} />
                                            </div>
                                        )}
                                        {!isShowCloseBtn?.state && (
                                            <>
                                                <div className="info-note">
                                                    <div className="frame-29614">
                                                        <img className="tag-013" src={LabelIcon} alt="" />
                                                        <div className="_2-products">{i?.BOtags?.length} products</div>
                                                    </div>
                                                </div>
                                                <div className="frame-295922390">
                                                    <img className="samsoe-samsoe-logo-white-3" src={i?.logo} alt="" />
                                                </div>
                                            </>
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                        {!isMobile && (
                            <div className="frame-295922570" onClick={handleScrollLeft}>
                                <div className="frame-295922394">
                                    <img className="arrow-narrow-right" src={ArrowNext} alt="" />
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </StyledPage>
    );
}

const Beacon = ({ i, item, targetImageRef }) => {
    const navigate = useNavigate();
    return (
        <div
            style={{
                position: "absolute",
                left: item?.beaconXposition * (targetImageRef?.current?.clientWidth / targetImageRef?.current?.naturalWidth) + "px",
                top: item?.beaconYposition * (targetImageRef?.current?.clientHeight / targetImageRef?.current?.naturalHeight) + "px",
                zIndex: 2,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                gap: "5px",
                cursor: "pointer"
            }}
            onClick={() => {
                navigate(`/${i?.ourID}`);
            }}>
            <div
                style={{
                    backgroundColor: "#0000004D ",
                    height: "40px",
                    width: "40px",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    animation: "pulse-white 1.5s infinite"
                }}>
                <div style={{ backgroundColor: "#FFFFFF", height: "20px", width: "20px", borderRadius: "50%" }}></div>
            </div>
            <div style={{ backgroundColor: "#0000004D", padding: "8.4px 15.2px 8.4px 15.2px", borderRadius: "20px" }}>
                {item?.products?.[0]?.productType}
            </div>
        </div>
    );
};
