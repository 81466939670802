import React from "react";
import { DefaultPopupCard } from "./DefaultPopupCard";
import styled from "styled-components";
import deleteIcon from "../../../assets/images/order-remove.svg";
/* #region CSS */
const StyledPage = styled.div`
    &.frame-29643,
    &.frame-29643 * {
        box-sizing: border-box;
    }
    &.frame-29643 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-end;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313411 {
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: flex-end;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313402 {
        display: flex;
        flex-direction: row;
        gap: 32px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313406 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex: 1;
        position: relative;
    }
    .frame-3134022 {
        display: flex;
        flex-direction: column;
        gap: 32px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313401 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-3590 {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .group-3413 {
        flex-shrink: 0;
        width: 50px;
        height: 50px;
        position: static;
    }
    .ellipse-80 {
        background: var(--wrap-wrap-orange, rgba(255, 153, 0, 0.1));
        border-radius: 50%;
        width: 50px;
        height: 50px;
        position: absolute;
        top: 0px;
    }
    .info-circle {
        border-radius: 88px;
        width: 5.94%;
        height: 48%;
        position: absolute;
        right: 47.03%;
        left: 47.03%;
        bottom: 26%;
        top: 26%;
        overflow: visible;
    }
    .sorry {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-family: "OpenSans-SemiBold", sans-serif;
        font-size: 22px;
        line-height: 24px;
        font-weight: 600;
        position: relative;
        align-self: stretch;
    }
    .confirmation-text-message {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: center;
        font-family: var(--subtitle-2-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 14px);
        line-height: var(--subtitle-2-line-height, 24px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
        align-self: stretch;
    }
    .frame-29729 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313414 {
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313399 {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313412 {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .btn-md-solid {
        background: var(--buttons-button-secondary, #212121);
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex: 1;
        height: 44px;
        position: relative;
        box-shadow: var(--bt-drop-shadow-box-shadow, 0px 0px 4px 0px rgba(30, 38, 49, 0.35));
        cursor: pointer;
    }
    .frame-313497 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313349 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .button {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-family: var(--button-text-medium-regular-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--button-text-medium-regular-font-size, 14px);
        line-height: var(--button-text-medium-regular-line-height, 24px);
        font-weight: var(--button-text-medium-regular-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .btn-md-solid2 {
        background: var(--buttons-button-primary, #f7665f);
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex: 1;
        height: 44px;
        position: relative;
        box-shadow: var(--bt-drop-shadow-box-shadow, 0px 0px 4px 0px rgba(30, 38, 49, 0.35));
        cursor: pointer;
    }
`;
/* #endregion CSS */
export default function CartRemovePopup({ visible, length, setShowDeletePopup, onRemoveFromCart }) {
    return (
        <DefaultPopupCard visible={visible} mobileUsesFullScreen={false}>
            <StyledPage className="frame-29643">
                <div className="frame-313411">
                    <div className="frame-313402">
                        <div className="frame-313406">
                            <div className="frame-3134022">
                                <div className="frame-313401">
                                    <div className="frame-3590">
                                        <div className="group-3413">
                                            <div className="ellipse-80"></div>
                                            <img className="info-circle" src={deleteIcon} alt="" />
                                        </div>
                                    </div>
                                    <div className="sorry">Deleting item</div>
                                    <div className="confirmation-text-message">
                                        {length > 1
                                            ? "Are you sure want to delete this item?"
                                            : "Deleting this item will end the checkout process. Are you sure you want to proceed?"}
                                    </div>
                                </div>
                            </div>
                            <div className="frame-29729">
                                <div className="frame-313414">
                                    <div className="frame-313399">
                                        <div className="frame-313412">
                                            <div className="btn-md-solid" onClick={onRemoveFromCart}>
                                                <div className="frame-313497">
                                                    <div className="frame-313349">
                                                        <div className="button">Yes</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="btn-md-solid2" onClick={() => setShowDeletePopup(false)}>
                                                <div className="frame-313497">
                                                    <div className="frame-313349">
                                                        <div className="button">No</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </StyledPage>
        </DefaultPopupCard>
    );
}
