import React, { useState } from "react";
import styled from "styled-components";
import { ReactComponent as ColorAtention } from "../../../../assets/images/color-atention.svg";
import { ReactComponent as ColorUnavailable } from "../../../../assets/images/color-unavailable.svg";
import GreyInfo from "../../../../assets/images/grey-info.svg";
import * as Formatter from "../../../../utils/Formatter";
import * as Library from "../../../../utils/Library";

const ColorPickerStyled = styled.div`
    &.frame-313511_3,
    &.frame-313511_3 * {
        box-sizing: border-box;
    }
    &.frame-313511_3 {
        display: flex;
        flex-direction: column;
        height: 195px;
        gap: 16px;
        align-items: flex-start;
        //justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313513_3 {
        display: flex;
        flex-direction: column;
        gap: 18px;
        align-items: flex-start;
        //justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313715_3 {
        display: flex;
        flex-direction: column;
        gap: 12px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313714_3 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        //justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
        height: 146px;
    }
    .frame-313716_3 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313500_3 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313499 {
        display: flex;
        flex-direction: column;
        gap: 17px;
        align-items: flex-start;
        //justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313718 {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313324_2 {
        padding: 0px 15px 0px 15px;
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .color-name {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-size: 14px;
        font-weight: 600;
        position: relative;
        align-self: stretch;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        height: 20px;
    }
    .frame-313320_3 {
        display: flex;
        flex-direction: row;
        gap: 6px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
        margin-top: 4px;
        margin-bottom: 4px;
    }
    .frame-313719_3 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .icon {
        flex-shrink: 0;
        width: 10px;
        height: 10px;
        position: relative;
        overflow: visible;
    }
    .selected-color-is-not-in-stock-in-m {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: center;
        font-size: 10px;
        font-weight: 400;
        position: relative;
    }
    .frame-313510 {
        padding: 0px 15px 0px 15px;
        display: flex;
        flex-direction: column;
        gap: 11px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313508 {
        display: flex;
        flex-direction: row;
        gap: 10px;
        row-gap: 14px;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
        height: 85px;
    }
    .color-circle {
        opacity: 0.800000011920929;
        flex-shrink: 0;
        width: 36px;
        height: 36px;
        position: relative;
    }
    .frame-313522 {
        border-radius: 600px;
        width: 36px;
        height: 36px;
        position: absolute;
        left: 0px;
        top: 0px;
        overflow: visible;
    }
    .ellipse-color-active {
        flex-shrink: 0;
        width: 36px;
        height: 36px;
        position: relative;
    }
    .image-50 {
        background: #ffffff;
        border-radius: 50px;
        width: 36px;
        height: 36px;
        position: absolute;
        left: 0px;
        top: 0px;
    }
    .image-49 {
        background: transparent;
        border-radius: 50px;
        border-style: solid;
        border-color: #121212;
        border-width: 1px;
        width: 32px;
        height: 32px;
        position: absolute;
        left: 2px;
        top: 2px;
    }
    .ellipse-color {
        background: #3b56e3;
        border-radius: 50px;
        border-style: solid;
        border-color: #212121;
        border-width: 1px;
        flex-shrink: 0;
        width: 36px;
        height: 36px;
        position: relative;
    }
    .frame-313509 {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313524 {
        border-radius: 600px;
        flex-shrink: 0;
        width: 36px;
        height: 36px;
        position: relative;
        overflow: visible;
    }
    .frame-3135222 {
        border-radius: 600px;
        flex-shrink: 0;
        width: 36px;
        height: 36px;
        position: relative;
        overflow: visible;
    }
    .frame-313512 {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .pagination-ellipses {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: flex-start;
        justify-content: flex-start;
        flex-shrink: 0;
        position: relative;
    }
    .ellipse-page.active {
        background: var(--buttons-button-primary, #f7665f);
        border-radius: 50%;
        flex-shrink: 0;
        width: 8px;
        height: 8px;
        position: relative;
    }
    .ellipse-page {
        background: var(--buttons-button-secondary, #212121);
        border-radius: 50%;
        flex-shrink: 0;
        width: 8px;
        height: 8px;
        position: relative;
    }
    .frame-3135132_3 {
        padding: 0px 14px 0px 14px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-3135034 {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .btn-fw-botag_3 {
        background: var(--buttons-button-secondary, #212121);
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex: 1;
        height: 33px;
        position: relative;
        box-shadow: var(--bt-drop-shadow-box-shadow, 0px 0px 4px 0px rgba(30, 38, 49, 0.35));
    }
    .frame-313497 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313349 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .button {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-size: 12px;
        line-height: var(--button-text-small-line-height, 16px);
        font-weight: var(--button-text-small-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .btn-fw-botag2_3 {
        background: var(--buttons-button-primary, #f7665f);
        border-radius: 5px;
        //opacity: 0.2;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex: 1;
        height: 33px;
        position: relative;
        box-shadow: var(--bt-drop-shadow-box-shadow, 0px 0px 4px 0px rgba(30, 38, 49, 0.35));
    }
    .button2 {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .color-not-found {
        display: flex;
        font-size: 10px;
        justify-content: center;
        align-items: center;
        color: #8c8c8c;
        width: 100%;
    }
`;

export default function BOTagBodySelectColor({
    colors,
    selectedColor,
    setSelectedColor,
    setShowColorSelection,
    setShowDeliveryMethodSelection,
    stockData,
    selectedNewColor,
    setSelectedNewColor,
    botagData,
    setSelectedBotag,
    setSelectedImage,
    setProductImageLoader,
    setSelectedSize,
    selectedNewSize,
    setSelectedQuantity,
    selectedNewQuantity
}) {
    const handleSelectedColor = selectedColor => {
        const selectedColorObj = colors.find(
            color => Formatter.formatColorHex(color.colorCode) === Formatter.formatColorHex(selectedColor)
        );
        return selectedColorObj || {};
    };

    const colorHasStock = colorCode => {
        if (!stockData) return false;
        return stockData?.some(
            stock => Formatter.formatColorHex(stock.colorCode) === Formatter.formatColorHex(colorCode) && stock.totalStock > 0
        );
    };
    function checkSizeAndColor(arr, selectedSize, selecetedColor) {
        return arr.some(item => item.size === selectedSize && item.colorCode === selecetedColor.replace("#", ""));
    }
    const { color: colorName } = handleSelectedColor(selectedColor);
    const [selectedNewColorName, setSelectedNewColorName] = useState(colorName || "--");
    const [showNotFoundMsg, setShowNotFoundMsg] = useState(false);
    const [notFoundMsg, setNotFoundMsg] = useState("Selected color is not in stock in any size");
    const [page, setPage] = useState(1);
    const limit = 8;
    const colorsSubset = colors.slice((page - 1) * limit, page * limit);
    const numberOfPages = Math.ceil(colors.length / limit);
    const currentPage = page;
    const getProductImage = color => {
        setProductImageLoader(true);
        setSelectedImage([]);
        Library.makePostRequest("getProductImages", { productID: botagData?.data?.productObjID, colorCode: color.replace("#", "") }, false)
            .then(res => {
                botagData.image = res?.data?.data[0]?.URL;
                botagData.images = res?.data?.data;
                setSelectedBotag(botagData);
                setSelectedImage(res?.data?.data);
                setProductImageLoader(false);
            })
            .catch(e => {
                console.log(e);
            });
    };

    return (
        <ColorPickerStyled className="frame-313511_3">
            <div className="frame-313513_3">
                <div className="frame-313715_3">
                    <div className="frame-313714_3">
                        <div className="frame-313716_3">
                            <div className="frame-313500_3">
                                <div className="frame-313499">
                                    <div className="frame-313718">
                                        <div className="frame-313324_2">
                                            <div className="color-name">{selectedNewColorName}</div>
                                            {showNotFoundMsg ? (
                                                <div className="color-not-found">
                                                    <img src={GreyInfo} alt="GreyInfo" />
                                                    &nbsp; {notFoundMsg}
                                                </div>
                                            ) : (
                                                <div className="color-not-found">&nbsp;</div>
                                            )}
                                        </div>

                                        <div className="frame-313510">
                                            <div className="frame-313508">
                                                {colorsSubset.map((colorObj, index) => {
                                                    const { colorCode, color: colorName } = colorObj;
                                                    const color = Formatter.formatColorHex(colorCode);

                                                    const colorUnavailable = !colorHasStock(color);
                                                    return colorUnavailable ? (
                                                        <div className="ellipse-color-active">
                                                            <ColorUnavailable
                                                                className="frame-313524 "
                                                                alt="Unavailable"
                                                                style={{
                                                                    color: color,
                                                                    zIndex: 9999,
                                                                    border:
                                                                        color.toString() === selectedNewColor.toString()
                                                                            ? "2px solid #848484"
                                                                            : undefined
                                                                }}
                                                                onClick={() => {
                                                                    setShowNotFoundMsg(true);
                                                                    setSelectedNewColor(color);
                                                                    setSelectedNewColorName(colorName);
                                                                    getProductImage(color);
                                                                }}
                                                            />
                                                        </div>
                                                    ) : color.toString() !== selectedNewColor.toString() ? (
                                                        <div
                                                            key={index}
                                                            className="ellipse-color"
                                                            style={{ background: color }}
                                                            onClick={() => {
                                                                setShowNotFoundMsg(false);
                                                                setSelectedNewColor(color);
                                                                setSelectedNewColorName(colorName);
                                                                getProductImage(color);

                                                                if (!checkSizeAndColor(stockData, selectedNewSize, color)) {
                                                                    setShowNotFoundMsg(true);
                                                                    setNotFoundMsg(`Selected color is not in stock in ${selectedNewSize}`);
                                                                }
                                                            }}></div>
                                                    ) : (
                                                        <div key={index} className="ellipse-color-active">
                                                            <div className="image-50"></div>
                                                            <div className="image-49" style={{ background: color }}></div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                            <div className="frame-313512">
                                                <div className="pagination-ellipses" style={{ height: "8px" }}>
                                                    {numberOfPages > 1 &&
                                                        Array.from({ length: numberOfPages }).map((_, index) => (
                                                            <div
                                                                key={index}
                                                                className={`ellipse-page cursor-pointer ${
                                                                    currentPage === index + 1 ? "active" : ""
                                                                }`}
                                                                onClick={() => setPage(index + 1)}></div>
                                                        ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="frame-3135132_3">
                        <div className="frame-3135034">
                            <div
                                className="btn-fw-botag_3 cursor-pointer"
                                onClick={() => {
                                    setSelectedNewColor("#" + botagData?.selectedColor);
                                    setSelectedColor("#" + botagData?.selectedColor);
                                    setShowColorSelection(false);
                                    setShowDeliveryMethodSelection(true);
                                }}>
                                <div className="frame-313497">
                                    <div className="frame-313349">
                                        <div className="button">Clear</div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="btn-fw-botag2_3 cursor-pointer"
                                onClick={e => {
                                    if (!showNotFoundMsg) {
                                        setSelectedQuantity(selectedNewQuantity);
                                        setSelectedColor(selectedNewColor);
                                        setSelectedSize(selectedNewSize);
                                        setShowColorSelection(false);
                                        setShowDeliveryMethodSelection(true);
                                    } else {
                                        e.preventDefault();
                                    }
                                }}
                                style={
                                    showNotFoundMsg
                                        ? {
                                              opacity: 0.2,
                                              cursor: "no-drop"
                                          }
                                        : {}
                                }>
                                <div className="frame-313497">
                                    <div className="frame-313349">
                                        <div className="button2">Select</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ColorPickerStyled>
    );
}
