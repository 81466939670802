/**
 * @file PreLoader.js
 * @desc A component that displays a loader with a spinning icon and text.
 * @module components/common/loaders/PreLoader
 */

import LoaderSvg from "./../../../assets/images/loader.svg";
//import LoaderIcon from "./../../../assets/images/loader-icon.png";
import styled from "styled-components";

/* #region  SCOPED CSS */
const StyledPage = styled.div`
    .pre-loader-img.pre-enrollment {
        height: 100%;
    }

    .pre-loader-img.pre-enrollment.pre-loader {
        height: calc(100vh - 172px);
    }

    .pre-loader-img {
        text-align: center;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        /* position: fixed; */
        top: 0;
        right: 0;
        background: var(--backgrounds-lines-n-600);
        left: 0;
        z-index: 9999;
    }

    .maintain-height {
        position: relative;
        height: 85px;
        width: 85px;
    }

    .pre-loader-img img.loader-boicon {
        width: 35px;
        position: absolute;
        top: 20px;
        right: 25px;
    }

    .pre-loader-img img {
        width: 75px;
    }
`;
/* #endregion */

/**
 * preLoader component.
 *
 * @param {Object} props - The component props.
 * @param {string} props.className - Additional CSS class name for the component.
 * @returns {JSX.Element} The Loader component.
 */
export default function PreLoader(props) {
    return (
        <StyledPage className={`${props?.className || ""}`}>
            <div className="pre-loader-img pre-enrollment pre-loader">
                <div className="maintain-height">
                    {/* <img className="loader-boicon" src={LoaderIcon} alt="loader" /> */}
                    <img src={LoaderSvg} alt="loader" />
                </div>
            </div>
        </StyledPage>
    );
}
