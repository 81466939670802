import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import EditPen from "../../../../assets/images/edit-new-pen.svg";
import ArrowUpRight from "../../../../assets/images/arror-up-right.svg";
import LayoutContext from "../../../../contexts/LayoutContext";
/* #region STYLED CSS */
const StyledPage = styled.div`
    &.frame-313547,
    &.frame-313547 * {
        box-sizing: border-box;
    }
    &.frame-313547 {
        background: #151515;
        border-radius: 5px;
        border-style: solid;
        border-color: var(--border-border-primary, rgba(166, 166, 166, 0.2));
        border-width: 1px;
        padding: 16px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-295922834 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .product-name {
        color: var(--text-text-primary, #ffffff);
        text-align: left;
        font-family: var(--caption-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--caption-font-size, 12px);
        line-height: var(--caption-line-height, 20px);
        font-weight: var(--caption-font-weight, 400);
        position: relative;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .arrow-narrow-up-right {
        flex-shrink: 0;
        width: 12px;
        height: 12px;
        position: relative;
        overflow: visible;
    }
    .product-name2 {
        color: var(--text-text-success, #69c23f);
        text-align: left;
        font-family: var(--caption-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--caption-font-size, 12px);
        line-height: var(--caption-line-height, 20px);
        font-weight: var(--caption-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        cursor: pointer;
    }
    .line-31 {
        margin-top: -1px;
        border-style: solid;
        border-color: var(--border-border-primary, rgba(166, 166, 166, 0.2));
        border-width: 1px 0 0 0;
        align-self: stretch;
        flex-shrink: 0;
        height: 0px;
        position: relative;
    }
    .frame-295922843 {
        display: flex;
        flex-direction: row;
        gap: 24px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .product-name3 {
        text-align: left;
        font-family: var(--caption-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--caption-font-size, 12px);
        line-height: var(--caption-line-height, 20px);
        font-weight: var(--caption-font-weight, 400);
        position: relative;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .product-name-3-span {
        color: var(--text-text-secondary, #8c8c8c);
        font-family: var(--caption-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--caption-font-size, 12px);
        line-height: var(--caption-line-height, 20px);
        font-weight: var(--caption-font-weight, 400);
    }
    .product-name-3-span2 {
        color: var(--text-text-primary, #ffffff);
        font-family: var(--caption-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--caption-font-size, 12px);
        line-height: var(--caption-line-height, 20px);
        font-weight: var(--caption-font-weight, 400);
    }
    .frame-295922841 {
        opacity: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-295922812 {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: flex-end;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313385 {
        display: flex;
        flex-direction: column;
        gap: 36px;
        align-items: flex-end;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313378 {
        display: flex;
        flex-direction: row;
        gap: 6px;
        align-items: center;
        justify-content: flex-start;
        flex-shrink: 0;
        position: relative;
    }
    .icon {
        border-radius: 0px;
        flex-shrink: 0;
        width: 8px;
        height: 8px;
        position: relative;
        overflow: visible;
    }
    .edit {
        color: var(--text-text-brand, #f7665f);
        text-align: left;
        font-family: var(--caption-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--caption-font-size, 12px);
        line-height: var(--caption-line-height, 20px);
        font-weight: var(--caption-font-weight, 400);
        position: relative;
    }
`;

export default function PickupCard({ storeInfo, placeOrderLoading }) {
    /*const storeAddress = `${storeInfo?.storeDetails?.address || ""}, ${storeInfo?.storeDetails?.postalCode || ""} ${
        storeInfo?.storeDetails?.city || ""
    }, ${storeInfo?.storeDetails?.country || ""}`;*/
    const storeInformation = storeInfo?.storeDetails;
    const storeAddress = `${storeInformation?.address} ${storeInformation?.addressLine2 ? storeInformation?.addressLine2 : ""} ${
        storeInformation?.addressNumber ? storeInformation?.addressNumber : ""
    }, ${storeInformation?.postalCode} ${storeInformation?.city}, ${storeInformation?.country}`;
    const { setShowStoreInformation, setStoreInformation } = useContext(LayoutContext);
    const showStoreinfoFunc = storeDetails => {
        setShowStoreInformation(true);
        setStoreInformation(storeDetails);
    };
    return (
        <StyledPage className="frame-313547">
            <div className="frame-295922834">
                <div className="product-name">Pick-up at {storeInfo?.storeName}</div>
                <img className="arrow-narrow-up-right" src={ArrowUpRight} alt="" style={{ opacity: placeOrderLoading ? "0.3" : "1" }} />
                <div
                    className="product-name2"
                    onClick={e => (placeOrderLoading ? e.preventDefault() : showStoreinfoFunc(storeInfo?.storeDetails))}
                    style={{ opacity: placeOrderLoading ? "0.3" : "1", cursor: placeOrderLoading ? "no-drop" : "pointer" }}>
                    Opening hours
                </div>
            </div>
            <div className="line-31"></div>
            <div className="frame-295922843">
                <div className="product-name3">
                    <span>
                        <span className="product-name-3-span">
                            Store address:
                            <br />
                        </span>
                        <span className="product-name-3-span2">{storeAddress}</span>
                    </span>
                </div>
                <div className="frame-295922841">
                    <div className="frame-295922812">
                        <div className="frame-313385">
                            <div className="frame-313378">
                                <img className="icon" src={EditPen} alt="" />
                                <div className="edit">Edit</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </StyledPage>
    );
}
