import React, { useEffect, useState, useRef } from "react";
import { useDraggable } from "react-use-draggable-scroll";
import styled from "styled-components";
import Pen from "../../../assets/images/suggest-pen.svg";
import Skeleton from "../../common/skeletons/Skeleton";
import LayoutContext from "../../../contexts/LayoutContext";
import * as Library from "../../../utils/Library";
import AppContext from "../../../contexts/AppContext";
import { useNavigate } from "react-router-dom";
/* #region CSS */
const StyledPage = styled.div`
    &.frame-295922555,
    &.frame-295922555 * {
        box-sizing: border-box;
    }
    &.frame-295922555 {
        display: flex;
        flex-direction: column;
        gap: 32px;
        align-items: flex-start;
        justify-content: flex-start;
        position: relative;
    }

    .frame-313729 {
        display: flex;
        flex-direction: column;
        gap: 32px;
        align-items: flex-start;
        justify-content: flex-start;
        flex-shrink: 0;
        width: 100%;
        position: relative;
        overflow: hidden;
    }
    .frame-313730 {
        display: flex;
        flex-direction: row;
        gap: 32px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313706 {
        display: flex;
        flex-direction: row;
        gap: 13px;
        align-items: flex-start;
        justify-content: flex-start;
        flex: 1;
        position: relative;
        padding: 0px 15px;
    }
    .shop-by-brands {
        color: #ffffff;
        text-align: center;
        font-family: var(--heading-1-font-family, "OpenSans-Bold", sans-serif);
        font-size: var(--heading-1-font-size, 24px);
        line-height: var(--heading-1-line-height, 24px);
        font-weight: var(--heading-1-font-weight, 700);
        position: relative;
    }
    .frame-3137292 {
        display: flex;
        flex-direction: row;
        gap: 13px;
        align-items: flex-start;
        justify-content: flex-start;
        flex-shrink: 0;
        width: 63px;
        height: 24px;
        position: relative;
    }
    .frame-295922410 {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        height: 274px;
        position: relative;
        overflow-x: scroll;
        padding: 0px 15px;
    }
    .frame-295922410.mobile {
        height: 115px;
    }
    .frame-295922562 {
        background: linear-gradient(to left, rgba(0, 0, 0, 0.68), rgba(0, 0, 0, 0.68));
        border-radius: 16px;
        padding: 32px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex: 1;
        position: absolute;
        cursor: pointer;
        width: 456px;
    }
    .frame-295922562.mobile {
        width: 178px;
    }
    .hoverOnDesktop {
        opacity: 100%;
    }
    .hoverOnDesktopOpacity {
        opacity: 40%;
        cursor: pointer !important;
    }
    .hoverOnDesktop:active {
        cursor: grabbing !important;
    }
    .frame-313691 {
        padding: 32px;
        display: flex;
        flex-direction: column;
        gap: 32px;
        align-items: center;
        justify-content: center;
        // align-self: stretch;
        flex-shrink: 0;
        height: 134px;
        position: relative;
        width: 395px;
    }
    .frame-313691.mobile {
        width: 195px;
        height: 115px;
    }
    .gant-2-1 {
        width: 100%;
        -webkit-user-drag: none; /* Safari and Chrome */
        -khtml-user-drag: none; /* Konqueror */
        -moz-user-drag: none; /* Firefox */
        -o-user-drag: none; /* Opera */
        user-drag: none;
    }
    .frame-313698 {
        background: linear-gradient(to left, rgba(0, 0, 0, 0.68), rgba(0, 0, 0, 0.68));
        border-radius: 16px;
        padding: 32px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex: 1;
        position: relative;
    }
    .frame-3136912 {
        padding: 32px;
        display: flex;
        flex-direction: column;
        gap: 12px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        height: 134px;
        position: relative;
        width: 395px;
    }
    .frame-313700 {
        background: linear-gradient(to left, rgba(0, 0, 0, 0.68), rgba(0, 0, 0, 0.68));
        border-radius: 16px;
        padding: 32px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex: 1;
        position: relative;
    }
    .frame-295922561 {
        background: var(--color-emphasis, #9cf9cf);
        border-radius: 16px;
        padding: 32px 24px 32px 24px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex: 1;
        position: relative;
    }
    .frame-295922559.mobile {
        width: 178px;
    }
    .frame-295922559 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        // align-self: stretch;
        flex-shrink: 0;
        position: relative;
        width: 395px;
    }
    .more-brands-coming-soon {
        color: var(--color-gray-700, #000000);
        text-align: center;
        font-family: "ItcAvantGardeGothicProDemi-Regular", sans-serif;
        font-size: 32px;
        line-height: 32px;
        font-weight: 600;
        position: relative;
        align-self: stretch;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .more-brands-coming-soon.mobile {
        font-size: 16px;
        line-height: 16px;
    }
    .btn-lg-solid {
        background: var(--buttons-button-secondary, #212121);
        border-radius: 30px;
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 206.51px;
        height: 46.08px;
        position: relative;
        box-shadow: 0px 28.8px 40px 0px rgba(83, 188, 141, 1);
        cursor: pointer;
    }
    .btn-lg-solid.mobile {
        width: 156.51px;
        height: 36.08px;
    }
    .frame-29590 {
        display: flex;
        flex-direction: row;
        gap: 7.68px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .pencil-line {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        position: relative;
        overflow: visible;
    }
    .pencil-line.mobile {
        width: 14px;
        height: 14px;
    }
    .suggest-a-brand {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-family: "OpenSans-SemiBold", sans-serif;
        font-size: 13.440001487731934px;
        line-height: 23.04px;
        font-weight: 600;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .suggest-a-brand.mobile {
        font-size: 10.440001487731934px;
    }
    .chips {
        background: #9cf9cf;
        border-radius: var(--corner-radius-pill, 30px);
        padding: var(--spacings-sm, 8px) var(--spacings-md, 12px) var(--spacings-sm, 8px) var(--spacings-md, 12px);
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 115px;
        height: 32px;
        position: absolute;
        right: 12px;
        top: 12px;
        backdrop-filter: blur(5px);
    }
    .frame-29614 {
        display: flex;
        flex-direction: row;
        gap: 4.8px;
        row-gap: 0px;
        align-items: center;
        justify-content: flex-start;
        flex-shrink: 0;
        position: relative;
    }
    .coming-soon {
        color: var(--color-gray-700, #000000);
        text-align: left;
        font-family: var(--label-labeltext-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--label-labeltext-font-size, 14px);
        line-height: var(--label-labeltext-line-height, 17px);
        font-weight: var(--label-labeltext-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .brands-container {
        display: flex;
        gap: 16px;
        padding: 0px 15px;
    }
`;
/* #endregion CSS */
export default function ShopByBrand() {
    /* #region Context */

    const { isMobile, setShowBOImagePageID, setShowRightUserLocationPanel, setShowSuggestPopup } = React.useContext(LayoutContext);
    const { setSelectedBrandID, setBOTagsList } = React.useContext(AppContext);
    /* #endregion Context */

    /* #region States */
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const images = [1, 2, 3, 4];
    const [hoverEffect, setHoverEffect] = useState({ state: false, id: "" });

    /* #endregion States*/

    /* #region Methods */
    const ref = useRef();
    const { events } = useDraggable(ref, {
        applyRubberBandEffect: true
    });
    const navigate = useNavigate();

    const getImages = () => {
        Library.makePostRequest("getHomeBrands", "-", false, setIsLoading)
            .then(res => {
                const { data } = res;

                if (res?.status === 200) {
                    setData(data?.data);
                }
            })
            .catch(e => {
                console.log(e);
            });
    };

    const handleNavigateToBrandFeed = brandID => {
        navigate("/feed");
        setSelectedBrandID(brandID);
        setBOTagsList([]);
        setShowBOImagePageID("");
        setShowRightUserLocationPanel(false);
    };
    /* #endregion Methods */
    /* #region EFFECTS */
    useEffect(() => {
        getImages();
    }, []);
    /* #endregion EFFECTS */
    return (
        <StyledPage className="frame-295922555">
            <div className="frame-313729">
                <div className="frame-313730">
                    <div className="frame-313706">
                        <div className="shop-by-brands">Shop by brands</div>
                    </div>
                    <div className="frame-3137292"></div>
                </div>
                {isLoading ? (
                    <div className="brands-container">
                        {images.map(i => (
                            <Skeleton width={isMobile ? 195 : 450} height={isMobile ? 134 : 250} style={{ borderRadius: "20px" }} />
                        ))}
                    </div>
                ) : (
                    <div className={isMobile ? "frame-295922410 mobile" : "frame-295922410"} {...events} ref={ref}>
                        {data.slice(0, 3).map(i => {
                            return (
                                <>
                                    <div
                                        className={`frame-295922562 ${isMobile ? "mobile" : ""}`}
                                        onClick={e => (i?.status === "Inactive" ? e.preventDefault() : handleNavigateToBrandFeed(i?._id))}
                                        style={{
                                            backgroundImage: `url(${i?.BOtagHomeURL})`,
                                            backgroundSize: "cover",
                                            backgroundRepeat: "no-repeat",
                                            backgroundPosition: "center",
                                            position: "relative"
                                        }}>
                                        {i?.status === "Inactive" ? (
                                            <div
                                                onMouseEnter={() => {
                                                    setHoverEffect({
                                                        state: true,
                                                        id: i?._id
                                                    });
                                                }}
                                                onMouseLeave={() => {
                                                    setHoverEffect({
                                                        state: false,
                                                        id: i?._id
                                                    });
                                                }}
                                                style={{
                                                    padding: "5px 8px",
                                                    position: "absolute",
                                                    top: "10px",
                                                    right: "10px",
                                                    backgroundColor: "#9cf9cf",
                                                    borderRadius: "20px",
                                                    color: "black",
                                                    opacity: "100% !important",
                                                    zIndex: 1,
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    width: isMobile ? "75px" : "115px",
                                                    height: isMobile ? "21.12px" : "32px",
                                                    fontSize: isMobile ? "9.22px" : "14px"
                                                }}>
                                                Coming soon!
                                            </div>
                                        ) : null}
                                        <div
                                            onMouseEnter={() => {
                                                setHoverEffect({ state: true, id: i?._id });
                                            }}
                                            onMouseLeave={() => {
                                                setHoverEffect({
                                                    state: false,
                                                    id: i?._id
                                                });
                                            }}
                                            className={`frame-295922562 ${isMobile ? "" : "hoverOnDesktop"} 
                                            ${!isMobile && hoverEffect?.state && hoverEffect?.id === i?._id && "hoverOnDesktopOpacity"}
                                            `}
                                            style={{ width: "100%", height: "100%", left: "0", zIndex: 0 }}></div>
                                        <div
                                            onMouseEnter={() => {
                                                setHoverEffect({
                                                    state: true,
                                                    id: i?._id
                                                });
                                            }}
                                            onMouseLeave={() => {
                                                setHoverEffect({
                                                    state: false,
                                                    id: i?._id
                                                });
                                            }}
                                            className={`frame-313691 ${isMobile ? "mobile" : ""}`}>
                                            <img
                                                className="gant-2-1"
                                                src={i?.mainLogoURL}
                                                alt=""
                                                style={{
                                                    height: isMobile ? "50px" : "90px",
                                                    objectFit: "cover"
                                                }}
                                            />
                                        </div>
                                    </div>
                                </>
                            );
                        })}

                        <div className={"frame-295922561"}>
                            <div className={`frame-295922559 ${isMobile ? "mobile" : ""}`}>
                                <div className={`more-brands-coming-soon ${isMobile ? "mobile" : ""}`}>
                                    More brands
                                    <br />
                                    coming soon!
                                </div>
                                <div className={`btn-lg-solid ${isMobile ? "mobile" : ""}`} onClick={() => setShowSuggestPopup(true)}>
                                    <div className="frame-29590">
                                        <img className={`pencil-line ${isMobile ? "mobile" : ""}`} src={Pen} alt="" />
                                        <div className={`suggest-a-brand ${isMobile ? "mobile" : ""}`}>Suggest a brand</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </StyledPage>
    );
}
