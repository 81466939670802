import React, { useState } from "react";
import styled from "styled-components";
import * as Library from "./../../utils/Library";
import LayoutContext from "../../contexts/LayoutContext";
import logGA4Event from "../../utils/firebase/analytics";

const StyledPage = styled.div`
    main {
        width: 100vw;
        height: 100dvh;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    /*Password Page*/
    .passwordPageCom {
        background: #000000;
        z-index: 999;
    }
    .passHeader {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .loginInput .form-control {
        border: 1px solid #303030;
        border-radius: 0;
        background: #212121;
        height: 40px;
        width: 259px;
        color: #fff;
        font-size: 14px;
        font-weight: 400;
    }
    .loginDivItem {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
    }
    .loginInput {
        margin: auto;
        text-align: center;
        position: relative;
    }
    .loginInput .form-control::placeholder {
        color: #fff;
    }
    .loginInput .form-control:focus {
        box-shadow: none;
    }
    .loginInput .form-control:focus-visible {
        outline: none;
    }
    button.letsBoPassword {
        position: relative;
        padding: 0 50px 0 20px;
        line-height: 50px;
        position: relative;
        display: inline-block;
        color: #ffffff;
        background: none;
        border: none;
        margin-top: 40px;
    }
    .letsBoPassword::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        background: transparent;
        border: 1px solid #ea4020;
        border-right: 1px solid transparent;
        transition: ease all 0.35s;
        width: 50px;
        height: 50px;
    }
    .letsBoPassword:hover {
        color: #fff;
    }
    .letsBoPassword:hover::before {
        background: #ea4020;
        width: 100%;
        border-color: #ea4020;
    }
    .btnNames {
        font-size: 14px;
        font-weight: 500;
        text-transform: uppercase;
        position: relative;
        z-index: 1;
    }
    .arrowmyin {
        width: 13px;
        height: 2px;
        background: currentColor;
        display: inline-block;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        right: 25px;
    }
    .arrowmyin::after {
        width: 8px;
        height: 8px;
        border-right: 2px solid currentColor;
        border-top: 2px solid currentColor;
        content: "";
        position: absolute;
        top: -3px;
        right: 0;
        display: inline-block;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
    }
    .form-control {
        display: block;
        width: 100%;
        height: 34px;
        /* padding: 6px 12px; */
        font-size: 14px;
        line-height: 1.42857143;
        color: #555;
        background-color: #fff;
        background-image: none;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    }
    .form-control {
        display: block;
        width: 100%;
        height: calc(1.5em + 0.75rem + 2px);
        padding: 0 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
    .loginInput .form-control.error {
        border-color: red;
    }
    span.loginErrorFields {
        position: absolute;
        width: 100%;
        text-align: left;
        min-width: 364px;
        left: 0;
        color: red;
        font-size: 11px;
        font-weight: 400;
    }
    /*End Paddword Page*/
`;

export default function PasswordPage() {
    /* #region STATES */
    const [password, setPassword] = useState("");
    const [passError, setPassError] = useState(false);
    const [textError, setTextError] = useState(false);
    const [buttonDisable, setButtonDisable] = useState(false);

    const { setShowRightSidebar, setShowHeader, setShowFooter } = React.useContext(LayoutContext);
    /* #endregion */

    /* #region METHODS */

    const handlePassword = e => {
        setPassError(false);
        setTextError(false);
        setPassword(e.target.value);
    };

    const handleValidation = () => {
        let formIsValid = true;
        if (!password) {
            formIsValid = false;
            setPassError(true);
        }
        return formIsValid;
    };

    const handleForm = async () => {
        if (handleValidation()) {
            await checkPasswordRequest(password);
        }
    };

    const handleKeyDown = e => {
        if (e.charCode === 13 || e.keyCode === 13) {
            handleForm();
        }
    };

    const checkPasswordRequest = async pass => {
        const response = await Library.makePostRequest("WebsitePasswordValidation", { password: pass }, false, setButtonDisable);

        if (response && response.data && response.data.status === 200) {
            localStorage.setItem("BOTagCode", pass);
            setShowRightSidebar(true);
            setShowHeader(true);
            setShowFooter(true);
            window.location.href = "/feed";
        } else {
            setPassError(true);
            setTextError(true);
        }
    };
    /* #endregion */

    /* #region EFFECTS */

    React.useEffect(() => {
        setShowRightSidebar(false);
        setShowHeader(false);
        setShowFooter(false);
    }, []);

    /* #endregion */

    return (
        <StyledPage>
            <main>
                <div className="passwordPageCom">
                    <div className="loginDivItem">
                        <div className="loginInput">
                            <input
                                onClick={() => logGA4Event("Password_PopUp")}
                                type="password"
                                className={passError ? "form-control error" : "form-control"}
                                placeholder="Enter password"
                                onChange={handlePassword}
                                value={password}
                                onKeyDown={handleKeyDown}
                            />

                            {textError && (
                                <span className="loginErrorFields">Incorrect password. Calling 911 (just kidding, try again)</span>
                            )}

                            <button className="letsBoPassword" type="submit" onClick={handleForm} disabled={buttonDisable}>
                                <span className="btnNames">LET'S BO!</span>
                                <i className="arrowmyin"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </main>
        </StyledPage>
    );
}
