import { Box } from "@mui/material";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Skeleton from "./Skeleton";
import React from "react";
import LayoutContext from "../../../contexts/LayoutContext";

const skeletonItems = [
    { height: 650 },
    { height: 350 },
    { height: 450 },
    { height: 550 },
    { height: 650 },
    { height: 550 },
    { height: 450 },
    { height: 650 }
];

export default function FeedSkeleton() {
    const { isMobile } = React.useContext(LayoutContext);
    return (
        <Box className="MasonryContainer">
            <ImageList variant="masonry" cols={isMobile ? 1 : 4} gap={14} style={{ marginTop: "0" }}>
                {skeletonItems.map((item, index) => (
                    <ImageListItem key={index} style={{ cursor: "pointer" }}>
                        <Skeleton width={"100%"} height={item.height} style={{ borderRadius: "30px" }} />
                    </ImageListItem>
                ))}
            </ImageList>
        </Box>
    );
}
