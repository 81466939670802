import React from "react";
import styled from "styled-components";
import LayoutContext from "../../../contexts/LayoutContext";
import MenuEdit from "../../../assets/images/menu-edit.svg";
import MenuLogout from "../../../assets/images/menu-logout.svg";
import MenuSettings from "../../../assets/images/menu-settings.svg";
import MenuProfile from "../../../assets/images/menu-profile.svg";
import MenuOrders from "../../../assets/images/menu-orders.svg";
import UserContext from "../../../contexts/UserContext";
import packageJson from "../../../../package.json";
import logGA4Event from "../../../utils/firebase/analytics";

/* #region STYLED CSS */
const StyledPage = styled.div`
    &.menu-panel-container {
        position: absolute;
        top: 46px;
        right: 24px;
        z-index: 9999;
    }

    .property-1-default,
    .property-1-default * {
        box-sizing: border-box;
    }
    .property-1-default {
        background: var(--surface-surface-secondary, #121212);
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        gap: 0px;
        align-items: flex-start;
        justify-content: flex-start;
        position: relative;
        box-shadow: var(--dropshadow-pop-up2-box-shadow, 4px 4px 87.4px 0px rgba(0, 0, 0, 1));
        width: 305px;
    }
    .frame-313243 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        height: 58px;
        position: relative;
    }
    /*.frame-313243:hover {
        background: var(--border-border-quaternary);
    }*/
    .frame-313241 {
        padding: 0px 16px 0px 16px;
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;

        margin-top: 16px;
    }
    .frame-313242 {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .group-3414 {
        flex-shrink: 0;
        width: 26px;
        height: 26px;
        position: relative;
        overflow: visible;
    }
    .morten-bothebot-com {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: left;
        font-size: 14px;
        font-weight: 600;
        position: relative;
        flex: 1;
    }
    .frame-313451 {
        background: var(--buttons-button-secondary, #212121);
        border-radius: 51px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        flex-shrink: 0;
        width: 26px;
        height: 26px;
        position: relative;
    }
    .edit-02 {
        flex-shrink: 0;
        width: 26px;
        height: 12px;
        position: relative;
        overflow: visible;
    }
    .separator {
        display: flex;
        flex-direction: row;
        gap: 6px;
        align-items: center;
        border-color: var(--border-border-tertiary, #373737);
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        height: auto;
        position: relative;
        overflow: visible;
        width: 100%;
        margin: 0px;
    }
    .menu-panel-element {
        padding: 0px 16px 0px 16px;
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        height: 50px;
        position: relative;
        cursor: pointer;
    }
    .menu-panel-element:hover {
        background: var(--border-border-quaternary);
    }
    .frame-313247 {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .frame-313244 {
        padding: 10px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 24px;
        height: 24px;
        position: relative;
    }
    .tag-02 {
        flex-shrink: 0;
        width: 20px;
        height: 20px;
        position: relative;
        overflow: visible;
    }
    .orders {
        color: var(--text-text-primary, #ffffff);
        text-align: left;
        font-family: var(--subtitle-2-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 14px);
        line-height: var(--subtitle-2-line-height, 24px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
        flex: 1;
    }
    .settings-02 {
        flex-shrink: 0;
        width: 20px;
        height: 20px;
        position: relative;
        overflow: visible;
    }
    .settings {
        color: var(--text-text-primary, #ffffff);
        text-align: left;
        font-family: var(--subtitle-2-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 14px);
        line-height: var(--subtitle-2-line-height, 24px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
    }
    .log-out-01 {
        flex-shrink: 0;
        width: 20px;
        height: 20px;
        position: relative;
        overflow: visible;
    }
    .log-out {
        color: var(--text-text-primary, #ffffff);
        text-align: left;
        font-family: var(--subtitle-2-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 14px);
        line-height: var(--subtitle-2-line-height, 24px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
        flex: 1;
    }
    .version-1-2 {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: left;
        font-family: var(--subtitle-2-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 14px);
        line-height: var(--subtitle-2-line-height, 24px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
    }
`;
/* #endregion */

/**
 * The MenuPanel component is a common component that renders a menu panel.
 *
 * @param {object} props - The props of the component.
 * @param {function} props.onLogout - The function to call when the user logs out.
 * @returns {JSX.Element} - The JSX element of the component.
 */
export default function MenuPanel(props) {
    /* #region VARS */
    //Contexts
    const { userData } = React.useContext(UserContext);
    const {
        setShowRightMyOrdersPanel,
        showMenuPanel,
        setShowMenuPanel,
        setShowRightSettingsPanel,
        setShowYourAccountPannel
    } = React.useContext(LayoutContext);

    /* #endregion */

    /* #region EFFECTS */
    React.useEffect(() => {
        if (showMenuPanel) {
            setTimeout(() => {
                document.addEventListener("click", onClickOutside);
            }, 100);
        } else {
            document.removeEventListener("click", onClickOutside);
        }

        return () => {
            document.removeEventListener("click", onClickOutside);
        };
    }, [showMenuPanel]);
    /* #endregion */

    /* #region METHODS */
    const onClickOutside = () => {
        setShowMenuPanel(false);
    };

    const onClickProfile = () => {
        //TODO: IMPLEMENT ACTION
    };

    const onClickOrders = () => {
        setShowRightMyOrdersPanel(true);
    };

    const onClickSettings = () => {
        logGA4Event("Setting");
        setShowRightSettingsPanel(true);
    };

    const onClickLogout = () => {
        props?.onLogout();
        logGA4Event("Logout");
    };
    const handleYourAccountFunction = () => {
        setShowYourAccountPannel(true);
        setShowRightSettingsPanel(false);
        logGA4Event("Account");
    };

    /* #endregion */

    return (
        <StyledPage className="menu-panel-container">
            <div className="property-1-default">
                <div className="frame-313243 cursor-pointer" onClick={onClickProfile}>
                    <div className="frame-313241">
                        <div className="frame-313242">
                            <img className="group-3414" src={MenuProfile} alt="Profile" />
                            <div className="morten-bothebot-com">{userData?.email}</div>
                        </div>
                        <div className="frame-313451" onClick={handleYourAccountFunction}>
                            <img className="edit-02" src={MenuEdit} alt="Edit" />
                        </div>
                    </div>
                    <hr className="separator" />
                </div>
                <div className="menu-panel-element" onClick={onClickOrders}>
                    <div className="frame-313247">
                        <div className="frame-313244">
                            <img className="tag-02" src={MenuOrders} alt="Orders" />
                        </div>
                        <div className="orders">Orders</div>
                    </div>
                </div>
                <div className="menu-panel-element" onClick={onClickSettings}>
                    <div className="frame-313247">
                        <div className="frame-313244">
                            <img className="settings-02" src={MenuSettings} alt="Settings" />
                        </div>
                        <div className="settings">Settings</div>
                    </div>
                </div>
                <div className="menu-panel-element" onClick={onClickLogout}>
                    <div className="frame-313247">
                        <div className="frame-313244">
                            <img className="log-out-01" src={MenuLogout} alt="Log out" />
                        </div>
                        <div className="log-out">Log out</div>
                        <div className="version-1-2">Version {packageJson.version}</div>
                    </div>
                </div>
            </div>
        </StyledPage>
    );
}
