import { useState, useEffect } from "react";

const useScrollEnd = (ref, offset = 0) => {
    const [isAtEnd, setIsAtEnd] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (!ref.current) return;

            const { scrollTop, scrollHeight, clientHeight } = ref.current;
            const isAtEnd = scrollTop + clientHeight >= scrollHeight - offset;

            setIsAtEnd(isAtEnd);
        };

        const element = ref.current;
        if (element) {
            element.addEventListener("scroll", handleScroll);
            handleScroll();
        }

        return () => {
            if (element) {
                element.removeEventListener("scroll", handleScroll);
            }
        };
    }, [ref, offset]);

    return isAtEnd;
};

export default useScrollEnd;
