import React, { useEffect } from "react";
import styled from "styled-components";
import ShopByBrand from "./ShopByBrand";
import Welcome from "./Welcome";
import PopularBrands from "./PopularBrands";
import Newest from "./Newest";
import PhysicalStores from "./PhysicalStores";
import Footer from "./Footer";
import LayoutContext from "./../../../contexts/LayoutContext";
/* #region CSS */
const StyledPage = styled.div`
    &.main,
    &.main * {
        box-sizing: border-box;
    }
    &.main {
        height: 100%;
        width: 100%;
        overflow: scroll;
    }
    .main-home-container {
        display: flex;
        flex-direction: column;
        gap: 32px;
    }
`;
/* #endregion CSS */
export default function Index() {
    const { setShowFooter, setHomeLogo, setFeedLogo } = React.useContext(LayoutContext);
    useEffect(() => {
        setShowFooter(false);
        setHomeLogo(true);
        setFeedLogo(false);
    }, []);
    return (
        <StyledPage className="main">
            <div className="main-home-container">
                <Welcome />
                <ShopByBrand />
                {/*<PopularBrands />*/}
                <Newest />
                <PhysicalStores />
                <Footer />
            </div>
        </StyledPage>
    );
}
