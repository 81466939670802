import React, { useState } from "react";
import styled from "styled-components";
import { Tooltip } from "antd";
import GreyInfo from "../../../../assets/images/grey-info.svg";
const SizePickerStyled = styled.div`
    &.frame-313511_2,
    &.frame-313511_2 * {
        box-sizing: border-box;
    }
    &.frame-313511_2 {
        display: flex;
        flex-direction: column;
        height: 195px;
        gap: 16px;
        align-items: flex-start;
        //justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313500_2 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        //justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313499_2 {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
        //justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
        height: 142px;
    }
    .frame-313324_2 {
        padding: 0px 15px 0px 15px;
        display: flex;
        flex-direction: column;
        /*gap: 10px;*/
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
        /*height: 20px;*/
    }
    .color-not-found {
        display: flex;
        font-size: 10px;
        justify-content: center;
        align-items: center;
        color: #8c8c8c;
        width: 100%;
    }
    .size-text {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-size: 14px;
        font-weight: 600;
        position: relative;
        width: 204px;
        height: 20px;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    .frame-313511_22 {
        padding: 0px 15px 0px 15px;
        display: flex;
        flex-direction: column;
        gap: 14px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313508 {
        display: flex;
        flex-direction: row;
        gap: 10px;
        row-gap: 14px;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
        height: 85px;
    }

    .size-circle {
        flex-shrink: 0;
        width: 36px;
        height: 36px;
        position: relative;
    }
    .size-circle:hover {
        cursor: pointer;
    }
    .size-circle.unavailable:hover {
        cursor: not-allowed;
    }
    .size-circle.active .ellipse-size {
        background: var(--buttons-button-secondary, #212121);
        border-radius: 50%;
        border-style: solid;
        border-color: var(--border-border-white, #ffffff);
        border-width: 2px;
        width: 100%;
        height: 100%;
        position: absolute;
        right: 0%;
        left: 0%;
        bottom: 0%;
        top: 0%;
    }
    .size {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-size: 10px;
        font-weight: 400;
        position: absolute;
        right: 8.33%;
        left: 8.33%;
        width: 83.33%;
        bottom: 25.6%;
        top: 23.02%;
        height: 51.39%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .ellipse-size {
        background: var(--buttons-button-secondary, #212121);
        border-radius: 50%;
        width: 100%;
        height: 100%;
        position: absolute;
        right: 0%;
        left: 0%;
        bottom: 0%;
        top: 0%;
    }
    .size-circle.unavailable .ellipse-size {
        background: var(--surface-surface-overlay, rgba(13, 13, 13, 0.8));
        border-radius: 50%;
        border-style: solid;
        border-color: var(--border-border-tertiary, #373737);
        border-width: 1px;
        width: 100%;
        height: 100%;
        position: absolute;
        right: 0%;
        left: 0%;
        bottom: 0%;
        top: 0%;
    }

    .size-circle.unavailable.active .ellipse-size {
        border-color: #ffffff;
        border-width: 2px;
    }

    .size-circle.unavailable .size {
        color: var(--text-text-secondary, #8c8c8c);
    }
    .size-circle .line-32 {
        display: none;
    }
    .size-circle.unavailable .line-32 {
        display: block;
        border-style: solid;
        border-color: var(--border-border-tertiary, #373737);
        border-width: 1px 0 0 0;
        width: 102.14%;
        height: 0%;
        position: absolute;
        right: -16.03%;
        left: 13.89%;
        bottom: 86.11%;
        top: 13.89%;
        transform-origin: 0 0;
        transform: rotate(45deg) scale(1, 1);
    }
    .frame-313513 {
        display: flex;
        flex-direction: column;
        //gap: 18px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313512 {
        opacity: 1;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .pagination-ellipses {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: flex-start;
        justify-content: flex-start;
        flex-shrink: 0;
        position: relative;
    }
    .ellipse-page.active {
        background: var(--buttons-button-primary, #f7665f);
        border-radius: 50%;
        flex-shrink: 0;
        width: 8px;
        height: 8px;
        position: relative;
    }
    .ellipse-page {
        background: var(--buttons-button-secondary, #212121);
        border-radius: 50%;
        flex-shrink: 0;
        width: 8px;
        height: 8px;
        position: relative;
    }
    .frame-3135132 {
        padding: 0px 14px 0px 14px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-3135034 {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .btn-fw-botag_2 {
        background: var(--buttons-button-secondary, #212121);
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex: 1;
        height: 33px;
        position: relative;
        box-shadow: var(--bt-drop-shadow-box-shadow, 0px 0px 4px 0px rgba(30, 38, 49, 0.35));
    }
    .frame-313497 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313349 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .button {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-size: var(--button-text-small-font-size, 12px);
        line-height: var(--button-text-small-line-height, 16px);
        font-weight: var(--button-text-small-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .btn-fw-botag2_2 {
        background: var(--buttons-button-primary, #f7665f);
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex: 1;
        height: 33px;
        position: relative;
        box-shadow: var(--bt-drop-shadow-box-shadow, 0px 0px 4px 0px rgba(30, 38, 49, 0.35));
    }
    .button2 {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

export default function BOTagBodySelectSize({
    sizes,
    selectedSize,
    setSelectedSize,
    setShowSizeSelection,
    setShowDeliveryMethodSelection,
    stockData,
    selectedNewSize,
    setSelectedNewSize,
    setSelectedColor,
    selectedNewColor,
    setSelectedQuantity,
    selectedNewQuantity,
    botagData
}) {
    const [page, setPage] = useState(1);
    const limit = 8;
    const sizesSubset = sizes?.slice((page - 1) * limit, page * limit);
    const numberOfPages = Math.ceil(sizes?.length / limit);
    const currentPage = page;
    const [showNotFoundMsg, setShowNotFoundMsg] = useState(false);
    const [notFoundMsg, setNotFoundMsg] = useState(`Size ${selectedNewSize} is not in stock in any color`);
    const sizeHasStock = size => {
        if (!stockData) return false;
        return stockData?.some(stock => stock.size === size && stock.totalStock > 0);
    };
    function checkSizeAndColor(arr, selectedSize, selecetedColor) {
        return arr.some(item => item.size === selectedSize && item.colorCode === selecetedColor.replace("#", ""));
    }
    return (
        <SizePickerStyled className="frame-313511_2">
            <div className="frame-313500_2">
                <div className="frame-313499_2">
                    <div className="frame-313324_2">
                        <div className="size-text">{selectedNewSize}</div>
                        {showNotFoundMsg ? (
                            <div className="color-not-found">
                                <img src={GreyInfo} alt="GreyInfo" />
                                &nbsp; {notFoundMsg}
                            </div>
                        ) : (
                            <div className="color-not-found">&nbsp;</div>
                        )}
                    </div>
                    <div className="frame-313511_22">
                        <div className="frame-313508">
                            {sizesSubset.map(size => (
                                <Tooltip placement="top" title={size.length > 3 ? size : ""} color="#212121">
                                    <div
                                        key={size}
                                        className={`size-circle ${size === selectedNewSize ? "active" : ""} ${
                                            sizeHasStock(size) ? "" : "unavailable"
                                        }`}
                                        onClick={() => {
                                            setSelectedNewSize(size);
                                            if (!sizeHasStock(size)) {
                                                setShowNotFoundMsg(true);
                                                setNotFoundMsg(`Size ${size} is not in stock in any color`);
                                            } else {
                                                setShowNotFoundMsg(false);
                                                setNotFoundMsg("");
                                                if (!checkSizeAndColor(stockData, size, selectedNewColor)) {
                                                    setShowNotFoundMsg(true);
                                                    setNotFoundMsg(`Size ${size} is not in stock in selected color`);
                                                }
                                            }
                                        }}>
                                        <div className="ellipse-size"></div>
                                        {size.length > 3 ? (
                                            <div className="size">{`${size.substring(0, 3)}...`}</div>
                                        ) : (
                                            <>
                                                <div className="size">{size}</div>
                                            </>
                                        )}
                                        {!sizeHasStock(size) && <div className="line-32"></div>}
                                    </div>
                                </Tooltip>
                            ))}
                        </div>
                        <div className="frame-313512">
                            <div className="pagination-ellipses" style={{ height: "8px" }}>
                                {numberOfPages > 1 &&
                                    Array.from({ length: numberOfPages })?.map((_, index) => (
                                        <div
                                            key={index}
                                            className={`ellipse-page cursor-pointer ${currentPage === index + 1 ? "active" : ""}`}
                                            onClick={() => setPage(index + 1)}></div>
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="frame-313513">
                <div className="frame-3135132">
                    <div className="frame-3135034">
                        <div
                            className="btn-fw-botag_2 cursor-pointer"
                            onClick={() => {
                                setSelectedNewSize(botagData?.selectedSize);
                                setSelectedSize(botagData?.selectedSize);
                                setShowSizeSelection(false);
                                setShowDeliveryMethodSelection(true);
                            }}>
                            <div className="frame-313497">
                                <div className="frame-313349">
                                    <div className="button">Clear</div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="btn-fw-botag2_2 cursor-pointer"
                            onClick={e => {
                                if (!showNotFoundMsg) {
                                    setSelectedQuantity(selectedNewQuantity);
                                    setSelectedColor(selectedNewColor);
                                    setSelectedSize(selectedNewSize);
                                    setShowSizeSelection(false);
                                    setShowDeliveryMethodSelection(true);
                                } else {
                                    e.preventDefault();
                                }
                            }}
                            style={
                                showNotFoundMsg
                                    ? {
                                          opacity: 0.2,
                                          cursor: "no-drop"
                                      }
                                    : {}
                            }>
                            <div className="frame-313497">
                                <div className="frame-313349">
                                    <div className="button2">Select</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </SizePickerStyled>
    );
}
