import React, { useState } from "react";
const StandardsContext = React.createContext();

export const StandardsProvider = ({ children }) => {
    /* #region States */

    // Error Dialog
    const [errorDialogShow, setErrorDialogShow] = useState(false); // Show Error Dialog
    const [errorDialogTitle, setErrorDialogTitle] = useState(""); // Error Dialog Title
    const [errorDialogMessage, setErrorDialogMessage] = useState(""); // Error Dialog Message
    const [errorDialogConfirmText, setErrorDialogConfirmText] = useState("OK"); // Error Dialog Confirm Button Text
    const [errorDialogCancelText, setErrorDialogCancelText] = useState("Cancel"); // Error Dialog Cancel Button Text
    const [errorDialogAction, setErrorDialogAction] = useState(""); // Error Dialog Action will be "confirm" or "cancel"

    const [defaultNotificationCard, setDefaultNotificationCard] = useState({
        show: false,
        message: "",
        type: "success", // success, error, warning
        autoClose: 5000
    });
    /* #endregion */

    return (
        <StandardsContext.Provider
            value={{
                // Error Dialog
                errorDialogShow,
                setErrorDialogShow,
                errorDialogTitle,
                setErrorDialogTitle,
                errorDialogMessage,
                setErrorDialogMessage,
                errorDialogConfirmText,
                setErrorDialogConfirmText,
                errorDialogCancelText,
                setErrorDialogCancelText,
                errorDialogAction,
                setErrorDialogAction,

                defaultNotificationCard,
                setDefaultNotificationCard
            }}>
            {children}
        </StandardsContext.Provider>
    );
};

export default StandardsContext;
