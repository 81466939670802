import React, { useEffect } from "react";
import LayoutContext from "../../../contexts/LayoutContext";
import styled from "styled-components";
import * as Library from "../../../utils/Library";
import ImageBeacons from "./ImageBeacons";
import BOTag from "./BOTag";
import AppContext from "../../../contexts/AppContext";
import { UserContext } from "../../../contexts/UserContext";
import ShareLocationPopup from "../../common/dialogs/ShareLocationPopup";
import { fetchAddressFromCoordinates } from "../../../utils/LocationLib";
import { useNavigate } from "react-router-dom";
import logGA4Event from "../../../utils/firebase/analytics";
import { getCoordinates, getLocation, saveCoordinates, saveLocation } from "../../../utils/helperFunctions";

/* #region STYLED CSS */
const StyledPage = styled.div`
    .BOImageContainer {
        width: 100%;
        box-sizing: content-box;
        height: calc(100vh - 140px);
        position: relative;
    }
    .mobile-skeleton {
        height: 404px !important;
    }
    .BoimageCanvas {
        width: 100%;
        height: 100%;
        object-fit: contain;
        flex: 1 1 0;
        //border-radius: 8px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 9px;
        display: flex;
        overflow: hidden;
        position: relative;
        min-height: 350px;
    }

    .TagsContainerElement {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .BOTagContainer {
        align-items: center;
        background-color: #10141b;
        border-radius: 10px;
        bottom: 0;
        /* box-shadow: 0px 0px 0px 100vw #10141b80;  */
        display: flex;
        flex-direction: column;
        gap: 14px;
        left: 0;
        margin: auto;
        position: absolute;
        right: 0;
        top: 0;
        width: fit-content;
        height: fit-content;
    }
`;
/* #endregion */

export default function BOImagePage({ setBrokenURL }) {
    /* #region VARS */
    const { showBOImagePageID, setShowHeader, setShowFooter, setShowRightSidebar, setShowRightUserLocationPanel, isMobile } =
        React.useContext(LayoutContext);
    const { setUserGPSCoordinates, setUserAddress, userAddress } = React.useContext(UserContext);
    const {
        boImageIsLoading,
        setBOImageIsLoading,
        selectedBotag,
        setSelectedBotag,
        setSelectedBotagStoreDetails,
        BOTagsList,
        setBOTagsList,
        setSelectedBOImageData,
        primaryImageData,
        setPrimaryImageData,
        selectedHomePageBotagId,
        setSelectedHomePageBotagId
    } = React.useContext(AppContext);

    const newUserAddress = getLocation();
    const newUserCoords = getCoordinates();

    const navigate = useNavigate();
    const [selectedBotagBeforeLocationPopup, setSelectedBotagBeforeLocationPopup] = React.useState(null);
    const [showLocationPopup, setShowLocationPopup] = React.useState(false);
    const [formFieldImageURL, setFormFieldImageURL] = React.useState("");

    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        if (!showBOImagePageID) return;
        setShowHeader(true);
        setShowFooter(true);
        setShowRightSidebar(true);
        setShowRightUserLocationPanel(true);

        getBOImageData(showBOImagePageID);
        setSelectedBotag(false);
        Library.changeUrlWithoutReloading(`/${showBOImagePageID}`);
    }, [showBOImagePageID]);
    /* #endregion */

    /* #region METHODS */
    const getBOImageData = BOimageID => {
        logGA4Event("BOimage_Loading");
        //setSelectedBOImageData(null);
        Library.makePostRequest(
            "getBOimageData",
            { ourID: BOimageID },
            false,
            setBOImageIsLoading,
            onBOImageNotFound,
            undefined,
            false
        ).then(res => {
            if (res.data.status === 200) {
                const BOImageData = res.data.data;

                setFormFieldImageURL(BOImageData.imageURL);

                logGA4Event("BOimage_Shown");
                const BOTags = BOImageData?.BOtags?.map((tag, index) => {
                    return {
                        number: index + 1,
                        selected: false,
                        completed: true,
                        data: {
                            beaconXposition: tag.beaconXposition,
                            beaconYposition: tag.beaconYposition,
                            GTINbarcode: tag.GTINbarcode,
                            productObjID: tag.productObjID,
                            color: tag.color,
                            size: tag.size
                        },
                        naturalX: tag.beaconXposition,
                        naturalY: tag.beaconYposition,
                        GTINbarcode: tag.GTINbarcode,
                        selectedColor: tag.color,
                        selectedSize: tag.size,

                        //new
                        images: tag?.iamges, // [{name, URL, _id}]
                        image: tag?.iamges[0]?.URL,
                        category: tag?.category,
                        productName: tag?.productName,
                        productType: tag?.productType,
                        colors: tag?.availableColorSize?.color,
                        sizes: tag?.availableColorSize?.size,
                        labelBrandID: BOImageData?.labelBrandID,
                        labelBrandName: BOImageData?.brandInfo?.name,
                        productID: tag?.productID,
                        stockData: tag?.stockData
                    };
                });
                setBOTagsList(BOTags);
                const result = BOTags.map(item => ({
                    number: item.number,
                    image: item.image,
                    images: item.images
                }));

                setPrimaryImageData(result);

                //Removing BOtags to avoid confusion and circular JSON
                delete BOImageData.BOtags;
                setSelectedBOImageData(BOImageData);
            } else {
                setBrokenURL(true);
            }
        });
    };
    useEffect(() => {
        if (selectedHomePageBotagId && BOTagsList.length) {
            const homeSelectedPin = BOTagsList.find(item => item.GTINbarcode === selectedHomePageBotagId);
            if (!newUserCoords?.latitude || !newUserCoords?.longitude || !newUserAddress) {
                setSelectedBotagBeforeLocationPopup(homeSelectedPin);
                setShowLocationPopup(true);
                return;
            }
            localStorage.setItem("isBotagOpen", true);

            setSelectedBotag(homeSelectedPin);
            setSelectedHomePageBotagId("");
        }
    }, [BOTagsList, selectedHomePageBotagId]);
    const onBOImageNotFound = error => {
        console.log(error?.message?.toString());
        //Library.showErrorMessage("The BO image was not found. Please try again later.");
        navigate("/404");
    };

    const onBeaconClick = tag => {
        const result = primaryImageData.find(item => item.number === tag.number);
        tag.image = result?.image;
        tag.images = result?.images;
        if (!newUserCoords?.latitude || !newUserCoords?.longitude || !newUserAddress) {
            setSelectedBotagBeforeLocationPopup(tag);
            setShowLocationPopup(true);
            return;
        }
        localStorage.setItem("isBotagOpen", true);
        setSelectedBotag(tag);
    };

    const onClickOutsideBotag = () => {
        if (selectedBotag) {
            setSelectedBotag(null);
            setSelectedBotagStoreDetails(null);
        }
    };

    const onLocationChanged = (location, isBotagOpen) => {
        const { latitude, longitude, city, country, postalCode } = location;

        setUserGPSCoordinates({ latitude, longitude });
        saveCoordinates({ latitude, longitude });

        fetchAddressFromCoordinates(latitude, longitude, city, country, postalCode).then(newUserAddress => {
            setUserAddress(newUserAddress);
            saveLocation(newUserAddress);

            if (selectedBotagBeforeLocationPopup && isBotagOpen) {
                localStorage.setItem("isBotagOpen", true);
                setSelectedBotag(selectedBotagBeforeLocationPopup);
                setSelectedBotagBeforeLocationPopup(null);
            }
        });
    };

    const openBotag = () => {
        if (selectedBotagBeforeLocationPopup) {
            setSelectedBotag(selectedBotagBeforeLocationPopup);
            setSelectedBotagBeforeLocationPopup(null);
        }
    };
    /* #endregion */

    return (
        <StyledPage onClick={onClickOutsideBotag} className="BOImagePage-container">
            <ShareLocationPopup
                visible={showLocationPopup}
                setVisible={setShowLocationPopup}
                onLocationSelected={onLocationChanged}
                openBotag={openBotag}
            />
            <div className={`BOImageContainer ${isMobile && boImageIsLoading ? "mobile-skeleton" : ""}`}>
                <div className="BoimageCanvas">
                    <ImageBeacons
                        src={formFieldImageURL}
                        BOTagsList={BOTagsList}
                        setBOTagsList={setBOTagsList}
                        isLoading={boImageIsLoading}
                        onBeaconClick={tag => {
                            onBeaconClick(tag);
                            logGA4Event("BOimage_NoBeacons");
                        }}
                        selectedBotag={selectedBotag}
                    />
                </div>

                {selectedBotag && (
                    <div className="BOTagContainer">
                        <BOTag />
                    </div>
                )}
            </div>
        </StyledPage>
    );
}
