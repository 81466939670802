import React, { useContext, useEffect, useState } from "react";
import * as Formatter from "../../../../utils/Formatter";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import UserContext from "../../../../contexts/UserContext";
import AppContext from "../../../../contexts/AppContext";
import LayoutContext from "../../../../contexts/LayoutContext";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import momentTimeZone from "moment-timezone";
import { Tooltip } from "antd";
import vector0 from "../../../../assets/images/vector0.png";
import { getMobileOperatingSystem } from "../../../../utils/helperFunctions";
export default function BOTagBodyDeliveryAvailable({
    deliveryData,
    deliveryMethod,
    GTINbarcode,
    selectedQuantity,
    selectedColor,
    selectedSize,
    deliveryPreference,
    animate,
    setAnimate,
    fireworks,
    setFireworks
}) {
    /* #region VARS */
    const { setCheckoutCartData, userData, checkoutCartData, shippingRecipient, setQuickBuyData, quickBuyData } = useContext(UserContext);
    const { selectedBotag, setSelectedBotag, setSelectedBotagStoreDetails } = useContext(AppContext);
    const {
        setShowRightSidebar,
        setShowRightCheckoutPanel,
        setShowStoreInformation,
        setStoreInformation,
        setQuickBuyPopup,
        setShowFireWorks
    } = useContext(LayoutContext);
    const userGPSCoordinates = JSON.parse(localStorage.getItem("userCoords"))?.coords;
    const userAddress = JSON.parse(localStorage.getItem("userLocation"))?.location;
    //TODO: CHECK THESE HARDCODED VALUES
    //const isStoreOpen = true;
    const storeDetails = deliveryData?.storeDetail;
    const isStoreOpen = () => {
        const isWeekday = new Date().getDay() >= 1 && new Date().getDay() <= 5;
        const isSaturday = new Date().getDay() === 6;

        const openHours = isWeekday
            ? storeDetails?.openHours?.weekdays
            : isSaturday
            ? storeDetails?.openHours?.saturdays
            : storeDetails?.openHours?.sundays;

        if (!openHours || !openHours?.hourStart || !openHours?.hourEnd) return false;
        //const currentTime = moment().utcOffset("+0100").format("HH:mm");
        const currentTime = momentTimeZone.tz("Europe/Oslo").format("HH:mm");
        const hourStart = openHours?.hourStart; // "09:00"
        const hourEnd = openHours?.hourEnd; // "18:00"
        return currentTime >= hourStart && currentTime <= hourEnd;
    };

    const currencySymbol = "kr";

    const distanceToStore = Formatter.formatDistance(deliveryData?.distanceToStore, "km", "--");
    const storeName = deliveryData?.storeDetail?.name || "--";
    const price = deliveryData?.price;
    const discountPrice = deliveryData?.discountPrice;
    const priceStr = Formatter.formatPrice(price, "");
    const discountPriceStr = discountPrice ? Formatter.formatPrice(discountPrice, "") : "";
    const deliveryCharges = deliveryData?.deliveryCharges;
    const deliveryChargesStr = Formatter.formatPrice(deliveryCharges, "");
    const totalPrice = deliveryData?.totalPrice ? deliveryData?.totalPrice : deliveryData?.price;
    const totalPriceStr = Formatter.formatPrice(price * selectedQuantity + (deliveryCharges || 0), "");
    const totalDiscountPriceStr = Formatter.formatPrice(discountPrice * selectedQuantity + (deliveryCharges || 0), "");
    const operatingSystem = getMobileOperatingSystem();

    /* #endregion */

    /* #Region EFFECTS */
    /* #endregion */

    /* #region METHODS */
    const getRandomColor = () => {
        const colors = [
            "rgba(255,0,0,1)", // Red
            "rgba(0,255,0,1)", // Green
            "rgba(0,0,255,1)", // Blue
            "rgba(255,255,0,1)", // Yellow
            "rgba(255,105,180,1)" // Pink
        ];
        return colors[Math.floor(Math.random() * colors.length)];
    };
    const onAddToCheckoutClick = () => {
        setAnimate(true);
        for (let i = 0; i < 15; i++) {
            const firework = {
                left: `${Math.random() * 100}%`,
                top: `${Math.random() * 100}%`,
                color: getRandomColor(),
                size: Math.random() * 30 + 10
            };
            setFireworks(prevFireworks => [...prevFireworks, firework]);
        }

        setTimeout(() => {
            setShowFireWorks(true);
            setTimeout(() => {
                setShowFireWorks(false);
            }, 500);
        }, 2000);
        const item = document.getElementById("item");
        item &&
            item.addEventListener(
                "animationend",
                () => {
                    setAnimate(false);
                },
                { once: true }
            );

        const BOTagData = { ...selectedBotag };

        BOTagData.GTINbarcode = GTINbarcode;
        BOTagData.selectedQuantity = selectedQuantity;
        BOTagData.selectedColor = selectedColor;
        BOTagData.selectedSize = selectedSize;
        BOTagData.deliveryPreference = deliveryPreference;
        BOTagData.deliveryMethod = deliveryMethod;
        BOTagData.storeName = storeName;
        BOTagData.storeDetails = storeDetails;
        BOTagData.recipient =
            shippingRecipient?.firstName && shippingRecipient?.lastName
                ? shippingRecipient?.firstName + " " + shippingRecipient?.lastName
                : userData?.firstName && userData?.lastName
                ? userData?.firstName + " " + userData?.lastName
                : "";
        BOTagData.recipientJson = {};
        BOTagData.recipientJson.firstName = shippingRecipient?.firstName ? shippingRecipient?.firstName : userData?.firstName;
        BOTagData.recipientJson.lastName = shippingRecipient?.lastName ? shippingRecipient?.lastName : userData?.lastName;
        BOTagData.selectedColorName =
            BOTagData?.colors?.find(color => Formatter.formatAndCompareColors(color?.colorCode, BOTagData?.selectedColor))?.color || "--";

        BOTagData.totalStock = BOTagData.stockData.find(
            stock => stock.size === BOTagData?.selectedSize && Formatter.formatAndCompareColors(stock.colorCode, BOTagData?.selectedColor)
        )?.totalStock;

        /* #region REMOVING UNNECESSARY DATA FROM BOTag */
        delete BOTagData.data;
        delete BOTagData.sizes;
        delete BOTagData.colors;
        //delete BOTagData.images;
        delete BOTagData.naturalX;
        delete BOTagData.naturalY;
        delete BOTagData.x;
        delete BOTagData.y;
        delete BOTagData.number;
        delete BOTagData.selected;
        delete BOTagData.stockData;
        delete BOTagData.category;
        delete BOTagData.completed;
        /* #endregion */

        const BOTagAlgorithmData = { ...deliveryData };
        const FormattedData = {
            distanceToStore,
            price: discountPrice ? discountPrice : price,
            priceStr: Formatter.formatPrice(discountPrice ? discountPrice : price),
            deliveryCharges,
            deliveryChargesStr: deliveryChargesStr,
            totalPrice: (discountPrice ? discountPrice : price) * selectedQuantity + (deliveryCharges || 0), // add deliveryCharges if any
            totalPriceStr: Formatter.formatPrice((discountPrice ? discountPrice : price) * selectedQuantity + (deliveryCharges || 0))
        };
        const existingItem = checkoutCartData.items.find(item => {
            return (
                item.BOTagData.GTINbarcode === GTINbarcode &&
                item.BOTagData.storeName === storeName &&
                JSON.stringify(item.UserAddress) === JSON.stringify(userAddress) &&
                item.BOTagData.selectedSize === selectedSize &&
                item.BOTagData.selectedColor === selectedColor &&
                item.BOTagData.deliveryMethod === deliveryMethod
            );
        });

        if (existingItem) {
            setCheckoutCartData(prevState => {
                const updatedItem = { ...existingItem };
                updatedItem.FormattedData.totalPrice += (discountPrice ? discountPrice : price) * selectedQuantity;
                updatedItem.FormattedData.totalPriceStr = updatedItem.FormattedData.totalPrice.toLocaleString();
                updatedItem.BOTagData.selectedQuantity += selectedQuantity;

                const items = prevState.items.filter(item => item.itemID !== existingItem.itemID);
                return {
                    ...prevState,
                    items: [updatedItem, ...items], //prevState.items.map(item => (item.itemID === existingItem.itemID ? updatedItem : item)),
                    total: prevState.total + (discountPrice ? discountPrice : price) * selectedQuantity
                };
            });
        } else {
            const itemID = uuidv4(); //USE THIS AS A UNIQUE ID FOR EACH ITEM IN THE CART

            if (deliveryMethod === "courier") {
                const alreadyExistsCourier = checkoutCartData.items.find(item => {
                    return item.BOTagData.storeName === storeName && item.BOTagData.deliveryMethod === "courier";
                });

                if (alreadyExistsCourier) {
                    //DELIVERY PRICE IS FREE
                    FormattedData.deliveryCharges = 0;
                    FormattedData.deliveryChargesStr = "";
                    FormattedData.totalPrice = price * selectedQuantity;
                    FormattedData.totalPriceStr = Formatter.formatPrice(price * selectedQuantity);

                    //CHANGE ALSO IN BOTAGALGORITHM DATA
                    BOTagAlgorithmData.deliveryCharges = 0;
                }
            }

            const item = {
                itemID,
                BOTagData,
                BOTagAlgorithmData,
                FormattedData,
                UserData: userData,
                UserAddress: userAddress,
                UserGPSCoordinates: userGPSCoordinates
            };
            setCheckoutCartData(prevState => {
                return {
                    ...prevState,
                    items: [item, ...prevState.items],
                    total: prevState.total + FormattedData.totalPrice
                };
            });
        }

        //setShowRightCheckoutPanel(false);
        setShowRightSidebar(true);
        // setSelectedBotag(null);
        // setSelectedBotagStoreDetails(null);
    };
    const onQuickBuyClick = () => {
        const BOTagData = { ...selectedBotag };

        BOTagData.GTINbarcode = GTINbarcode;
        BOTagData.selectedQuantity = selectedQuantity;
        BOTagData.selectedColor = selectedColor;
        BOTagData.selectedSize = selectedSize;
        BOTagData.deliveryPreference = deliveryPreference;
        BOTagData.deliveryMethod = deliveryMethod;
        BOTagData.storeName = storeName;
        BOTagData.storeDetails = storeDetails;
        BOTagData.recipient =
            shippingRecipient?.firstName && shippingRecipient?.lastName
                ? shippingRecipient?.firstName + " " + shippingRecipient?.lastName
                : userData?.firstName && userData?.lastName
                ? userData?.firstName + " " + userData?.lastName
                : "";
        BOTagData.recipientJson = {};
        BOTagData.recipientJson.firstName = shippingRecipient?.firstName ? shippingRecipient?.firstName : userData?.firstName;
        BOTagData.recipientJson.lastName = shippingRecipient?.lastName ? shippingRecipient?.lastName : userData?.lastName;
        BOTagData.selectedColorName =
            BOTagData?.colors?.find(color => Formatter.formatAndCompareColors(color?.colorCode, BOTagData?.selectedColor))?.color || "--";

        BOTagData.totalStock = BOTagData.stockData.find(
            stock => stock.size === BOTagData?.selectedSize && Formatter.formatAndCompareColors(stock.colorCode, BOTagData?.selectedColor)
        )?.totalStock;

        /* #region REMOVING UNNECESSARY DATA FROM BOTag */
        delete BOTagData.data;
        delete BOTagData.sizes;
        delete BOTagData.colors;
        //delete BOTagData.images;
        delete BOTagData.naturalX;
        delete BOTagData.naturalY;
        delete BOTagData.x;
        delete BOTagData.y;
        delete BOTagData.number;
        delete BOTagData.selected;
        delete BOTagData.stockData;
        delete BOTagData.category;
        delete BOTagData.completed;
        /* #endregion */

        const BOTagAlgorithmData = { ...deliveryData };
        const FormattedData = {
            distanceToStore,
            price: discountPrice ? discountPrice : price,
            priceStr: Formatter.formatPrice(discountPrice ? discountPrice : price),
            deliveryCharges,
            deliveryChargesStr: deliveryChargesStr,
            totalPrice: (discountPrice ? discountPrice : price) * selectedQuantity + (deliveryCharges || 0), // add deliveryCharges if any
            totalPriceStr: Formatter.formatPrice((discountPrice ? discountPrice : price) * selectedQuantity + (deliveryCharges || 0))
        };

        const itemID = uuidv4(); //USE THIS AS A UNIQUE ID FOR EACH ITEM IN THE CART

        const item = {
            itemID,
            BOTagData,
            BOTagAlgorithmData,
            FormattedData,
            UserData: userData,
            UserAddress: userAddress,
            UserGPSCoordinates: userGPSCoordinates
        };
        setQuickBuyData(prevState => {
            return {
                ...prevState,
                items: [item],
                total: FormattedData.totalPrice
            };
        });
        setQuickBuyPopup(true);
    };
    const showStoreinfoFunc = () => {
        setShowStoreInformation(true);
        setStoreInformation(storeDetails);
    };

    /* #endregion */

    return (
        <div className="frame-313502" style={{ gap: operatingSystem === "iOS" && "10px" }}>
            <div className="frame-313500">
                <div className="frame-313499">
                    <div className="frame-313323">
                        <div className="open-234-m-from-you">
                            <span className={`open-234-m-from-you-span ${isStoreOpen() ? "open" : "closed"}`}>
                                {isStoreOpen() ? "Open" : "Closed"}
                            </span>

                            {deliveryMethod === "pickup" ? (
                                <span className="open-234-m-from-you-span2">
                                    {isStoreOpen() ? ` - Store is ${distanceToStore} from you` : " - You can still buy now"}
                                </span>
                            ) : deliveryMethod === "courier" ? (
                                <span className="open-234-m-from-you-span2">
                                    {` - Delivery ~ ${deliveryData?.estimatedTime || "--"} `}
                                    {isStoreOpen() ? "" : "after opening"}
                                </span>
                            ) : deliveryMethod === "shipping" ? (
                                <span
                                    style={{
                                        position: "relative",
                                        verticalAlign: "text-top",
                                        display: "inline-flex",
                                        gap: "4px"
                                    }}>
                                    <span className="open-234-m-from-you-span2" style={{ position: "relative", verticalAlign: "text-top" }}>
                                        &nbsp;{`- ${deliveryData?.estimatedTime || "--"} with `}
                                    </span>
                                    <img
                                        className="vector"
                                        alt="wolt"
                                        src={deliveryData?.cf?.logoURL}
                                        style={{ height: "10px", marginTop: "2px" }}
                                    />
                                </span>
                            ) : (
                                <span>Unknown delivery method: {deliveryMethod}</span>
                            )}
                        </div>
                    </div>
                    <div className="frame-313324">
                        <Tooltip
                            placement="top"
                            title={storeName.length > 20 ? storeName : ""}
                            color="#212121"
                            overlayClassName="custom-tool-tip">
                            <div className="sams-e-sams-e-akersgata" onClick={showStoreinfoFunc}>
                                {storeName.length > 20 ? `${storeName.substring(0, 20)}...` : storeName}
                            </div>
                        </Tooltip>
                    </div>
                </div>
                <div className="frame-313327">
                    <div className="frame-313325">
                        <div className="line-26"></div>
                        <div className="frame-313319">
                            <div className="item-1-500-nok">
                                <span>
                                    {deliveryMethod === "pickup" ? (
                                        <span className="item-1-500-nok-span">
                                            Item{" "}
                                            {discountPriceStr ? (
                                                <>
                                                    <span
                                                        style={{
                                                            textDecoration: "line-through"
                                                        }}>
                                                        {priceStr}
                                                    </span>{" "}
                                                    <span
                                                        style={{
                                                            color: "#FF0000"
                                                        }}>
                                                        {discountPriceStr}
                                                    </span>
                                                </>
                                            ) : (
                                                <span>{priceStr}</span>
                                            )}{" "}
                                            <span
                                                style={
                                                    discountPriceStr
                                                        ? {
                                                              color: "#FF0000"
                                                          }
                                                        : {}
                                                }>
                                                {currencySymbol}
                                            </span>{" "}
                                            {selectedQuantity > 1 ? "(" + selectedQuantity + ")" : ""}
                                        </span>
                                    ) : deliveryCharges !== 0 ? (
                                        <span className="item-1-500-nok-span">
                                            Item{" "}
                                            {discountPriceStr ? (
                                                <>
                                                    <span
                                                        style={{
                                                            textDecoration: "line-through"
                                                        }}>
                                                        {priceStr}
                                                    </span>{" "}
                                                    <span
                                                        style={{
                                                            color: "#FF0000"
                                                        }}>
                                                        {discountPriceStr}
                                                    </span>
                                                </>
                                            ) : (
                                                <span>{priceStr}</span>
                                            )}{" "}
                                            <span
                                                style={
                                                    discountPriceStr
                                                        ? {
                                                              color: "#FF0000"
                                                          }
                                                        : {}
                                                }>
                                                {currencySymbol}
                                            </span>{" "}
                                            {selectedQuantity > 1 ? "(" + selectedQuantity + ")" : ""} / Delivery {deliveryChargesStr}{" "}
                                            {currencySymbol}
                                        </span>
                                    ) : (
                                        <span className="item-1-500-nok-span">
                                            Item {priceStr} {currencySymbol} {selectedQuantity > 1 ? "(" + selectedQuantity + ")" : ""}
                                            <span
                                                style={{
                                                    fontSize: "10px",
                                                    fontWeight: "400",
                                                    color: "rgb(247, 102, 95)"
                                                }}>
                                                / Delivery FREE today!
                                            </span>
                                        </span>
                                    )}
                                </span>
                            </div>
                        </div>
                        <div className="line-25"></div>
                    </div>
                    <div className="_1-500-nok">
                        <span>
                            <span className="_1-500-nok-span">
                                {discountPriceStr ? totalDiscountPriceStr : totalPriceStr} {currencySymbol}
                            </span>
                        </span>
                    </div>
                </div>
            </div>
            <div className="frame-3135012">
                <div className="frame-313503" onClick={onAddToCheckoutClick}>
                    <div className="btn-fw-botag" style={{ background: "#212121" }}>
                        <div className="frame-313497">
                            <div className="frame-313349">
                                <div className="button">Add to bag</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="frame-313503" onClick={onQuickBuyClick}>
                    <div className="btn-fw-botag">
                        <div className="frame-313497">
                            <div className="frame-313349">
                                <div className="button">Quick buy</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
