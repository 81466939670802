import React from "react";
import Skeleton from "@mui/material/Skeleton";

const OrderPanelSkeleton = ({ isMobile }) => {
    return (
        <div
            style={{
                border: "1px solid #212121",
                width: isMobile ? "auto" : "100%",
                padding: "16px",
                borderRadius: "5px"
            }}>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%"
                }}>
                <div>
                    <Skeleton sx={{ bgcolor: "grey.900", borderRadius: "15px" }} variant="rectangular" height={"10.5px"} width={"152px"} />
                </div>
                <div>
                    <Skeleton sx={{ bgcolor: "grey.900", borderRadius: "12.5px" }} variant="rectangular" height={"8.75px"} width={"37px"} />
                </div>
            </div>
            <div style={{ marginTop: "16px" }}>
                <Skeleton sx={{ bgcolor: "grey.900", borderRadius: "15px" }} variant="rectangular" height={"10.5px"} width={"257px"} />
            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    marginTop: "16px"
                }}>
                <div>
                    <Skeleton sx={{ bgcolor: "grey.900", borderRadius: "15px" }} variant="rectangular" height={"10.5px"} width={"165px"} />
                </div>
                <div>
                    <Skeleton
                        sx={{ bgcolor: "grey.900", borderRadius: "12.5px" }}
                        variant="rectangular"
                        height={"12.25px"}
                        width={"61px"}
                    />
                </div>
            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    marginTop: "16px"
                }}>
                <div>
                    <Skeleton sx={{ bgcolor: "grey.900", borderRadius: "15px" }} variant="rectangular" height={"10.5px"} width={"54px"} />
                </div>
                <div>
                    <Skeleton sx={{ bgcolor: "grey.900", borderRadius: "0.5px" }} variant="rectangular" height={"1px"} width={"241px"} />
                </div>
            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    marginTop: "16px"
                }}>
                <div></div>
                <div style={{ marginTop: "-10px" }}>
                    <Skeleton sx={{ bgcolor: "grey.900", borderRadius: "0.5px" }} variant="rectangular" height={"20px"} width={"32.87px"} />
                </div>
            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%"
                }}>
                <div>
                    <Skeleton sx={{ bgcolor: "grey.900", borderRadius: "15px" }} variant="rectangular" height={"10.5px"} width={"81px"} />
                </div>
                <div style={{ marginTop: "16px" }}>
                    <Skeleton sx={{ bgcolor: "grey.900", borderRadius: "12.5px" }} variant="rectangular" height={"8.75px"} width={"56px"} />
                </div>
                <div style={{ marginTop: "16px" }}>
                    <Skeleton sx={{ bgcolor: "grey.900", borderRadius: "12.5px" }} variant="rectangular" height={"8.75px"} width={"56px"} />
                </div>
            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "16px",
                    gap: "10px",
                    padding: "0 16px"
                }}>
                <div>
                    <Skeleton sx={{ bgcolor: "grey.900", borderRadius: "5px" }} variant="rectangular" height={"30px"} width={"166px"} />
                </div>
                <div>
                    <Skeleton sx={{ bgcolor: "grey.900", borderRadius: "5px" }} variant="rectangular" height={"30px"} width={"166px"} />
                </div>
            </div>
        </div>
    );
};

export default OrderPanelSkeleton;
