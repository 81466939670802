import axios from "axios";

/**
 * Fetches the address from the given latitude and longitude coordinates.
 * @param {number} latitude - The latitude coordinate.
 * @param {number} longitude - The longitude coordinate.
 * @returns {Promise<Object|null>} - A promise that resolves to an object containing the fetched address information,
 * or null if an error occurred.
 */
export const fetchAddressFromCoordinates = async (latitude, longitude, city, country, postalCode) => {
    const newUserAddress = {
        street: undefined,
        streetNumber: undefined,
        city: undefined,
        country: undefined,
        postCode: undefined
    };

    if (country && city) {
        newUserAddress.street = "";
        newUserAddress.streetNumber = "";
        newUserAddress.city = city || "";
        newUserAddress.country = country || "";
        newUserAddress.postCode = "";
        localStorage.setItem("isIpLocation", true);
        return newUserAddress;
    }

    try {
        const res = await axios.post(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_MAP_KEY}`
        );

        if (res) {
            const firstResult = res?.data?.results[0] || "";
            newUserAddress.street = firstResult?.address_components?.find(tmp => tmp?.types?.includes("route"))?.long_name || "";
            newUserAddress.streetNumber =
                firstResult?.address_components?.find(tmp => tmp?.types?.includes("street_number"))?.long_name || "";
            newUserAddress.city =
                firstResult?.address_components?.find(tmp => tmp?.types?.includes("postal_town"))?.long_name || city || "";
            newUserAddress.country =
                firstResult?.address_components?.find(tmp => tmp?.types?.includes("country"))?.long_name || country || "";
            newUserAddress.postCode =
                firstResult?.address_components?.find(tmp => tmp?.types?.includes("postal_code"))?.long_name || postalCode || "";

            localStorage.setItem("isIpLocation", false);
            return newUserAddress;
        }

        return null;
    } catch (error) {
        console.error("Error while fetching user address", error);
        return null;
    }
};
export const getLatLngFromAddress = address => {
    const apiKey = process.env.REACT_APP_MAP_KEY;
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${apiKey}`;

    return fetch(url)
        .then(response => response.json())
        .then(data => {
            if (data.results.length) {
                const latLng = data.results[0].geometry.location;
                return latLng;
            }
        })
        .catch(error => console.error(error));
};
/**
 * @deprecated This function is deprecated and will be removed or refactored in the future.
 * Retrieves the user's location based on their IP address.
 * @returns {Promise<{latitude: number, longitude: number}>} The latitude and longitude of the user's location.
 * @throws {Error} If there is an error while fetching the user's location by IP.
 */
export const getLocationByIP = async () => {
    try {
        const res = await axios.get("https://geolocation-db.com/json/");

        if (res) {
            const { latitude, longitude } = res.data;

            return { latitude, longitude };
        } else throw new Error("Failed to fetch user location by IP");
    } catch (error) {
        console.error("Error while fetching user location by IP", error);
        return null;
    }
};

/**
 * Retrieves the user's location based on their IP address.
 * ATTENTION: This has a rate limit of something like 10k or more requests per month. So we should use it wisely.
 * @param {Function} callback -  The callback function that receives the user's location.
 */

export const getUserLocationByIP = async callback => {
    try {
        const response = await axios.get(`https://ipinfo.io/json?token=${process.env.REACT_APP_IP_TOKEN}`);
        const loc = response?.data?.loc;
        const [lat, lon] = loc.split(",");
        const address = response?.data;

        if (response?.status === 200) {
            callback({
                latitude: Number(lat),
                longitude: Number(lon),
                country: address?.country,
                city: address?.city,
                postalCode: address?.postal
            });
        }
    } catch (error) {
        console.error(error);
    }
};

/**
 * Retrieves the distance between two sets of coordinates using calculations provided by Github COPILOT using the Haversine formula.
 * @param {{latitude: number, longitude: number}} coords1 - The first set of coordinates.
 * @param {{latitude: number, longitude: number}} coords2 - The second set of coordinates.
 * @param {number} fixedDecimals - The number of decimals to round the result to.
 * @param {boolean} addKmSuffix - Whether to add the "km" suffix to the result.
 * @param {string} defaultError - The default value to return if an error occurs. Defaults to "--".
 * @returns {string|number} The distance between the two sets of coordinates. If an error occurs, the default error value is returned.
 * @example getDistanceBetweenCoordinates(coords1, coords2, 2, true, "--"); // Returns "123.45 km"
 */
export const getDistanceBetweenCoordinates = (coords1, coords2, fixedDecimals = undefined, addKmSuffix = false, defaultError = "--") => {
    try {
        function deg2rad(deg) {
            return deg * (Math.PI / 180);
        }

        const R = 6371; // Radius of the earth in km
        const dLat = deg2rad(coords2.latitude - coords1.latitude); // deg2rad below
        const dLon = deg2rad(coords2.longitude - coords1.longitude);
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(deg2rad(coords1.latitude)) * Math.cos(deg2rad(coords2.latitude)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const d = R * c; // Distance in km

        const distance = fixedDecimals !== undefined ? d.toFixed(fixedDecimals) : d;

        if (addKmSuffix) return `${distance} km`;
        return distance;
    } catch (error) {
        console.error("Error while calculating distance between coordinates", error);
        return defaultError;
    }
};
