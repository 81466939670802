import { useContext } from "react";
import styled from "styled-components";
import LayoutContext from "../../../contexts/LayoutContext";
import UserContext from "../../../contexts/UserContext";

const PopOverStyles = styled.div`
    .dropdown-mini,
    .dropdown-mini * {
        box-sizing: border-box;
    }
    .dropdown-mini {
        background: var(--surface-surface-tertiary, #212121);
        border-radius: 4px;
        padding: 8px 4px 8px 4px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        row-gap: 0px;
        align-items: flex-start;
        justify-content: flex-start;
        width: 126px;
        position: relative;
        box-shadow: var(--dropdown-ds-box-shadow, 0px 0px 10px 0px rgba(17, 22, 32, 1));
    }
    .frame-75 {
        display: flex;
        flex-direction: column;
        gap: 0px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-84 {
        border-radius: 4px;
        padding: 8px;
        display: flex;
        flex-direction: row;
        gap: 8px;
        row-gap: 0px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313639 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        row-gap: 0px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .delivery {
        color: var(--text-text-primary, #ffffff);
        text-align: left;
        font-family: "OpenSans-Regular", sans-serif;
        font-size: 12px;
        font-weight: 400;
        position: relative;
        flex: 1;
    }
`;
const PopOver = ({ data, setShowPopOver }) => {
    const { setShowReturnAndFeedBackPopup } = useContext(LayoutContext);
    const { setProductInfo } = useContext(UserContext);
    return (
        <PopOverStyles>
            <div
                className="dropdown-mini"
                onClick={() => {
                    setProductInfo(data);
                    setShowReturnAndFeedBackPopup(true);
                    setShowPopOver(false);
                }}
                style={{ cursor: "pointer" }}>
                <div className="frame-75">
                    <div className="frame-84">
                        <div className="frame-313639">
                            <div className="delivery">Return / Feedback</div>
                        </div>
                    </div>
                </div>
            </div>
        </PopOverStyles>
    );
};
export default PopOver;
