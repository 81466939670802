import styled from "styled-components";
import { DefaultPopupCard } from "./DefaultPopupCard";
import { useContext, useState } from "react";
import LayoutContext from "../../../contexts/LayoutContext";
import Check from "../../../assets/images/Checkbox.png";
import StoreIcon from "../../../assets/images/store-icon.png";
import Info from "../../../assets/images/info.svg";
import Uncheck from "../../../assets/images/uncheck.png";
import ButtonLoader from "../loaders/ButtonLoader";
import * as Library from "../../../utils/Library";
import UserContext from "../../../contexts/UserContext";
import StandardsContext from "../../../contexts/StandardsContext";

const ReturnItemPopupStyles = styled.div`
    .frame-313406,
    .frame-313406 * {
        box-sizing: border-box;
    }
    .frame-313406 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex: 1;
        position: relative;
    }
    .frame-313402 {
        display: flex;
        flex-direction: column;
        gap: 32px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313401 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-3590 {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .group-3413 {
        flex-shrink: 0;
        width: 50px;
        height: 50px;
        position: static;
    }
    .ellipse-80 {
        background: var(--wrap-wrap-orange, rgba(255, 153, 0, 0.1));
        border-radius: 50%;
        width: 50px;
        height: 50px;
        position: absolute;
        //left: 177px;
        top: 0px;
    }
    .info-circle {
        border-radius: 88px;
        width: 5.94%;
        height: 48%;
        position: absolute;
        right: 47.03%;
        left: 47.03%;
        bottom: 26%;
        top: 26%;
        overflow: visible;
    }
    .sorry {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-family: "OpenSans-SemiBold", sans-serif;
        font-size: 22px;
        line-height: 24px;
        font-weight: 600;
        position: relative;
        align-self: stretch;
    }
    .are-you-sure-you-would-like-to-return-this-item {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: center;
        font-family: var(--subtitle-2-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 14px);
        line-height: var(--subtitle-2-line-height, 24px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
        align-self: stretch;
    }
    .frame-313669 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        flex-shrink: 0;
        width: 100%;
        position: relative;
    }
    .large-item-picker {
        border-radius: 5px;
        border-style: solid;
        border-color: var(--border-border-primary, rgba(166, 166, 166, 0.2));
        border-width: 1px;
        padding: 16px 0px 16px 0px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313477 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313449 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313447 {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-29715 {
        padding: 0px 16px 0px 16px;
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .luan-j-shirt-cosmic-4-3 {
        border-radius: 5px;
        flex-shrink: 0;
        width: 44.83px;
        height: 60px;
        position: relative;
        object-fit: cover;
    }
    .frame-29710 {
        display: flex;
        flex-direction: column;
        gap: 6px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex: 1;
        position: relative;
    }
    .brand-product-type {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: left;
        font-family: var(--caption-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--caption-font-size, 12px);
        line-height: var(--caption-line-height, 20px);
        font-weight: var(--caption-font-weight, 400);
        position: relative;
        align-self: stretch;
    }
    .product-name {
        color: var(--text-text-primary, #ffffff);
        text-align: left;
        font-family: "OpenSans-SemiBold", sans-serif;
        font-size: 14px;
        line-height: 14px;
        font-weight: 600;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .brand-product-type2 {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: left;
        font-family: var(--caption-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--caption-font-size, 12px);
        line-height: var(--caption-line-height, 20px);
        font-weight: var(--caption-font-weight, 400);
        position: relative;
    }
    .frame-29538 {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .brand-product-type3 {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .brand-product-type-3-span {
        color: var(--text-text-primary, #ffffff);
        font-family: "OpenSans-SemiBold", sans-serif;
        font-size: 14px;
        line-height: 20px;
        font-weight: 600;
    }
    .brand-product-type-3-span2 {
        color: var(--text-text-primary, #ffffff);
        font-family: var(--caption-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--caption-font-size, 12px);
        line-height: var(--caption-line-height, 20px);
        font-weight: var(--caption-font-weight, 400);
    }
    .brand-product-type-3-span3 {
        color: var(--text-text-primary, #ffffff);
        font-family: "OpenSans-Regular", sans-serif;
        font-size: 10px;
        line-height: 20px;
        font-weight: 400;
    }
    .frame-313454 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313448 {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313444 {
        padding: 0px 16px 0px 16px;
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313443 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .brand-product-type4 {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: left;
        font-family: "OpenSans-Regular", sans-serif;
        font-size: 12px;
        line-height: 20px;
        font-weight: 400;
        position: relative;
        width: 37.04px;
    }
    .line-26 {
        margin-top: -0.5px;
        border-style: solid;
        border-color: var(--border-border-tertiary, #373737);
        border-width: 0.5px 0 0 0;
        flex: 1;
        height: 0px;
        position: relative;
        transform-origin: 0 0;
        transform: rotate(0.001deg) scale(1, 1);
    }
    .frame-313445 {
        padding: 0px 16px 0px 16px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-29721 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: flex-end;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-29719 {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .frame-29717 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-29716 {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: center;
        justify-content: flex-end;
        flex: 1;
        position: relative;
    }
    .group-3626 {
        flex-shrink: 0;
        width: 45px;
        height: 45px;
        position: relative;
        overflow: visible;
    }
    .frame-313440 {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: flex-end;
        justify-content: center;
        flex: 1;
        position: relative;
    }
    .frame-313441 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-end;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .product-name2 {
        color: var(--text-text-primary, #ffffff);
        text-align: left;
        font-family: "OpenSans-SemiBold", sans-serif;
        font-size: 14px;
        line-height: 20px;
        font-weight: 600;
        position: relative;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .open {
        color: var(--text-text-success, #69c23f);
        text-align: left;
        font-family: var(--regular-text-xxs-font-family, "OpenSans-SemiBold", sans-serif);
        font-size: var(--regular-text-xxs-font-size, 10px);
        font-weight: var(--regular-text-xxs-font-weight, 600);
        position: relative;
        width: 26.65px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .closed {
        color: #ff0000;
        text-align: left;
        font-family: var(--regular-text-xxs-font-family, "OpenSans-SemiBold", sans-serif);
        font-size: var(--regular-text-xxs-font-size, 10px);
        font-weight: var(--regular-text-xxs-font-weight, 600);
        position: relative;
        width: 26.65px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .product-name3 {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: left;
        font-family: var(--caption-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--caption-font-size, 12px);
        line-height: var(--caption-line-height, 20px);
        font-weight: var(--caption-font-weight, 400);
        position: relative;
        align-self: stretch;
        height: 16px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .checkbox {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: flex-start;
        flex-shrink: 0;
        width: 404px;
        position: relative;
    }
    .ellipse-1 {
        border-radius: 50%;
        border-style: solid;
        border-color: var(--border-border-primary, rgba(166, 166, 166, 0.2));
        border-width: 1px;
        flex-shrink: 0;
        width: 18px;
        height: 18px;
        position: relative;
    }
    .frame-29729 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313414 {
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313399 {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313412 {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .btn-md-solid {
        background: var(--buttons-button-secondary, #212121);
        border-radius: 5px;
        padding: 0px 44px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex: 1;
        height: 44px;
        position: relative;
        box-shadow: var(--bt-drop-shadow-box-shadow, 0px 0px 4px 0px rgba(30, 38, 49, 0.35));
    }
    .frame-313497 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313349 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .button {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-family: var(--button-text-medium-regular-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--button-text-medium-regular-font-size, 14px);
        line-height: var(--button-text-medium-regular-line-height, 24px);
        font-weight: var(--button-text-medium-regular-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .btn-md-solid2 {
        background: var(--buttons-button-primary, #f7665f);
        border-radius: 5px;
        opacity: 0.2;
        padding: 0px 44px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex: 1;
        height: 44px;
        position: relative;
        box-shadow: var(--bt-drop-shadow-box-shadow, 0px 0px 4px 0px rgba(30, 38, 49, 0.35));
    }
    .term-condition {
        color: #8c8c8c;
    }
    .term-condition:hover {
        color: #f7665f;
    }
`;

const ReturnItemPopup = () => {
    /* #region VARS */
    const { setShowReturnItemPopup, setShowReturnSubmittedPopup, setShowPolicyPopup } = useContext(LayoutContext);
    const { productInfo, setProductInfo } = useContext(UserContext);
    const { setDefaultNotificationCard } = useContext(StandardsContext);

    const timeStamp = `${productInfo?.storeInfo?.openHours?.Weekdays?.hourStart}-${productInfo?.storeInfo?.openHours?.Weekdays?.hourEnd}`;
    const [checkbox, setCheckbox] = useState(false);
    const [loading, setLoading] = useState(false);
    /* #endregion */

    /* #region METHODS */
    const returnFunc = async () => {
        try {
            const response = await Library.makeCommonPostRequest(
                "orders",
                "updateSalesStatus",
                { saleIDs: [productInfo?._id], status: "ToReturn" },
                false,
                null,
                () => {},
                undefined
            );
            if (response?.status === 200) {
                setShowReturnItemPopup(false);
                setShowReturnSubmittedPopup(true);
                setProductInfo(null);
            }
        } catch (error) {
            //Library.showErrorMessage("Something went wrong. Code 400");
            Library.showNotification({
                message: "Something went wrong. Code 400",
                type: "error",
                setDefaultNotificationCard
            });
            console.error("ReturnFunc", error);
        }
    };
    /* #endregion */

    return (
        <DefaultPopupCard>
            <ReturnItemPopupStyles>
                <div className="frame-313406" style={{ pointerEvents: checkbox && loading && "none" }}>
                    <div className="frame-313402">
                        <div className="frame-313401">
                            <div className="frame-3590">
                                <div className="group-3413">
                                    <div className="ellipse-80"></div>
                                    <img className="info-circle" src={Info} alt="" />
                                </div>
                            </div>
                            <div className="sorry">Returning item</div>
                            <div className="are-you-sure-you-would-like-to-return-this-item">
                                Are you sure you would like to return this item?
                            </div>
                        </div>
                    </div>
                    <div className="frame-313669">
                        <div className="large-item-picker">
                            <div className="frame-313477">
                                <div className="frame-313449">
                                    <div className="frame-313449">
                                        <div className="frame-313447">
                                            <div className="frame-29715">
                                                <img className="luan-j-shirt-cosmic-4-3" src={productInfo?.imageURL || ""} alt="" />

                                                <div className="frame-29710">
                                                    <div className="brand-product-type">
                                                        {productInfo?.brandName || ""} / {productInfo?.productType || ""}
                                                    </div>
                                                    <div className="product-name">{productInfo?.productName || ""}</div>
                                                    <div className="brand-product-type2">
                                                        {productInfo?.color || ""} - {productInfo?.size || ""} -{" "}
                                                        {productInfo?.quantity || ""}
                                                    </div>
                                                </div>
                                                <div className="frame-29538">
                                                    <div className="brand-product-type3">
                                                        <span>
                                                            <span className="brand-product-type-3-span">
                                                                {productInfo?.itemPrice.toLocaleString().replaceAll(",", "." || "")}{" "}
                                                            </span>
                                                            <span className="brand-product-type-3-span2"></span>
                                                            <span className="brand-product-type-3-span3">NOK</span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="frame-313454">
                                            <div className="frame-313448">
                                                <div className="frame-313444">
                                                    <div className="frame-313443">
                                                        <div className="brand-product-type4">STORE</div>
                                                        <div className="line-26"></div>
                                                    </div>
                                                </div>
                                                <div className="frame-313445">
                                                    <div className="frame-29721">
                                                        <div className="frame-29719">
                                                            <div className="frame-29717">
                                                                <div className="frame-29716">
                                                                    <img className="group-3626" src={StoreIcon} alt="" />
                                                                    <div className="frame-313440">
                                                                        <div className="frame-313441">
                                                                            <div className="product-name2">
                                                                                {productInfo?.storeName || ""}
                                                                            </div>
                                                                            <div className={timeStamp ? "open" : "closed"}>
                                                                                {timeStamp ? "Open" : "Closed"}
                                                                            </div>
                                                                        </div>
                                                                        <div className="product-name3">
                                                                            {productInfo?.storeAddress || ""}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-100" style={{ display: "flex", gap: "10px", cursor: "pointer" }}>
                            {checkbox ? (
                                <img className="checkbox2" src={Check} alt="" width="20px" onClick={() => setCheckbox(!checkbox)} />
                            ) : (
                                <img className="checkbox2" src={Uncheck} alt="" width="20px" onClick={() => setCheckbox(!checkbox)} />
                            )}
                            <span>
                                I have read and agree with{" "}
                                <span className="term-condition" onClick={() => setShowPolicyPopup("terms")}>
                                    Terms & conditions
                                </span>
                            </span>
                        </div>
                    </div>
                    <div className="frame-29729">
                        <div className="frame-313414">
                            <div className="frame-313399">
                                <div className="frame-313412">
                                    <div
                                        className="btn-md-solid"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => setShowReturnItemPopup(false)}>
                                        <div className="frame-313497">
                                            <div className="frame-313349">
                                                <div className="button">No, cancel</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="btn-md-solid2"
                                        style={{ opacity: checkbox ? 1 : 0.2, pointerEvents: !checkbox && "none", cursor: "pointer" }}
                                        onClick={() => {
                                            if (checkbox) {
                                                setLoading(true);
                                                returnFunc();
                                            }
                                        }}>
                                        <div className="frame-313497">
                                            <div className="frame-313349">
                                                <div className="button">
                                                    {checkbox && loading ? (
                                                        <div style={{ display: "flex", gap: "4px" }}>
                                                            <span style={{ paddingTop: "3px" }}>
                                                                <ButtonLoader />
                                                            </span>
                                                            Please wait
                                                        </div>
                                                    ) : (
                                                        <div>Yes</div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ReturnItemPopupStyles>
        </DefaultPopupCard>
    );
};
export default ReturnItemPopup;
