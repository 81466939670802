import React, { useContext } from "react";
import { DefaultPopupCard } from "./DefaultPopupCard";
import styled from "styled-components";
import { ReactComponent as Frame290570 } from "../../../assets/images/close-btn.svg";
import LayoutContext from "../../../contexts/LayoutContext";
import moment from "moment";
import momentTimeZone from "moment-timezone";
const StyledPage = styled.div`
    &.store-card-pop-up,
    &.store-card-pop-up * {
        box-sizing: border-box;
    }
    &.store-card-pop-up {
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-end;
        justify-content: flex-start;
        position: relative;
        //box-shadow: var(--dropshadow-pop-up2-box-shadow, 4px 4px 87.4px 0px rgba(0, 0, 0, 1));
        overflow: hidden;
        //background: #0b0b0b;
    }
    .frame-295922384 {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: flex-end;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313474 {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: flex-start;
        justify-content: center;
        flex-shrink: 0;
        width: 100%;
        position: relative;
    }
    .gant-stortingsgata {
        color: var(--text-text-primary, #ffffff);
        text-align: left;
        font-family: "OpenSans-SemiBold", sans-serif;
        font-size: 22px;
        line-height: 28px;
        font-weight: 600;
        position: relative;
        flex: 1;
    }
    .frame-29057 {
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 30px;
        height: 30px;
        position: relative;
        overflow: visible;
    }
    .stortingsgata-20-0161-oslo-norway {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: left;
        font-family: var(--subtitle-2-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 14px);
        line-height: var(--subtitle-2-line-height, 24px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
        align-self: stretch;
    }
    .frame-313402 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-295922375 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .image-156 {
        border-radius: 5px;
        align-self: stretch;
        flex-shrink: 0;
        height: 200px;
        position: relative;
        object-fit: cover;
    }
    .frame-295922374 {
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313307 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-295922379 {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .open-hours-open-now {
        text-align: left;
        font-family: var(--heading-4-font-family, "OpenSans-SemiBold", sans-serif);
        font-size: var(--heading-4-font-size, 14px);
        line-height: var(--heading-4-line-height, 20px);
        font-weight: var(--heading-4-font-weight, 600);
        position: relative;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .open-hours-open-now-span {
        color: var(--text-text-primary, #ffffff);
        font-family: var(--heading-4-font-family, "OpenSans-SemiBold", sans-serif);
        font-size: var(--heading-4-font-size, 14px);
        line-height: var(--heading-4-line-height, 20px);
        font-weight: var(--heading-4-font-weight, 600);
    }
    .open-hours-open-now-span2 {
        color: var(--icons-icon-green, #69c23f);
        font-family: var(--heading-4-font-family, "OpenSans-SemiBold", sans-serif);
        font-size: var(--heading-4-font-size, 14px);
        line-height: var(--heading-4-line-height, 20px);
        font-weight: var(--heading-4-font-weight, 600);
    }
    .frame-29671 {
        border-radius: 8px;
        border-style: solid;
        border-color: var(--border-border-primary-10, rgba(166, 166, 166, 0.1));
        border-width: 1px;
        padding: 16px;
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-295922380 {
        display: flex;
        flex-direction: row;
        gap: 32px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-29669 {
        display: flex;
        flex-direction: row;
        gap: 24px;
        align-items: flex-start;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .frame-29606 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .monday {
        color: var(--text-text-primary, #ffffff);
        text-align: left;
        font-family: var(--body-text-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--body-text-font-size, 14px);
        line-height: var(--body-text-line-height, 24px);
        letter-spacing: var(--body-text-letter-spacing, -0.015em);
        font-weight: var(--body-text-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    ._10-00-19-00 {
        color: var(--text-text-primary, #ffffff);
        text-align: right;
        font-family: var(--body-text-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--body-text-font-size, 14px);
        line-height: var(--body-text-line-height, 24px);
        letter-spacing: var(--body-text-letter-spacing, -0.015em);
        font-weight: var(--body-text-font-weight, 400);
        position: relative;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    .frame-29670 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .friday {
        color: var(--text-text-primary, #ffffff);
        text-align: left;
        font-family: var(--body-text-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--body-text-font-size, 14px);
        line-height: var(--body-text-line-height, 24px);
        letter-spacing: var(--body-text-letter-spacing, -0.015em);
        font-weight: var(--body-text-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .frame-295922381 {
        display: flex;
        flex-direction: row;
        gap: 32px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .tuesday {
        color: var(--text-text-primary, #ffffff);
        text-align: left;
        font-family: var(--body-text-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--body-text-font-size, 14px);
        line-height: var(--body-text-line-height, 24px);
        letter-spacing: var(--body-text-letter-spacing, -0.015em);
        font-weight: var(--body-text-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .frame-29668 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .saturday {
        color: var(--text-text-primary, #ffffff);
        text-align: left;
        font-family: var(--body-text-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--body-text-font-size, 14px);
        line-height: var(--body-text-line-height, 24px);
        letter-spacing: var(--body-text-letter-spacing, -0.015em);
        font-weight: var(--body-text-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .frame-295922382 {
        display: flex;
        flex-direction: row;
        gap: 32px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-296702 {
        display: flex;
        flex-direction: row;
        gap: 24px;
        align-items: flex-start;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .wednesday {
        color: var(--text-text-primary, #ffffff);
        text-align: left;
        font-family: var(--body-text-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--body-text-font-size, 14px);
        line-height: var(--body-text-line-height, 24px);
        letter-spacing: var(--body-text-letter-spacing, -0.015em);
        font-weight: var(--body-text-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .frame-296712 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .sunday {
        color: var(--text-text-primary, #ffffff);
        text-align: left;
        font-family: var(--body-text-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--body-text-font-size, 14px);
        line-height: var(--body-text-line-height, 24px);
        letter-spacing: var(--body-text-letter-spacing, -0.015em);
        font-weight: var(--body-text-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .closed {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: right;
        font-family: var(--body-text-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--body-text-font-size, 14px);
        line-height: var(--body-text-line-height, 24px);
        letter-spacing: var(--body-text-letter-spacing, -0.015em);
        font-weight: var(--body-text-font-weight, 400);
        position: relative;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    .frame-295922383 {
        display: flex;
        flex-direction: row;
        gap: 32px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .thursday {
        color: var(--text-text-primary, #ffffff);
        text-align: left;
        font-family: var(--body-text-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--body-text-font-size, 14px);
        line-height: var(--body-text-line-height, 24px);
        letter-spacing: var(--body-text-letter-spacing, -0.015em);
        font-weight: var(--body-text-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .frame-296682 {
        opacity: 0;
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    span.open-hours-open-now-span2.store-closed {
        color: #ff0000;
    }
    @media only screen and (max-width: 992px) {
        .monday,
        ._10-00-19-00,
        .tuesday,
        .wednesday,
        .thursday,
        .friday,
        .saturday,
        .sunday,
        .closed {
            font-size: 12px;
        }
        .frame-295922380,
        .frame-295922381,
        .frame-295922382,
        .frame-295922383 {
            gap: 20px;
        }
    }
`;
export default function StoreInfoPopup({ visible }) {
    const { setShowStoreInformation, storeInformation } = useContext(LayoutContext);
    const isStoreOpen = () => {
        const isWeekday = new Date().getDay() >= 1 && new Date().getDay() <= 5;
        const isSaturday = new Date().getDay() === 6;

        const openHours = isWeekday
            ? storeInformation?.openHours?.weekdays
            : isSaturday
            ? storeInformation?.openHours?.saturdays
            : storeInformation?.openHours?.sundays;

        if (!openHours || !openHours?.hourStart || !openHours?.hourEnd) return false;

        //get current time in Norway Oslo with moment
        //const currentTime = moment().utcOffset("+0100").format("HH:mm");
        const currentTime = momentTimeZone.tz("Europe/Oslo").format("HH:mm");
        const hourStart = openHours?.hourStart; // "09:00"
        const hourEnd = openHours?.hourEnd; // "18:00"

        //check if current time in Oslo is between open hours
        return currentTime >= hourStart && currentTime <= hourEnd;
    };
    function getHours(json) {
        const hours = {
            weekdays: getDayHours(json?.weekdays),
            saturdays: getDayHours(json?.saturday),
            sundays: getDayHours(json?.sunday)
        };
        return hours;
    }

    function getDayHours(day) {
        if (day?.hourStart && day?.hourEnd) {
            return `${day?.hourStart} - ${day?.hourEnd}`;
        } else {
            return "Closed";
        }
    }
    return (
        <DefaultPopupCard visible={visible} mobileUsesFullScreen={false}>
            <StyledPage className="store-card-pop-up">
                <div className="frame-295922384">
                    <div className="frame-313474">
                        <div className="gant-stortingsgata">{storeInformation?.name}</div>
                        <Frame290570 className="frame-29057 cursor-pointer" onClick={() => setShowStoreInformation(false)} />
                    </div>
                    <div className="stortingsgata-20-0161-oslo-norway">{`${storeInformation?.address} ${
                        storeInformation?.addressLine2 ? storeInformation?.addressLine2 : ""
                    } ${storeInformation?.addressNumber ? storeInformation?.addressNumber : ""}, ${storeInformation?.postalCode} ${
                        storeInformation?.city
                    }, ${storeInformation?.country}`}</div>
                </div>
                <div className="frame-313402">
                    <div className="frame-295922375">
                        <img className="image-156" src={storeInformation?.pictureURL} alt="" />
                        <div className="frame-295922374">
                            <div className="frame-313307">
                                <div className="frame-295922379">
                                    <div className="open-hours-open-now">
                                        <span>
                                            <span className="open-hours-open-now-span">Open hours:</span>
                                            <span
                                                className={
                                                    isStoreOpen() ? "open-hours-open-now-span2" : "open-hours-open-now-span2 store-closed"
                                                }>
                                                {isStoreOpen() ? " Open now" : " Currently closed"}
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div className="frame-29671">
                                    <div className="frame-295922380">
                                        <div className="frame-29669">
                                            <div className="frame-29606">
                                                <div className="monday">Monday:</div>
                                                <div className="_10-00-19-00">{getHours(storeInformation?.openHours)?.weekdays}</div>
                                            </div>
                                        </div>
                                        <div className="frame-29670">
                                            <div className="friday">Friday:</div>
                                            <div className="_10-00-19-00">{getHours(storeInformation?.openHours)?.weekdays}</div>
                                        </div>
                                    </div>
                                    <div className="frame-295922381">
                                        <div className="frame-29670">
                                            <div className="tuesday">Tuesday:</div>
                                            <div className="_10-00-19-00">{getHours(storeInformation?.openHours)?.weekdays}</div>
                                        </div>
                                        <div className="frame-29668">
                                            <div className="saturday">Saturday:</div>
                                            <div className="_10-00-19-00">{getHours(storeInformation?.openHours)?.saturdays}</div>
                                        </div>
                                    </div>
                                    <div className="frame-295922382">
                                        <div className="frame-296702">
                                            <div className="frame-29606">
                                                <div className="wednesday">Wednesday:</div>
                                                <div className="_10-00-19-00">{getHours(storeInformation?.openHours)?.weekdays}</div>
                                            </div>
                                        </div>
                                        <div className="frame-296712">
                                            <div className="sunday">Sunday:</div>
                                            <div className="closed">{getHours(storeInformation?.openHours)?.sundays}</div>
                                        </div>
                                    </div>
                                    <div className="frame-295922383">
                                        <div className="frame-296702">
                                            <div className="frame-29606">
                                                <div className="thursday">Thursday:</div>
                                                <div className="_10-00-19-00">{getHours(storeInformation?.openHours)?.weekdays}</div>
                                            </div>
                                        </div>
                                        <div className="frame-296682">
                                            <div className="sunday">Sunday:</div>
                                            <div className="closed">{getHours(storeInformation?.openHours)?.sundays}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </StyledPage>
        </DefaultPopupCard>
    );
}
