import React, { useEffect, useState, useRef } from "react";
const LayoutContext = React.createContext();

export const LayoutProvider = ({ children }) => {
    /* #region States */
    const [showHeader, setShowHeader] = useState(true); // Header
    const [showFooter, setShowFooter] = useState(false); // Footer
    const [showBOImagePageID, setShowBOImagePageID] = useState(""); // IF BOImage ourID is set, it will show the BOImage Page instead of the Feed Page in /feed route
    const [showMenuPanel, setShowMenuPanel] = useState(false); // Menu Panel
    const [locationLoader, setLocationLoader] = useState(false);
    // WINDOW WIDTH AND ISMOBILE
    const [windowWidth, setWindowWidth] = useState(window.innerWidth); // Window Width
    const [isMobile, setIsMobile] = useState(windowWidth <= 992); // Is Mobile will be true if windowWidth is less than or equal to 992
    const [cartCount, setCartCount] = useState(0);
    const [notDeliverCourier, setNotDeliverCourier] = useState(false);
    // Right Sidebar
    const [showRightSidebar, setShowRightSidebar] = useState(true); //  Right Sidebar
    const [showRightUserLocationPanel, setShowRightUserLocationPanel] = useState(false); // Right Sidebar User Location Panel
    const [showRightCheckoutPanel, setShowRightCheckoutPanel] = useState(false); // Right Sidebar Checkout Panel
    const [showRightMyOrdersPanel, setShowRightMyOrdersPanel] = useState(false); // Right Sidebar My Orders Panel
    const [showRightYourAccountPanel, setShowRightYourAccountPanel] = useState(false); // Right Sidebar Your Account Panel
    const [showRightMyOrdersDetailsPanel, setShowRightMyOrdersDetailsPanel] = useState({
        orderID: null,
        isOpen: false
    }); // Right Sidebar My Orders Details Panel
    const [showRightSettingsPanel, setShowRightSettingsPanel] = useState(false); // Right Sidebar Settings Panel
    const [orderID, setOrderID] = useState(null); //orderID for see order status in thankyou panel
    const [footerLogoClicked, setFooterLogoClicked] = useState(false);
    // Popups
    const [showPolicyPopup, setShowPolicyPopup] = useState(false); // Policy Popup, can be "terms", "privacy" or "returns"
    const [showPaymentPopup, setShowPaymentPopup] = useState(false); // checkout payment popup
    const [showThankYouPopup, setShowThankyouPopup] = useState(false); //Show ThankYou popup
    const [showCourierInstructionPopup, setShowCourierInstructionPopup] = useState(false); //Courier Instruction Popup
    const [courierInstructionsData, setCourierInstructionsData] = useState({});
    const [showShareBOimagePopup, setShowShareBOimagePopup] = useState(false);
    const [showShippingAddressPopup, setShowShippingAddressPopup] = useState(false); // Shipping Address Popup
    const [shippingAddressPopupData, setShippingAddressPopupData] = useState(null); // Shipping Address Popup Data
    const [showConfirmBox, setShowConfirmBox] = React.useState(false);
    const [showYourAccountPannel, setShowYourAccountPannel] = useState(false); //Right Sidebar Your Account
    const [validatingPasswordToken, setValidatingPasswordToken] = useState(true);
    const [passwordIsChanged, setPasswordIsChanged] = useState(false);
    const [showLoginForm, setShowLoginForm] = useState(true);
    const [showRegistration, setShowRegistration] = useState(false);
    const [showPersonalInformation, setShowPersonalInformation] = useState(false);
    const [showCardInformation, setShowCardInformation] = useState(false);
    const [showPopOver, setShowPopOver] = useState(false);
    const [showReturnAndFeedBackPopup, setShowReturnAndFeedBackPopup] = useState(false); // Return and Feedback Popup
    const [showFeedbackPopup, setShowFeedbackPopup] = useState(false); // Feedback Popup
    const [showReturnPopup, setShowReturnPopup] = useState(false); // Return Popup
    const [showReturnItemPopup, setShowReturnItemPopup] = useState(false); // Return Item Popup
    const [showFeedbackSubmittedPopup, setShowFeedbackSubmittedPopup] = useState(false); // Feedback Submitted Popup
    const [showReturnSubmittedPopup, setShowReturnSubmittedPopup] = useState(false); // Return Submitted Popup
    const [showSuggestPopup, setShowSuggestPopup] = useState(false);
    const [showQrPopup, setShowQrPopup] = useState(false);
    const [qrData, setQrData] = useState("");
    const [showBOPicpageID, setShowBOPicpageID] = useState("");
    const [paymentPopupData, setPaymentPopupData] = useState({
        firstName: undefined,
        lastName: undefined,
        email: undefined,
        phoneNumber: undefined,
        nameOnCard: undefined
    }); //payment popup data
    const [stripeCardData, setStripeCardData] = useState({
        isCardNumber: false,
        isCardExpData: false,
        isCardCvv: false,
        isComplete: false
    });
    const [placeOrderLoading, setPlaceOrderLoading] = useState(false); // Place Order Loading
    const [cardPlaceOrderLoading, setCardPlaceOrderLoading] = useState(false); // Place Order Loading

    const [showUserLoginRegisterPopup, setShowUserLoginRegisterPopup] = useState(false); // User Login Register  Popup
    const [showAccountUnsavedPopup, setShowAccountUnsavedPopup] = React.useState(false); // Account unsaved changes popup
    const [showAccountDeletePopup, setShowAccountDeletePopup] = useState(false); // Account Delete popup
    const [showForgotPasswordPopup, setShowForgotPasswordPopup] = useState(false);
    const [showPasswordForm, setShowPasswordForm] = useState(false);

    const [showBeacon, setShowBeacon] = useState(true); // State to hide and show beacons in footer
    const [showStoreInformation, setShowStoreInformation] = useState(false);
    const [storeInformation, setStoreInformation] = useState("");
    const [homeLoge, setHomeLogo] = useState(false);
    const [feedLogo, setFeedLogo] = useState(false);
    const [recentlyViewedImages, setRecentlyViewedImages] = useState([]);
    const [shareLocationSuccess, setShareLocationSuccess] = React.useState(true);
    const [showProductGalleryLightbox, setShowProductGalleryLightbox] = useState({
        isOpen: false,
        showProductDetails: true,
        slides: []
    }); // Product Gallery Lightbox
    const [quickBuyPopup, setQuickBuyPopup] = useState(false);
    const [fireworks, setFireworks] = useState([]);
    const [showFireWorks, setShowFireWorks] = useState(false);
    const mainContent = useRef(null);
    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        const handleWindowSizeChange = () => {
            setWindowWidth(window.innerWidth);
            setIsMobile(window.innerWidth <= 992);
        };

        window.addEventListener("resize", handleWindowSizeChange);

        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    /* #endregion */

    return (
        <LayoutContext.Provider
            value={{
                showHeader,
                setShowHeader,
                showFooter,
                setShowFooter,
                showBOImagePageID,
                setShowBOImagePageID,
                showMenuPanel,
                setShowMenuPanel,
                windowWidth,
                isMobile,
                showRightSidebar,
                setShowRightSidebar,
                showRightUserLocationPanel,
                setShowRightUserLocationPanel,
                showRightCheckoutPanel,
                setShowRightCheckoutPanel,
                showRightMyOrdersPanel,
                setShowRightMyOrdersPanel,
                showRightYourAccountPanel,
                setShowRightYourAccountPanel,
                showPolicyPopup,
                setShowPolicyPopup,
                showShippingAddressPopup,
                setShowShippingAddressPopup,
                shippingAddressPopupData,
                setShippingAddressPopupData,
                showPaymentPopup,
                setShowPaymentPopup,
                paymentPopupData,
                setPaymentPopupData,
                stripeCardData,
                setStripeCardData,
                placeOrderLoading,
                setPlaceOrderLoading,
                setShowThankyouPopup,
                showThankYouPopup,
                setShowUserLoginRegisterPopup,
                showUserLoginRegisterPopup,
                showRightMyOrdersDetailsPanel,
                setShowRightMyOrdersDetailsPanel,
                showRightSettingsPanel,
                setShowRightSettingsPanel,
                showYourAccountPannel,
                setShowYourAccountPannel,
                showAccountDeletePopup,
                setShowAccountDeletePopup,
                showConfirmBox,
                setShowConfirmBox,
                showForgotPasswordPopup,
                setShowForgotPasswordPopup,
                validatingPasswordToken,
                setValidatingPasswordToken,
                passwordIsChanged,
                setPasswordIsChanged,
                setShowPasswordForm,
                showPasswordForm,
                showLoginForm,
                setShowLoginForm,
                showRegistration,
                setShowRegistration,
                showPersonalInformation,
                setShowPersonalInformation,
                showCardInformation,
                setShowCardInformation,
                orderID,
                setOrderID,
                showCourierInstructionPopup,
                setShowCourierInstructionPopup,
                showAccountUnsavedPopup,
                setShowAccountUnsavedPopup,
                courierInstructionsData,
                setCourierInstructionsData,
                showBeacon,
                setShowBeacon,
                showShareBOimagePopup,
                setShowShareBOimagePopup,
                showStoreInformation,
                setShowStoreInformation,
                storeInformation,
                setStoreInformation,
                showPopOver,
                setShowPopOver,
                showReturnAndFeedBackPopup,
                setShowReturnAndFeedBackPopup,
                showFeedbackPopup,
                setShowFeedbackPopup,
                showReturnPopup,
                setShowReturnPopup,
                showReturnItemPopup,
                setShowReturnItemPopup,
                showFeedbackSubmittedPopup,
                setShowFeedbackSubmittedPopup,
                showReturnSubmittedPopup,
                setShowReturnSubmittedPopup,
                showQrPopup,
                setShowQrPopup,
                qrData,
                setQrData,
                cardPlaceOrderLoading,
                setCardPlaceOrderLoading,
                showProductGalleryLightbox,
                setShowProductGalleryLightbox,
                homeLoge,
                setHomeLogo,
                feedLogo,
                setFeedLogo,
                showSuggestPopup,
                setShowSuggestPopup,
                recentlyViewedImages,
                setRecentlyViewedImages,
                mainContent,
                shareLocationSuccess,
                setShareLocationSuccess,
                locationLoader,
                setLocationLoader,
                quickBuyPopup,
                setQuickBuyPopup,
                fireworks,
                setFireworks,
                showFireWorks,
                setShowFireWorks,
                showBOPicpageID,
                setShowBOPicpageID,
                cartCount,
                setCartCount,
                footerLogoClicked,
                setFooterLogoClicked,
                notDeliverCourier,
                setNotDeliverCourier
            }}>
            {children}
        </LayoutContext.Provider>
    );
};

export default LayoutContext;
