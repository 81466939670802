import React from "react";
import styled from "styled-components";
import EditPen from "../../../../assets/images/edit-new-pen.svg";

/* #region STYLED CSS */
const StyledPage = styled.div`
    &.frame-313547 {
        background: #151515;
        border-radius: 5px;
        border-style: solid;
        border-color: var(--border-border-primary, rgba(166, 166, 166, 0.2));
        border-width: 1px;
        padding: 16px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-295922844 {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-295922834 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-end;
        flex-shrink: 0;
        position: relative;
    }
    .product-name {
        color: var(--text-text-primary, #ffffff);
        text-align: left;
        font-family: var(--caption-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--caption-font-size, 12px);
        line-height: var(--caption-line-height, 20px);
        font-weight: var(--caption-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .vector {
        flex-shrink: 0;
        width: 28.2px;
        height: 16px;
        position: relative;
        overflow: visible;
        object-fit: cover;
    }
    .line-31 {
        margin-top: -1px;
        border-style: solid;
        border-color: var(--border-border-primary, rgba(166, 166, 166, 0.2));
        border-width: 1px 0 0 0;
        align-self: stretch;
        flex-shrink: 0;
        height: 0px;
        position: relative;
    }
    .frame-295922843 {
        display: flex;
        flex-direction: row;
        gap: 24px;
        align-items: flex-start;
        justify-content: space-between;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
        height: 60px;
    }
    .frame-295922841 {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-295922812 {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: flex-end;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313385 {
        display: flex;
        flex-direction: column;
        gap: 36px;
        align-items: flex-end;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313378 {
        display: flex;
        flex-direction: row;
        gap: 6px;
        align-items: center;
        justify-content: flex-start;
        flex-shrink: 0;
        position: relative;
        cursor: pointer;
    }
    .icon {
        border-radius: 0px;
        flex-shrink: 0;
        position: relative;
        overflow: visible;
    }
    .edit {
        color: var(--text-text-brand, #f7665f);
        text-align: left;
        font-family: var(--caption-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--caption-font-size, 12px);
        line-height: var(--caption-line-height, 20px);
        font-weight: var(--caption-font-weight, 400);
        position: relative;
    }
    .main-address {
        width: 312px;
        display: flex;
        flex-direction: column;
        gap: 9px;
    }
    .deliver-to,
    .deliver-to-instruction {
        color: #8c8c8c;
        font-size: 12px;
        font-weight: 400;
        font-family: var(--caption-font-family, "OpenSans-Regular", sans-serif);
    }
    .deliver-to-address {
        color: #ffffff;
        font-size: 12px;
        font-weight: 400;
        font-family: var(--caption-font-family, "OpenSans-Regular", sans-serif);
    }
`;
/* #endregion STYLED CSS */
export default function CourierCard({
    courierImage,
    toAddress,
    priceStr,
    currency = "NOK",
    courierInstructionsData,
    handleEditClick,
    item,
    placeOrderLoading
}) {
    return (
        <StyledPage className="frame-313547">
            <div className="frame-295922844">
                <div className="frame-295922834">
                    <div className="product-name">Delivery by</div>
                    <img className="vector" src={courierImage} alt="vector0" />
                </div>
                <div className="product-name">{`${priceStr} ${currency}`}</div>
            </div>
            <div className="line-31"></div>
            <div className="frame-295922843">
                <div className="main-address">
                    <div className="deliver-to">Deliver to:</div>
                    <div className="deliver-to-address">{toAddress}</div>
                    <div className="deliver-to-instruction">
                        {!courierInstructionsData ? "(No instructions added)" : `(${courierInstructionsData})`}
                    </div>
                </div>
                <div className="frame-295922841">
                    <div className="frame-295922812">
                        <div className="frame-313385">
                            <div
                                className="frame-313378"
                                onClick={e => {
                                    placeOrderLoading ? e.preventDefault() : handleEditClick(item?.itemID);
                                }}
                                style={{ opacity: placeOrderLoading ? "0.3" : "1", cursor: placeOrderLoading ? "no-drop" : "pointer" }}>
                                <img className="icon" src={EditPen} alt="EditPen" />
                                <div className="edit">Edit</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </StyledPage>
    );
}
