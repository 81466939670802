import styled from "styled-components";
import { DefaultPopupCard } from "./DefaultPopupCard";
import { ReactComponent as Frame290570 } from "../../../assets/images/close-btn.svg";
import Copy from "./../../../assets/images/copy.png";
import Share from "./../../../assets/images/share-07.png";
import { useContext } from "react";
import LayoutContext from "../../../contexts/LayoutContext";
import { useState } from "react";
import { useEffect } from "react";

const ShareBOimagePopupStyle = styled.div`
    .frame-29643,
    .frame-29643 * {
        box-sizing: border-box;
    }
    .frame-29643 {
        // padding: 24px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-end;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-3590 {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .group-3413 {
        flex-shrink: 0;
        width: 50px;
        height: 50px;
        position: static;
    }
    .ellipse-80 {
        background: var(--wrap-wrap-brand, rgba(247, 102, 95, 0.1));
        border-radius: 50%;
        width: 50px;
        height: 50px;
        position: absolute;
        left: calc(50% - 25px);
        top: calc(50% - 25px);
    }
    .share-07 {
        width: 28px;
        height: 50%;
        position: relative;
        left: 20.4%;
        top: 11px;
        overflow: visible;
    }
    .share-b-oimage {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-family: "OpenSans-SemiBold", sans-serif;
        font-size: 22px;
        line-height: 24px;
        font-weight: 600;
        position: relative;
        align-self: stretch;
    }
    .frame-296432 {
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .separator {
        display: flex;
        flex-direction: row;
        gap: 6px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .line-27 {
        margin-top: -0.5px;
        border-style: solid;
        border-color: var(--border-border-tertiary, #373737);
        border-width: 0.5px 0 0 0;
        flex: 1;
        height: 0px;
        position: relative;
        transform-origin: 0 0;
        transform: rotate(0.001deg) scale(1, 1);
    }
    .text-label-here {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: center;
        font-family: var(--caption-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--caption-font-size, 12px);
        line-height: var(--caption-line-height, 20px);
        font-weight: var(--caption-font-weight, 400);
        position: relative;
    }
    .line-26 {
        margin-top: -0.5px;
        border-style: solid;
        border-color: var(--border-border-tertiary, #373737);
        border-width: 0.5px 0 0 0;
        flex: 1;
        height: 0px;
        position: relative;
        transform-origin: 0 0;
        transform: rotate(0.001deg) scale(1, 1);
    }
    .input-field {
        background: var(--surface-surface-secondary, #121212);
        border-radius: 5px;
        border-style: solid;
        border-color: var(--border-border-primary, rgba(166, 166, 166, 0.2));
        border-width: 1px;
        padding: 8px 8px 8px 16px;
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
        box-shadow: var(--dropdown-input-field-box-shadow, 0px 0px 15px 0px rgba(0, 0, 0, 0.25));
    }
    .frame-29645 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .frame-29647 {
        flex: 1;
        height: 24px;
        position: relative;
        overflow: hidden;
    }
    .holzweiler-com-image-12-s-2-axz-ya-789829 {
        color: var(--text-text-primary, #ffffff);
        text-align: left;
        font-family: var(--subtitle-2-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 14px);
        line-height: var(--subtitle-2-line-height, 24px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: absolute;
        left: 0px;
        top: 0px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        white-space: nowrap;
        overflow: scroll;
        width: 275px;
    }
    .buttons {
        background: var(--buttons-button-secondary, #212121);
        border-radius: 5px;
        padding: 16px;
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 93px;
        height: 32px;
        position: relative;
        box-shadow: var(--bt-drop-shadow-box-shadow, 0px 0px 4px 0px rgba(30, 38, 49, 0.35));
        cursor: pointer;
    }
    .frame-29646 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .copy-03 {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        position: relative;
        overflow: visible;
    }
    .copy-url {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-family: var(--button-text-small-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--button-text-small-font-size, 12px);
        line-height: var(--button-text-small-line-height, 16px);
        font-weight: var(--button-text-small-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

const ShareBOimagePopup = ({ visible }) => {
    const { setShowShareBOimagePopup } = useContext(LayoutContext);
    const [isCopy, setIsCopy] = useState(false);
    const [url, setUrl] = useState("");
    useEffect(() => {
        setUrl(window.location.href);
    }, [url]);
    const copyUrl = () => {
        navigator.clipboard.writeText(url);
        setIsCopy(true);
    };
    return (
        <DefaultPopupCard visible={visible} backgroundColor="#121212" mobileUsesFullScreen={false}>
            <ShareBOimagePopupStyle>
                <div className="frame-29643">
                    <div className="frame-3590">
                        <div className="group-3413">
                            <div className="ellipse-80"></div>
                            <img className="share-07" src={Share} alt="" />
                        </div>
                        <Frame290570
                            style={{ position: "absolute", right: "0px" }}
                            className="frame-29057 cursor-pointer"
                            onClick={() => setShowShareBOimagePopup(false)}
                        />
                    </div>
                    <div className="share-b-oimage">Share BOimage!</div>
                    <div className="frame-296432">
                        <div className="separator">
                            <div className="line-27"></div>
                            <div className="text-label-here">Copy link below</div>
                            <div className="line-26"></div>
                        </div>
                    </div>
                    <div className="input-field">
                        <div className="frame-29645">
                            <div className="frame-29647">
                                <div className="holzweiler-com-image-12-s-2-axz-ya-789829">{window.location.href}</div>
                            </div>
                            <div className="buttons" onClick={copyUrl}>
                                <div className="frame-29646">
                                    <img className="copy-03" src={Copy} alt="" />

                                    <div className="copy-url">{isCopy ? "Copied" : "Copy URL"}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ShareBOimagePopupStyle>
        </DefaultPopupCard>
    );
};
export default ShareBOimagePopup;
