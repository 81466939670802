import styled from "styled-components";
import buttonSpinner from "../../../assets/images/buttonSpinner.png";

const ButtonStyles = styled.div`
    .button-spinner {
        width: 18px !important;
        height: 18px !important;
        animation: spin 1s linear infinite;
    }
    @keyframes spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
`;

const ButtonLoader = () => {
    return (
        <ButtonStyles>
            <img src={buttonSpinner} alt="" className="button-spinner" />
        </ButtonStyles>
    );
};

export default ButtonLoader;
