import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as Frame290570 } from "../../../../assets/images/close-btn.svg";
import LayoutContext from "../../../../contexts/LayoutContext";
import Spinner from "../../../../assets/images/spinner.svg";
import { isValidEmail } from "../../../../utils/Formatter";
import * as Library from "../../../../utils/Library";
import SpinnerPopup from "./Spinner";
import UserContext from "../../../../contexts/UserContext";
/* #region CSS */
const StyledPage = styled.div`
    &.frame-29643,
    &.frame-29643 * {
        box-sizing: border-box;
    }
    &.frame-29643 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313583 {
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313367 {
        display: flex;
        flex-direction: column;
        gap: 40px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313591 {
        display: flex;
        flex-direction: row;
        gap: 40px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .forgot-password {
        color: var(--text-text-primary, #ffffff);
        text-align: left;
        font-family: "OpenSans-SemiBold", sans-serif;
        font-size: 22px;
        line-height: 28px;
        font-weight: 600;
        position: relative;
        flex: 1;
    }
    .frame-29057 {
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 30px;
        height: 30px;
        position: relative;
        overflow: visible;
    }
    .enter-the-email-address-associated-with-your-account-and-we-ll-send-you-a-link-to-reset-your-password {
        color: #8c8c8c;
        text-align: left;
        font-family: var(--subtitle-2-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 14px);
        line-height: var(--subtitle-2-line-height, 24px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
        align-self: stretch;
    }
    .frame-313584 {
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .input-field {
        background: var(--surface-surface-secondary, #121212);
        border-radius: 5px;
        border-style: solid;
        border-color: var(--border-border-primary, rgba(166, 166, 166, 0.2));
        border-width: 1px;
        padding: 16px;
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        height: 46px;
        position: relative;
        box-shadow: var(--dropdown-input-field-box-shadow, 0px 0px 15px 0px rgba(0, 0, 0, 0.25));
    }
    .label {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: left;
        font-family: var(--label-medium-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--label-medium-font-size, 14px);
        line-height: var(--label-medium-line-height, 14px);
        font-weight: var(--label-medium-font-weight, 400);
        position: relative;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .btn-md-solid {
        background: var(--buttons-button-primary, #f7665f);
        border-radius: 5px;
        opacity: 0.2;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        height: 44px;
        position: relative;
        box-shadow: var(--bt-drop-shadow-box-shadow, 0px 0px 4px 0px rgba(30, 38, 49, 0.35));
        cursor: no-drop;
    }
    .frame-313497 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313349 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .button {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-family: var(--button-text-medium-regular-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--button-text-medium-regular-font-size, 14px);
        line-height: var(--button-text-medium-regular-line-height, 24px);
        font-weight: var(--button-text-medium-regular-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .floating-input {
        position: relative;
        width: 100%;
    }
    .customer-info-inputs {
        background-color: initial;
        border: 1px solid #373737;
        border-radius: 5px;
        color: #fff;
        display: inline-block;
        height: 0px;
        outline: none;
        padding: 28px 16px 16px;
        width: 100%;
    }
    .floating-input input:focus + label,
    .floating-input input:not(:placeholder-shown) + label {
        color: #8c8c8c;
        font-size: 8px;
        font-weight: 600;
        left: 18px;
        top: 5px;
    }
    .floating-input label {
        color: #8c8c8c;
        font-size: 14px;
        font-weight: 400;
        left: 22px;
        pointer-events: none;
        position: absolute;
        top: 13px;
        transition: all 0.2s ease;
    }
    .customer-info-inputs.has-value {
        opacity: 1;
        border: 1px solid #8c8f93;
    }
    .btn-md-solid.active {
        opacity: 1;
        cursor: pointer;
    }
    .spinner-text {
        display: flex;
        flex-direction: row;
    }
    .spinner-img {
        animation: spin 1s linear infinite;
    }

    @keyframes spin {
        to {
            transform: rotate(360deg);
        }
    }
    .floating-input .error {
        border-color: #f7665f !important;
    }
    .floating-input .disabled {
        opacity: 0.2 !important;
    }
    .error-text {
        font-size: 12px;
        color: #f7665f;
        position: absolute;
    }
`;
/* #endregion CSS */
export default function ForgotPassword({ setIsMailSent }) {
    /* #region VARS */
    const { setShowForgotPasswordPopup, setShowUserLoginRegisterPopup } = React.useContext(LayoutContext);
    const { userData } = React.useContext(UserContext);

    const [email, setEmail] = useState(localStorage.getItem("isLoggedIn") === "true" ? userData?.email : "");
    const [emailError, setEmailError] = useState(false);
    const [emailErrorText, setEmailErrorText] = useState("");
    const [emailHasValue, setEmailHasValue] = useState(false);
    const [isButtonDisable, setIsButtonDisable] = useState(true);
    const [isFormSubmit, setIsFormSubmit] = useState(false);
    /* #endregion VARS */
    /* #region METHODS */
    const handleCloseLogin = () => {
        setShowForgotPasswordPopup(false);
        if (localStorage.getItem("isLoggedIn") === "true") {
            setShowUserLoginRegisterPopup(false);
        }
    };
    const handleUserEmail = e => {
        setEmailError(false);
        setEmailErrorText("");
        setEmail(e.target.value);
    };
    const handleValidateForm = () => {
        let isFormValid = true;
        if (!email) {
            setEmailError(true);
            isFormValid = false;
        }
        if (email && !isValidEmail(email)) {
            setEmailError(true);
            isFormValid = false;
            setEmailErrorText("Invalid email");
        }
        return isFormValid;
    };
    const handleResetFormSubmitFunction = () => {
        if (!isFormSubmit && handleValidateForm()) {
            setIsFormSubmit(true);
            Library.makePostRequest(
                "requestNewPassword",
                {
                    email: email
                },
                false,
                setIsFormSubmit
            )
                .then(res => {
                    const { data } = res;
                    if (data?.success) {
                        setIsMailSent(true);
                    }
                })
                .catch(e => {
                    console.log(e);
                });
        }
    };
    /* #endregion METHODS */
    /* #region EFFECTS */
    React.useEffect(() => {
        if (email) {
            setEmailHasValue(true);
            setIsButtonDisable(false);
        } else {
            setIsButtonDisable(true);
            setEmailHasValue(false);
        }
    }, [email]);
    useEffect(() => {
        if (localStorage.getItem("isLoggedIn") === "true") {
            handleResetFormSubmitFunction();
        }
    }, [localStorage.getItem("isLoggedIn")]);
    /* #endregion */
    return (
        <>
            {localStorage.getItem("isLoggedIn") === "true" ? (
                <SpinnerPopup />
            ) : (
                <StyledPage className="frame-29643">
                    <div className="frame-313583">
                        <div className="frame-313367">
                            <div className="frame-313591">
                                <div className="forgot-password">Forgot password</div>
                                <Frame290570 className="frame-29057 cursor-pointer" onClick={handleCloseLogin} />
                            </div>
                        </div>
                        <div className="enter-the-email-address-associated-with-your-account-and-we-ll-send-you-a-link-to-reset-your-password">
                            Enter the email address associated with your account and we’ll send you a link to reset your password.
                        </div>
                    </div>
                    <div className="frame-313584">
                        <div className="floating-input">
                            <input
                                type="text"
                                className={`customer-info-inputs ${emailHasValue ? "has-value" : ""} ${emailError ? "error" : ""} ${
                                    isFormSubmit ? "disabled" : ""
                                }`}
                                placeholder=" "
                                autoComplete="off"
                                value={email}
                                onChange={handleUserEmail}
                                disabled={isFormSubmit}
                            />
                            <label>Email</label>
                            <div className="error-text">{emailErrorText}</div>
                        </div>
                        <div className={`btn-md-solid  ${!isButtonDisable ? "active" : ""}`}>
                            <div className="frame-313497">
                                <div className="frame-313349">
                                    <div className="button" onClick={handleResetFormSubmitFunction}>
                                        {isFormSubmit ? (
                                            <span className="spinner-text">
                                                <img src={Spinner} alt="" className="spinner-img" />
                                                &nbsp;
                                                {"  "}Please wait
                                            </span>
                                        ) : (
                                            "Send link to my email"
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </StyledPage>
            )}
        </>
    );
}
