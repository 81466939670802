import React, { useState } from "react";
import styled from "styled-components";
import Vector from "../../assets/images/chevron-up0.svg";
import IconVisa from "../../assets/images/icon-visa.png";
import IconEdit from "../../assets/images/icon-edit.svg";
import LayoutContext from "../../contexts/LayoutContext";
import UserContext from "../../contexts/UserContext";
import logGA4Event from "../../utils/firebase/analytics";
/* #region SCOPED CSS */
const ConfirmPurchaseStyles = styled.div`
    &.frame-29741,
    &.frame-29741 * {
        box-sizing: border-box;
    }
    &.frame-29741 {
        background: var(--dark-neutrals-n-500, #121212);
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        position: relative;
    }
    .your-info-container {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 100%;
        position: relative;
        margin-top: 16px;

        transition: height 0.4s ease-in-out;
    }
    .place-order-container {
        background: var(--dark-neutrals-n-500, #121212);
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 100%;
        position: relative;
        z-index: 9;
    }
    .frame-313386 {
        display: flex;
        flex-direction: column;
        gap: 5px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313653 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313391 {
        display: flex;
        flex-direction: row;
        gap: 5px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313390 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        position: relative;

        transition: opacity 0.2s;
    }
    .frame-29716 {
        display: flex;
        flex-direction: column;
        gap: 0px;
        align-items: flex-start;
        justify-content: center;
        flex: 1;
        position: relative;
    }
    .your-info {
        color: #ffffff;
        text-align: left;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: -0.015em;
        font-weight: 600;
        position: relative;
        width: 321px;
    }
    .solid {
        transform: rotate(0deg);
        transition: transform 0.3s ease 0s;
    }
    .solid.enabled {
        transform: rotate(180deg);
    }
    .separator {
        display: flex;
        flex-direction: row;
        gap: 6px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        height: auto;
        position: relative;
        overflow: visible;
        margin: 0px !important;
    }
    .frame-313398 {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313396 {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: flex-start;
        justify-content: center;
        flex: 1;
        position: relative;
    }
    .frame-313392 {
        display: flex;
        flex-direction: column;
        gap: 2px;
        align-items: flex-start;
        justify-content: flex-start;
        flex: 1;
        height: 86px;
        position: relative;
    }
    .frame-297162 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex: 1;
        position: relative;
    }
    .product-name {
        color: var(--dark-text-icons-and-buttons-white-600, #ffffff);
        text-align: left;
        font-size: 12px;
        line-height: 20px;
        font-weight: 600;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .frame-29719 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-297163 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .product-name2 {
        color: var(--dark-text-icons-and-buttons-gray-500, #8c8c8c);
        text-align: left;
        font-family: var(--caption-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--caption-font-size, 12px);
        line-height: var(--caption-line-height, 20px);
        font-weight: var(--caption-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .frame-29720 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .product-name3 {
        color: var(--dark-text-icons-and-buttons-gray-500, #8c8c8c);
        text-align: left;
        font-family: "OpenSans-Regular", sans-serif;
        font-size: 12px;
        line-height: 20px;
        font-weight: 400;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .frame-29721 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313397 {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: flex-start;
        justify-content: center;
        flex: 1;
        position: relative;
    }
    .frame-3133922 {
        display: flex;
        flex-direction: column;
        gap: 2px;
        align-items: flex-end;
        justify-content: center;
        flex: 1;
        height: 86px;
        position: relative;
    }
    .image-144 {
        flex-shrink: 0;
        width: 32.87px;
        height: 20px;
        position: relative;
        object-fit: cover;
    }
    .frame-29722 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-end;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-297164 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-end;
        flex: 1;
        position: relative;
    }
    .frame-29723 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313384 {
        display: flex;
        flex-direction: column;
        gap: 36px;
        align-items: flex-end;
        justify-content: flex-start;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313378 {
        display: flex;
        flex-direction: row;
        gap: 6px;
        align-items: center;
        justify-content: flex-start;
        flex-shrink: 0;
        position: relative;
    }
    .icon {
        border-radius: 0px;
        flex-shrink: 0;
        width: 8px;
        height: 8px;
        position: relative;
        overflow: visible;
    }
    .change {
        color: #ffffff;
        text-align: left;
        font-size: 12px;
        font-weight: 600;
        position: relative;
        cursor: pointer;
    }
    .frame-313387 {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .separator2 {
        display: flex;
        flex-direction: row;
        gap: 6px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .line-27 {
        margin-top: -0.5px;
        border-style: solid;
        border-color: var(--border-border-tertiary, #373737);
        border-width: 0.5px 0 0 0;
        flex: 1;
        height: 0px;
        position: relative;
        transform-origin: 0 0;
        transform: rotate(0.001deg) scale(1, 1);
    }
    .text-label-here {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: center;
        font-family: var(--caption-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--caption-font-size, 12px);
        line-height: var(--caption-line-height, 20px);
        font-weight: var(--caption-font-weight, 400);
        position: relative;
    }
    .line-26 {
        margin-top: -0.5px;
        border-style: solid;
        border-color: var(--border-border-tertiary, #373737);
        border-width: 0.5px 0 0 0;
        flex: 1;
        height: 0px;
        position: relative;
        transform-origin: 0 0;
        transform: rotate(0.001deg) scale(1, 1);
    }
    .frame-313385 {
        display: flex;
        flex-direction: column;
        gap: 5px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        height: 41px;
        position: relative;
    }
    .frame-29432 {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        flex-shrink: 0;
        width: 100%;
        height: 41px;
        position: relative;
    }
    .price {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        position: relative;
        flex: 1;
        height: 24px;
    }
    .price-span {
        color: var(--text-text-primary, #ffffff);
        font-family: "OpenSans-Bold", sans-serif;
        font-size: 30px;
        line-height: 24px;
        font-weight: 700;
    }
    .price-span2 {
        color: var(--text-text-primary, #ffffff);
        font-family: "OpenSans-Bold", sans-serif;
        font-size: 20px;
        line-height: 24px;
        font-weight: 700;
    }
    .btn-lg-solid {
        background: var(--buttons-button-primary, #f7665f);
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        height: 48px;
        position: relative;
        box-shadow: var(--bt-drop-shadow-box-shadow, 0px 0px 4px 0px rgba(30, 38, 49, 0.35));
    }
    .frame-29590 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .button {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-family: var(--button-text-medium-regular-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--button-text-medium-regular-font-size, 14px);
        line-height: var(--button-text-medium-regular-line-height, 24px);
        font-weight: var(--button-text-medium-regular-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px !important;
    }
    .privacy-disclaimer {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: center;
        font-family: "OpenSans-Regular", sans-serif;
        font-size: 10px;
        line-height: 16px;
        font-weight: 400;
        position: relative;
        align-self: stretch;
    }
    .privacy-disclaimer-span {
        color: var(--text-text-secondary, #8c8c8c);
        font-family: "OpenSans-Regular", sans-serif;
        font-size: 10px;
        line-height: 16px;
        font-weight: 400;
    }
    .privacy-disclaimer-span2 {
        color: var(--text-text-secondary, #8c8c8c);
        font-family: "OpenSans-Regular", sans-serif;
        font-size: 10px;
        line-height: 16px;
        font-weight: 400;
        text-decoration: underline;
    }
    .privacy-disclaimer-span3 {
        color: var(--text-text-secondary, #8c8c8c);
        font-family: "OpenSans-Regular", sans-serif;
        font-size: 10px;
        line-height: 16px;
        font-weight: 400;
    }
    .privacy-disclaimer-span4 {
        color: var(--text-text-secondary, #8c8c8c);
        font-family: "OpenSans-Regular", sans-serif;
        font-size: 10px;
        line-height: 16px;
        font-weight: 400;
        text-decoration: underline;
    }
    .privacy-disclaimer-span5 {
        color: var(--text-text-secondary, #8c8c8c);
        font-family: "OpenSans-Regular", sans-serif;
        font-size: 10px;
        line-height: 16px;
        font-weight: 400;
    }
    .privacy-disclaimer.no-info {
        font-size: 14px;
        line-height: 24px;
    }
    .privacy-disclaimer.no-info .privacy-disclaimer-span2,
    .privacy-disclaimer.no-info .privacy-disclaimer-span3 {
        font-size: 14px;
        line-height: 24px;
    }
    .privacy-disclaimer-span2:hover,
    .privacy-disclaimer-span4:hover {
        color: #f7665f;
    }
`;
/* #endregion */

export default function ConfirmPurchaseLayout({
    userName,
    email,
    phone,
    cardNumber,
    cardType = "visa",
    cardExpDate,
    totalAmount,
    currency = "NOK",
    handleSubmit,
    isMobile
}) {
    /* #region VARS */
    const [showYourInfo, setShowYourInfo] = useState(false);
    const { setShowPolicyPopup, setShowPaymentPopup, setPaymentPopupData, setPlaceOrderLoading } = React.useContext(LayoutContext);
    const { checkoutPaymentData, userData } = React.useContext(UserContext);
    /* #endregion */

    /* #region METHODS */
    const placeOrderClicked = () => {
        // If all fields are filled, place order without showing payment popup
        if (validateAllFields()) {
            setPaymentPopupData({
                firstName: userData?.firstName,
                lastName: userData?.lastName,
                email: userData?.email,
                phoneNumber: userData?.phone,
                nameOnCard: checkoutPaymentData?.cardHolder
            });
            setPlaceOrderLoading(true);
            handleSubmit("SAVED_PAYMENT");
            //alert("SAVED_PAYMENT");
        } else {
            setShowPaymentPopup(true);
            logGA4Event("Confirm_PopUp_UserInfo");
        }
    };

    const validateAllFields = () => {
        const { cardNumber, cardHolder, cardExpiration, cardCVV, cardName, customerID, paymentMethodID } = checkoutPaymentData;
        const { firstName, lastName, email, phone } = userData;

        if (
            !cardNumber ||
            !cardHolder ||
            !cardExpiration ||
            !cardCVV ||
            !cardName ||
            !customerID ||
            !paymentMethodID ||
            !firstName ||
            !lastName ||
            !email ||
            !phone
        ) {
            //TODO: REMOVE LATER... TEMPORARY FOR TESTING
            /*console.log("Missing fields", checkoutPaymentData, userData);*/
            return false;
        }
        return true;
    };

    /* #endregion */

    return (
        <ConfirmPurchaseStyles className="frame-29741" style={{ paddingRight: isMobile && "16px" }}>
            {checkoutPaymentData?.cardNumber && (
                <YourInfoSection
                    showYourInfo={showYourInfo}
                    setShowYourInfo={setShowYourInfo}
                    cardType={cardType}
                    cardNumber={cardNumber}
                    cardExpDate={cardExpDate}
                    userName={userName}
                    email={email}
                    phone={phone}
                    setShowPaymentPopup={setShowPaymentPopup}
                    setPaymentPopupData={setPaymentPopupData}
                    userData={userData}
                    checkoutPaymentData={checkoutPaymentData}
                />
            )}

            <PlaceOrderSection
                totalAmount={totalAmount}
                currency={currency}
                setShowPolicyPopup={setShowPolicyPopup}
                placeOrderClicked={placeOrderClicked}
                setShowPaymentPopup={setShowPaymentPopup}
                checkoutPaymentData={checkoutPaymentData}
            />
        </ConfirmPurchaseStyles>
    );
}

/* #region SMALL COMPONENTS */
const YourInfoSection = ({
    showYourInfo,
    setShowYourInfo,
    cardType,
    cardNumber,
    cardExpDate,
    userName,
    email,
    phone,
    setShowPaymentPopup,
    setPaymentPopupData,
    userData,
    checkoutPaymentData
}) => {
    return (
        <div className="your-info-container" style={{ height: showYourInfo ? "143px" : "19px" }}>
            <div className="frame-313386">
                <div className="frame-313653">
                    <div className="frame-313391">
                        <div className="frame-313390" style={{ opacity: showYourInfo ? "1" : "0" }}>
                            <div className="frame-29716">
                                <div className="your-info">Your info</div>
                            </div>
                        </div>

                        <div className="frame-313390" style={{ position: "absolute", left: "0", opacity: showYourInfo ? "0" : "1" }}>
                            <div className="group-3591">
                                <img className="image-143" src={IconVisa} alt="" />
                            </div>
                            <div className="frame-29716">
                                <div className="_3459">{`****${cardNumber}`}</div>
                            </div>
                        </div>

                        <img
                            className={`solid cursor-pointer ${showYourInfo ? "enabled" : ""}`}
                            src={Vector}
                            alt=""
                            onClick={() => setShowYourInfo(!showYourInfo)}
                        />
                    </div>
                </div>
            </div>
            <div className="separator" />
            <div className="frame-313398">
                <div className="frame-313396">
                    <div className="frame-313392">
                        <div className="frame-297162">
                            <div className="product-name">Customer info</div>
                        </div>
                        <div className="frame-29719">
                            <div className="frame-297163">
                                <div className="product-name2">{userName}</div>
                            </div>
                        </div>
                        <div className="frame-29720">
                            <div className="frame-297163">
                                <div className="product-name3">{email}</div>
                            </div>
                        </div>
                        <div className="frame-29721">
                            <div className="frame-297163">
                                <div className="product-name3">{phone}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="frame-313397">
                    <div className="frame-3133922">
                        <img className="image-144" src={cardType === "visa" ? IconVisa : undefined} alt="" />
                        <div className="frame-29722">
                            <div className="frame-297164">
                                <div className="product-name3">Visa ending *{cardNumber}</div>
                            </div>
                        </div>
                        <div className="frame-29723">
                            <div className="frame-297164">
                                <div className="product-name3">Expires {cardExpDate}</div>
                            </div>
                        </div>
                        <div className="frame-313384">
                            <div className="frame-313378">
                                <img className="icon" src={IconEdit} alt="" />
                                <div
                                    className="change"
                                    onClick={() => {
                                        console.log("Show payment popup", checkoutPaymentData, userData);
                                        setShowPaymentPopup(true);
                                    }}>
                                    Change
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const PlaceOrderSection = ({ totalAmount, currency, setShowPolicyPopup, placeOrderClicked, checkoutPaymentData }) => {
    return (
        <div className="place-order-container">
            <div className="frame-313387">
                <div className="separator2">
                    <div className="line-27"></div>
                    <div className="text-label-here">Total to pay</div>
                    <div className="line-26"></div>
                </div>
                <div className="frame-313385">
                    <div className="frame-29432">
                        <div className="price">
                            <span>
                                <span className="price-span">{totalAmount}</span>
                                <span className="price-span2">&nbsp;{currency}</span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="btn-lg-solid cursor-pointer" onClick={placeOrderClicked}>
                <div className="frame-29590">
                    <div className="button">{checkoutPaymentData?.cardNumber ? "Place order" : "Confirm"}</div>
                </div>
            </div>
            {checkoutPaymentData?.cardNumber ? (
                <div className="privacy-disclaimer">
                    <span>
                        <span className="privacy-disclaimer-span">
                            By clicking &quot;Place order&quot; you agree to the
                            <br />
                        </span>
                        <span className="privacy-disclaimer-span2 cursor-pointer" onClick={() => setShowPolicyPopup("terms")}>
                            Terms
                        </span>
                        <span className="privacy-disclaimer-span3"> | </span>
                        <span className="privacy-disclaimer-span4 cursor-pointer" onClick={() => setShowPolicyPopup("privacy")}>
                            Privacy policy
                        </span>
                        <span className="privacy-disclaimer-span3"> | </span>
                        <span className="privacy-disclaimer-span4 cursor-pointer" onClick={() => setShowPolicyPopup("returns")}>
                            Returns
                        </span>
                        <span className="privacy-disclaimer-span5">&nbsp;of BOtag Norge AS</span>
                    </span>
                </div>
            ) : (
                <div className="privacy-disclaimer no-info">
                    <span className="privacy-disclaimer-span-no-info">BOtag Norge AS - </span>
                    <span className="privacy-disclaimer-span2 cursor-pointer" onClick={() => setShowPolicyPopup("terms")}>
                        Terms
                    </span>
                    <span className="privacy-disclaimer-span3"> | </span>
                    <span className="privacy-disclaimer-span2 cursor-pointer" onClick={() => setShowPolicyPopup("privacy")}>
                        Privacy policy
                    </span>
                    <span className="privacy-disclaimer-span3"> | </span>
                    <span className="privacy-disclaimer-span2 cursor-pointer" onClick={() => setShowPolicyPopup("returns")}>
                        Returns
                    </span>
                </div>
            )}
        </div>
    );
};
/* #endregion */
