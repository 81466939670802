import React from "react";
import styled from "styled-components";
import boIkon10 from "../../../assets/images/bo-ikon-10.svg";
import LayoutContext from "./../../../contexts/LayoutContext";
import { detect } from "detect-browser";
/* #region CSS */
const StyledPage = styled.div`
    &.frame-295922556,
    &.frame-295922556 * {
        box-sizing: border-box;
    }
    &.frame-295922556 {
        display: flex;
        flex-direction: column;
        gap: 32px;
        align-items: center;
        justify-content: flex-start;
        position: relative;
        padding: 0px 25px;
        padding-bottom: 32px;
    }
    .exclusion-7 {
        flex-shrink: 0;
        width: 52px;
        height: 46px;
        position: relative;
        overflow: visible;
    }
    .frame-295922535 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    ._2024-b-otag-norge-as {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: center;
        font-family: var(--subtitle-2-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 14px);
        line-height: var(--subtitle-2-line-height, 24px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .div {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: center;
        font-family: var(--subtitle-2-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 14px);
        line-height: var(--subtitle-2-line-height, 24px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .all-rights-reserved {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: center;
        font-family: var(--subtitle-2-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 14px);
        line-height: var(--subtitle-2-line-height, 24px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .returns {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: center;
        font-family: var(--subtitle-2-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 14px);
        line-height: var(--subtitle-2-line-height, 24px);
        font-weight: var(--subtitle-2-font-weight, 400);
        text-decoration: underline;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .returns:hover,
    .privacy-policy:hover,
    .terms-conditions:hover {
        color: #f7665f;
    }
    .terms-conditions {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: center;
        font-family: var(--subtitle-2-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 14px);
        line-height: var(--subtitle-2-line-height, 24px);
        font-weight: var(--subtitle-2-font-weight, 400);
        text-decoration: underline;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .privacy-policy {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: center;
        font-family: var(--subtitle-2-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 14px);
        line-height: var(--subtitle-2-line-height, 24px);
        font-weight: var(--subtitle-2-font-weight, 400);
        text-decoration: underline;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    @media only screen and (max-width: 992px) {
        .frame-295922535 {
            flex-direction: column;
        }
        .div {
            display: none;
        }
    }
`;
/* #endregion CSS */
export default function Footer() {
    const { setShowPolicyPopup, isMobile } = React.useContext(LayoutContext);
    const browser = detect();
    return (
        <StyledPage className="frame-295922556">
            <img className="exclusion-7" src={boIkon10} alt="home" />
            <div className="frame-295922535">
                <div className="_2024-b-otag-norge-as">© 2024 BOtag Norge AS</div>
                <div className="div">|</div>
                <div className="all-rights-reserved">All rights reserved</div>
                <div className="div">|</div>
                <div className="returns" onClick={() => setShowPolicyPopup("returns")}>
                    Returns
                </div>
                <div className="div">|</div>
                <div className="terms-conditions" onClick={() => setShowPolicyPopup("terms")}>
                    Terms &amp; Conditions
                </div>
                <div className="div">|</div>
                <div className="privacy-policy" onClick={() => setShowPolicyPopup("privacy")}>
                    Privacy Policy
                </div>
            </div>
        </StyledPage>
    );
}
