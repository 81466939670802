import React, { useEffect } from "react";
import LayoutContext from "../../../contexts/LayoutContext";
import UserLocationPanel from "./UserLocationPanel";
import CheckoutPanel from "./CheckoutPanel";
import SettingsPannel from "./SettingsPannel";
import AnchorDrawer from "../../../AnchorDrawer";
import OrdersPanel from "./OrdersPanel";
import OrderDetailsPanel from "./OrderDetailsPanel";
import YourAccountPannel from "./YourAccountPannel";
import ProductDetailsPanel from "./ProductDetailsSection";
import { detect } from "detect-browser";

export default function RightPanelDrawerSwitcher() {
    /* #region VARS */
    let customClass = "";
    const browser = detect();
    const {
        isMobile,
        showRightUserLocationPanel,
        showRightCheckoutPanel,
        showRightMyOrdersPanel,
        showRightSettingsPanel,
        showRightMyOrdersDetailsPanel,
        showYourAccountPannel,
        showProductGalleryLightbox,

        setShowRightCheckoutPanel,
        setShowRightMyOrdersPanel,
        setShowRightSettingsPanel,
        setShowRightMyOrdersDetailsPanel,
        setShowYourAccountPannel
    } = React.useContext(LayoutContext);

    const UserLocation = <UserLocationPanel />;
    const ProductDetails = <ProductDetailsPanel showLightboxPanelData={true} />;
    const Checkout = <CheckoutPanel />;
    const MyOrders = <OrdersPanel />;
    const OrderDetails = <OrderDetailsPanel />;
    const Settings = <SettingsPannel />;
    const YourAccount = <YourAccountPannel />;
    /* #endregion */

    /* #region METHODS */
    const processRightPanelOrDrawer = () => {
        //TODO: THIS METHOD CAN BE IMPROVED
        let PanelToShow = null;
        let MobileDrawerToShow = null;
        //PRODUCT DETAILS PANEL WHEN SHOWING GALLERY
        if (showProductGalleryLightbox?.isOpen && showProductGalleryLightbox?.showProductDetails !== false && !isMobile)
            PanelToShow = ProductDetails;
        //CHECKOUT PANEL
        else if (showRightCheckoutPanel && !isMobile) PanelToShow = Checkout;
        else if (showRightCheckoutPanel && isMobile) {
            MobileDrawerToShow = Checkout;
            customClass = "custom-checkout-class";
        }
        //ORDERS PANEL
        else if (showRightMyOrdersPanel && !isMobile) PanelToShow = MyOrders;
        else if (showRightMyOrdersPanel && isMobile) MobileDrawerToShow = MyOrders;
        //ORDERS DETAIL PANEL
        else if (showRightMyOrdersDetailsPanel?.isOpen && !isMobile) PanelToShow = OrderDetails;
        else if (showRightMyOrdersDetailsPanel?.isOpen && isMobile) MobileDrawerToShow = OrderDetails;
        //SETTINGS PANEL
        else if (showRightSettingsPanel && !isMobile) PanelToShow = Settings;
        else if (showRightSettingsPanel && isMobile) MobileDrawerToShow = Settings;
        //YOUR ACCOUNT PANEL
        else if (showYourAccountPannel && !isMobile) PanelToShow = YourAccount;
        else if (showYourAccountPannel && isMobile) MobileDrawerToShow = YourAccount;
        //USER LOCATION PANEL (This should be the last one)
        else if (showRightUserLocationPanel && !isMobile) PanelToShow = UserLocation;
        if (isMobile && showRightUserLocationPanel) PanelToShow = UserLocation;

        if (!PanelToShow && !MobileDrawerToShow) return { PanelToShow: null, MobileDrawerToShow: null };
        return { PanelToShow, MobileDrawerToShow };
    };

    /* #endregion */

    const { PanelToShow, MobileDrawerToShow } = processRightPanelOrDrawer();

    /* #region HANDLING OPENING A NEW PANEL */
    useEffect(() => {
        if (showRightUserLocationPanel) handleNewPanelOpened("UserLocation");
    }, [showRightUserLocationPanel]);

    useEffect(() => {
        if (showRightCheckoutPanel) handleNewPanelOpened("Checkout");
    }, [showRightCheckoutPanel]);

    useEffect(() => {
        if (showRightMyOrdersPanel) handleNewPanelOpened("MyOrders");
    }, [showRightMyOrdersPanel]);

    useEffect(() => {
        if (showRightSettingsPanel) handleNewPanelOpened("Settings");
    }, [showRightSettingsPanel]);

    useEffect(() => {
        if (showRightMyOrdersDetailsPanel) handleNewPanelOpened("OrderDetails");
    }, [showRightMyOrdersDetailsPanel]);

    useEffect(() => {
        if (showYourAccountPannel) handleNewPanelOpened("YourAccount");
    }, [showYourAccountPannel]);

    /**
     * Handles closing of other panels when a new panel is opened except for UserLocationPanel that stays open in background
     * @param {string} name - Name of the panel that is opened
     * @returns {void}
     **/
    const handleNewPanelOpened = name => {
        if (name === "UserLocation") {
            setShowRightCheckoutPanel(false);
            setShowRightMyOrdersPanel(false);
            setShowRightSettingsPanel(false);
            setShowRightMyOrdersDetailsPanel(false);
            setShowYourAccountPannel(false);
        }
        if (name === "Checkout") {
            setShowRightMyOrdersPanel(false);
            setShowRightSettingsPanel(false);
            setShowRightMyOrdersDetailsPanel(false);
            setShowYourAccountPannel(false);
        }
        if (name === "MyOrders") {
            setShowRightCheckoutPanel(false);
            setShowRightSettingsPanel(false);
            setShowRightMyOrdersDetailsPanel(false);
            setShowYourAccountPannel(false);
        }
        if (name === "Settings") {
            setShowRightCheckoutPanel(false);
            setShowRightMyOrdersPanel(false);
            setShowRightMyOrdersDetailsPanel(false);
            setShowYourAccountPannel(false);
        }
        if (name === "OrderDetails") {
            setShowRightCheckoutPanel(false);
            setShowRightMyOrdersPanel(false);
            setShowRightSettingsPanel(false);
            setShowYourAccountPannel(false);
        }
        if (name === "YourAccount") {
            setShowRightCheckoutPanel(false);
            setShowRightMyOrdersPanel(false);
            setShowRightSettingsPanel(false);
            setShowRightMyOrdersDetailsPanel(false);
        }
    };
    /* #endregion */

    return (
        (PanelToShow !== null || MobileDrawerToShow !== null) && (
            <div className="mainRightMenu">
                {MobileDrawerToShow !== null && (
                    <AnchorDrawer anchor="bottom" isOpen={true} customClass={customClass}>
                        {MobileDrawerToShow}
                    </AnchorDrawer>
                )}
                {PanelToShow !== null && PanelToShow}
            </div>
        )
    );
}
