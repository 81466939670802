import React, { Suspense } from "react";
import NoInternetMarker from "./components/common/NoInternetMarker";
import PreLoader from "./components/common/loaders/PreLoader";
import { createBrowserRouter, RouterProvider, Outlet, useLocation, useNavigate } from "react-router-dom";
import "./App.css";
import "./assets/css/Responsive.css";
import "./assets/css/Colors.css";
import "./assets/css/UtilityClasses.css";
import { AllRoutes } from "./routes/AllRoutes";
import Header from "./components/common/mainApp/Header";
import Footer from "./components/common/mainApp/Footer";
import AppContext from "./contexts/AppContext";
import LayoutContext from "./contexts/LayoutContext";
import StandardsContext from "./contexts/StandardsContext";
import DefaultErrorPopup from "./components/common/dialogs/DefaultErrorPopup";
import ShowPolicyDialog from "./components/common/dialogs/ShowPolicyDialog";
import { ToastContainer } from "react-toastify";
import ShipmentAdressPopup from "./components/common/dialogs/ShipmentAdressPopup";
import RightPanelDrawerSwitcher from "./components/common/mainApp/RightPanelDrawerSwitcher";
import RegistrationLoginForgotPassword from "./components/common/dialogs/UserRegistrationAndLogin/RegistrationLoginForgotPassword";
import DeleteAccountPopup from "./components/common/dialogs/DeleteAccountPopup";
import ConfirmLeavePage from "./components/common/dialogs/ConfirmLeavePage";
import ResetPasswordMain from "./components/common/dialogs/UserRegistrationAndLogin/ResetPasswordMain";
import ThankYouPopup from "./components/common/dialogs/ThankYouPopup";
import UserContext from "./contexts/UserContext";
import ShareBOimagePopup from "./components/common/dialogs/ShareBOimagePopup";
import StoreInfoPopup from "./components/common/dialogs/StoreInfoPopup";
import FeedbackAndReturnPopup from "./components/common/dialogs/FeedbackAndReturnPopup";
import FeedbackPopup from "./components/common/dialogs/FeedbackPopup";
import ReturnPopup from "./components/common/dialogs/ReturnPopup";
import ReturnItemPopup from "./components/common/dialogs/ReturnItemPopup";
import FeedbackSubmittedPopup from "./components/common/dialogs/FeedbackSubmittedPopup";
import ReturnSubmittedPopup from "./components/common/dialogs/ReturnSubmittedPopup";
import OrderSubmitLoader from "./components/common/dialogs/OrderSubmitLoader";
import OrderQRPopup from "./components/common/dialogs/OrderQRPopup";
import BOTagLightBox from "./components/pages/BOImage/BOTagComponents/BOTagLightBox";
import SuggestBrand from "./components/common/dialogs/SuggestBrand";
import { DefaultNotificationCard } from "./components/common/dialogs/DefaultNotificationCard";
import QuickBuy from "./components/common/dialogs/QuickBuy/Index";
import NotDeliverByCourier from "./components/common/dialogs/NotDeliverByCourier";

export default function App() {
    const router = createBrowserRouter([{ element: <RouterLayoutTemplate />, children: AllRoutes }]);
    return <RouterProvider router={router} />;
}

/* #region SMALL COMPONENTS */

function RouterLayoutTemplate() {
    /* #region VARS */
    const navigate = useNavigate();
    const location = useLocation();
    const passwordToken = new URLSearchParams(location.search).get("passwordToken");
    const { appPasswordActive, appPasswordValidated } = React.useContext(AppContext);
    const {
        showHeader,
        showFooter,
        showPolicyPopup,
        showShippingAddressPopup,
        showBOImagePageID,
        isMobile,
        showAccountDeletePopup,
        showUserLoginRegisterPopup,
        showConfirmBox,
        showAccountUnsavedPopup,
        showThankYouPopup,
        showShareBOimagePopup,
        showStoreInformation,
        showReturnAndFeedBackPopup,
        showFeedbackPopup,
        showReturnPopup,
        showReturnItemPopup,
        showFeedbackSubmittedPopup,
        showReturnSubmittedPopup,
        placeOrderLoading,
        showQrPopup,
        showProductGalleryLightbox,
        showSuggestPopup,
        quickBuyPopup,
        notDeliverCourier
    } = React.useContext(LayoutContext);
    const { checkoutCartData } = React.useContext(UserContext);

    const locationClass = showBOImagePageID ? "boImagePage" : location.pathname.split("/")[1] + "Page";
    /* #endregion */

    /* #region EFFECTS */
    React.useEffect(() => {
        if (!appPasswordActive) return;
        if (appPasswordValidated) return;

        if (!localStorage.getItem("BOTagCode")) {
            navigate("/password");
            return;
        }

        if (location.pathname === "/password" && appPasswordValidated) {
            navigate("/feed");
            return;
        }
    }, [appPasswordActive, location.pathname, localStorage.getItem("BOTagCode")]);

    React.useEffect(() => {
        //setTimeout 60min
        const timer = setTimeout(() => {
            //TODO: Check if password is valid
            //TODO: Add fullscreen loader while checking
        }, 3600000);

        return () => {
            clearTimeout(timer);
        };
    }, []);

    /* #endregion */
    return (
        <div className="wrapper">
            <NoInternetMarker />
            <ToastContainer />
            <DefaultNotificationCard />

            <Suspense
                fallback={
                    <div>
                        <PreLoader />
                    </div>
                }>
                {showThankYouPopup && <ThankYouPopup checkoutCartData={checkoutCartData} />}
                {showShareBOimagePopup && <ShareBOimagePopup />}
                {showReturnAndFeedBackPopup && <FeedbackAndReturnPopup />}
                {showFeedbackPopup && <FeedbackPopup />}
                {showReturnPopup && <ReturnPopup />}
                {showReturnItemPopup && <ReturnItemPopup />}
                {showFeedbackSubmittedPopup && <FeedbackSubmittedPopup />}
                {showReturnSubmittedPopup && <ReturnSubmittedPopup />}

                <div className={`mainWrapper ${locationClass} ${isMobile ? "mobile" : "desktop"}`}>
                    <ErrorDialog />

                    <div className="leftSide">
                        {showHeader && <Header className="mainHeader" />}
                        <div className="mainContent" id="mainContent">
                            {" "}
                            {<Outlet /> /* ROUTES WILL RENDER HERE */}{" "}
                        </div>
                        {showFooter && <Footer className="mainFooter" />}

                        {showProductGalleryLightbox?.isOpen && !isMobile && <BOTagLightBox />}
                    </div>

                    <RightPanelDrawerSwitcher />
                </div>

                {/* POPUPS */}
                {showShippingAddressPopup && <ShipmentAdressPopup />}
                {showPolicyPopup && <ShowPolicyDialog />}
                {showUserLoginRegisterPopup && <RegistrationLoginForgotPassword />}
                {showAccountDeletePopup && <DeleteAccountPopup />}
                {showConfirmBox && <ConfirmLeavePage />}
                {showAccountUnsavedPopup && <ConfirmLeavePage />}
                {passwordToken && <ResetPasswordMain />}
                {showStoreInformation && <StoreInfoPopup />}
                {placeOrderLoading && <OrderSubmitLoader />}
                {showQrPopup && <OrderQRPopup />}
                {showSuggestPopup && <SuggestBrand />}
                {showProductGalleryLightbox?.isOpen && isMobile && <BOTagLightBox />}
                {quickBuyPopup && <QuickBuy />}
                {notDeliverCourier && <NotDeliverByCourier />}
            </Suspense>
        </div>
    );
}

//TODO: REVIEW THIS COMPONENT TO BE MORE INTUITIVE
function ErrorDialog() {
    const {
        errorDialogShow,
        setErrorDialogShow,
        errorDialogTitle,
        setErrorDialogTitle,
        errorDialogMessage,
        setErrorDialogMessage,
        errorDialogConfirmText,
        errorDialogCancelText,
        setErrorDialogAction
    } = React.useContext(StandardsContext);

    return errorDialogShow ? (
        <div>
            <DefaultErrorPopup
                title={errorDialogTitle}
                message={errorDialogMessage}
                confirmBtnText={errorDialogConfirmText}
                cancelBtnText={errorDialogCancelText}
                cancelBtnAction={() => {
                    setErrorDialogShow(false);
                    setErrorDialogTitle("");
                    setErrorDialogMessage("");
                    setErrorDialogAction("cancel");
                }}
                confirmBtnAction={() => {
                    setErrorDialogShow(false);
                    setErrorDialogTitle("");
                    setErrorDialogMessage("");
                    setErrorDialogAction("confirm");
                }}
            />
        </div>
    ) : null;
}

/* #endregion */
