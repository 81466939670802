import React, { useState } from "react";
import styled from "styled-components";
import LayoutContext from "../../../../contexts/LayoutContext";
import { ReactComponent as Frame290570 } from "../../../../assets/images/close-btn.svg";
import PhoneNumberInput from "../../forms/PhoneNumberInput";
import { phoneNumberPattern } from "../../../../utils/helperFunctions";
import * as Library from "../../../../utils/Library";
import StandardsContext from "../../../../contexts/StandardsContext";
import UserContext from "../../../../contexts/UserContext";

/* #region STYLED CSS */
const StyledPage = styled.div`
    &.frame-29643,
    &.frame-29643 * {
        box-sizing: border-box;
    }
    &.frame-29643 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313565 {
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313366 {
        display: flex;
        flex-direction: column;
        gap: 40px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313591 {
        display: flex;
        flex-direction: row;
        gap: 40px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .welcome {
        color: var(--text-text-primary, #ffffff);
        text-align: left;
        font-family: "OpenSans-SemiBold", sans-serif;
        font-size: 22px;
        line-height: 28px;
        font-weight: 600;
        position: relative;
        flex: 1;
    }
    .frame-29057 {
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 30px;
        height: 30px;
        position: relative;
        overflow: visible;
    }
    .let-s-complete-your-account-with-2-simple-steps-you-can-also-do-this-later-in-menu-settings {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: left;
        font-family: var(--subtitle-2-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 14px);
        line-height: var(--subtitle-2-line-height, 24px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
        align-self: stretch;
    }
    .frame-313567 {
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313566 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .add-your-info {
        color: var(--text-text-primary, #ffffff);
        text-align: left;
        font-family: var(--heading-4-font-family, "OpenSans-SemiBold", sans-serif);
        font-size: var(--heading-4-font-size, 14px);
        line-height: var(--heading-4-line-height, 20px);
        font-weight: var(--heading-4-font-weight, 600);
        position: relative;
        align-self: stretch;
    }
    .frame-313372 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-29733 {
        display: flex;
        flex-direction: row;
        gap: 12px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .input-field {
        background: var(--surface-surface-secondary, #121212);
        border-radius: 5px;
        border-style: solid;
        border-color: var(--border-border-primary, rgba(166, 166, 166, 0.2));
        border-width: 1px;
        padding: 16px;
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        position: relative;
        box-shadow: var(--dropdown-input-field-box-shadow, 0px 0px 15px 0px rgba(0, 0, 0, 0.25));
    }
    .label {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: left;
        font-family: var(--label-medium-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--label-medium-font-size, 14px);
        line-height: var(--label-medium-line-height, 14px);
        font-weight: var(--label-medium-font-weight, 400);
        position: relative;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .input-field2 {
        background: var(--surface-surface-secondary, #121212);
        border-radius: 5px;
        border-style: solid;
        border-color: var(--border-border-secondary, #8c8f93);
        border-width: 1px;
        opacity: 0.2;
        padding: 16px;
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        height: 46px;
        position: relative;
        box-shadow: var(--dropdown-input-field-box-shadow, 0px 0px 15px 0px rgba(0, 0, 0, 0.25));
    }
    .frame-313475 {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: flex-start;
        justify-content: center;
        flex: 1;
        height: 14px;
        position: relative;
    }
    .label2 {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: left;
        font-family: var(--label-xsmall-font-family, "OpenSans-SemiBold", sans-serif);
        font-size: var(--label-xsmall-font-size, 8px);
        line-height: var(--label-xsmall-line-height, 8px);
        font-weight: var(--label-xsmall-font-weight, 600);
        position: relative;
        align-self: stretch;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .label3 {
        color: var(--text-text-primary, #ffffff);
        text-align: left;
        font-family: var(--label-medium-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--label-medium-font-size, 14px);
        line-height: var(--label-medium-line-height, 14px);
        font-weight: var(--label-medium-font-weight, 400);
        position: relative;
        align-self: stretch;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .input-field-number {
        background: var(--surface-surface-secondary, #121212);
        border-radius: 5px;
        border-style: solid;
        border-color: var(--border-border-primary, rgba(166, 166, 166, 0.2));
        border-width: 1px;
        padding: 8px 16px 8px 16px;
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
        box-shadow: var(--dropdown-input-field-box-shadow, 0px 0px 15px 0px rgba(0, 0, 0, 0.25));
    }
    .frame-29021 {
        display: flex;
        flex-direction: row;
        gap: 7px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        height: 30px;
        position: relative;
    }
    .emoji-norway {
        flex-shrink: 0;
        width: 18px;
        height: 18px;
        position: relative;
        overflow: visible;
    }
    .chevron-down {
        flex-shrink: 0;
        width: 12px;
        height: 12px;
        position: relative;
        overflow: visible;
    }
    .frame-29524 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: flex-start;
        flex-shrink: 0;
        position: relative;
    }
    ._47 {
        color: var(--text-text-primary, #ffffff);
        text-align: left;
        font-family: var(--label-medium-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--label-medium-font-size, 14px);
        line-height: var(--label-medium-line-height, 14px);
        font-weight: var(--label-medium-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .phone-number {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: left;
        font-family: var(--label-medium-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--label-medium-font-size, 14px);
        line-height: var(--label-medium-line-height, 14px);
        font-weight: var(--label-medium-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .frame-313572 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313387 {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .separator {
        display: flex;
        flex-direction: row;
        gap: 6px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .line-27 {
        margin-top: -0.5px;
        border-style: solid;
        border-color: var(--border-border-tertiary, #373737);
        border-width: 0.5px 0 0 0;
        flex: 1;
        height: 0px;
        position: relative;
        transform-origin: 0 0;
        transform: rotate(0.001deg) scale(1, 1);
    }
    .text-label-here {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: center;
        font-family: var(--caption-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--caption-font-size, 12px);
        line-height: var(--caption-line-height, 20px);
        font-weight: var(--caption-font-weight, 400);
        position: relative;
    }
    .line-26 {
        margin-top: -0.5px;
        border-style: solid;
        border-color: var(--border-border-tertiary, #373737);
        border-width: 0.5px 0 0 0;
        flex: 1;
        height: 0px;
        position: relative;
        transform-origin: 0 0;
        transform: rotate(0.001deg) scale(1, 1);
    }
    .frame-29637 {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .btn-md-solid {
        background: var(--buttons-button-secondary, #212121);
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex: 1;
        height: 44px;
        position: relative;
        box-shadow: var(--bt-drop-shadow-box-shadow, 0px 0px 4px 0px rgba(30, 38, 49, 0.35));
        cursor: pointer;
    }
    .frame-313497 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313349 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .button {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-family: var(--button-text-medium-regular-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--button-text-medium-regular-font-size, 14px);
        line-height: var(--button-text-medium-regular-line-height, 24px);
        font-weight: var(--button-text-medium-regular-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .btn-md-solid2 {
        background: var(--buttons-button-primary, #f7665f);
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex: 1;
        height: 44px;
        position: relative;
        box-shadow: var(--bt-drop-shadow-box-shadow, 0px 0px 4px 0px rgba(30, 38, 49, 0.35));
        cursor: pointer;
    }
    .floating-input {
        position: relative;
        width: 100%;
    }
    .customer-info-inputs {
        background-color: initial;
        border: 1px solid #373737;
        border-radius: 5px;
        color: #fff;
        display: inline-block;
        height: 0px;
        outline: none;
        padding: 28px 16px 16px;
        width: 100%;
    }
    .floating-input input:focus + label,
    .floating-input input:not(:placeholder-shown) + label {
        color: #8c8c8c;
        font-size: 8px;
        font-weight: 600;
        left: 18px;
        top: 5px;
    }
    .floating-input label {
        color: #8c8c8c;
        font-size: 14px;
        font-weight: 400;
        left: 22px;
        pointer-events: none;
        position: absolute;
        top: 13px;
        transition: all 0.2s ease;
    }
    .customer-info-inputs.has-value {
        opacity: 1;
        border: 1px solid #8c8f93;
    }
`;
/* #endregion STYLED CSS */
export default function PersonalInformation() {
    /* #region VARS */
    const { setShowUserLoginRegisterPopup, setShowLoginForm, setShowRegistration, setShowPersonalInformation, setShowCardInformation } =
        React.useContext(LayoutContext);
    const { setDefaultNotificationCard } = React.useContext(StandardsContext);
    const { userData, setUserData } = React.useContext(UserContext);

    const [firstName, setFirstName] = React.useState(userData?.firstName || "");
    const [lastName, setLastName] = React.useState(userData?.lastName || "");
    const [phoneNumber, setPhoneNumber] = React.useState(userData?.phone || "");
    const [isLoading, setIsLoading] = useState(false);
    const email = userData?.email;

    const formValidation = firstName?.length > 0 && lastName?.length > 0 && phoneNumber?.length > 5 ? true : false;
    /* #endregion VARS */

    /* #region METHODS */
    const handleCloseLogin = () => {
        setShowUserLoginRegisterPopup(false);
        //Library.showSuccessMessage("Account has been created.");

        Library.showNotification({
            message: "Account has been created.",
            type: "success",
            setDefaultNotificationCard
        });
    };
    const handleFirstName = e => {
        setFirstName(e.target.value);
    };
    const handleLastName = e => {
        setLastName(e.target.value);
    };
    const gotoNextStepFun = () => {
        setShowPersonalInformation(false);
        setShowLoginForm(false);
        setShowRegistration(false);
        setShowCardInformation(true);
    };
    const callback = (e, c) => {
        setPhoneNumber(phoneNumberPattern(e, c?.format));
    };
    const updateBOthebotAccount = async () => {
        gotoNextStepFun();
        const formData = {
            bothebotAccountID: localStorage.getItem("pid"),
            firstName: firstName,
            lastName: lastName,
            email: email,
            phoneNumber: phoneNumber
        };

        try {
            await Library.makePostRequest("updateBOthebotAccount", formData, false, setIsLoading, error => {}, false, false);

            setUserData({
                firstName: firstName,
                lastName: lastName,
                email: email,
                phone: phoneNumber
            });
        } catch (error) {
            //Library.showErrorMessage("Something went wrong. Code 400");
            console.error("getUserCodeRequest", error);
            Library.showNotification({
                message: "Something went wrong. Code 400",
                type: "error",
                setDefaultNotificationCard
            });
        }
    };

    /* #endregion METHODS */

    return (
        <StyledPage className="frame-29643">
            <div className="frame-313565">
                <div className="frame-313366">
                    <div className="frame-313591">
                        <div className="welcome">Welcome!</div>
                        <Frame290570 className="frame-29057 cursor-pointer" onClick={handleCloseLogin} />
                    </div>
                </div>
                <div className="let-s-complete-your-account-with-2-simple-steps-you-can-also-do-this-later-in-menu-settings">
                    Let’s complete your account with 2 simple steps. You can also do this later in menu / settings.
                </div>
            </div>
            <div className="frame-313567">
                <div className="frame-313566">
                    <div className="add-your-info">Add your info</div>
                    <div className="frame-313372">
                        <div className="frame-29733">
                            <div className="floating-input">
                                <input
                                    type="text"
                                    className={"customer-info-inputs"}
                                    placeholder=" "
                                    value={firstName}
                                    autoComplete="off"
                                    onChange={handleFirstName}
                                />
                                <label>First name</label>
                            </div>
                            <div className="floating-input">
                                <input
                                    type="text"
                                    className={"customer-info-inputs"}
                                    placeholder=" "
                                    value={lastName}
                                    autoComplete="off"
                                    onChange={handleLastName}
                                />
                                <label>Last name</label>
                            </div>
                        </div>
                        <div className="input-field2">
                            <div className="frame-313475">
                                <div className="label2">Email</div>
                                <div className="label3">{email}</div>
                            </div>
                        </div>
                        <div className="floating-input">
                            <PhoneNumberInput callback={callback} phoneNumber={phoneNumber} />
                        </div>
                    </div>
                </div>
                <div className="frame-313572">
                    <div className="frame-313387">
                        <div className="separator">
                            <div className="line-27"></div>
                            <div className="text-label-here">Step 1 / 2</div>
                            <div className="line-26"></div>
                        </div>
                    </div>
                    <div className="frame-29637">
                        <div className="btn-md-solid" onClick={gotoNextStepFun}>
                            <div className="frame-313497">
                                <div className="frame-313349">
                                    <div className="button">Skip step</div>
                                </div>
                            </div>
                        </div>
                        <div className="btn-md-solid2" style={{ opacity: formValidation ? 1 : 0.2 }} onClick={updateBOthebotAccount}>
                            <div className="frame-313497">
                                <div className="frame-313349">
                                    <div className="button">Next</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </StyledPage>
    );
}
