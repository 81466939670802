import styled from "styled-components";
import { DefaultPopupCard } from "./DefaultPopupCard";
import CheckIcon from "./../../../assets/images/success.png";
import Bulb from "./../../../assets/images/bulb.png";
import React from "react";
import LayoutContext from "../../../contexts/LayoutContext";
import * as Formatter from "../../../utils/Formatter";
import UserContext from "../../../contexts/UserContext";
import logGA4Event from "../../../utils/firebase/analytics";

/* #region SCOPED CSS */
const ThankYouPopupStyles = styled.div`
    .frame-29643,
    .frame-29643 * {
        box-sizing: border-box;
    }
    .frame-29643 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-end;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
        padding-top: 20px;
    }
    .frame-313412 {
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: flex-end;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313402 {
        display: flex;
        flex-direction: row;
        gap: 32px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313406 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex: 1;
        position: relative;
    }
    .frame-3134022 {
        display: flex;
        flex-direction: column;
        gap: 32px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313401 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-3590 {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
        margin: 15px 0px;
    }
    .confetti-success-1 {
        flex-shrink: 0;
        width: 127px;
        height: 126.02px;
        position: absolute;
        object-fit: cover;
    }
    .thank-you-morten {
        color: #ffffff;
        text-align: center;
        font-family: var(--heading-1-font-family, "OpenSans-Bold", sans-serif);
        font-size: var(--heading-1-font-size, 24px);
        line-height: var(--heading-1-line-height, 24px);
        font-weight: var(--heading-1-font-weight, 700);
        position: relative;
        align-self: stretch;
    }
    .frame-313418 {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .please-wait-for-store-confirmation-receipt-and-order-confirmation-sent-to-morten-botag-no {
        text-align: center;
        font-family: var(--subtitle-2-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 14px);
        line-height: var(--subtitle-2-line-height, 24px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
        flex: 1;
    }
    .please-wait-for-store-confirmation-receipt-and-order-confirmation-sent-to-morten-botag-no-span {
        color: var(--text-text-warning, #ff9900);
        font-family: var(--subtitle-2-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 14px);
        line-height: var(--subtitle-2-line-height, 24px);
        font-weight: var(--subtitle-2-font-weight, 400);
    }
    .please-wait-for-store-confirmation-receipt-and-order-confirmation-sent-to-morten-botag-no-span2 {
        color: #8c8c8c;
        font-family: var(--subtitle-2-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 14px);
        line-height: var(--subtitle-2-line-height, 24px);
        font-weight: var(--subtitle-2-font-weight, 400);
    }
    .please-wait-for-store-confirmation-receipt-and-order-confirmation-sent-to-morten-botag-no-span3 {
        color: var(--text-text-secondary, #8c8c8c);
        font-family: var(--subtitle-2-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 14px);
        line-height: var(--subtitle-2-line-height, 24px);
        font-weight: var(--subtitle-2-font-weight, 400);
    }
    .please-wait-for-store-confirmation-receipt-and-order-confirmation-sent-to-morten-botag-no-span4 {
        color: var(--text-text-primary, #ffffff);
        font-family: var(--subtitle-2-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 14px);
        line-height: var(--subtitle-2-line-height, 24px);
        font-weight: var(--subtitle-2-font-weight, 400);
    }
    .info-action-card {
        border-radius: 5px;
        border-style: solid;
        border-color: var(--border-border-primary, rgba(166, 166, 166, 0.2));
        border-width: 1px;
        padding: 10px 16px 10px 16px;
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-29614 {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .frame-313540 {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .please-share-your-experience-with-us {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: left;
        font-family: var(--caption-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--caption-font-size, 12px);
        line-height: var(--caption-line-height, 20px);
        font-weight: var(--caption-font-weight, 400);
        position: relative;
        flex: 1;
    }
    .buttons {
        border-radius: 5px;
        border-style: solid;
        border-color: var(--border-border-primary, rgba(166, 166, 166, 0.2));
        border-width: 1px;
        padding: 8px;
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 101px;
        height: 32px;
        position: relative;
        box-shadow: var(--bt-drop-shadow-box-shadow, 0px 0px 4px 0px rgba(30, 38, 49, 0.35));
    }
    .button {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-family: var(--button-text-small-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--button-text-small-font-size, 12px);
        line-height: var(--button-text-small-line-height, 16px);
        font-weight: var(--button-text-small-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .frame-29729 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .separator {
        display: flex;
        flex-direction: row;
        gap: 6px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
        margin: 0 !important;
        padding: none !important;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        border-style: none !important;
        border-color: none !important;
        border-width: 0 !important;
    }
    .line-27 {
        margin-top: -0.5px;
        border-style: solid;
        border-color: var(--border-border-tertiary, #373737);
        border-width: 0.5px 0 0 0;
        flex: 1;
        height: 0px;
        position: relative;
        transform-origin: 0 0;
        transform: rotate(0.001deg) scale(1, 1);
    }
    .text-label-here {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: center;
        font-family: var(--caption-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--caption-font-size, 12px);
        line-height: var(--caption-line-height, 20px);
        font-weight: var(--caption-font-weight, 400);
        position: relative;
    }
    .line-26 {
        margin-top: -0.5px;
        border-style: solid;
        border-color: var(--border-border-tertiary, #373737);
        border-width: 0.5px 0 0 0;
        flex: 1;
        height: 0px;
        position: relative;
        transform-origin: 0 0;
        transform: rotate(0.001deg) scale(1, 1);
    }
    .price {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        position: relative;
        align-self: stretch;
        height: 24px;
    }
    .price-span {
        color: var(--text-text-primary, #ffffff);
        font-family: var(--heading-1-font-family, "OpenSans-Bold", sans-serif);
        font-size: var(--heading-1-font-size, 24px);
        line-height: var(--heading-1-line-height, 24px);
        font-weight: var(--heading-1-font-weight, 700);
    }
    .price-span2 {
        color: var(--text-text-primary, #ffffff);
        font-family: "OpenSans-Bold", sans-serif;
        font-size: 18px;
        line-height: 24px;
        font-weight: 700;
    }
    .frame-313541 {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: center;
        justify-content: flex-end;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-29717 {
        border-radius: 4px;
        border-style: solid;
        border-color: var(--border-border-primary, rgba(166, 166, 166, 0.2));
        border-width: 1px;
        padding: 16px;
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: flex-start;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .frame-29716 {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: center;
        justify-content: flex-end;
        flex: 1;
        position: relative;
    }
    .group-3626 {
        flex-shrink: 0;
        width: 45px;
        height: 45px;
        position: relative;
        overflow: visible;
    }
    .frame-313440 {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: flex-end;
        justify-content: center;
        flex: 1;
        position: relative;
    }
    .frame-313441 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-end;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .product-name {
        color: var(--text-text-primary, #ffffff);
        text-align: left;
        font-family: "OpenSans-SemiBold", sans-serif;
        font-size: 14px;
        line-height: 20px;
        font-weight: 600;
        position: relative;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .product-name2 {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: left;
        font-family: var(--caption-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--caption-font-size, 12px);
        line-height: var(--caption-line-height, 20px);
        font-weight: var(--caption-font-weight, 400);
        position: relative;
        align-self: stretch;
        height: 16px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .open {
        color: var(--text-text-success, #69c23f);
        text-align: left;
        font-family: var(--regular-text-xxs-font-family, "OpenSans-SemiBold", sans-serif);
        font-size: var(--regular-text-xxs-font-size, 10px);
        font-weight: var(--regular-text-xxs-font-weight, 600);
        position: relative;
        width: 26.65px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .frame-29640 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .lightbulb-05 {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        position: relative;
        overflow: visible;
    }
    .by-creating-a-b-oaccount-you-will-not-need-to-fill-out-any-info-next-time {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: left;
        font-family: var(--caption-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--caption-font-size, 12px);
        line-height: var(--caption-line-height, 20px);
        font-weight: var(--caption-font-weight, 400);
        position: relative;
        flex: 1;
    }
    .buttons2 {
        border-radius: 5px;
        border-style: solid;
        border-color: var(--border-border-primary, rgba(166, 166, 166, 0.2));
        border-width: 1px;
        padding: 8px;
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        height: 32px;
        position: relative;
        box-shadow: var(--bt-drop-shadow-box-shadow, 0px 0px 4px 0px rgba(30, 38, 49, 0.35));
    }
    .frame-29754 {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .btn-md-solid {
        background: var(--buttons-button-secondary, #212121);
        border-radius: 5px;

        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex: 1;
        height: 44px;
        position: relative;
        box-shadow: var(--bt-drop-shadow-box-shadow, 0px 0px 4px 0px rgba(30, 38, 49, 0.35));
    }
    .frame-313497 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313349 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .button2 {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-family: var(--button-text-medium-regular-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--button-text-medium-regular-font-size, 14px);
        line-height: var(--button-text-medium-regular-line-height, 24px);
        font-weight: var(--button-text-medium-regular-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .btn-md-solid2 {
        background: var(--buttons-button-primary, #f7665f);
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex: 1;
        height: 44px;
        position: relative;
        box-shadow: var(--bt-drop-shadow-box-shadow, 0px 0px 4px 0px rgba(30, 38, 49, 0.35));
    }
`;
/* #endregion */
const ThankYouPopup = ({ checkoutCartData }) => {
    /* #region VARS */
    const {
        paymentPopupData,
        setShowThankyouPopup,
        setStripeCardData,
        setShowUserLoginRegisterPopup,
        setShowRightMyOrdersDetailsPanel,
        setShowRightUserLocationPanel,
        orderID
    } = React.useContext(LayoutContext);
    const { setCheckoutCartData, thankYouAmount } = React.useContext(UserContext);
    const { firstName, email } = paymentPopupData;
    /* #endregion */

    /* #region METHODS */
    const resetValidation = () => {
        setStripeCardData({
            isCardNumber: false,
            isCardExpData: false,
            isCardCvv: false,
            isComplete: false
        });
    };
    const NavigateFunc = () => {
        const newTab = window.open("https://forms.office.com/e/0jLEz4aYQ0", "_blank");
        newTab.focus();
    };

    const emptyCheckoutFunc = () => {
        resetValidation();
        setCheckoutCartData({ items: [], total: 0, currency: "NOK", currencySymbol: "kr" });
    };
    /* #endregion */
    return (
        <DefaultPopupCard>
            <ThankYouPopupStyles>
                <div className="frame-29643">
                    <div className="frame-313412">
                        <div className="frame-313402">
                            <div className="frame-313406">
                                <div className="frame-3134022">
                                    <div className="frame-313401">
                                        <div className="frame-3590">
                                            <img className="confetti-success-1" src={CheckIcon} alt="" />
                                        </div>
                                        <div className="thank-you-morten">Thank you, {firstName}!</div>
                                        <div className="frame-313418">
                                            <div className="please-wait-for-store-confirmation-receipt-and-order-confirmation-sent-to-morten-botag-no">
                                                <span>
                                                    <span className="please-wait-for-store-confirmation-receipt-and-order-confirmation-sent-to-morten-botag-no-span">
                                                        Please wait for store confirmation.
                                                    </span>
                                                    <span className="please-wait-for-store-confirmation-receipt-and-order-confirmation-sent-to-morten-botag-no-span2">
                                                        <br />R
                                                    </span>
                                                    <span className="please-wait-for-store-confirmation-receipt-and-order-confirmation-sent-to-morten-botag-no-span3">
                                                        eceipt and order confirmation sent to:
                                                        <br />
                                                    </span>
                                                    <span className="please-wait-for-store-confirmation-receipt-and-order-confirmation-sent-to-morten-botag-no-span4">
                                                        {email}
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="info-action-card">
                        <div className="frame-29614">
                            <div className="frame-313540">
                                <div className="please-share-your-experience-with-us">Please share your experience with us:</div>
                            </div>
                            <div
                                className="buttons"
                                onClick={() => {
                                    setShowThankyouPopup(false);
                                    emptyCheckoutFunc();
                                    logGA4Event("Feedback_PopUp");
                                    NavigateFunc();
                                }}>
                                <div className="button">Give feedback</div>
                            </div>
                        </div>
                    </div>
                    <div className="frame-29729">
                        <div className="separator">
                            <div className="line-27"></div>
                            <div className="text-label-here">Order total</div>
                            <div className="line-26"></div>
                        </div>
                        <div className="price">
                            <span>
                                <span className="price-span">{thankYouAmount} </span>
                                <span className="price-span2">NOK</span>
                            </span>
                        </div>
                    </div>
                    <div className="frame-29640">
                        <div className="separator">
                            <div className="line-27"></div>
                            <div className="text-label-here">What’s next?</div>
                            <div className="line-26"></div>
                        </div>
                    </div>
                    {localStorage.getItem("isLoggedIn") ? null : (
                        <div className="info-action-card">
                            <div className="frame-29614">
                                <div className="frame-313540">
                                    <img className="lightbulb-05" src={Bulb} alt="" />
                                    <div className="by-creating-a-b-oaccount-you-will-not-need-to-fill-out-any-info-next-time">
                                        By creating a BOaccount you will not need to fill out any info next time!
                                    </div>
                                </div>
                                <div
                                    className="buttons2"
                                    onClick={() => {
                                        setShowThankyouPopup(false);
                                        //emptyCheckoutFunc();
                                        logGA4Event("CreateAccount");
                                        setShowUserLoginRegisterPopup(true);
                                    }}>
                                    <div className="button">Create account</div>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="frame-29754">
                        <div
                            className="btn-md-solid"
                            onClick={() => {
                                setShowThankyouPopup(false);
                                //emptyCheckoutFunc();
                                setShowRightMyOrdersDetailsPanel({
                                    orderID: orderID,
                                    isOpen: true
                                });
                                setShowRightUserLocationPanel(false);
                                logGA4Event("OrderDetails");
                            }}>
                            <div className="frame-313497">
                                <div className="frame-313349">
                                    <div className="button2">See order status</div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="btn-md-solid2"
                            onClick={() => {
                                setShowThankyouPopup(false);
                                //emptyCheckoutFunc();
                            }}>
                            <div className="frame-313497">
                                <div className="frame-313349">
                                    <div className="button2">Continue shopping</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ThankYouPopupStyles>
        </DefaultPopupCard>
    );
};

export default ThankYouPopup;
