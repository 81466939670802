import React, { useState } from "react";
import styled from "styled-components";
import ArrowBack from "./../../../assets/images/arrow-back.svg";
//import NorwayFlag from "./../../../assets/images/norway-flag.svg";
//import ArrowDrop from "./../../../assets/images/arrow-drop.svg";
import trash from "./../../../assets/images/trash.svg";
import key from "./../../../assets/images/key.svg";
import LayoutContext from "../../../contexts/LayoutContext";
import UserContext from "../../../contexts/UserContext";
import SubmitLoader from "../loaders/SubmitLoader";
import * as Library from "../../../utils/Library";
import PhoneNumberInput from "../forms/PhoneNumberInput";
import { phoneNumberPattern } from "../../../utils/helperFunctions";
import StandardsContext from "../../../contexts/StandardsContext";

/* #region CSS */
const StyledPage = styled.div`
    &.settings-without-profile-picture,
    &.settings-without-profile-picture * {
        box-sizing: border-box;
    }
    &.settings-without-profile-picture {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        height: 96vh;
        position: relative;
        width: 100%;
    }
    .panel-headers {
        align-self: stretch;
        flex-shrink: 0;
        height: 32px;
        position: relative;
    }
    .frame-29001 {
        display: flex;
        flex-direction: row;
        gap: 20px;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        position: absolute;
        left: 0px;
        top: 1px;
    }
    .back-btn {
        background: var(--buttons-button-quaternary, rgba(166, 166, 166, 0.1));
        border-radius: 5px;
        padding: 4px 8px 4px 8px;
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 45px;
        height: 30px;
        position: relative;
        cursor: pointer;
    }
    .arrow-left {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        position: relative;
        overflow: visible;
    }
    .order-nr-321325-j {
        color: var(--text-text-primary, #ffffff);
        text-align: left;
        font-family: var(--heading-3-font-family, "OpenSans-SemiBold", sans-serif);
        font-size: var(--heading-3-font-size, 16px);
        line-height: var(--heading-3-line-height, 24px);
        font-weight: var(--heading-3-font-weight, 600);
        position: relative;
        flex: 1;
    }
    .btn-xs-secondary {
        background: var(--buttons-button-secondary, #212121);
        border-radius: 5px;
        padding: 10px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        height: 30px;
        position: relative;
        cursor: pointer;
    }
    .button {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-family: var(--button-text-small-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--button-text-small-font-size, 12px);
        line-height: var(--button-text-small-line-height, 16px);
        font-weight: var(--button-text-small-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .separator {
        display: flex;
        flex-direction: row;
        gap: 6px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        height: auto;
        position: relative;
        overflow: visible;
    }
    .frame-313519 {
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313515 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .your-info {
        color: var(--text-text-primary, #ffffff);
        text-align: left;
        font-family: var(--heading-4-font-family, "OpenSans-SemiBold", sans-serif);
        font-size: var(--heading-4-font-size, 14px);
        line-height: var(--heading-4-line-height, 20px);
        font-weight: var(--heading-4-font-weight, 600);
        position: relative;
    }
    .frame-313505 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-29734 {
        display: flex;
        flex-direction: column;
        gap: 12px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-29733 {
        display: flex;
        flex-direction: row;
        gap: 12px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .input-field {
        background: var(--surface-surface-secondary, #121212);
        border-radius: 5px;
        border-style: solid;
        border-color: var(--border-border-primary, rgba(166, 166, 166, 0.2));
        border-width: 1px;
        padding: 16px;
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        position: relative;
        box-shadow: var(--dropdown-input-field-box-shadow, 0px 0px 15px 0px rgba(0, 0, 0, 0.25));
    }
    .label {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: left;
        font-family: var(--label-medium-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--label-medium-font-size, 14px);
        line-height: var(--label-medium-line-height, 14px);
        font-weight: var(--label-medium-font-weight, 400);
        position: relative;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .input-field2 {
        background: var(--surface-surface-secondary, #121212);
        border-radius: 5px;
        border-style: solid;
        border-color: var(--border-border-secondary, #8c8f93);
        border-width: 1px;
        opacity: 0.2;
        padding: 16px;
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        height: 46px;
        position: relative;
        box-shadow: var(--dropdown-input-field-box-shadow, 0px 0px 15px 0px rgba(0, 0, 0, 0.25));
    }
    .frame-313475 {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: flex-start;
        justify-content: center;
        flex: 1;
        height: 14px;
        position: relative;
    }
    .label2 {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: left;
        font-family: var(--label-xsmall-font-family, "OpenSans-SemiBold", sans-serif);
        font-size: var(--label-xsmall-font-size, 8px);
        line-height: var(--label-xsmall-line-height, 8px);
        font-weight: var(--label-xsmall-font-weight, 600);
        position: relative;
        align-self: stretch;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .label3 {
        color: var(--text-text-primary, #ffffff);
        text-align: left;
        font-family: var(--label-medium-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--label-medium-font-size, 14px);
        line-height: var(--label-medium-line-height, 14px);
        font-weight: var(--label-medium-font-weight, 400);
        position: relative;
        align-self: stretch;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .input-field-number {
        background: var(--surface-surface-secondary, #121212);
        border-radius: 5px;
        border-style: solid;
        border-color: var(--border-border-primary, rgba(166, 166, 166, 0.2));
        border-width: 1px;
        padding: 8px 16px 8px 16px;
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
        box-shadow: var(--dropdown-input-field-box-shadow, 0px 0px 15px 0px rgba(0, 0, 0, 0.25));
    }
    .frame-29021 {
        display: flex;
        flex-direction: row;
        gap: 7px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        height: 30px;
        position: relative;
    }
    .emoji-norway {
        flex-shrink: 0;
        width: 18px;
        height: 18px;
        position: relative;
        overflow: visible;
    }
    .chevron-down {
        flex-shrink: 0;
        width: 12px;
        height: 12px;
        position: relative;
        overflow: visible;
    }
    .frame-29524 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: flex-start;
        flex-shrink: 0;
        position: relative;
    }
    ._47 {
        color: var(--text-text-primary, #ffffff);
        text-align: left;
        font-family: var(--label-medium-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--label-medium-font-size, 14px);
        line-height: var(--label-medium-line-height, 14px);
        font-weight: var(--label-medium-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .phone-number {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: left;
        font-family: var(--label-medium-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--label-medium-font-size, 14px);
        line-height: var(--label-medium-line-height, 14px);
        font-weight: var(--label-medium-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .frame-313518 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .account-deletion {
        color: var(--text-text-primary, #ffffff);
        text-align: left;
        font-family: var(--heading-4-font-family, "OpenSans-SemiBold", sans-serif);
        font-size: var(--heading-4-font-size, 14px);
        line-height: var(--heading-4-line-height, 20px);
        font-weight: var(--heading-4-font-weight, 600);
        position: relative;
    }
    .btn-lg-delete-action {
        background: var(--wrap-wrap-red, rgba(255, 0, 0, 0.1));
        border-radius: 6px;
        padding: 10px 12px 10px 12px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        height: 48px;
        position: relative;
        cursor: pointer;
    }
    .frame-29508 {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 343px;
        position: relative;
    }
    .trash-03 {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        position: relative;
        overflow: visible;
    }
    .delete-account {
        color: var(--text-text-error, #ff0000);
        text-align: center;
        font-family: var(--button-text-medium-regular-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--button-text-medium-regular-font-size, 14px);
        line-height: var(--button-text-medium-regular-line-height, 24px);
        font-weight: var(--button-text-medium-regular-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .frame-29728 {
        background: var(--surface-surface-secondary, #121212);
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        flex-shrink: 0;
        width: 100%;
        position: absolute;
        bottom: 0px;
    }
    .btn-lg-solid {
        background: var(--buttons-button-primary, #f7665f);
        border-radius: 5px;
        opacity: 0.2;
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 100%;
        height: 48px;
        position: relative;
        box-shadow: var(--bt-drop-shadow-box-shadow, 0px 0px 4px 0px rgba(30, 38, 49, 0.35));
        cursor: no-drop;
    }
    .btn-lg-solid.active {
        cursor: pointer;
        opacity: 1;
    }
    .frame-29590 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .button2 {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-family: var(--button-text-medium-regular-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--button-text-medium-regular-font-size, 14px);
        line-height: var(--button-text-medium-regular-line-height, 24px);
        font-weight: var(--button-text-medium-regular-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .by-clicking-save-changes-you-will-update-all-changes-made-you-can-make-changes-as-much-as-you-want {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: center;
        font-family: "OpenSans-Regular", sans-serif;
        font-size: 10px;
        line-height: 16px;
        font-weight: 400;
        position: relative;
        align-self: stretch;
    }
    .seperator {
        display: flex;
        flex-direction: row;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        justify-content: space-between;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
        border-bottom: 1px solid #212121;
    }
    .floating-input {
        position: relative;
        width: 100%;
    }
    .customer-info-inputs {
        background-color: initial;
        border: 1px solid #373737;
        border-radius: 5px;
        color: #fff;
        display: inline-block;
        height: 0px;
        outline: none;
        padding: 28px 16px 16px;
        width: 100%;
        font-size: 14px;
    }
    .customer-info-inputs.typing {
        border: 1px solid #fff;
    }
    .floating-input input:focus + label,
    .floating-input input:not(:placeholder-shown) + label {
        color: #8c8c8c;
        font-size: 8px;
        font-weight: 600;
        left: 18px;
        top: 5px;
    }
    .floating-input label {
        color: #8c8c8c;
        font-size: 14px;
        font-weight: 400;
        left: 22px;
        pointer-events: none;
        position: absolute;
        top: 13px;
        transition: all 0.2s ease;
    }
    .customer-info-inputs.has-value {
        opacity: 1;
        border: 1px solid #8c8f93;
    }
    .btn-lg-password-action {
        background: rgb(30, 38, 49);
        border-radius: 6px;
        padding: 10px 12px 10px 12px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        height: 48px;
        position: relative;
        cursor: pointer;
    }
    .delete-account {
        color: #ffffff;
        text-align: center;
        font-family: var(--button-text-medium-regular-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--button-text-medium-regular-font-size, 14px);
        line-height: var(--button-text-medium-regular-line-height, 24px);
        font-weight: var(--button-text-medium-regular-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;
/* #endregion CSS */

export default function YourAccountPannel() {
    /* #region VARS */
    const {
        setShowRightSettingsPanel,
        setShowRightCheckoutPanel,
        setShowYourAccountPannel,
        setShowAccountDeletePopup,
        setShowAccountUnsavedPopup,
        setShowUserLoginRegisterPopup,
        setShowForgotPasswordPopup
    } = React.useContext(LayoutContext);
    const { userData, setUserData, setCheckoutCartData, checkoutCartData } = React.useContext(UserContext);
    const { setDefaultNotificationCard } = React.useContext(StandardsContext);
    const [isFirstNameTyping, setIsFirstNameTyping] = useState(false);
    const [isLastNameTyping, setIsLastNameTyping] = useState(false);
    const [firstName, setFirstName] = React.useState(userData?.firstName);
    const [lastName, setLastName] = React.useState(userData?.lastName);
    const [phoneNumber, setPhoneNumber] = React.useState(userData?.phone);
    const [isButtonEnable, setIsButtonEnable] = React.useState(false);
    const [isFormSubmit, setIsFormSubmit] = React.useState(false);
    React.useEffect(() => {
        Library.getUserInfo(getUserDataCallback);
    }, []);
    /* #endregion VARS */

    /* #region Methods */
    const getUserDataCallback = data => {
        const { firstName, lastName, phoneNumber } = data;
        setFirstName(firstName);
        setLastName(lastName);
        setPhoneNumber(phoneNumber);
    };
    const handleGoBackFunction = () => {
        if (isButtonEnable) {
            setShowAccountUnsavedPopup(true);
        } else {
            setShowRightSettingsPanel(true);
            setShowRightCheckoutPanel(false);
            setShowYourAccountPannel(false);
        }
    };
    const handleCloseSetting = () => {
        if (isButtonEnable) {
            setShowAccountUnsavedPopup(true);
        } else {
            setShowRightSettingsPanel(false);
            setShowRightCheckoutPanel(false);
            setShowYourAccountPannel(false);
        }
    };
    const handleFirstName = e => {
        setFirstName(e.target.value);
        setIsButtonEnable(true);
        setIsFirstNameTyping(true);
    };
    const handleLastName = e => {
        setLastName(e.target.value);
        setIsButtonEnable(true);
        setIsLastNameTyping(true);
    };
    const handleDeleteFunction = () => {
        setShowAccountDeletePopup(true);
    };
    const handleUpdateProfile = () => {
        if (isButtonEnable) {
            setIsFormSubmit(true);
            Library.makePostRequest(
                "updateBOthebotAccount",
                {
                    bothebotAccountID: localStorage.getItem("pid"),
                    firstName: firstName,
                    lastName: lastName,
                    phoneNumber: phoneNumber,
                    email: userData?.email,
                    pictureUrl: ""
                },
                false,
                setIsFormSubmit
            )
                .then(res => {
                    const { data } = res;
                    if (data?.status === 200) {
                        //Library.showSuccessMessage(data?.message);
                        Library.showNotification({
                            message: data?.message,
                            type: "success",
                            setDefaultNotificationCard
                        });
                        setIsButtonEnable(false);
                        setUserData({
                            firstName: firstName,
                            lastName: lastName,
                            email: userData?.email,
                            phone: phoneNumber
                        });
                        checkoutCartData.items.forEach(item => {
                            if (item.BOTagData.recipient === "") {
                                item.BOTagData.recipient = `${firstName} ${lastName}`;
                            }
                        });
                        setCheckoutCartData(checkoutCartData);
                    } else {
                        //Library.showErrorMessage(data?.message);
                        Library.showNotification({
                            message: data?.message,
                            type: "error",
                            setDefaultNotificationCard
                        });
                    }
                })
                .catch(e => {});
        }
    };
    const handleChangePasswordFunction = () => {
        setShowUserLoginRegisterPopup(true);
        setShowForgotPasswordPopup(true);
    };
    const callback = (e, c) => {
        setPhoneNumber(phoneNumberPattern(e, c?.format));
        setIsButtonEnable(true);
    };
    /* #endregion Methods */

    return (
        <>
            <StyledPage className="settings-without-profile-picture">
                <div className="panel-headers">
                    <div className="frame-29001">
                        <div className="back-btn" onClick={handleGoBackFunction}>
                            <img className="arrow-left" src={ArrowBack} alt="" />
                        </div>
                        <div className="order-nr-321325-j">Your account</div>
                        <div className="btn-xs-secondary" onClick={handleCloseSetting}>
                            <div className="button">Close</div>
                        </div>
                    </div>
                </div>
                <div className="seperator"></div>
                <div className="frame-313519">
                    <div className="frame-313515">
                        <div className="your-info">Your info</div>
                        <div className="frame-313505">
                            <div className="frame-29734">
                                <div className="frame-29733">
                                    <div className="floating-input">
                                        <input
                                            type="text"
                                            className={`customer-info-inputs ${isFirstNameTyping ? "typing" : ""}`}
                                            placeholder=" "
                                            value={firstName}
                                            autoComplete="off"
                                            onChange={handleFirstName}
                                        />
                                        <label>First name</label>
                                    </div>
                                    <div className="floating-input">
                                        <input
                                            type="text"
                                            className={`customer-info-inputs ${isLastNameTyping ? "typing" : ""}`}
                                            placeholder=" "
                                            value={lastName}
                                            autoComplete="off"
                                            onChange={handleLastName}
                                        />
                                        <label>Last name</label>
                                    </div>
                                </div>
                                <div className="input-field2">
                                    <div className="frame-313475">
                                        <div className="label2">Email</div>
                                        <div className="label3">{userData?.email}</div>
                                    </div>
                                </div>

                                <PhoneNumberInput callback={callback} phoneNumber={phoneNumber} />
                            </div>
                        </div>
                    </div>
                    <div className="frame-313518">
                        <div className="account-deletion">Password</div>
                        <div className="btn-lg-password-action" onClick={handleChangePasswordFunction}>
                            <div className="frame-29508">
                                <img className="trash-03" src={key} alt="" />
                                <div className="delete-account">Change password</div>
                            </div>
                        </div>
                    </div>
                    <div className="frame-313518">
                        <div className="account-deletion">Account deletion</div>
                        <div className="btn-lg-delete-action" onClick={handleDeleteFunction}>
                            <div className="frame-29508">
                                <img className="trash-03" src={trash} alt="" />
                                <div className="delete-account">Delete account</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="frame-29728">
                    <div className={`btn-lg-solid ${isButtonEnable ? "active" : ""}`} onClick={handleUpdateProfile}>
                        <div className="frame-29590">
                            <div className="button2">Save changes</div>
                        </div>
                    </div>
                    <div className="by-clicking-save-changes-you-will-update-all-changes-made-you-can-make-changes-as-much-as-you-want">
                        By clicking “Save changes” you will update all changes
                        <br />
                        made. You can make changes as much as you want.
                    </div>
                </div>
                {isFormSubmit ? <SubmitLoader /> : ""}
            </StyledPage>
        </>
    );
}
