import React from "react";
import Skeleton from "@mui/material/Skeleton";

const StoreRightPanelSkeleton = () => {
    return (
        <div
            style={{
                border: "0.5px solid rgba(166, 166, 166, 0.2)",
                height: "253px",
                width: "100%",
                borderRadius: "5px",
                marginTop: "0"
            }}>
            <div className="" style={{ height: "100%", position: "relative" }}>
                <Skeleton sx={{ bgcolor: "grey.900", borderRadius: "5px" }} variant="rectangular" height={"250px"} width={"100%"} />

                <div
                    style={{
                        backgroundColor: "#121212",
                        padding: "16px",
                        height: "128.64px",
                        position: "absolute",
                        bottom: "0",
                        width: "100%",
                        borderBottomLeftRadius: "5px",
                        borderBottomRightRadius: "5px"
                    }}>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginBottom: "10px",
                            height: "52px"
                        }}>
                        <Skeleton variant="circular" sx={{ bgcolor: "grey.900", width: "45px", height: "45px" }} />
                        <div style={{ marginLeft: "10px" }}>
                            <Skeleton
                                variant="text"
                                sx={{
                                    bgcolor: "grey.900",
                                    width: "238px",
                                    height: "18px",
                                    borderRadius: "5px",
                                    marginBottom: "5px",
                                    marginLeft: "5px"
                                }}
                            />
                            <Skeleton
                                variant="text"
                                sx={{
                                    bgcolor: "grey.900",
                                    width: "274px",
                                    height: "18px",
                                    borderRadius: "5px",
                                    marginLeft: "5px"
                                }}
                            />
                        </div>
                    </div>
                    <div
                        style={{
                            height: "0.5px",
                            backgroundColor: "rgb(55, 55, 55)",
                            width: "calc(100% + 32px)",
                            marginLeft: "-16px"
                        }}></div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            alignItems: "center",
                            marginTop: "10px",
                            height: "17.14px"
                        }}>
                        <Skeleton
                            variant="text"
                            sx={{
                                bgcolor: "grey.900",
                                width: "113px",
                                height: "18px",
                                borderRadius: "5px",
                                marginTop: "15px"
                            }}
                        />
                        <Skeleton
                            variant="text"
                            sx={{
                                bgcolor: "grey.900",
                                width: "20px",
                                height: "18px",
                                borderRadius: "5px",
                                marginTop: "15px"
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StoreRightPanelSkeleton;
