import React, { useState, useEffect } from "react";
import * as Library from "../utils/Library";
export const UserContext = React.createContext();

export const UserProvider = ({ children }) => {
    /* #region User Data */
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
    const [BOTagAccountID, setBOTagAccountID] = useState(undefined);
    const [userData, setUserData] = useState(defaultUserData);
    const [userAddress, setUserAddress] = useState(defaultAddressData);
    const [userGPSCoordinates, setUserGPSCoordinates] = useState(defaultGPSCoordinates);

    const [checkoutCartData, setCheckoutCartData] = useState(defaultCheckoutCartData);
    const [quickBuyData, setQuickBuyData] = useState(defaultCheckoutCartData);
    const [checkoutPaymentData, setCheckoutPaymentData] = useState(defaultCheckoutPaymentData);
    const [pickupDistance, setPickupDistance] = useState(50);
    const [shippingRecipient, setShippingRecipient] = useState({ firstName: null, lastName: null });
    const [productInfo, setProductInfo] = useState(null);
    const [thankYouAmount, setThankYouAmount] = useState("0");
    /* #endregion */

    /* #region EFFECTS */

    useEffect(() => {
        if (localStorage.getItem("isLoggedIn") && localStorage.getItem("pid")) {
            Library.makePostRequest("getUser", { _id: localStorage.getItem("pid") }).then(res => {
                if (res?.data?.status === 200) {
                    const { _id, firstName, lastName, email, phoneNumber, cards } = res?.data?.data;
                    const allUserInfo = {
                        _id,
                        firstName,
                        lastName,
                        email,
                        phone: phoneNumber,
                        cards
                    };
                    userLogin(
                        allUserInfo,
                        setIsUserLoggedIn,
                        setUserData,
                        setUserAddress,
                        setUserGPSCoordinates,
                        setBOTagAccountID,
                        setCheckoutPaymentData,
                        setShippingRecipient,
                        setCheckoutCartData,
                        checkoutCartData
                    );
                    //setShippingRecipient({ firstName: firstName, lastName: lastName });
                } else {
                    userLogout(
                        setIsUserLoggedIn,
                        setUserData,
                        setUserAddress,
                        setUserGPSCoordinates,
                        setCheckoutCartData,
                        setCheckoutPaymentData,
                        setBOTagAccountID,
                        setShippingRecipient
                    );
                }
            });
        }
    }, []);

    //TODO: REMOVE LATER... TEMPORARY FOR TESTING CHANGES

    //TODO: REMOVE LATER... TEMPORARY FOR TESTING CHANGES

    /* #endregion */

    return (
        <UserContext.Provider
            value={{
                isUserLoggedIn,
                setIsUserLoggedIn,
                BOTagAccountID,
                setBOTagAccountID,
                userData,
                setUserData,
                userAddress,
                setUserAddress,
                userGPSCoordinates,
                setUserGPSCoordinates,
                checkoutCartData,
                setCheckoutCartData,
                checkoutPaymentData,
                setCheckoutPaymentData,
                pickupDistance,
                setPickupDistance,
                shippingRecipient,
                setShippingRecipient,
                productInfo,
                setProductInfo,
                quickBuyData,
                setQuickBuyData,
                thankYouAmount,
                setThankYouAmount
            }}>
            {children}
        </UserContext.Provider>
    );
};

export default UserContext;

/* #region Default Data */
const defaultUserData = {
    firstName: undefined,
    lastName: undefined,
    email: undefined,
    phoneCountryPrefix: "+47",
    phone: undefined
};

const defaultAddressData = localStorage.getItem("userLocation")
    ? JSON.parse(localStorage.getItem("userLocation"))?.location
    : {
          street: undefined,
          streetNumber: undefined,
          city: undefined,
          country: undefined,
          postCode: undefined
      };
const defaultGPSCoordinates = localStorage.getItem("userCoords")
    ? JSON.parse(localStorage.getItem("userCoords"))?.coords
    : { latitude: undefined, longitude: undefined };

const defaultCheckoutCartData = {
    items: [],
    total: 0,
    currency: "NOK",
    currencySymbol: "kr"
};

const defaultCheckoutPaymentData = {
    cardNumber: undefined,
    cardHolder: undefined,
    cardExpiration: undefined,
    cardCVV: undefined,
    cardName: undefined,
    customerID: undefined,
    paymentMethodID: undefined
};
/* #endregion */

/* #region Methods */
export function userLogin(
    allUserInfo,
    setIsUserLoggedIn,
    setUserData,
    setUserAddress,
    setUserGPSCoordinates,
    setBOTagAccountID,
    setCheckoutPaymentData,
    setShippingRecipient,
    setCheckoutCartData,
    checkoutCartData
) {
    const { _id, firstName, lastName, email, phone, street, streetNumber, city, country, postCode, latitude, longitude, cards } =
        allUserInfo;
    setIsUserLoggedIn(true);
    setUserData({ firstName, lastName, email, phone });
    setBOTagAccountID(_id);
    setCheckoutPaymentData({
        cardNumber: cards?.cardNumber,
        cardHolder: cards?.cardHolderName,
        cardExpiration: cards?.cardExpDate,
        cardCVV: cards?.cardCVV,
        cardName: cards?.cardName,
        customerID: cards?.customerID,
        paymentMethodID: cards?.paymentMethodID
    });
    setShippingRecipient({ firstName: firstName, lastName: lastName });
    setCheckoutCartData(checkoutCartData);
    return null;
}

export function userLogout(
    //TODO: CHECK IF ALL DATA WAS RESET ON LOGOUT
    setIsUserLoggedIn,
    setUserData,
    setUserAddress,
    setUserGPSCoordinates,
    setCheckoutCartData,
    setCheckoutPaymentData,
    setBOTagAccountID,
    setShippingRecipient
) {
    setIsUserLoggedIn(false);
    setUserData(defaultUserData);
    setCheckoutCartData(defaultCheckoutCartData);
    setCheckoutPaymentData(defaultCheckoutPaymentData);
    setBOTagAccountID(undefined);
    setShippingRecipient({ firstName: null, lastName: null });
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("pid");
}

export function addItemToCart(item, checkoutCartData, setCheckoutCartData) {
    const newItems = [...checkoutCartData.items, item];
    const newTotal = checkoutCartData.total + item.price;

    setCheckoutCartData({ items: newItems, total: newTotal });
}

/* #endregion */
