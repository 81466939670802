import React from "react";
import styled from "styled-components";
import Spinner from "../../../../assets/images/SpinnerRed.svg";
import LayoutContext from "../../../../contexts/LayoutContext";
/* #region CSS */
const StyledPage = styled.div`
    &.not-logged-in,
    &.not-logged-in * {
        box-sizing: border-box;
    }
    &.not-logged-in {
        background: #0c0c0c;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: center;
        height: 262px;
        position: relative;
        box-shadow: var(--dropshadow-pop-up2-box-shadow, 4px 4px 87.4px 0px rgba(0, 0, 0, 1));
    }
    &.mobile-frame-29643 {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 95%;
    }
    .frame-29643 {
        padding: 24px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313411 {
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .spiner-with-bg {
        flex-shrink: 0;
        width: 40px;
        height: 40px;
        position: relative;
    }
    .spiner {
        width: 100%;
        height: 100%;
        position: absolute;
        right: 0%;
        left: 0%;
        bottom: 0%;
        top: 0%;
    }
    .ellipse-1 {
        background: #f7665f;
        border-radius: 50%;
        opacity: 0.3;
        width: 100%;
        height: 100%;
        position: absolute;
        right: 0%;
        left: 0%;
        bottom: 0%;
        top: 0%;
    }
    .ellipse-12 {
        background: #f7665f;
        border-radius: 50%;
        width: 100%;
        height: 100%;
        position: absolute;
        right: 0%;
        left: 0%;
        bottom: 0%;
        top: 0%;
    }
    .spinner-text {
        display: flex;
        flex-direction: row;
    }
    .spinner-img {
        animation: spin 1s linear infinite;
        height: 40px;
        width: 40px;
    }

    @keyframes spin {
        to {
            transform: rotate(360deg);
        }
    }
`;
/* #endregion CSS */
export default function SpinnerPopup() {
    /* #region VARS */
    const { isMobile } = React.useContext(LayoutContext);
    /* #endregion VARS */
    return (
        <StyledPage className={`not-logged-in ${isMobile ? "mobile-frame-29643" : ""}`}>
            <div className="frame-29643">
                <div className="frame-313411">
                    <div className="spiner-with-bg">
                        <span className="spinner-text">
                            <img src={Spinner} alt="" className="spinner-img" />
                        </span>
                    </div>
                </div>
            </div>
        </StyledPage>
    );
}
