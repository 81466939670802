import { useState, useEffect } from "react";

function useViewportHeight() {
    const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

    useEffect(() => {
        const initialHeight = window.innerHeight;

        const handleResize = () => {
            const currentHeight = window.innerHeight;

            if (Math.abs(currentHeight - initialHeight) < 100) {
                setViewportHeight(currentHeight);
            }
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return viewportHeight;
}

export default useViewportHeight;
