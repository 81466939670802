import React from "react";
import LayoutContext from "../../../../contexts/LayoutContext";
import Login from "./Login";
import Registration from "./Registration";
import PersonalInformation from "./PersonalInformation";
import CardInformation from "./CardInformation";

export default function UserRegistrationAndLogin({ visible }) {
    /* #region VARS */
    const { showLoginForm, showRegistration, showPersonalInformation, showCardInformation } = React.useContext(LayoutContext);
    /* #endregion VARS */
    return (
        <>
            {showLoginForm && <Login />}
            {showRegistration && <Registration />}
            {showPersonalInformation && <PersonalInformation />}
            {showCardInformation && <CardInformation />}
        </>
    );
}
