import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Frame290570 } from "../../../../assets/images/close-btn.svg";
import { ReactComponent as PasswordEyeHide } from "../../../../assets/images/password-eye-hide.svg";
import { ReactComponent as PasswordEyeHideActive } from "../../../../assets/images/password-eye-hide-active.svg";
import { validatePassword } from "../../../../utils/Formatter";
import * as Library from "../../../../utils/Library";
import Spinner from "../../../../assets/images/spinner.svg";
import LayoutContext from "../../../../contexts/LayoutContext";
import UserContext from "../../../../contexts/UserContext";
import styled from "styled-components";
import StandardsContext from "../../../../contexts/StandardsContext";

/* #region CSS */
const StyledPage = styled.div`
    &.frame-29643,
    &.frame-29643 * {
        box-sizing: border-box;
    }
    &.frame-29643 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313583 {
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313367 {
        display: flex;
        flex-direction: column;
        gap: 40px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313591 {
        display: flex;
        flex-direction: row;
        gap: 40px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .new-password {
        color: var(--text-text-primary, #ffffff);
        text-align: left;
        font-family: "OpenSans-SemiBold", sans-serif;
        font-size: 22px;
        line-height: 28px;
        font-weight: 600;
        position: relative;
        flex: 1;
    }
    .frame-29057 {
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 30px;
        height: 30px;
        position: relative;
        overflow: visible;
    }
    .password-must-contain-at-least-7-letters-and-1-number {
        color: #8c8c8c;
        text-align: left;
        font-family: var(--subtitle-2-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 14px);
        line-height: var(--subtitle-2-line-height, 24px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
        align-self: stretch;
    }
    .frame-313584 {
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313596 {
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .input-field {
        background: var(--surface-surface-secondary, #121212);
        border-radius: 5px;
        border-style: solid;
        border-color: var(--border-border-primary, rgba(166, 166, 166, 0.2));
        border-width: 1px;
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        height: 46px;
        position: relative;
        box-shadow: var(--dropdown-input-field-box-shadow, 0px 0px 15px 0px rgba(0, 0, 0, 0.25));
    }
    .label {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: left;
        font-family: var(--label-medium-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--label-medium-font-size, 14px);
        line-height: var(--label-medium-line-height, 14px);
        font-weight: var(--label-medium-font-weight, 400);
        position: relative;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .eye {
        flex-shrink: 0;
        width: 14px;
        height: 14px;
        position: relative;
        overflow: visible;
    }
    .eye2 {
        flex-shrink: 0;
        width: 14px;
        height: 14px;
        position: relative;
        overflow: visible;
    }
    .btn-md-solid {
        background: var(--buttons-button-primary, #f7665f);
        border-radius: 5px;
        opacity: 0.2;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        height: 44px;
        position: relative;
        box-shadow: var(--bt-drop-shadow-box-shadow, 0px 0px 4px 0px rgba(30, 38, 49, 0.35));
        cursor: no-drop;
    }
    .frame-313497 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313349 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .button {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-family: var(--button-text-medium-regular-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--button-text-medium-regular-font-size, 14px);
        line-height: var(--button-text-medium-regular-line-height, 24px);
        font-weight: var(--button-text-medium-regular-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .floating-input {
        position: relative;
        width: 100%;
    }
    .customer-info-inputs {
        background-color: initial;
        border: 1px solid #373737;
        border-radius: 5px;
        color: #fff;
        display: inline-block;
        height: 0px;
        outline: none;
        padding: 28px 16px 16px;
        width: 100%;
    }
    .floating-input input:focus + label,
    .floating-input input:not(:placeholder-shown) + label {
        color: #8c8c8c;
        font-size: 8px;
        font-weight: 600;
        left: 18px;
        top: 5px;
    }
    .floating-input label {
        color: #8c8c8c;
        font-size: 14px;
        font-weight: 400;
        left: 22px;
        pointer-events: none;
        position: absolute;
        top: 13px;
        transition: all 0.2s ease;
    }
    .customer-info-inputs.has-value {
        opacity: 1;
        border: 1px solid #8c8f93;
    }
    .btn-md-solid.active {
        opacity: 1;
        cursor: pointer;
    }
    .toggle-password {
        position: absolute;
        right: 14px;
        top: 16px;
    }
    .floating-input .error {
        border-color: #f7665f !important;
    }
    .error-text {
        font-size: 12px;
        color: #f7665f;
        position: absolute;
    }
    .spinner-text {
        display: flex;
        flex-direction: row;
    }
    .spinner-img {
        animation: spin 1s linear infinite;
    }

    @keyframes spin {
        to {
            transform: rotate(360deg);
        }
    }
`;
/* #endregion CSS */
export default function ChangePasswordForm() {
    /* #region VARS */
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState(false);
    const [passwordErrorText, setPasswordErrorText] = useState("");
    const [togglePassword, setTogglePassword] = useState(false);
    const [passwordHasValue, setPasswordHasValue] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState(false);
    const [confirmPasswordErrorText, setConfirmPasswordErrorText] = useState("");
    const [toggleConfirmPassword, setToggleConfirmPassword] = useState(false);
    const [confirmPasswordHasValue, setConfirmPasswordHasValue] = useState(false);
    const [buttonEnable, setButtonEnable] = useState(false);
    const [isFormSubmit, setIsFormSubmit] = useState(false);

    const { setPasswordIsChanged, setShowPasswordForm } = React.useContext(LayoutContext);
    const { BOTagAccountID } = React.useContext(UserContext);
    const { setDefaultNotificationCard } = React.useContext(StandardsContext);
    const navigate = useNavigate();
    /* #endregion VARS */

    /* #region METHODS */
    const handleCloseLogin = () => {
        navigate("/feed");
    };
    const handlePassword = e => {
        setPassword(e.target.value);
        setPasswordError(false);
        setPasswordErrorText("");
    };
    const handleTogglePassword = () => {
        setTogglePassword(!togglePassword);
    };
    const handleConfirmPassword = e => {
        setConfirmPassword(e.target.value);
        setConfirmPasswordError(false);
        setConfirmPasswordErrorText("");
    };
    const handleToggleConfirmPassword = () => {
        setToggleConfirmPassword(!toggleConfirmPassword);
    };
    const handleValidation = () => {
        let isFormValid = true;
        if (!password) {
            isFormValid = false;
            setPasswordError(true);
        }
        if (password && !validatePassword(password)) {
            isFormValid = false;
            setPasswordError(true);
            setPasswordErrorText("Password must contain at least 7 letters and 1 number.");
        }
        if (!confirmPassword) {
            isFormValid = false;
            setConfirmPasswordError(true);
        }
        if (password && confirmPassword && password !== confirmPassword) {
            isFormValid = false;
            setConfirmPasswordError(true);
            setConfirmPasswordErrorText("Password didn’t match");
        }
        return isFormValid;
    };
    const handlePasswordForm = () => {
        if (handleValidation()) {
            if (!isFormSubmit) {
                setPasswordHasValue(false);
                setConfirmPasswordHasValue(false);
                setIsFormSubmit(true);
                Library.makePostRequest(
                    "updateBOthebotAccountPassword",
                    {
                        password: password,
                        bothebotAccountID: BOTagAccountID
                    },
                    false,
                    setIsFormSubmit
                )
                    .then(res => {
                        const { data } = res;
                        if (data?.status === 200) {
                            setPasswordIsChanged(true);
                            setShowPasswordForm(false);
                        } else {
                            //Library.showSuccessMessage(data?.message);
                            Library.showNotification({
                                message: data?.message,
                                type: "success",
                                setDefaultNotificationCard
                            });
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    });
            }
        }
    };
    /* #endregion METHODS */

    /* #region EFFECT */
    useEffect(() => {
        if (password) {
            setPasswordHasValue(true);
        } else {
            setPasswordHasValue(false);
        }
        if (confirmPassword) {
            setConfirmPasswordHasValue(true);
        } else {
            setConfirmPasswordHasValue(false);
        }
        if (password && confirmPassword && password === confirmPassword) {
            setButtonEnable(true);
        } else {
            setButtonEnable(false);
        }
    }, [password, confirmPassword]);
    /* #endregion EFFECT */
    return (
        <StyledPage className="frame-29643">
            <div className="frame-313583">
                <div className="frame-313367">
                    <div className="frame-313591">
                        <div className="new-password">New password</div>
                        <Frame290570 className="frame-29057 cursor-pointer" onClick={handleCloseLogin} />
                    </div>
                </div>
                <div className="password-must-contain-at-least-7-letters-and-1-number">
                    Password must contain at least 7 letters and 1 number
                </div>
            </div>
            <div className="frame-313584">
                <div className="frame-313596">
                    <div className="floating-input">
                        <input
                            type={!togglePassword ? "password" : "text"}
                            className={`customer-info-inputs ${passwordHasValue ? "has-value" : ""} ${passwordError ? "error" : ""}`}
                            placeholder=" "
                            autoComplete="off"
                            value={password}
                            onChange={handlePassword}
                            style={{ opacity: isFormSubmit ? "0.2" : "1" }}
                        />
                        <label>Password</label>
                        <div className="error-text">{passwordErrorText}</div>
                        <span className="toggle-password">
                            {passwordHasValue ? (
                                <PasswordEyeHideActive
                                    className="frame-29057 cursor-pointer"
                                    style={{
                                        height: "15px",
                                        width: "15px"
                                    }}
                                    onClick={handleTogglePassword}
                                />
                            ) : (
                                <PasswordEyeHide
                                    className="frame-29057 cursor-pointer"
                                    style={{
                                        height: "15px",
                                        width: "15px"
                                    }}
                                    onClick={handleTogglePassword}
                                />
                            )}
                        </span>
                    </div>
                    <div className="floating-input">
                        <input
                            type={!toggleConfirmPassword ? "password" : "text"}
                            className={`customer-info-inputs ${confirmPasswordHasValue ? "has-value" : ""} ${
                                confirmPasswordError ? "error" : ""
                            }`}
                            placeholder=" "
                            autoComplete="off"
                            value={confirmPassword}
                            onChange={handleConfirmPassword}
                            style={{ opacity: isFormSubmit ? "0.2" : "1" }}
                        />
                        <label>Password</label>
                        <div className="error-text">{confirmPasswordErrorText}</div>
                        <span className="toggle-password">
                            {confirmPasswordHasValue ? (
                                <PasswordEyeHideActive
                                    className="frame-29057 cursor-pointer"
                                    style={{
                                        height: "15px",
                                        width: "15px"
                                    }}
                                    onClick={handleToggleConfirmPassword}
                                />
                            ) : (
                                <PasswordEyeHide
                                    className="frame-29057 cursor-pointer"
                                    style={{
                                        height: "15px",
                                        width: "15px"
                                    }}
                                    onClick={handleToggleConfirmPassword}
                                />
                            )}
                        </span>
                    </div>
                </div>
                <div className={`btn-md-solid ${buttonEnable ? "active" : ""}`} onClick={handlePasswordForm}>
                    <div className="frame-313497">
                        <div className="frame-313349">
                            <div className="button">
                                {isFormSubmit ? (
                                    <span className="spinner-text">
                                        <img src={Spinner} alt="" className="spinner-img" />
                                        &nbsp;
                                        {"  "}
                                        {localStorage.getItem("isLoggedIn") === "true" ? "Changing password..." : "Resetting password..."}
                                    </span>
                                ) : localStorage.getItem("isLoggedIn") === "true" ? (
                                    "Change password"
                                ) : (
                                    "Reset password"
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </StyledPage>
    );
}
