import React from "react";
import StockExceedInfo from "../../../../assets/images/stockexceedinfo.svg";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";

export default function BOTagBodyDeliveryUnavailable({
    unavailableReason,
    unavailableReasonError,
    headerMessage,
    textColor = "#F7665F",
    checkAnotherLoactionBtn,
    setShowLocationPopup,
    deliveryMethod
}) {
    return (
        <div
            style={{
                // borderTop: "0.5px solid rgb(56, 56, 56)",
                margin: "0px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                height: headerMessage ? "190.5px" : "144.5px"
            }}>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                    width: "182px",
                    justifyContent: "center",
                    height: !checkAnotherLoactionBtn && "145px"
                }}>
                <div className="stockexceedIcon" style={{ marginBottom: "4px" }}>
                    <img src={StockExceedInfo} alt="" />
                </div>
                <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                    <div className="checkText" style={{ display: "flex", flexDirection: "column" }}>
                        <span style={{ fontWeight: "600", lineHeight: "16px", fontSize: "12px" }}>{unavailableReason}</span>
                    </div>
                    <div className="checkText" style={{ display: "flex", flexDirection: "column" }}>
                        <span style={{ fontWeight: "400", lineHeight: "16px", fontSize: "10px", color: textColor }}>
                            {unavailableReasonError}
                        </span>
                    </div>
                </div>
            </div>
            {checkAnotherLoactionBtn && deliveryMethod !== "pickup" ? (
                <div className="frame-3135012" onClick={() => setShowLocationPopup(true)}>
                    <div className="frame-313503 full-width-btn">
                        <div className="btn-fw-botag" style={{ background: "#212121" }}>
                            <div className="frame-313497">
                                <div className="frame-313349">
                                    <div className="button">Check other locations</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    <div className="frame-3135012" style={{ opacity: 0.2 }}>
                        <div className="frame-313503">
                            <div className="btn-fw-botag" style={{ background: "#212121", cursor: "no-drop" }}>
                                <div className="frame-313497">
                                    <div className="frame-313349">
                                        <div className="button">Add to bag</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="frame-313503">
                            <div className="btn-fw-botag" style={{ cursor: "no-drop" }}>
                                <div className="frame-313497">
                                    <div className="frame-313349">
                                        <div className="button">Quick buy</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}
