import React, { useState } from "react";
import WarnIcon from "./../../../assets/images/deleteAccountAlert.png";
import ErrorIcon from "./../../../assets/images/x-close.png";
import loader from "../../../assets/images/paymentloader.gif";
import { Toaster } from "react-hot-toast";
import styled from "styled-components";

const StyledPage = styled.div`
    &.show-popup {
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0;
        right: 0;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        background-color: rgba(24, 24, 24, 0.5);
        z-index: 99;
        max-width: 100%;
        backdrop-filter: blur(6px);
    }
    .bothebot-pup {
        background-color: #10141b;
        min-height: 330px;
        width: 390px;
        border-radius: 10px;
        flex-direction: column;
        align-items: center;
        padding: 38px 47px;
        display: flex;
        position: relative;
    }
    .location-popup-warn-btns {
        width: 47%;
        padding: 11px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        background-color: #1e263159;
        border: none;
        outline: none;
    }
`;

const DefaultErrorPopup = ({
    title,
    message,
    cancelBtnText,
    confirmBtnText,
    cancelBtnAction = undefined,
    confirmBtnAction = undefined,
    isMobile = false,
    loadingConfirmBtnText = undefined,
    useWarningIcon = false
}) => {
    const [isLoading, setIsLoading] = useState(false);

    return (
        <StyledPage className="show-popup" style={{ zIndex: "99999" }}>
            <div
                className="bothebot-pup locatonPopDev"
                style={{
                    minHeight: "auto",
                    color: "#ffffff",
                    width: isMobile ? "auto" : "440px",
                    height: isMobile && "50vh"
                }}>
                <div style={{ marginBottom: "16px" }}>
                    {useWarningIcon ? (
                        <img src={WarnIcon} alt="" />
                    ) : (
                        <div
                            style={{
                                width: "50px",
                                height: "50px",
                                backgroundColor: "rgba(255, 0, 0, 0.10)",
                                borderRadius: "50%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                            <img src={ErrorIcon} alt="" style={{ width: "16px", height: "16px" }} />
                        </div>
                    )}
                </div>
                <div
                    style={{
                        fontSize: "22px",
                        fontWeight: 600,
                        marginBottom: "16px"
                    }}>
                    {title}
                </div>
                <div
                    style={{
                        color: "#8C8C8C",
                        fontWeight: "400",
                        fontSize: "14px",
                        textAlign: "center",
                        marginBottom: "16px"
                    }}>
                    {message}
                </div>

                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        marginBottom: "10px"
                    }}>
                    <div
                        className="location-popup-warn-btns"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            if (cancelBtnAction) {
                                cancelBtnAction();
                            }
                        }}>
                        {cancelBtnText}
                    </div>
                    <button
                        className="location-popup-warn-btns"
                        style={{
                            backgroundColor: "rgba(247, 102, 95, 1)"
                        }}
                        onClick={() => {
                            if (confirmBtnAction) {
                                confirmBtnAction();
                            }
                        }}>
                        {isLoading ? (
                            <>
                                <img src={loader} alt="" style={{ width: "15px", height: "15px" }} /> &nbsp;
                                {loadingConfirmBtnText ? loadingConfirmBtnText : confirmBtnText}
                            </>
                        ) : (
                            <span>{confirmBtnText}</span>
                        )}
                    </button>
                    <Toaster />
                </div>
            </div>
        </StyledPage>
    );
};

export default DefaultErrorPopup;
