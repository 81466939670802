import React, { useEffect } from "react";
import styled from "styled-components";
import eyeOff0 from "../../../assets/images/eye-off0.svg";
import eyeOn from "../../../assets/images/eye-On.svg";

import share070 from "../../../assets/images/share-070.svg";
import { useContext } from "react";
import LayoutContext from "../../../contexts/LayoutContext";
import AppContext from "../../../contexts/AppContext";
import { useNavigate } from "react-router-dom";
import Skeleton from "../skeletons/Skeleton";
import { Tooltip } from "antd";
const StyledPage = styled.div`
    .footer,
    .footer * {
        box-sizing: border-box;
    }
    .footer {
        background: var(--surface-surface-secondary, #121212);
        border-style: solid;
        border-color: var(--border-border-primary-10, rgba(166, 166, 166, 0.1));
        border-width: 1px 0px 0px 0px;
        padding: 16px 24px 16px 24px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
        justify-content: center;
        height: 60px;
        width: 100%;
        position: relative;
        overflow: hidden;
    }
    .frame-12 {
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-14 {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: flex-start;
        justify-content: flex-start;
        flex: 1;
        position: relative;
        cursor: pointer;
    }
    .samsoe-samsoe-new-white-2 {
        flex-shrink: 0;
        width: auto;
        height: 60px;
        position: relative;
        object-fit: cover;
    }
    .frame-15 {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: flex-end;
        justify-content: center;
        flex: 1;
        position: relative;
    }
    .frame-2 {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .frame-29153 {
        border-radius: 10px;
        padding: 10px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 40px;
        height: 40px;
        position: relative;
    }
    .frame-29153:hover {
        background: var(--buttons-button-secondary-active, #373737);
    }
    .eye-off {
        flex-shrink: 0;
        width: 24px;
        height: 24px;
        position: relative;
        overflow: visible;
    }
    .frame-29155 {
        border-radius: 10px;
        padding: 10px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 40px;
        height: 40px;
        position: relative;
    }
    .frame-29155:hover {
        background: var(--buttons-button-secondary-active, #373737);
    }
    .share-07 {
        flex-shrink: 0;
        width: 24px;
        height: 24px;
        position: relative;
        overflow: visible;
    }
    .main-description {
        max-width: 500px;
        /*height: 55px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;*/
    }
    .caption {
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        text-transform: capitalize;
    }
    .description {
        color: rgb(140, 140, 140);
        font-size: 14px;
        line-height: 16px;
    }
    .skeleton {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }
    .skeleton-icon {
        display: flex;
        flex-direction: row;
        gap: 15px;
    }
`;
const FooterStyledPage = styled.div`
    &.mobile-description {
        min-height: 52px;
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 10px;
        flex-shrink: 0;
        border-top: 1px solid rgba(166, 166, 166, 0.25);
        background: #121212;
    }
    .main-mobile-description {
        width: auto;
        padding: 16px 16px !important;
    }
    .main-mobile-description .caption {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
    }
    .main-mobile-description .description {
        font-weight: 400;
        color: #8c8c8c;
    }
`;
export default function Footer(props) {
    const {
        showBeacon,
        setShowBeacon,
        setShowShareBOimagePopup,
        setShowBOImagePageID,
        setShowRightUserLocationPanel,
        setFooterLogoClicked
    } = useContext(LayoutContext);
    const { selectedBOImageData, setSelectedBrandID, setBOTagsList, selectedBotag } = React.useContext(AppContext);
    const { isMobile } = React.useContext(LayoutContext);
    const navigate = useNavigate();
    useEffect(() => {
        if (!isMobile && selectedBOImageData && selectedBOImageData?.description) {
            const descriptionElement = document.querySelector(".description");
            const text = descriptionElement.textContent;
            const maxLength = 120; // adjust this value to set the maximum length of the text

            if (text.length > maxLength) {
                const truncatedText = text.substring(0, maxLength) + "...";
                descriptionElement.textContent = truncatedText;
            }
        }
    }, [selectedBOImageData]);
    const handleNavigateToBrandFeed = brandID => {
        navigate("/feed");
        setSelectedBrandID(brandID);
        setBOTagsList([]);
        setShowBOImagePageID("");
        setShowRightUserLocationPanel(false);
        setFooterLogoClicked(true);
    };
    return (
        <>
            <StyledPage className={props?.className || ""}>
                <div className="footer">
                    <div className="frame-12">
                        <div className="frame-14">
                            {selectedBOImageData?.brandInfo?.logos?.mainLogoURL ? (
                                <img
                                    className="samsoe-samsoe-new-white-2"
                                    src={selectedBOImageData?.brandInfo?.logos?.mainLogoURL}
                                    alt=""
                                    onClick={() => handleNavigateToBrandFeed(selectedBOImageData?.brandInfo?._id)}
                                />
                            ) : (
                                <Skeleton width={200} height={20} type={"footer"} />
                            )}
                        </div>
                        {!isMobile && selectedBOImageData ? (
                            <div className="main-description">
                                <span className="caption">{selectedBOImageData?.caption}</span>
                                {selectedBOImageData?.description && (
                                    <Tooltip
                                        placement="top"
                                        title={
                                            selectedBOImageData?.description.length > 120 ? (
                                                selectedBOImageData?.description.length > 100 ? (
                                                    selectedBOImageData?.description
                                                ) : (
                                                    <span dangerouslySetInnerHTML={{ __html: selectedBOImageData?.description }}></span>
                                                )
                                            ) : (
                                                ""
                                            )
                                        }
                                        color="#212121"
                                        overlayClassName="custom-tool-tip">
                                        <span className="description">{" - " + selectedBOImageData?.description}</span>
                                    </Tooltip>
                                )}
                            </div>
                        ) : (
                            !isMobile && (
                                <div className="skeleton">
                                    <Skeleton width={369} height={12.25} type={"footer"} />
                                    <Skeleton width={413} height={12.25} type={"footer"} />
                                </div>
                            )
                        )}

                        <div className="frame-15">
                            {selectedBOImageData ? (
                                <div className="frame-2">
                                    {!selectedBotag ? (
                                        <div
                                            className="frame-29153"
                                            onClick={() => setShowBeacon(!showBeacon)}
                                            style={{ cursor: "pointer" }}>
                                            <img className="eye-off" src={showBeacon ? eyeOff0 : eyeOn} alt="" />
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                    <div
                                        className="frame-29155"
                                        onClick={() => setShowShareBOimagePopup(true)}
                                        style={{ cursor: "pointer" }}>
                                        <img className="share-07" src={share070} alt="" />
                                    </div>
                                </div>
                            ) : (
                                <div className="skeleton-icon">
                                    <Skeleton width={18} height={18} type={"footer"} />
                                    <Skeleton width={18} height={18} type={"footer"} />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </StyledPage>
            {isMobile && (
                <FooterStyledPage className="mobile-description">
                    <div className="main-mobile-description">
                        {selectedBOImageData ? (
                            <span className="caption">
                                {selectedBOImageData?.caption}{" "}
                                {selectedBOImageData?.description && (
                                    <span className="description">{" - " + selectedBOImageData?.description}</span>
                                )}
                            </span>
                        ) : (
                            <div className="skeleton">
                                <Skeleton width={369} height={20} type={"footer"} />
                            </div>
                        )}
                    </div>
                </FooterStyledPage>
            )}
        </>
    );
}
