import React, { useState } from "react";
import loader from "../../../assets/images/paymentloader.gif";
import styled from "styled-components";
import LayoutContext from "../../../contexts/LayoutContext";
import AnchorDrawer from "../../../AnchorDrawer";

const StyledPage = styled.div`
    &.defaultPopupOverlay {
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0;
        right: 0;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        background-color: rgba(24, 24, 24, 0.5);
        z-index: 99;
        max-width: 100%;
        backdrop-filter: blur(6px);
    }
    .defaultPopupContainer {
        background-color: #10141b;
        min-height: auto;
        width: 404px;
        border-radius: 10px;
        flex-direction: column;
        align-items: center;
        padding: 24px;
        display: flex;
        position: relative;
        box-shadow: var(--dropshadow-pop-up2-box-shadow, 4px 4px 87.4px 0px rgba(0, 0, 0, 1));
        color: #ffffff;
    }
    .popupBtns {
        width: 47%;
        padding: 11px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        border: none;
        outline: none;
        cursor: pointer;
        color: #ffffff;
        width: 100%;
    }
    .popupBtnsContainer {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: 20px;
        gap: 10px;
    }
`;

/**
 * A reusable component for displaying a default popup card.
 * An example for this component is ShareLocationPopup!
 * Note: NOT EVERY PROP IS FULLY TESTED FOR BUGS
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} [props.visible=true] - Determines whether the popup card is visible or hidden.
 * @param {ReactNode} props.children - The content to be displayed inside the popup card.
 * @param {string} [props.backgroundColor="#121212"] - The background color of the popup card.
 * @param {boolean} [props.useConfirmBtn=false] - Determines whether to display a confirm button.
 * @param {boolean} [props.useCancelBtn=false] - Determines whether to display a cancel button.
 * @param {string} [props.cancelBtnText="Cancel"] - The text to be displayed on the cancel button.
 * @param {string} [props.confirmBtnText="Confirm"] - The text to be displayed on the confirm button.
 * @param {function} [props.cancelBtnAction=undefined] - The action to be performed when the cancel button is clicked.
 * @param {function} [props.confirmBtnAction=undefined] - The action to be performed when the confirm button is clicked.
 * @param {boolean} [props.isLoadingData=false] - Determines whether the data is loading.
 * @param {boolean} [props.mobileUsesFullScreen=true] - Determines whether the popup card uses the full screen on mobile.
 * @param {function} props.onClickOutside - this function triggers when clicking outside the popup.
 * @param {function} props.onDrawerClick - this function triggers when clicking on anywhere on drawer.
 *
 * @returns {JSX.Element} The rendered DefaultPopupCard component.
 */
export const DefaultPopupCard = ({
    visible = true,
    children,
    backgroundColor = "#121212",
    useConfirmBtn = false,
    useCancelBtn = false,
    cancelBtnText = "Cancel",
    confirmBtnText = "Confirm",
    cancelBtnAction = undefined,
    confirmBtnAction = undefined,
    isLoadingData = false,
    mobileUsesFullScreen = true,
    className,
    onClickOutside = () => {},
    onDrawerClick = () => {},
    zIndex = null
}) => {
    /* #region VARS */
    const [isLoading, setIsLoading] = useState(false);
    const { isMobile } = React.useContext(LayoutContext);
    /* #endregion */

    /* #region METHODS */
    const onConfirmBtnClick = async () => {
        if (!confirmBtnAction) return;
        setIsLoading(true);
        await confirmBtnAction();
        setIsLoading(false);
    };

    const onCancelBtnClick = async () => {
        if (!cancelBtnAction) return;
        setIsLoading(true);
        await cancelBtnAction();
        setIsLoading(false);
    };
    /* #endregion */

    return (
        <PopupMobileContainer
            isMobile={isMobile}
            visible={visible}
            mobileUsesFullScreen={mobileUsesFullScreen}
            onClickOutside={onClickOutside}
            onDrawerClick={onDrawerClick}
            zIndex={zIndex}>
            <div className="defaultPopupContainer" style={{ backgroundColor: backgroundColor }}>
                <div className="w-100">{children}</div>

                {(useCancelBtn || useConfirmBtn) && (
                    <div className="popupBtnsContainer">
                        {useCancelBtn && (
                            <button
                                className="popupBtns btnCancel"
                                style={{ backgroundColor: "rgba(30, 38, 49, 0.35)" }}
                                onClick={onCancelBtnClick}>
                                {cancelBtnText}
                            </button>
                        )}
                        {useConfirmBtn && (
                            <button
                                className="popupBtns btnConfirm"
                                style={{ backgroundColor: "rgba(247, 102, 95, 1)" }}
                                onClick={onConfirmBtnClick}>
                                {isLoading || isLoadingData ? (
                                    <>
                                        <img src={loader} alt="" style={{ width: "15px", height: "15px" }} /> &nbsp;
                                        {confirmBtnText}
                                    </>
                                ) : (
                                    <span>{confirmBtnText}</span>
                                )}
                            </button>
                        )}
                    </div>
                )}
            </div>
        </PopupMobileContainer>
    );
};

/* #region SMALL COMPONENTS */

const PopupMobileContainer = ({ isMobile, children, visible, mobileUsesFullScreen, onClickOutside, onDrawerClick, zIndex }) => {
    return isMobile ? (
        <AnchorDrawer anchor="bottom" isOpen={visible} mobileUsesFullScreen={mobileUsesFullScreen} onDrawerClick={onDrawerClick}>
            {children}
        </AnchorDrawer>
    ) : (
        <StyledPage
            className="defaultPopupOverlay"
            style={{ zIndex: zIndex ? zIndex : "99999", display: visible ? "flex" : "none" }}
            onClick={onClickOutside}>
            {children}
        </StyledPage>
    );
};

/* #endregion */
