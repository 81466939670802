import React from "react";
import styled from "styled-components";

/* #region SCOPED CSS */
const StyledInput = styled.div`
    .floating-input {
        position: relative;
        width: 100%;
    }
    .floating-input label {
        position: absolute;
        top: 13px;
        left: 22px;
        pointer-events: none;
        transition: 0.2s ease all;
        color: #8c8c8c;
        font-size: 13px;
        font-weight: 400;
    }

    .floating-input input:focus + label,
    .floating-input input:not(:placeholder-shown) + label {
        top: 5px;
        font-size: 8px;
        left: 18px;
        font-weight: 600;
        color: #8c8c8c;
    }
    .customer-info-inputs {
        background-color: transparent;
        display: inline-block;
        width: 100%;
        outline: none;
        padding: 16px;
        padding-top: 28px;
        height: 46px;
        border-radius: 5px;
        color: #ffffff;
        font-size: 14px;
    }
`;
/* #endregion */
const FloatingInput = ({ label, onChange, value, isDisable = false, isTyping = false }) => {
    return (
        <StyledInput style={{ width: "100%" }}>
            <div className="floating-input" style={{ opacity: isDisable ? 0.2 : 1 }}>
                <input
                    type="text"
                    className="customer-info-inputs"
                    placeholder=" "
                    autoComplete="off"
                    required
                    onChange={onChange}
                    value={value}
                    style={{
                        border:
                            value?.length > 0
                                ? isTyping
                                    ? "1px solid var(--text-text-primary)"
                                    : "1px solid var(--border-border-primary-10)"
                                : "1px solid var(--border-border-primary-10)"
                    }}
                    disabled={isDisable}
                />
                <label>{label}</label>
            </div>
        </StyledInput>
    );
};

export default FloatingInput;
