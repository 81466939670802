import BOImagePage from "../components/pages/BOImage/BOImagePage";
import FeedPage from "../components/pages/Feed/FeedPage";
import PageNotFound from "../components/pages/PageNotFound";
import PasswordPage from "../components/pages/PasswordPage";
import PageRedirectComponent from "../components/pages/PageRedirectComponent";
import HomePage from "../components/pages/Home/Index";

export const AllRoutes = [
    {
        path: "/",
        element: <HomePage />
    },
    {
        path: "/:BOImageID",
        element: <FeedPage />
    },
    {
        path: "/password",
        element: <PasswordPage />
    },
    {
        path: "/feed",
        element: <FeedPage />
    },
    {
        path: "/feed/:brandID",
        element: <FeedPage />
    },
    {
        path: "/pic/:GTINourID",
        exact: true,
        element: <FeedPage />
    },
    {
        path: "/boimage",
        element: <BOImagePage />
    },
    {
        path: "/404",
        element: <PageNotFound />
    },
    {
        path: "*",
        element: <PageNotFound />
    }
];
