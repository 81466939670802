import React from "react";
import StockExceedInfo from "../../../../assets/images/stockexceedinfo.svg";
import { ReactComponent as Checkbox } from "../../../../assets/images/terms-check.svg";
export default function BOTagBodyDeliveryUnavailable({
    pickupDistance,
    setStoreTooAway,
    deliveryMethod,
    fastestOption,
    deliveryPreference
}) {
    const [dontShow, setDontShow] = React.useState(false);
    const handleDontShowAgain = () => {
        setDontShow(true);
    };
    return (
        <div
            style={{
                margin: "0px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                height: "144.5px"
            }}>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                    width: "180px",
                    justifyContent: "center",
                    gap: "5px",
                    height: "140px"
                }}>
                <div className="stockexceedIcon">
                    <img src={StockExceedInfo} alt="" />
                </div>
                <div className="checkText" style={{ display: "flex", flexDirection: "column" }}>
                    <span style={{ fontWeight: "600", lineHeight: "16px", fontSize: "12px" }}>
                        {fastestOption === deliveryMethod
                            ? deliveryPreference === "fastest"
                                ? `Fastest option is pick up, but it’s more than ${pickupDistance} km away.`
                                : `Cheapest option is pick up, but it’s more than ${pickupDistance} km away`
                            : `Nearest store for pick up is more than ${pickupDistance} km away.`}
                    </span>
                </div>
                <div
                    className="checkText cursor-pointer"
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        fontWeight: "400",
                        lineHeight: "16px",
                        fontSize: "10px",
                        color: "#8C8C8C",
                        gap: "5px",
                        alignItems: "center",
                        justifyContent: "center"
                    }}
                    onClick={handleDontShowAgain}>
                    {dontShow ? (
                        <Checkbox
                            className="frame-29057 cursor-pointer"
                            style={{
                                height: "14px",
                                width: "14px"
                            }}
                        />
                    ) : (
                        <div className="ellipse-1-99303"></div>
                    )}{" "}
                    Don’t inform me again
                </div>
            </div>
            <div
                className="frame-3135012"
                onClick={() => {
                    setStoreTooAway(false);
                    localStorage.setItem("dontShow", dontShow);
                }}>
                <div className="frame-313503 full-width-btn">
                    <div className="btn-fw-botag" style={{ background: "#212121" }}>
                        <div className="frame-313497">
                            <div className="frame-313349">
                                <div className="button">Show anyway</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
