import React, { useEffect, useState } from "react";
import { DefaultPopupCard } from "./DefaultPopupCard";
import styled from "styled-components";
import QrIcon from "./../../../assets/images/qr-icon.svg";
import QRCode from "react-qr-code";
import { ReactComponent as Frame290570 } from "../../../assets/images/close-btn.svg";
import { ReactComponent as Frame290 } from "../../../assets/images/back.svg";
import LayoutContext from "../../../contexts/LayoutContext";

const POS_FRONT_SYSTEMS_ID = "6537dc8613db5d57a8cc5815";

const StyledPage = styled.div`
    &.frame-29643,
    &.frame-29643 * {
        box-sizing: border-box;
    }
    &.frame-29643 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-end;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-3590 {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .group-3413 {
        flex-shrink: 0;
        width: 50px;
        height: 50px;
        position: static;
    }
    .ellipse-80 {
        background: var(--wrap-wrap-brand, rgba(247, 102, 95, 0.1));
        border-radius: 50%;
        width: 50px;
        height: 50px;
        position: absolute;
        left: calc(50% - 25px);
        top: calc(50% - 25px);
    }
    .qr-code-01 {
        width: 5.94%;
        height: 48%;
        position: absolute;
        right: 47.03%;
        left: 47.03%;
        bottom: 26%;
        top: 26%;
        overflow: visible;
    }
    .your-qr-codes {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-family: "OpenSans-SemiBold", sans-serif;
        font-size: 22px;
        line-height: 24px;
        font-weight: 600;
        position: relative;
        align-self: stretch;
    }
    .for-pick-up-orders-please-show-the-relevant-qr-code-to-the-store-clerk-when-you-arrive-to-receive-your-order {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: center;
        font-family: var(--subtitle-2-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 14px);
        line-height: var(--subtitle-2-line-height, 24px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
        align-self: stretch;
    }
    .separator {
        display: flex;
        flex-direction: row;
        gap: 6px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .line-27 {
        margin-top: -0.5px;
        border-style: solid;
        border-color: var(--border-border-tertiary, #373737);
        border-width: 0.5px 0 0 0;
        flex: 1;
        height: 0px;
        position: relative;
        transform-origin: 0 0;
        transform: rotate(0.001deg) scale(1, 1);
    }
    .text-label-here {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: center;
        font-family: var(--caption-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--caption-font-size, 12px);
        line-height: var(--caption-line-height, 20px);
        font-weight: var(--caption-font-weight, 400);
        position: relative;
    }
    .line-26 {
        margin-top: -0.5px;
        border-style: solid;
        border-color: var(--border-border-tertiary, #373737);
        border-width: 0.5px 0 0 0;
        flex: 1;
        height: 0px;
        position: relative;
        transform-origin: 0 0;
        transform: rotate(0.001deg) scale(1, 1);
    }
    .frame-313541 {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: center;
        justify-content: flex-end;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-29717 {
        border-radius: 4px;
        border-style: solid;
        border-color: var(--border-border-primary, rgba(166, 166, 166, 0.2));
        border-width: 1px;
        padding: 16px;
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .frame-29716 {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: center;
        justify-content: flex-end;
        flex: 1;
        position: relative;
    }
    .frame-313440 {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: flex-end;
        justify-content: center;
        flex: 1;
        position: relative;
    }
    .frame-313441 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-end;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .product-name {
        color: var(--text-text-primary, #ffffff);
        text-align: left;
        font-family: "OpenSans-SemiBold", sans-serif;
        font-size: 14px;
        line-height: 20px;
        font-weight: 600;
        position: relative;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .product-name2 {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: left;
        font-family: var(--caption-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--caption-font-size, 12px);
        /* line-height: var(--caption-line-height, 20px); */
        font-weight: var(--caption-font-weight, 400);
        position: relative;
        align-self: stretch;
        /* height: 16px; */
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .frame-295922557 {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: flex-end;
        justify-content: flex-start;
        flex-shrink: 0;
        position: relative;
    }
    .btn-xs-secondary {
        background: var(--buttons-button-primary, #f7665f);
        border-radius: 5px;
        padding: 10px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        height: 30px;
        position: relative;
        cursor: pointer;
    }
    .button {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-family: var(--button-text-small-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--button-text-small-font-size, 12px);
        line-height: var(--button-text-small-line-height, 16px);
        font-weight: var(--button-text-small-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .frame-313542 {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: center;
        justify-content: flex-end;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .btn-xs-secondary2 {
        background: var(--buttons-button-secondary, #212121);
        border-radius: 5px;
        opacity: 0.2;
        padding: 10px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 101px;
        height: 30px;
        position: relative;
        cursor: no-drop;
    }
`;
const StyledPageQR = styled.div`
    &.frame-29643,
    &.frame-29643 * {
        box-sizing: border-box;
    }
    &.frame-29643 {
        display: flex;
        flex-direction: column;
        gap: 15px;
        align-items: flex-end;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-3590 {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .group-3413 {
        flex-shrink: 0;
        width: 50px;
        height: 50px;
        position: static;
    }
    .ellipse-80 {
        background: var(--wrap-wrap-brand, rgba(247, 102, 95, 0.1));
        border-radius: 50%;
        width: 50px;
        height: 50px;
        position: absolute;
        left: calc(50% - 25px);
        top: calc(50% - 25px);
    }
    .qr-code-01 {
        width: 5.94%;
        height: 48%;
        position: absolute;
        right: 47.03%;
        left: 47.03%;
        bottom: 26%;
        top: 26%;
        overflow: visible;
    }
    .your-qr-code {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-family: "OpenSans-SemiBold", sans-serif;
        font-size: 22px;
        line-height: 24px;
        font-weight: 600;
        position: relative;
        align-self: stretch;
    }
    .to-receive-your-order-show-this-qr-code-to-a-store-clerk-in-sams-e-sams-e-akerbrygge {
        text-align: center;
        font-family: var(--subtitle-2-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 14px);
        line-height: var(--subtitle-2-line-height, 24px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
        align-self: stretch;
    }
    .to-receive-your-order-show-this-qr-code-to-a-store-clerk-in-sams-e-sams-e-akerbrygge-span {
        color: var(--text-text-secondary, #8c8c8c);
        font-family: var(--subtitle-2-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 14px);
        line-height: var(--subtitle-2-line-height, 24px);
        font-weight: var(--subtitle-2-font-weight, 400);
    }
    .to-receive-your-order-show-this-qr-code-to-a-store-clerk-in-sams-e-sams-e-akerbrygge-span2 {
        color: var(--text-text-primary, #ffffff);
        font-family: var(--subtitle-2-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 14px);
        line-height: var(--subtitle-2-line-height, 24px);
        font-weight: var(--subtitle-2-font-weight, 400);
    }
    .to-receive-your-order-show-this-qr-code-to-a-store-clerk-in-sams-e-sams-e-akerbrygge-span3 {
        color: var(--text-text-secondary, #8c8c8c);
        font-family: var(--subtitle-2-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 14px);
        line-height: var(--subtitle-2-line-height, 24px);
        font-weight: var(--subtitle-2-font-weight, 400);
    }
    .frame-296432 {
        border-radius: 5px;
        padding: 15px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313403 {
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313404 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .https-zealous-pebble-0-ee-447-b-03-2-azurestaticapps-1 {
        border-radius: 5px;
        border-style: solid;
        border-width: 6px;
        flex-shrink: 0;
        position: relative;
        object-fit: cover;
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    ._321325-j-b-otag-pickup {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-family: var(--heading-3-font-family, "OpenSans-SemiBold", sans-serif);
        font-size: var(--heading-3-font-size, 16px);
        line-height: var(--heading-3-line-height, 24px);
        font-weight: var(--heading-3-font-weight, 600);
        position: relative;
        width: 307px;
    }
`;
export default function OrderQRPopup({ visible }) {
    const { qrData, setShowQrPopup } = React.useContext(LayoutContext);
    const [showQR, setShowQR] = useState(false);
    const [storeName, setStoreName] = useState(null);
    const [qrCode, setQrCode] = useState(null);
    const [storeList, setStoreList] = useState([]);
    useEffect(() => {
        if (qrData?.result.length) {
            const uniqueObjects = qrData?.result.filter(
                (obj, index, self) => index === self.findIndex(t => t.storeOurID === obj.storeOurID)
            );
            setStoreList(uniqueObjects);
        }
    }, [qrData]);

    const handleShowQRFunction = data => {
        setShowQR(true);
        setStoreName(data?.storeName);

        const startsWithO =
            data?.salePOSid === POS_FRONT_SYSTEMS_ID ? "O" : !data?.salePOSid && data?.storePOSid === POS_FRONT_SYSTEMS_ID ? "O" : "";

        setQrCode(`${startsWithO}${qrData?.orderDetails?.OurID}-BOtag-Pickup-${data?.storeOurID}`);
    };

    return (
        <DefaultPopupCard visible={visible} mobileUsesFullScreen={false}>
            {!showQR ? (
                <StyledPage className="frame-29643">
                    <div className="frame-3590">
                        <div className="group-3413">
                            <div className="ellipse-80"></div>
                            <img className="qr-code-01" src={QrIcon} alt="qr-code-010.svg" />
                        </div>
                        <Frame290570
                            style={{ position: "absolute", right: "0px", top: "0px" }}
                            className="frame-29057 cursor-pointer"
                            onClick={() => setShowQrPopup(false)}
                        />
                    </div>
                    <div className="your-qr-codes">Your QR codes</div>
                    <div className="for-pick-up-orders-please-show-the-relevant-qr-code-to-the-store-clerk-when-you-arrive-to-receive-your-order">
                        For pick up orders, please show the relevant QR code to the store clerk when you arrive to receive your order.
                    </div>
                    <div className="separator">
                        <div className="line-27"></div>
                        <div className="text-label-here">You bought from the following stores using pick up</div>
                        <div className="line-26"></div>
                    </div>
                    {storeList.map((v, i) => {
                        return v?.deliveryOption === "Pickup" ? (
                            <div className="frame-313541">
                                <div className="frame-29717">
                                    <div className="frame-29716">
                                        <div className="frame-313440">
                                            <div className="frame-313441">
                                                <div className="product-name">{v?.storeName}</div>
                                            </div>
                                            <div className="product-name2">{`${v?.storeFullAddress}`}</div>
                                        </div>
                                    </div>
                                    <div className="frame-295922557">
                                        {v?.status === "New" ? (
                                            <div className="btn-xs-secondary2">
                                                <div className="button">Not ready</div>
                                            </div>
                                        ) : (
                                            <div className="btn-xs-secondary" onClick={() => handleShowQRFunction(v)}>
                                                <div className="button">Show QR code</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            ""
                        );
                    })}
                </StyledPage>
            ) : (
                <StyledPageQR className="frame-29643">
                    <div className="frame-3590">
                        <div className="group-3413">
                            <div className="ellipse-80"></div>
                            <img className="qr-code-01" src={QrIcon} alt="qr-code-010.svg" />
                        </div>
                        <Frame290
                            style={{ position: "absolute", left: "0px", top: "0px" }}
                            className="frame-29057 cursor-pointer"
                            onClick={() => setShowQR(false)}
                        />
                    </div>
                    <div className="your-qr-code">Your QR code</div>
                    <div className="to-receive-your-order-show-this-qr-code-to-a-store-clerk-in-sams-e-sams-e-akerbrygge">
                        <span>
                            <span className="to-receive-your-order-show-this-qr-code-to-a-store-clerk-in-sams-e-sams-e-akerbrygge-span">
                                To receive your order, show this QR code to a store clerk in:
                                <br />
                            </span>
                            <span className="to-receive-your-order-show-this-qr-code-to-a-store-clerk-in-sams-e-sams-e-akerbrygge-span2">
                                {storeName}
                            </span>
                            <span className="to-receive-your-order-show-this-qr-code-to-a-store-clerk-in-sams-e-sams-e-akerbrygge-span3"></span>
                        </span>
                    </div>
                    <div className="frame-296432">
                        <div className="frame-313403">
                            <div className="frame-313404">
                                <div className="https-zealous-pebble-0-ee-447-b-03-2-azurestaticapps-1">
                                    <QRCode size={120} value={qrCode} />
                                </div>
                                <div className="_321325-j-b-otag-pickup">{qrCode}</div>
                            </div>
                        </div>
                    </div>
                </StyledPageQR>
            )}
        </DefaultPopupCard>
    );
}
