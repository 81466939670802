import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import target030 from "../../../assets/images/target-030.svg";
import { ReactComponent as MarkerPin020 } from "../../../assets/images/marker-pin-020.svg";
import { ReactComponent as MarkerPin009 } from "../../../assets/images/marker-pin-009.svg";
import { ReactComponent as MarkerPinW009 } from "../../../assets/images/marker-pin-w-009.svg";
import { StandaloneSearchBox } from "@react-google-maps/api";
import { UserContext } from "../../../contexts/UserContext";
import { ReactComponent as ChevronRight0 } from "../../../assets/images/map-pen.svg";
import { fetchAddressFromCoordinates } from "../../../utils/LocationLib";
import ShareLocationPopup from "../dialogs/ShareLocationPopup";
import GoogleMaps from "./GoogleMaps";
import LayoutContext from "../../../contexts/LayoutContext";
import MapSkeleton from "../skeletons/MapSkeleton";
import AppContext from "../../../contexts/AppContext";
import Union0 from "../../../assets/images/union0.svg";
import Store0 from "../../../assets/images/store0.svg";
import moment from "moment";
import momentTimeZone from "moment-timezone";
import StoreRightPanelSkeleton from "../skeletons/StoreRightPanelSkeleton";
import FeaturedProductsSection from "./FeaturedProductsSection";
import ProductDetailsSection from "./ProductDetailsSection";
import logGA4Event from "../../../utils/firebase/analytics";
import StandardsContext from "../../../contexts/StandardsContext";
import * as Library from "../../../utils/Library";
import { getCoordinates, getLocation, saveCoordinates, saveLocation } from "../../../utils/helperFunctions";
import ShareMyLocation from "./ShareMyLocation";
import InfoImage from "../../../assets/images/info-image-light.svg";
import { getDistanceBetweenCoordinates } from "../../../utils/LocationLib";
const StyledPage = styled.div`
    &.user-location-panel-container {
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: column;
        align-items: flex-start;
        flex-shrink: 0;
        justify-content: center;
    }

    .user-location-panel,
    .user-location-panel * {
        box-sizing: border-box;
    }
    .user-location-panel {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        /* min-height: 96.5vh !important; */
        position: relative;
        overflow: hidden;
        width: 100%;
    }
    .panel-headers {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        flex-shrink: 0;
        width: 100%;
        position: relative;
    }
    .frame-313366 {
        background: rgba(166, 166, 166, 0.1);
        border-radius: 500px;
        padding: 4px 14px 4px 14px;
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .assistive-panel {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-size: var(--heading-3-font-size, 16px);
        line-height: var(--heading-3-line-height, 24px);
        font-weight: var(--heading-3-font-weight, 600);
        position: relative;
    }
    .frame-313365 {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: flex-end;
        flex-shrink: 0;
        width: 159px;
        position: relative;
    }
    .separator-middle {
        border-style: solid;
        border-color: var(--border-border-tertiary, #373737);
        border-width: 0.5px 0 0 0;
        flex-shrink: 0;
        width: 100%;
        height: 0px;
        position: relative;
        transform-origin: 0 0;
        transform: rotate(0deg) scale(1, 1);
    }
    .tabs-level-1 {
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .buttons-fixed-width {
        background: var(--buttons-button-secondary, #212121);
        border-radius: 5px 0px 0px 5px;
        padding: 16px;
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: center;
        flex: 1;
        height: 36px;
        position: relative;
        box-shadow: var(--bt-drop-shadow-box-shadow, 0px 0px 4px 0px rgba(30, 38, 49, 0.35));
    }
    .buttons-fixed-width:last-child {
        border-radius: 0px 5px 5px 0px;
    }
    .buttons-fixed-width.active,
    .buttons-fixed-width:hover {
        background: var(--buttons-button-secondary-active, #373737);
    }
    .label {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-size: var(--button-text-small-semibold-font-size, 12px);
        line-height: var(--button-text-small-semibold-line-height, 16px);
        font-weight: var(--button-text-small-semibold-font-weight, 600);
        position: relative;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .label-active {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-family: var(--button-text-small-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--button-text-small-font-size, 12px);
        line-height: var(--button-text-small-line-height, 16px);
        font-weight: var(--button-text-small-font-weight, 400);
        position: relative;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .search-location {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        justify-content: flex-start;
        flex-shrink: 0;
        width: 100%;
        height: 48px;
        position: relative;
        width: 100%;
    }
    .frame-29592 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .buttons-fixed-width3 {
        border-radius: 5px;
        border-style: solid;
        border-color: rgba(166, 166, 166, 0.2);
        border-width: 1px;
        padding: 8px 8px 8px 16px;
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        height: 48px;
        position: relative;
        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
    }
    .frame-29480 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .frame-29480 > div {
        width: 100%;
    }
    .marker-pin-02 {
        flex-shrink: 0;
        width: 24px;
        height: 24px;
        position: relative;
        overflow: visible;
    }
    .insert-location {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: left;
        font-family: var(--subtitle-2-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 14px);
        line-height: var(--subtitle-2-line-height, 24px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        border: none;
        background: none;
        outline: none;
        width: 100%;

        /* padding-left: 5px; */
        padding-right: 5px;
    }
    .insert-location.has-data {
        color: #ffffff;
    }
    .frame-29593 {
        background: #f7665f;
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 48px;
        height: 48px;
        position: relative;
    }
    .target-03 {
        flex-shrink: 0;
        width: 20px;
        height: 20px;
        position: relative;
        overflow: visible;
    }
    .map-section {
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        /* flex: 1; */
        position: relative;
        height: 100vh;
    }
    .map-section-mobile {
        height: 656.5px;
    }
    .image-156 {
        width: 100%;
        border-radius: 5px;
        align-self: stretch;
        flex: 1;
        position: relative;
        object-fit: cover;
    }
    .buttons-section {
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 371px;
        height: 26px;
        position: relative;
        /*box-shadow: var(--bt-drop-shadow-box-shadow, 0px 0px 4px 0px rgba(30, 38, 49, 0.35));*/
    }
    .frame-29589 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .Button {
        text-align: center;
    }
    .text1 {
        color: #8c8c8c;
        font-size: 14px;
        font-family: "Open Sans";
        font-weight: 400;
        line-height: 24px;
        word-wrap: break-word;
    }
    .text2 {
        color: #8c8c8c;
        font-size: 14px;
        font-family: "Open Sans";
        font-weight: 400;
        text-decoration: underline;
        line-height: 24px;
        word-wrap: break-word;
        cursor: pointer;
    }
    .text2:hover {
        color: #f7665f;
    }
`;

const LocationStyle = styled.div`
    &.location-indicator,
    &.location-indicator * {
        box-sizing: border-box;
    }
    &.location-indicator {
        border-radius: 5px;
        border-style: solid;
        border-color: var(--border-border-primary-10, rgba(166, 166, 166, 0.2));
        border-width: 1px;
        padding: 10px 16px 10px 16px;
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        flex-shrink: 0;
        height: 68px;
        position: relative;
        width: 100%;
        cursor: pointer;
    }
    &.location-indicator:hover {
        background-color: hsla(0, 0%, 100%, 0.02);
        border: 1px solid hsla(0, 0%, 65%, 0.2);
    }

    .frame-29614 {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .frame-313472 {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .frame-29476 {
        flex-shrink: 0;
        width: 32px;
        height: 32px;
        position: relative;
    }
    .arrow-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .ellipse-86 {
        background: var(--wrap-wrap-brand, rgba(247, 102, 95, 0.1));
        border-radius: 50%;
        width: 32px;
        height: 32px;
        position: absolute;
        left: 0px;
        top: 0px;
    }
    .frame-29475 {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 18px;
        position: absolute;
        left: calc(50% - 10px);
        top: calc(50% - 9px);
    }
    .marker-pin-01 {
        flex-shrink: 0;
        width: 18px;
        height: 18px;
        position: relative;
        overflow: visible;
        color: #f7665f;
    }
    .user-location-address {
        color: var(--text-text-primary, #ffffff);
        text-align: left;
        font-family: "OpenSans-Regular", sans-serif;
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
        position: relative;
        /* width: 260px; */
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .chevron-right {
        flex-shrink: 0;
        position: relative;
        overflow: visible;
    }
    .share-loaction-text-1 {
        font-size: 12px;
        color: #8c8c8c;
    }
    .share-loaction-text-2 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 270px;
    }
    @media screen and (max-width: 360px) {
        .share-loaction-text-2 {
            max-width: 235px;
        }
    }
    @media screen and (max-width: 375px) {
        .share-loaction-text-2 {
            max-width: 230px;
        }
    }
`;

const StoreCardStyle = styled.div`
    &.store-card,
    &.store-card * {
        box-sizing: border-box;
    }
    &.store-card {
        border-radius: 5px;
        border-style: solid;
        border-color: rgba(166, 166, 166, 0.2);
        border-width: 1px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
        overflow: hidden;
        height: 253px;
        cursor: pointer;
    }
    .frame-313451 {
        border-radius: 5px 5px 0px 0px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        height: 128px;
        position: relative;
        object-fit: cover;
    }
    .frame-29719 {
        padding: 0px 16px 0px 16px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-29717 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-29716 {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: center;
        justify-content: flex-end;
        flex: 1;
        position: relative;
    }
    .map-pins {
        flex-shrink: 0;
        width: 45px;
        height: 45px;
        position: relative;
    }
    .union {
        width: 45px;
        height: 45px;
        position: absolute;
        left: 0px;
        top: 0px;
        overflow: visible;
    }
    .store {
        width: 58.06%;
        height: 58.06%;
        position: absolute;
        right: 22.58%;
        left: 19.35%;
        bottom: 19.35%;
        top: 22.58%;
        overflow: visible;
    }
    .frame-313440 {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: flex-end;
        justify-content: center;
        flex: 1;
        position: relative;
    }
    .frame-313441 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-end;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .product-name {
        color: var(--text-text-primary, #ffffff);
        text-align: left;
        font-size: 14px;
        line-height: 20px;
        font-weight: 600;
        position: relative;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .product-name2 {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: left;
        font-family: var(--caption-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--caption-font-size, 12px);
        line-height: var(--caption-line-height, 20px);
        font-weight: var(--caption-font-weight, 400);
        position: relative;
        align-self: stretch;
        height: 16px;
        /* display: flex; */
        align-items: center;
        justify-content: flex-start;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 276px;
    }
    .frame-313469 {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .separator {
        display: flex;
        flex-direction: row;
        gap: 6px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        height: auto;
        position: relative;
        overflow: visible;

        border-style: solid;
        border-color: var(--border-border-tertiary, #373737);
        border-width: 0.5px 0 0 0;
        flex-shrink: 0;
        width: 100%;
        position: relative;
    }
    .frame-29725 {
        padding: 0px 16px 0px 16px;
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-297172 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .frame-313359 {
        display: flex;
        flex-direction: row;
        gap: 3px;
        align-items: center;
        justify-content: flex-start;
        flex-shrink: 0;
        position: relative;
    }
    .date {
        color: var(--Text-text-primary, #fff);

        /* Caption-Semibold */
        font-family: "Open Sans";
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 14px; /* 116.667% */
    }
    .time {
        color: var(--Text-text-secondary, #8c8c8c);
        font-family: "Open Sans";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
    }
    .open,
    .closed {
        color: #69c23f;
        text-align: left;
        font-size: 10px;
        line-height: 16px;
        font-weight: 600;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .closed {
        color: #f7665f;
    }
    .pac-container-right .pac-container {
        left: 1527px !important;
        width: 315px !important;
    }
    .store-closed {
        color: #f1463d;
        font-size: 10px;
        font-weight: 600;
        font-family: OpenSans-Regular, sans-serif;
    }
    .store-closed-text {
        font-size: 10px;
        font-weight: 600;
        font-family: OpenSans-Regular, sans-serif;
    }
`;
const InformationDiv = styled.div`
    &.information {
        position: absolute;
        z-index: 9;
        height: 81px;
        width: 340px;
        background: rgb(18, 18, 18);
        border-radius: 5px;
        padding: 16px;
        top: 10px;
        -webkit-box-pack: center;
        justify-content: center;
        display: flex;
    }
    .info-main {
        display: flex;
        flex-direction: row;
        gap: 10px;
        justify-content: center;
        align-items: center;
        width: 308px;
        height: 49px;
    }
    .info-group-1 {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .info-group-2 {
        color: var(--Color-gray-white-invert, #f5f5f5);
        font-family: "Open Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
    }
    @media only screen and (max-width: 375px) {
        &.information {
            width: 320px;
        }
        .info-main {
            width: 288px;
        }
        .info-group-2 {
            font-size: 13px;
        }
    }
    @media only screen and (min-width: 376px) and (max-width: 390px) {
        &.information {
            width: 340px;
        }
        .info-main {
            width: 308px;
        }
    }
    @media only screen and (min-width: 391px) and (max-width: 414px) {
        &.information {
            width: 366px;
        }
        .info-main {
            width: 334px;
        }
    }
    @media only screen and (min-width: 415px) and (max-width: 430px) {
        &.information {
            width: 380px;
        }
        .info-main {
            width: 348px;
        }
    }
`;
export default function UserLocationPanel(props) {
    /* #region VARIABLES */
    const inputRef = useRef();
    const { setUserAddress, setUserGPSCoordinates } = useContext(UserContext);
    const {
        setShowPolicyPopup,
        setStoreInformation,
        setShowStoreInformation,
        isMobile,
        setShareLocationSuccess,
        locationLoader,
        setLocationLoader,
        quickBuyPopup
    } = useContext(LayoutContext);
    const { boImageIsLoading, botagAlgorithmnIsLoading, selectedBotagStoreDetails, selectedBotag } = useContext(AppContext);

    const [locationInput, setLocationInput] = React.useState("");
    const [showLocationPopup, setShowLocationPopup] = React.useState(false);
    const [panelCurrentTab, setPanelCurrentTab] = React.useState("map"); // ["map", "featured-products", "product-details"]
    const userAddress = getLocation();
    const userGPSCoordinates = getCoordinates();
    const { setDefaultNotificationCard } = React.useContext(StandardsContext);
    /* #endregion */

    /* #region METHODS */
    const handleGMapApiPlaceChanged = () => {
        const places = inputRef?.current?.getPlaces();
        if (places && typeof places[Symbol.iterator] === "function") {
            const [place] = places;
            if (place) {
                const latitude = place.geometry.location.lat();
                const longitude = place.geometry.location.lng();

                onLocationChanged({ latitude, longitude });
                Library.showNotification({
                    message: "Location successfully shared!",
                    type: "success",
                    setDefaultNotificationCard
                });
                if (isMobile) {
                    const element = document.getElementById("mainContent");
                    element.scrollIntoView({ behavior: "smooth", block: "center" });
                }
            }
        } else {
            console.error("GetPlaces did not return an iterable value.");
        }
    };

    const changeLocationClicked = () => {
        setShowLocationPopup(true);
    };

    const onLocationChanged = location => {
        const { latitude, longitude } = location;

        setUserGPSCoordinates({ latitude, longitude });
        saveCoordinates({ latitude, longitude });

        fetchAddressFromCoordinates(latitude, longitude).then(newUserAddress => {
            setUserAddress(newUserAddress);
            saveLocation(newUserAddress);
        });
    };

    const locationBtnClick = () => {
        setLocationLoader(true);
        const success = position => {
            const { latitude, longitude } = position.coords;
            if (latitude && longitude) {
                onLocationChanged({ latitude, longitude });
                Library.showNotification({
                    message: "Location successfully shared!",
                    type: "success",
                    setDefaultNotificationCard
                });
                if (isMobile) {
                    const element = document.getElementById("mainContent");
                    element.scrollIntoView({ behavior: "smooth", block: "center" });
                }
                setLocationLoader(false);
            }
        };
        const error = e => {
            console.log(e);
            setShowLocationPopup(true);
            setShareLocationSuccess(false);
            setLocationLoader(false);
        };
        const options = {
            enableHighAccuracy: true
        };
        navigator.geolocation.getCurrentPosition(success, error, options);
    };

    const showStoreinfoFunc = () => {
        setShowStoreInformation(true);
        setStoreInformation(selectedBotagStoreDetails);
    };
    const openBotag = () => {};
    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        if (selectedBotag && panelCurrentTab === "featured-products") {
            setPanelCurrentTab("product-details");
        }

        if (panelCurrentTab === "product-details" && !selectedBotag) {
            setPanelCurrentTab("featured-products");
        }
    }, [selectedBotag]);
    /* #endregion */

    return (
        <StyledPage className={`user-location-panel-container ${props?.className || ""}`}>
            <ShareLocationPopup
                visible={showLocationPopup}
                setVisible={setShowLocationPopup}
                onLocationSelected={onLocationChanged}
                openBotag={openBotag}
            />

            <div className="user-location-panel">
                <TitleComponentSection
                    currentTab={panelCurrentTab}
                    setPanelCurrentTab={setPanelCurrentTab}
                    selectedBotag={selectedBotag}
                    isMobile={isMobile}
                />

                {panelCurrentTab === "map" && (
                    <>
                        <LocationSection
                            userGPSCoordinates={userGPSCoordinates}
                            userAddress={userAddress}
                            inputRef={inputRef}
                            locationInput={locationInput}
                            setLocationInput={setLocationInput}
                            handleGMapApiPlaceChanged={handleGMapApiPlaceChanged}
                            changeLocationClicked={changeLocationClicked}
                            locationBtnClick={locationBtnClick}
                        />

                        <MapSection
                            userGPSCoordinates={userGPSCoordinates}
                            isLoading={boImageIsLoading || botagAlgorithmnIsLoading || false}
                            storeDetails={selectedBotagStoreDetails}
                            showStoreinfoFunc={showStoreinfoFunc}
                            isMobile={isMobile}
                            userAddress={userAddress}
                            locationBtnClick={locationBtnClick}
                            changeLocationClicked={changeLocationClicked}
                            quickBuyPopup={quickBuyPopup}
                        />

                        <StoreDetailsPanel
                            storeDetails={selectedBotagStoreDetails}
                            boImageIsLoading={boImageIsLoading}
                            botagAlgorithmnIsLoading={botagAlgorithmnIsLoading || false}
                            setShowStoreInformation={setShowStoreInformation}
                            setStoreInformation={setStoreInformation}
                            showStoreinfoFunc={showStoreinfoFunc}
                            selectedBotag={selectedBotag}
                        />

                        <FooterButtonsSection setShowPolicyPopup={setShowPolicyPopup} />
                    </>
                )}

                {!selectedBotag && panelCurrentTab === "featured-products" && <FeaturedProductsSection />}

                {selectedBotag && panelCurrentTab === "product-details" && <ProductDetailsSection />}
            </div>
        </StyledPage>
    );
}

/* #region SMALLER COMPONENTS */

const TitleComponentSection = ({ currentTab, setPanelCurrentTab, selectedBotag, isMobile }) => {
    return (
        <>
            <div
                className="panel-headers"
                style={{
                    display: isMobile ? "none" : ""
                }}>
                <div className="frame-313366">
                    <div className="assistive-panel">Assistive panel</div>
                </div>
                <div className="frame-313365"></div>
            </div>
            {!isMobile ? <div className="separator-middle"></div> : ""}
            <div className="tabs-level-1">
                <div className={`buttons-fixed-width ${currentTab === "map" ? "active" : ""}`} onClick={() => setPanelCurrentTab("map")}>
                    <div className="label">Map</div>
                </div>

                {selectedBotag && (
                    <div
                        className={`buttons-fixed-width ${currentTab === "product-details" ? "active" : ""}`}
                        onClick={() => {
                            setPanelCurrentTab("product-details");
                            logGA4Event("Product_Details");
                        }}>
                        <div className="label-active">Product details</div>
                    </div>
                )}

                {!selectedBotag && (
                    <div
                        className={`buttons-fixed-width ${currentTab === "featured-products" ? "active" : ""}`}
                        onClick={() => {
                            setPanelCurrentTab("featured-products");
                            logGA4Event("Featured_Products");
                        }}>
                        <div className="label-active">Featured products</div>
                    </div>
                )}
            </div>
        </>
    );
};

const LocationSection = ({
    userGPSCoordinates,
    userAddress,
    inputRef,
    locationInput,
    setLocationInput,
    handleGMapApiPlaceChanged,
    changeLocationClicked,
    locationBtnClick
}) => {
    return (
        <>
            {!userGPSCoordinates?.latitude || !userAddress ? (
                <div className="search-location">
                    <div className="frame-29592">
                        <div className="buttons-fixed-width3">
                            <div className="frame-29480 pac-container-right">
                                {locationInput ? <MarkerPinW009 className="marker-pin-02" /> : <MarkerPin009 className="marker-pin-02" />}
                                <StandaloneSearchBox // css class: .pac-container
                                    className=""
                                    onLoad={ref => (inputRef.current = ref)}
                                    onPlacesChanged={handleGMapApiPlaceChanged}>
                                    <input
                                        type="text"
                                        className={`insert-location ${locationInput ? "has-data" : ""}`}
                                        placeholder="Insert location"
                                        value={locationInput}
                                        onChange={e => setLocationInput(e.target.value)}
                                    />
                                </StandaloneSearchBox>
                            </div>
                        </div>
                        {/* <div className="frame-29593" onClick={locationBtnClick}>
                            <img className="target-03" src={target030} alt="" />
                        </div> */}
                    </div>
                </div>
            ) : (
                <LocationStyle className="location-indicator" onClick={changeLocationClicked}>
                    <div className="frame-29614">
                        <div className="frame-313472">
                            <div className="frame-29476">
                                <div className="ellipse-86"></div>
                                <div className="frame-29475">
                                    <MarkerPin020 className="marker-pin-01" src="marker-pin-010.svg" />
                                </div>
                            </div>
                            <div className="user-location-address">
                                {/* Tordenskiolds gate 2, 0160 Oslo */}
                                <div className="share-loaction-text-1">Your shared location</div>
                                <div className="share-loaction-text-2">
                                    {userAddress?.street} {userAddress?.streetNumber} {userAddress?.postCode} {userAddress?.city},{" "}
                                    {userAddress?.country}
                                </div>
                            </div>
                        </div>
                        <div className="arrow-icon">
                            <ChevronRight0 className="chevron-right" src="chevron-right0.svg" />
                        </div>
                    </div>
                </LocationStyle>
            )}
        </>
    );
};

const MapSection = ({
    userGPSCoordinates,
    isLoading,
    storeDetails,
    showStoreinfoFunc,
    isMobile,
    userAddress,
    locationBtnClick,
    changeLocationClicked,
    quickBuyPopup
}) => {
    const [showShareMyLocation, setShowMyLocation] = useState(true);
    const farawayZoom =
        storeDetails && userGPSCoordinates ? (getDistanceBetweenCoordinates(storeDetails, userGPSCoordinates) > 0.1 ? false : true) : false;

    return (
        <div className={`map-section ${isMobile ? "map-section-mobile" : ""}`}>
            {isLoading ? (
                <MapSkeleton />
            ) : (
                <>
                    {!userAddress && showShareMyLocation && (
                        <ShareMyLocation locationBtnClick={locationBtnClick} setShowMyLocation={setShowMyLocation} />
                    )}
                    {userAddress && !storeDetails && localStorage.getItem("isBotagOpen") !== "true" && <Information isMobile={isMobile} />}
                    <GoogleMaps
                        coords={userGPSCoordinates}
                        farawayZoom={farawayZoom}
                        storeCoords={storeDetails ? { latitude: storeDetails?.latitude, longitude: storeDetails?.longitude } : null}
                        showStoreinfoFunc={showStoreinfoFunc}
                        userAddress={userAddress}
                        isMobile={isMobile}
                        changeLocationClicked={changeLocationClicked}
                        quickBuyPopup={quickBuyPopup}
                    />
                </>
            )}
        </div>
    );
};

const StoreDetailsPanel = ({ storeDetails, boImageIsLoading, botagAlgorithmnIsLoading, showStoreinfoFunc, selectedBotag }) => {
    //TODO: REVIEW STORE OPEN/CLOSE DATETIMES
    const isStoreOpen = () => {
        const isWeekday = new Date().getDay() >= 1 && new Date().getDay() <= 5;
        const isSaturday = new Date().getDay() === 6;

        const openHours = isWeekday
            ? storeDetails?.openHours?.weekdays
            : isSaturday
            ? storeDetails?.openHours?.saturdays
            : storeDetails?.openHours?.sundays;
        if (!openHours || !openHours?.hourStart || !openHours?.hourEnd) return false;

        //get current time in Norway Oslo with moment
        //const currentTime = moment().utcOffset("+0100").format("HH:mm");
        const currentTime = momentTimeZone.tz("Europe/Oslo").format("HH:mm");
        const hourStart = openHours?.hourStart; // "09:00"
        const hourEnd = openHours?.hourEnd; // "18:00"

        //check if current time in Oslo is between open hours
        return currentTime >= hourStart && currentTime <= hourEnd;
    };

    //TODO: REVIEW STORE OPEN/CLOSE DATETIMES
    const storeTodayOpenHours = () => {
        const isWeekday = new Date().getDay() >= 1 && new Date().getDay() <= 5;
        const isSaturday = new Date().getDay() === 6;

        const openHours = isWeekday
            ? storeDetails?.openHours?.weekdays
            : isSaturday
            ? storeDetails?.openHours?.saturdays
            : storeDetails?.openHours?.sundays;

        if (!openHours || !openHours?.hourStart || !openHours?.hourEnd) return "--";

        return `${openHours?.hourStart} - ${openHours?.hourEnd}`;
    };
    return botagAlgorithmnIsLoading ? (
        <StoreRightPanelSkeleton />
    ) : !selectedBotag || !storeDetails || boImageIsLoading ? null : (
        <StoreCardStyle className="store-card" onClick={showStoreinfoFunc}>
            <img className="frame-313451" src={storeDetails?.pictureURL} alt="" />
            <div className="frame-29719">
                <div className="frame-29717">
                    <div className="frame-29716">
                        <div className="map-pins">
                            <img className="union" src={Union0} alt="" />
                            <img className="store" src={Store0} alt="" />
                        </div>
                        <div className="frame-313440">
                            <div className="frame-313441">
                                <div className="product-name">{storeDetails?.name}</div>
                            </div>
                            <div className="product-name2">{`${storeDetails?.address} ${
                                storeDetails?.addressLine2 ? storeDetails?.addressLine2 : ""
                            } ${storeDetails?.addressNumber ? storeDetails?.addressNumber : ""}, ${storeDetails?.postalCode} ${
                                storeDetails?.city
                            }, ${storeDetails?.country}`}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="frame-313469">
                <hr className="separator" />
                <div className="frame-29725">
                    <div className="frame-297172">
                        <div className="frame-313359">
                            {isStoreOpen() ? (
                                <>
                                    <span className="date">Today:</span>
                                    <span className="time">{storeTodayOpenHours()}</span>
                                </>
                            ) : (
                                <>
                                    <span className="store-closed">Closed </span>
                                    <span className="store-closed-text">| You can still order. Store will handle it when it opens.</span>
                                </>
                            )}
                        </div>
                    </div>
                    <div className={isStoreOpen() ? "open" : ""}>{isStoreOpen() ? "Open" : ""}</div>
                </div>
            </div>
        </StoreCardStyle>
    );
};

const FooterButtonsSection = ({ setShowPolicyPopup }) => {
    return (
        <div className="buttons-section">
            <div className="frame-29589">
                <div className="Button" style={{ textAlign: "center" }}>
                    <span className="text1">BOtag Norge AS - </span>
                    <span className="text2" onClick={() => setShowPolicyPopup("terms")}>
                        Terms
                    </span>
                    <span className="text1"> | </span>
                    <span className="text2" onClick={() => setShowPolicyPopup("privacy")}>
                        Privacy policy
                    </span>
                    <span className="text1"> | </span>
                    <span className="text2" onClick={() => setShowPolicyPopup("returns")}>
                        Returns
                    </span>
                </div>
            </div>
        </div>
    );
};
const Information = ({ isMobile }) => {
    const handleGoTop = () => {
        if (isMobile) {
            const element = document.getElementById("mainContent");
            element.scrollIntoView({ behavior: "smooth", block: "center" });
        }
    };
    return (
        <InformationDiv className="information" onClick={handleGoTop}>
            <div className="info-main">
                <div className="info-group-1">
                    <img src={InfoImage} alt="InfoImage" />
                </div>
                <div className="info-group-2">
                    {isMobile
                        ? "Scroll up and select a pulsing pin on the image to see the store on the map"
                        : "Select a pulsing pin on the image on the left to see the store on the map"}
                </div>
            </div>
        </InformationDiv>
    );
};
/* #endregion */
