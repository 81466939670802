import React, { useEffect } from "react";
import { DefaultPopupCard } from "./../DefaultPopupCard";
import { useLocation, useNavigate } from "react-router-dom";
import Spinner from "./Spinner";
import * as Library from "../../../../utils/Library";
import ChangePasswordForm from "./ChangePasswordForm";
import PasswordChangedSuccess from "./PasswordChangedSuccess";
import LayoutContext from "../../../../contexts/LayoutContext";
import UserContext from "../../../../contexts/UserContext";
import StandardsContext from "../../../../contexts/StandardsContext";

export default function ResetPasswordMain({ visible }) {
    /* #region VARS */
    const location = useLocation();
    const navigate = useNavigate();
    const passwordToken = new URLSearchParams(location.search).get("passwordToken");
    const { setShowPasswordForm, setValidatingPasswordToken, validatingPasswordToken, showPasswordForm, passwordIsChanged } =
        React.useContext(LayoutContext);
    const { setBOTagAccountID } = React.useContext(UserContext);
    const { setDefaultNotificationCard } = React.useContext(StandardsContext);
    /* #endregion CSS */

    /* #region EFFECTS */
    useEffect(() => {
        if (passwordToken) {
            Library.makePostRequest(
                "validateNewPasswordToken",
                {
                    token: passwordToken
                },
                false,
                setValidatingPasswordToken
            )
                .then(res => {
                    const { data } = res;
                    if (data?.error) {
                        //Library.showErrorMessage(data?.error);
                        Library.showNotification({
                            message: data?.error,
                            type: "error",
                            setDefaultNotificationCard
                        });
                        navigate("/feed");
                    } else {
                        setShowPasswordForm(true);
                        setBOTagAccountID(data?.bothebotAccountID);
                    }
                })
                .catch(e => {
                    //Library.showErrorMessage("Token already used");
                    Library.showNotification({
                        message: "Token already used",
                        type: "error",
                        setDefaultNotificationCard
                    });
                });
        }
    }, [passwordToken]);
    /* #endregion EFFECTS */

    return (
        <DefaultPopupCard visible={visible} mobileUsesFullScreen={true}>
            {validatingPasswordToken && <Spinner />}
            {showPasswordForm && <ChangePasswordForm />}
            {passwordIsChanged && <PasswordChangedSuccess />}
        </DefaultPopupCard>
    );
}
