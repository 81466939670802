import React, { useState, useContext } from "react";
import styled from "styled-components";
import { DefaultPopupCard } from "./../DefaultPopupCard";
import QuickBuyLogo from "./../../../../assets/images/quick-buy-logo.svg";
import Spinner from "./../../../../assets/images/spinner.svg";
import CourierCard from "./CourierCard";
import UserInformationAndCard from "./UserInformationAndCard";
import LayoutContext from "../../../../contexts/LayoutContext";
import UserContext from "../../../../contexts/UserContext";
import * as Formatter from "../../../../utils/Formatter";
import axios from "axios";
import { HANDLE_CARD_PAYMENT, HANDLE_PAYMENT_TRANSFERS, CREATE_ORDER } from "../../../../api/Endpoints";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import AppContext from "../../../../contexts/AppContext";
import { useNavigate } from "react-router-dom";
import PickupCard from "./PickupCard";
import ShippingCard from "./ShippingCard";
import UserNotLoggedIn from "./UserNotLoggedIn";
import CourierInstructionsPopup from "../CourierInstructionPopup";
/* #region STYLED CSS */
const StyledPage = styled.div`
    &.frame-29643,
    &.frame-29643 * {
        box-sizing: border-box;
    }
    &.frame-29643 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313569 {
        display: flex;
        flex-direction: column;
        gap: 32px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313568 {
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313474 {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-295922840 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .lightning-01 {
        flex-shrink: 0;
        width: 22px;
        height: 22px;
        position: relative;
        overflow: visible;
    }
    .quick-buy {
        color: var(--text-text-primary, #ffffff);
        text-align: left;
        font-family: "OpenSans-SemiBold", sans-serif;
        font-size: 22px;
        line-height: 28px;
        font-weight: 600;
        position: relative;
        flex: 1;
    }
    .btn-xs-secondary {
        background: var(--buttons-button-secondary, #212121);
        border-radius: 5px;
        padding: 10px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        height: 30px;
        position: relative;
        cursor: pointer;
    }
    .button {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-family: var(--button-text-small-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--button-text-small-font-size, 12px);
        line-height: var(--button-text-small-line-height, 16px);
        font-weight: var(--button-text-small-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .frame-295922845 {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }

    .frame-313658 {
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-295922839 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-295922838 {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .separator {
        display: flex;
        flex-direction: row;
        gap: 6px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .line-27 {
        margin-top: -0.5px;
        border-style: solid;
        border-color: var(--border-border-tertiary, #373737);
        border-width: 0.5px 0 0 0;
        flex: 1;
        height: 0px;
        position: relative;
        transform-origin: 0 0;
        transform: rotate(0.001deg) scale(1, 1);
    }
    .text-label-here {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: center;
        font-family: var(--caption-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--caption-font-size, 12px);
        line-height: var(--caption-line-height, 20px);
        font-weight: var(--caption-font-weight, 400);
        position: relative;
    }
    .line-26 {
        margin-top: -0.5px;
        border-style: solid;
        border-color: var(--border-border-tertiary, #373737);
        border-width: 0.5px 0 0 0;
        flex: 1;
        height: 0px;
        position: relative;
        transform-origin: 0 0;
        transform: rotate(0.001deg) scale(1, 1);
    }
    .frame-3133852 {
        display: flex;
        flex-direction: column;
        gap: 5px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-29432 {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        flex-shrink: 0;
        /* width: 372px; */
        height: 41px;
        position: relative;
    }
    .price {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        position: relative;
        flex: 1;
        height: 24px;
    }
    .price-span {
        color: var(--text-text-primary, #ffffff);
        font-family: "OpenSans-Bold", sans-serif;
        font-size: 24px;
        line-height: 24px;
        font-weight: 700;
    }
    .price-span2 {
        color: var(--text-text-primary, #ffffff);
        font-family: "OpenSans-Bold", sans-serif;
        font-size: 30px;
        line-height: 24px;
        font-weight: 700;
    }
    .price-span3 {
        color: var(--text-text-primary, #ffffff);
        font-family: "OpenSans-Bold", sans-serif;
        font-size: 18px;
        line-height: 24px;
        font-weight: 700;
    }
    .btn-md-solid {
        background: var(--buttons-button-primary, #f7665f);
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        height: 44px;
        position: relative;
        box-shadow: var(--bt-drop-shadow-box-shadow, 0px 0px 4px 0px rgba(30, 38, 49, 0.35));
        cursor: pointer;
    }
    .frame-313497 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313349 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .button2 {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-family: var(--button-text-medium-regular-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--button-text-medium-regular-font-size, 14px);
        line-height: var(--button-text-medium-regular-line-height, 24px);
        font-weight: var(--button-text-medium-regular-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .by-placing-order-you-agree-to-the-terms-privacy-policy-of-b-otag-norge-as {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: center;
        font-family: var(--caption-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--caption-font-size, 10px);
        line-height: var(--caption-line-height, 16px);
        font-weight: var(--caption-font-weight, 400);
        position: relative;
        align-self: stretch;
    }
    .by-placing-order-you-agree-to-the-terms-privacy-policy-of-b-otag-norge-as-span {
        color: var(--text-text-secondary, #8c8c8c);
        font-family: var(--caption-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--caption-font-size, 10px);
        line-height: var(--caption-line-height, 16px);
        font-weight: var(--caption-font-weight, 400);
    }
    .terms-condition {
        color: var(--text-text-secondary, #8c8c8c);
        font-family: var(--caption-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--caption-font-size, 10px);
        line-height: var(--caption-line-height, 16px);
        font-weight: var(--caption-font-weight, 400);
        text-decoration: underline;
        cursor: pointer;
    }
    .terms-condition:hover {
        color: #f7665f;
    }
    .by-placing-order-you-agree-to-the-terms-privacy-policy-of-b-otag-norge-as-span3 {
        color: var(--text-text-secondary, #8c8c8c);
        font-family: var(--caption-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--caption-font-size, 10px);
        line-height: var(--caption-line-height, 16px);
        font-weight: var(--caption-font-weight, 400);
    }
    .privay-policy {
        color: var(--text-text-secondary, #8c8c8c);
        font-family: var(--caption-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--caption-font-size, 10px);
        line-height: var(--caption-line-height, 16px);
        font-weight: var(--caption-font-weight, 400);
        text-decoration: underline;
        cursor: pointer;
    }
    .privay-policy:hover {
        color: #f7665f;
    }
    .by-placing-order-you-agree-to-the-terms-privacy-policy-of-b-otag-norge-as-span5 {
        color: var(--text-text-secondary, #8c8c8c);
        font-family: var(--caption-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--caption-font-size, 10px);
        line-height: var(--caption-line-height, 16px);
        font-weight: var(--caption-font-weight, 400);
    }
    .spinner-text {
        display: flex;
        flex-direction: row;
    }
    .spinner-img {
        animation: spin 1s linear infinite;
    }

    @keyframes spin {
        to {
            transform: rotate(360deg);
        }
    }
`;
/* #endregion STYLED CSS */
export default function QUickBuy({ visible }) {
    /* #region VARS */
    const {
        setQuickBuyPopup,
        setShowPolicyPopup,
        courierInstructionsData,
        paymentPopupData,
        setPaymentPopupData,
        shippingAddressPopupData,
        setOrderID,
        setShowThankyouPopup,
        setShowBOImagePageID,
        setShowRightUserLocationPanel,
        setShowRightCheckoutPanel,
        setShowCourierInstructionPopup,
        showCourierInstructionPopup,
        setShippingAddressPopupData,
        setShowShippingAddressPopup
    } = useContext(LayoutContext);
    const { quickBuyData, checkoutPaymentData, userData, BOTagAccountID, setCheckoutPaymentData, shippingRecipient, setThankYouAmount } =
        useContext(UserContext);
    const { selectedBOImageData, setSelectedBotag, setSelectedBotagStoreDetails } = useContext(AppContext);
    const item = quickBuyData?.items.length ? quickBuyData?.items[0] : null;
    const BOTagData = item?.BOTagData;
    const BOTagAlgData = item?.BOTagAlgorithmData;
    const FormattedData = item?.FormattedData;
    const { city, country, postCode, street, streetNumber } = item?.UserAddress;
    const UserAddress = `${street || ""} ${streetNumber || ""}, ${postCode || ""} ${city || ""}, ${country || ""}`;
    const deliveryMethod = BOTagData?.deliveryMethod;
    const elements = useElements();
    const stripe = useStripe();
    const navigate = useNavigate();
    const [placeOrderLoading, setPlaceOrderLoading] = useState(false);
    const [showPaymentPopupQuick, setShowPaymentPopupQuick] = useState(false);
    /* #endregion VARS */
    /*region methods*/
    const onCloseQuickBuy = () => {
        setQuickBuyPopup(false);
    };
    //stripe function
    const createNewOrder = async (updateResponse, cardCredentials, cardDetail) => {
        const _accountID = BOTagAccountID;
        let item;
        if (quickBuyData) {
            const arr = [];
            quickBuyData?.items.map(checkOutData => {
                const data = checkOutData?.BOTagData;
                const algorithmData = checkOutData?.BOTagAlgorithmData;
                const coordinates = checkOutData?.UserGPSCoordinates;
                const address = `${checkOutData?.UserAddress?.street} ${checkOutData?.UserAddress?.streetNumber}, ${checkOutData?.UserAddress?.postCode} ${checkOutData?.UserAddress?.city}, ${checkOutData?.UserAddress?.country}`;
                const payload = {
                    GTINbarcode: data?.GTINbarcode, //done
                    storeID: algorithmData?.storeDetail?._id, //done
                    productname: data?.productName, //done
                    brandName: data?.labelBrandName, //done
                    itemPrice: algorithmData?.discountPrice ? algorithmData?.discountPrice : algorithmData?.price, //done
                    isDiscount: algorithmData?.discountPrice ? true : false, //done
                    originalItemPrice: algorithmData?.price, //done
                    deliveryCost: algorithmData?.deliveryCharges || 0,
                    deliveryOption: algorithmData?.type, //done
                    deliveryPreferences: data?.deliveryPreference, //done
                    quantity: data?.selectedQuantity,
                    BOimageID: selectedBOImageData?._id,
                    deliveryID: algorithmData?.cf?._id || undefined, //done
                    brandID: data?.labelBrandID, //done
                    userLocation: {
                        address: address, //done
                        country: checkOutData?.UserAddress?.country, //done
                        latitude: coordinates?.latitude, //done
                        longitude: coordinates?.longitude, //done
                        courierInstructions:
                            checkOutData?.BOTagData?.deliveryMethod === "courier"
                                ? courierInstructionsData[checkOutData?.itemID]
                                : "undefined" //TODO: not done
                    }
                };
                if (algorithmData?.type === "shipping") {
                    if (shippingAddressPopupData?.shippingAddressChangedInPopup) {
                        payload.userLocation = {
                            address: shippingAddressPopupData?.addressName, //done
                            country: shippingAddressPopupData?.country, //done
                            latitude: coordinates?.latitude, //done
                            longitude: coordinates?.longitude, //done
                            doorNumber: shippingAddressPopupData?.addressNumber, //done
                            postCode: shippingAddressPopupData?.postCode, //done
                            city: shippingAddressPopupData?.city, //done

                            recipientFirstName: shippingRecipient?.firstName, //done
                            recipientLastName: shippingRecipient?.lastName, //done
                            recipientCompanyName: shippingAddressPopupData?.company, //done
                            floor: shippingAddressPopupData?.floor //done
                        };
                    } else {
                        payload.userLocation = {
                            address: address,
                            country: checkOutData?.UserAddress?.country,
                            latitude: coordinates?.latitude,
                            longitude: coordinates?.longitude,
                            // doorNumber: shippingUserAddress?.addressNumber, //TODO: not done
                            postCode: checkOutData?.UserAddress?.postCode,
                            city: checkOutData?.UserAddress?.city,
                            recipientFirstName: data?.recipientJson?.firstName || userData?.firstName,
                            recipientLastName: data?.recipientJson?.lastName || userData?.lastName,
                            recipientCompanyName: data?.company,
                            floor: checkOutData?.UserAddress?.floor
                        };
                    }
                }
                arr.push(payload);
            });
            item = arr;
        }
        const newOrder = {
            firstName: paymentPopupData?.firstName || userData?.firstName,
            lastName: paymentPopupData?.lastName || userData?.lastName,
            email: paymentPopupData?.email || userData?.email,
            cardName: checkoutPaymentData?.cardHolderName || "",
            cardNumber: checkoutPaymentData?.cardNumber || "",
            cardExpireDate: checkoutPaymentData?.cardExpDate || "",
            cardCVV: checkoutPaymentData?.cardCVV || "",
            mobileNumber: paymentPopupData?.phoneNumber || userData?.phone,
            items: item,
            accountID: _accountID || undefined,
            stripeUpdateResponse: updateResponse || {},
            cardCredentials: cardCredentials || undefined,
            // shippingUserAddress: shippingUserAddress || undefined, //TODO: not done
            cardDetail: cardDetail
        };
        const response = await axios.post(`${process.env.REACT_APP_API_URL}${CREATE_ORDER}`, newOrder);

        if (response?.status === 200) {
            setThankYouAmount(Formatter.formatPrice(quickBuyData?.total));
            const orderID = response.data?.orderID;
            setOrderID(orderID);
            setPlaceOrderLoading(false);
            setQuickBuyPopup(false);
            setShowThankyouPopup(true);
            setSelectedBotag(null);
            setSelectedBotagStoreDetails(null);
            setShowRightCheckoutPanel(false);
            setShowBOImagePageID(false);
            setShowRightUserLocationPanel(false);
            navigate("/feed");
        }
    };
    const handleSubmit = async (PAYMENT_METHOD = null) => {
        setPlaceOrderLoading(true);
        const data = [];
        try {
            if (quickBuyData) {
                quickBuyData?.items.map(item => {
                    const payload = {
                        storeID: item?.BOTagAlgorithmData?.storeDetail?._id,
                        companyID: item?.BOTagAlgorithmData?.storeDetail?.companyId,
                        totalPrice: quickBuyData?.total, //item?.BOTagAlgorithmData?.price,
                        type: item?.BOTagAlgorithmData?.type,
                        name: item?.BOTagData?.productName,
                        quantity: item?.BOTagData?.selectedQuantity
                    };
                    data?.push(payload);
                });
            }

            const body = {
                data: data,
                email: paymentPopupData?.email || userData?.email,
                name: paymentPopupData?.firstName || userData?.firstName,
                customerId: checkoutPaymentData?.customerID || undefined
            };
            const response = await axios.post(`${process.env.REACT_APP_API_URL}${HANDLE_CARD_PAYMENT}`, body);

            if (response?.data?.success) {
                try {
                    const cardElement = elements.getElement(CardElement);
                    console.log(cardElement);
                    const result = await stripe.confirmCardPayment(response.data.paymentIntent.client_secret, {
                        payment_method:
                            PAYMENT_METHOD === "SAVED_PAYMENT"
                                ? cardElement
                                    ? {
                                          card: cardElement,
                                          billing_details: {
                                              name: paymentPopupData?.nameOnCard
                                          }
                                      }
                                    : checkoutPaymentData?.paymentMethodID
                                    ? checkoutPaymentData?.paymentMethodID
                                    : {
                                          card: cardElement,
                                          billing_details: {
                                              name: paymentPopupData?.nameOnCard
                                          }
                                      }
                                : {
                                      card: cardElement,
                                      billing_details: {
                                          name: paymentPopupData?.nameOnCard
                                      }
                                  }
                    });

                    if (result.error) {
                        console.log(result.error);
                    } else {
                        if (result?.paymentIntent?.status?.toLowerCase() === "succeeded") {
                            const payload = {
                                connectedAccounts: response?.data?.connectedAccounts,
                                uuid: response?.data?.uuid,
                                paymentIntentId: response.data.paymentIntent?.id,
                                paymentMethod: result?.paymentIntent?.payment_method,
                                bothebotAccountId: BOTagAccountID,
                                customerId: response.data?.customerID
                            };

                            const handleTransferResponse = await axios.post(
                                `${process.env.REACT_APP_API_URL}${HANDLE_PAYMENT_TRANSFERS}`,
                                payload
                            );
                            if (PAYMENT_METHOD !== "SAVED_PAYMENT" || cardElement) {
                                const cardInformation = handleTransferResponse?.data?.cardDetail;
                                cardInformation.paymentMethodID = result?.paymentIntent?.payment_method;
                                cardInformation.customerID = response.data?.customerID;
                                if (localStorage.getItem("isLoggedIn") === "true") {
                                    cardInformation.cardHolder = cardInformation.cardHolderName;
                                    cardInformation.cardExpiration = cardInformation.cardExpDate;
                                    /*delete cardInformation.cardHolderName;
                                    delete cardInformation.cardExpDate;*/
                                    setCheckoutPaymentData(cardInformation);
                                }
                            }
                            createNewOrder(
                                handleTransferResponse?.data?.updateResponse,
                                handleTransferResponse?.data?.cardCredentials,
                                handleTransferResponse?.data?.cardDetail
                            );
                        }
                    }
                } catch (error) {
                    console.log("result", error);
                    createNewOrder(error?.response?.data?.updateResponse);
                }
            }
        } catch (err) {
            console.log("payment failed");
            setPlaceOrderLoading(false);
        }
    };

    const placeOrderClicked = () => {
        // If all fields are filled, place order without showing payment popup
        if (validateAllFields()) {
            setPaymentPopupData({
                firstName: userData?.firstName,
                lastName: userData?.lastName,
                email: userData?.email,
                phoneNumber: userData?.phone,
                nameOnCard: checkoutPaymentData?.cardHolder
            });
            //setPlaceOrderLoading(true);
            handleSubmit("SAVED_PAYMENT");
        } else {
            //setPlaceOrderLoading(true);
            handleSubmit();
        }
    };
    const validateAllFields = () => {
        const { cardNumber, cardHolder, cardExpiration, cardCVV, cardName, customerID, paymentMethodID } = checkoutPaymentData;
        const { firstName, lastName, email, phone } = userData;

        if (
            !cardNumber ||
            !cardHolder ||
            !cardExpiration ||
            !cardCVV ||
            !cardName ||
            !customerID ||
            !paymentMethodID ||
            !firstName ||
            !lastName ||
            !email ||
            !phone
        ) {
            //TODO: REMOVE LATER... TEMPORARY FOR TESTING
            /*console.log("Missing fields", checkoutPaymentData, userData);*/
            return false;
        }
        return true;
    };
    const handleEditClick = id => {
        setShowCourierInstructionPopup(true);
    };
    const onEditRecipientClick = item => {
        setShippingAddressPopupData(item.itemID);
        setShowShippingAddressPopup(true);
    };
    /*endregion methods */
    return (
        <DefaultPopupCard visible={visible} mobileUsesFullScreen={true} zIndex={9}>
            {showCourierInstructionPopup && <CourierInstructionsPopup item={item} itemID={item?.itemID} />}
            <StyledPage className="frame-29643">
                <div className="frame-313569">
                    <div className="frame-313568">
                        <div className="frame-313474">
                            <div className="frame-295922840">
                                <img className="lightning-01" src={QuickBuyLogo} alt="QuickBuyLogo" />
                                <div className="quick-buy">Quick buy</div>
                            </div>
                            <div
                                className="btn-xs-secondary"
                                onClick={e => (placeOrderLoading ? e.preventDefault() : onCloseQuickBuy())}
                                style={{ opacity: placeOrderLoading ? "0.3" : "1", cursor: placeOrderLoading ? "no-drop" : "pointer" }}>
                                <div className="button">Cancel</div>
                            </div>
                        </div>
                        <div className="frame-295922845">
                            {deliveryMethod === "pickup" && <PickupCard storeInfo={BOTagData} placeOrderLoading={placeOrderLoading} />}
                            {deliveryMethod === "courier" && (
                                <CourierCard
                                    courierImage={BOTagAlgData?.cf?.logoURL}
                                    toAddress={UserAddress}
                                    instructions={BOTagData?.instructions}
                                    priceStr={FormattedData?.deliveryChargesStr}
                                    currency="NOK"
                                    courierInstructionsData={
                                        typeof courierInstructionsData !== "undefined" && courierInstructionsData?.[item?.itemID]
                                    }
                                    handleEditClick={handleEditClick}
                                    item={item}
                                    placeOrderLoading={placeOrderLoading}
                                />
                            )}
                            {deliveryMethod === "shipping" && (
                                <ShippingCard
                                    shippingImage={BOTagAlgData?.cf?.logoURL}
                                    toAddress={UserAddress}
                                    recipient={BOTagData?.recipient}
                                    company={BOTagData?.company}
                                    priceStr={FormattedData?.deliveryChargesStr}
                                    currency="NOK"
                                    onEditRecipientClick={() => onEditRecipientClick(item)}
                                    placeOrderLoading={placeOrderLoading}
                                />
                            )}
                            {checkoutPaymentData?.cardNumber && !showPaymentPopupQuick ? (
                                <UserInformationAndCard
                                    checkoutPaymentData={checkoutPaymentData}
                                    userData={userData}
                                    setShowPaymentPopupQuick={setShowPaymentPopupQuick}
                                    placeOrderLoading={placeOrderLoading}
                                />
                            ) : (
                                <UserNotLoggedIn
                                    data={quickBuyData}
                                    handleSubmit={placeOrderClicked}
                                    placeOrderLoading={placeOrderLoading}
                                />
                            )}
                        </div>
                    </div>
                    {checkoutPaymentData?.cardNumber && !showPaymentPopupQuick && (
                        <div className="frame-313658">
                            <div className="frame-295922839">
                                <div className="frame-295922838">
                                    <div className="separator">
                                        <div className="line-27"></div>
                                        <div className="text-label-here">Total to pay</div>
                                        <div className="line-26"></div>
                                    </div>
                                    <div className="frame-3133852">
                                        <div className="frame-29432">
                                            <div className="price">
                                                <span>
                                                    <span className="price-span">{Formatter.formatPrice(quickBuyData?.total)}</span>
                                                    <span className="price-span2">&nbsp;</span>
                                                    <span className="price-span3">{quickBuyData?.currency}</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="btn-md-solid"
                                    onClick={e => (placeOrderLoading ? e.preventDefault() : placeOrderClicked())}
                                    style={{ cursor: placeOrderLoading ? "no-drop" : "pointer" }}>
                                    <div className="frame-313497">
                                        <div className="frame-313349">
                                            <div className="button2">
                                                {placeOrderLoading ? (
                                                    <span className="spinner-text">
                                                        <img src={Spinner} alt="" className="spinner-img" />
                                                        &nbsp;
                                                        {"  "}Placing order...
                                                    </span>
                                                ) : (
                                                    "Place order"
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                {checkoutPaymentData?.cardNumber && !showPaymentPopupQuick && (
                    <div className="by-placing-order-you-agree-to-the-terms-privacy-policy-of-b-otag-norge-as">
                        <span>
                            <span className="by-placing-order-you-agree-to-the-terms-privacy-policy-of-b-otag-norge-as-span">
                                By placing order you agree to the
                            </span>{" "}
                            <span
                                className="terms-condition"
                                onClick={e => (placeOrderLoading ? e.preventDefault() : setShowPolicyPopup("terms"))}
                                style={{ cursor: placeOrderLoading ? "no-drop" : "pointer" }}>
                                Terms
                            </span>{" "}
                            <span className="by-placing-order-you-agree-to-the-terms-privacy-policy-of-b-otag-norge-as-span3">&amp;</span>{" "}
                            <span
                                className="privay-policy"
                                onClick={e => (placeOrderLoading ? e.preventDefault() : setShowPolicyPopup("privacy"))}
                                style={{ cursor: placeOrderLoading ? "no-drop" : "pointer" }}>
                                Privacy policy
                            </span>{" "}
                            <span className="by-placing-order-you-agree-to-the-terms-privacy-policy-of-b-otag-norge-as-span5">
                                of BOtag Norge AS
                            </span>
                        </span>
                    </div>
                )}
            </StyledPage>
        </DefaultPopupCard>
    );
}
