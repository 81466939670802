import React, { useEffect, useState } from "react";
import { DefaultPopupCard } from "./DefaultPopupCard";
import styled from "styled-components";
import { ReactComponent as Frame290570 } from "../../../assets/images/close-btn.svg";
import LayoutContext from "../../../contexts/LayoutContext";
import Spinner from "../../../assets/images/spinner.svg";
import * as Library from "../../../utils/Library";
import SuccessSugestBrand from "./SuccessSugestBrand";
/* #region CSS */
const StyledPage = styled.div`
    &.frame-29643,
    &.frame-29643 * {
        box-sizing: border-box;
    }
    &.frame-29643 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
        padding: 8px;
    }
    &.frame-mobile {
        height: 312px;
    }
    .frame-313583 {
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313367 {
        display: flex;
        flex-direction: column;
        gap: 40px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313591 {
        display: flex;
        flex-direction: row;
        gap: 40px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .forgot-password {
        color: var(--text-text-primary, #ffffff);
        text-align: left;
        font-family: "OpenSans-SemiBold", sans-serif;
        font-size: 22px;
        line-height: 28px;
        font-weight: 600;
        position: relative;
        flex: 1;
    }
    .frame-29057 {
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 30px;
        height: 30px;
        position: relative;
        overflow: visible;
    }
    .help-us-bring-the-brands-you-love-to-b-otag {
        color: #8c8c8c;
        text-align: left;
        font-family: var(--subtitle-2-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 14px);
        line-height: var(--subtitle-2-line-height, 24px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
        align-self: stretch;
    }
    .frame-313584 {
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-295922567 {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .floating-input {
        position: relative;
        width: 100%;
    }
    .input-field {
        background-color: initial;
        border: 1px solid #ffffff;
        border-radius: 5px;
        color: #fff;
        display: inline-block;
        height: 0px;
        outline: none;
        padding: 28px 16px 16px;
        width: 100%;
        opacity: 0.2;
    }
    .floating-input input:focus + label,
    .floating-input input:not(:placeholder-shown) + label {
        color: #8c8c8c;
        font-size: 8px;
        font-weight: 600;
        left: 18px;
        top: 5px;
    }
    .floating-input label {
        color: #8c8c8c;
        font-size: 14px;
        font-weight: 400;
        left: 22px;
        pointer-events: none;
        position: absolute;
        top: 13px;
        transition: all 0.2s ease;
    }
    .input-field.has-value {
        opacity: 1;
        border: 1px solid #f7665f;
    }
    .btn-md-solid.active {
        opacity: 1;
        cursor: pointer;
    }
    .label {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: left;
        font-family: var(--label-medium-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--label-medium-font-size, 14px);
        line-height: var(--label-medium-line-height, 14px);
        font-weight: var(--label-medium-font-weight, 400);
        position: relative;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .btn-md-solid {
        cursor: no-drop;
        background: var(--buttons-button-primary, #f7665f);
        border-radius: 5px;
        opacity: 0.2;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 160px;
        height: 44px;
        position: relative;
        box-shadow: var(--bt-drop-shadow-box-shadow, 0px 0px 4px 0px rgba(30, 38, 49, 0.35));
    }
    .frame-313497 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313349 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .button {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-family: var(--button-text-medium-regular-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--button-text-medium-regular-font-size, 14px);
        line-height: var(--button-text-medium-regular-line-height, 24px);
        font-weight: var(--button-text-medium-regular-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .spinner-text {
        display: flex;
        flex-direction: row;
    }
    .spinner-img {
        animation: spin 1s linear infinite;
    }

    @keyframes spin {
        to {
            transform: rotate(360deg);
        }
    }
`;
/* #endregion CSS */
export default function SuggestBrand({ visible }) {
    /* #region VARS */
    const { setShowSuggestPopup, isMobile } = React.useContext(LayoutContext);
    const [brandName, setBrandName] = useState("");
    const [isButtonDisable, setIsButtonDisable] = useState(true);
    const [isFormSubmit, setIsFormSubmit] = useState(false);
    const [suggestBrandSuccess, setSuggestBrandSuccess] = useState(false);
    /* #endregion VARS */
    /* #region METHODS */
    const handleSuggestBrand = e => {
        setBrandName(e.target.value);
    };
    const handleSubmitForm = () => {
        setIsButtonDisable(true);
        setIsFormSubmit(true);
        Library.makePostRequest("addUserSuggestedBrand", { suggestedBrandName: brandName }, false, setIsFormSubmit)
            .then(res => {
                setIsButtonDisable(false);
                setSuggestBrandSuccess(true);
            })
            .catch(e => {
                console.log(e);
            });
    };
    /* #endregion METHODS */
    /* #region EFFECTS */
    useEffect(() => {
        if (brandName) {
            setIsButtonDisable(false);
        } else {
            setIsButtonDisable(true);
        }
    }, [brandName]);
    /* #endregion EFFECTS */
    return (
        <DefaultPopupCard visible={visible} mobileUsesFullScreen={false}>
            {suggestBrandSuccess ? (
                <SuccessSugestBrand setShowSuggestPopup={setShowSuggestPopup} />
            ) : (
                <StyledPage className={`frame-29643 ${isMobile ? "frame-mobile" : ""}`}>
                    <div className="frame-313583">
                        <div className="frame-313367">
                            <div className="frame-313591">
                                <div className="forgot-password">Suggest a brand</div>
                                <Frame290570
                                    className="frame-29057 cursor-pointer"
                                    onClick={e => (isFormSubmit ? e.preventDefault() : setShowSuggestPopup(false))}
                                    style={{ opacity: isFormSubmit ? 0.2 : 1 }}
                                />
                            </div>
                        </div>
                        <div className="help-us-bring-the-brands-you-love-to-b-otag">Help us bring the brands you love to HeyBO!</div>
                    </div>
                    <div className="frame-313584">
                        <div className="frame-295922567">
                            <div className="floating-input">
                                <input
                                    type="text"
                                    className={`input-field ${isButtonDisable ? "" : "has-value"}`}
                                    placeholder=" "
                                    value={brandName}
                                    autoComplete="off"
                                    onChange={handleSuggestBrand}
                                    disabled={isFormSubmit}
                                />
                                <label>What are your favorite brands?</label>
                            </div>
                        </div>
                        <div
                            className={`btn-md-solid ${isButtonDisable && !isFormSubmit ? "" : "active"}`}
                            onClick={e => (isButtonDisable ? e.preventDefault() : handleSubmitForm())}>
                            <div className="frame-313497">
                                <div className="frame-313349">
                                    <div className="button">
                                        {isFormSubmit ? (
                                            <span className="spinner-text">
                                                <img src={Spinner} alt="" className="spinner-img" />
                                                &nbsp;
                                                {"  "}Submitting...
                                            </span>
                                        ) : (
                                            "Submit"
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </StyledPage>
            )}
        </DefaultPopupCard>
    );
}
