import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import HomeMapIcon from "../../../assets/images/home-map-icon.svg";
import SelectArrow from "../../../assets/images/select-arrow.svg";
import StoreIcon from "../../../assets/images/storeIcon.svg";
import SelectedPin from "../../../assets/images/select-pin.svg";
import { GoogleMap, Marker, useJsApiLoader, MarkerClusterer, InfoWindow } from "@react-google-maps/api";
import * as Library from "../../../utils/Library";
import LayoutContext from "../../../contexts/LayoutContext";
/* #region CSS */
const StyledPage = styled.div`
    &.frame-295922553,
    &.frame-295922553 * {
        box-sizing: border-box;
    }
    &.frame-295922553 {
        display: flex;
        flex-direction: column;
        gap: 32px;
        align-items: center;
        justify-content: flex-start;
        position: relative;
        padding: 0px 25px;
    }
    .frame-295922529 {
        padding: 0px 140px 0px 140px;
        display: flex;
        flex-direction: column;
        gap: 32px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-295922529.pad-text {
        padding: 0px;
    }
    .frame-313711 {
        display: flex;
        flex-direction: column;
        gap: 32px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313714 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        /* align-self: stretch;
        flex-shrink: 0; */
        position: relative;
        gap: 16px;
    }
    ._50-physical-stores {
        color: #ffffff;
        text-align: center;
        font-family: "ItcAvantGardeGothicProDemi-Regular", sans-serif;
        font-size: 64px;
        font-weight: 600;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .main-text-area {
        color: #ffffff;
        text-align: center;
        font-family: "OpenSans-SemiBold", sans-serif;
        font-size: 20px;
        line-height: 30px;
        font-weight: 400;
        position: relative;
        align-self: stretch;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .group-3707 {
        flex-shrink: 0;
        width: 100%;
        position: relative;
    }
    .rectangle-4 {
        border-radius: 30px;
        height: 800px;
        width: 100%;
    }
    .frame-295922533 {
        background: #141414;
        border-radius: 43px;
        padding: 16px 16px 16px 32px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        height: 48px;
        position: absolute;
        right: 20px;
        top: 20px;
        z-index: 9;
        cursor: pointer;
    }
    .filter-by-city-all {
        text-align: center;
        font-family: var(--button-text-large-semibold-font-family, "OpenSans-SemiBold", sans-serif);
        font-size: var(--button-text-large-semibold-font-size, 16px);
        line-height: var(--button-text-large-semibold-line-height, 24px);
        font-weight: var(--button-text-large-semibold-font-weight, 600);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .filter-by-city-all-span {
        color: #ffffff;
        font-family: var(--button-text-large-semibold-font-family, "OpenSans-SemiBold", sans-serif);
        font-size: var(--button-text-large-semibold-font-size, 16px);
        line-height: var(--button-text-large-semibold-line-height, 24px);
        font-weight: var(--button-text-large-semibold-font-weight, 600);
    }
    .filter-by-city-all-span2 {
        color: var(--text-text-warning, #ff9900);
        font-family: var(--button-text-large-semibold-font-family, "OpenSans-SemiBold", sans-serif);
        font-size: var(--button-text-large-semibold-font-size, 16px);
        line-height: var(--button-text-large-semibold-line-height, 24px);
        font-weight: var(--button-text-large-semibold-font-weight, 600);
    }
    .chevron-right {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        position: relative;
        overflow: visible;
    }
    .all-cities-list {
        position: absolute;
        z-index: 9;
        right: 20px;
        width: 300px;
        height: 248px;
        border-radius: 16px;
        background: #212121;
        top: 70px;
    }
    .all-cities-list-child {
        display: flex;
        flex-direction: column;
        gap: 5px;
        padding: 15px;
        overflow: scroll;
        height: 235px;
        overflow: scroll;
    }
    .gm-style-iw-chr {
        display: none;
    }
    .gm-style-iw-c {
        padding: 0px !important;
        height: 48px !important;
        margin-top: -60px !important;
        background-color: #212121 !important;
    }
    .gm-style-iw-tc {
        display: none !important;
    }
    .gm-style-iw-t {
        bottom: 12px !important;
    }
    .gm-style-iw-d {
        height: 100%;
        background-color: #212121;
        display: flex;
        text-align: center;
        justify-content: center;
        flex-direction: column;
        padding: 15px;
        overflow: hidden !important;
    }
    .store-name {
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: 16px;
    }
    @media only screen and (max-width: 992px) {
        ._50-physical-stores {
            font-size: 32px;
            line-height: unset;
        }
        .main-text-area {
            font-size: 13px;
            line-height: 25px;
        }
        .filter-by-city-all-span {
            font-size: 12px;
        }
        .filter-by-city-all-span2 {
            font-size: 12px;
        }
        .frame-295922533 {
            height: 40px;
        }
        .all-cities-list {
            top: 62px;
            width: 260px;
        }
        .store-name {
            font-size: 12px;
        }
        .store-name img {
            height: 24px;
            width: 24px;
        }
        .gm-style-iw-c {
            height: 40px !important;
        }
    }
`;
/* #endregion CSS */
const GoogleMapWrapper = ({ children, center, mapRef, onLoad, defaultZoom, isMobile }) => {
    return (
        <GoogleMap
            ref={mapRef}
            onLoad={onLoad}
            center={center}
            mapContainerStyle={{ width: "100%", height: isMobile ? 450 : 800, borderRadius: "24px" }}
            clickableIcons={false}
            options={{
                zoom: defaultZoom,
                draggable: true,
                fullscreenControl: false,
                mapTypeControl: false,
                streetViewControl: false,
                termsOfUseLink: false,
                keyboardShortcuts: false,
                copyrights: false,
                styles: [
                    { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
                    {
                        elementType: "labels.text.stroke",
                        stylers: [{ color: "#242f3e" }]
                    },
                    {
                        elementType: "labels.text.fill",
                        stylers: [{ color: "#909eb2" }]
                    },
                    {
                        featureType: "administrative",
                        elementType: "labels.text.fill",
                        stylers: [{ color: "#d3d9e1" }]
                    },
                    {
                        featureType: "landscape",
                        elementType: "labels",
                        stylers: [{ visibility: "off" }]
                    },
                    {
                        featureType: "administrative.locality",
                        elementType: "labels.text.fill",
                        stylers: [{ color: "#ffffff" }]
                    },
                    {
                        featureType: "poi",
                        stylers: [{ visibility: "off" }]
                    },
                    {
                        featureType: "poi.business",
                        stylers: [{ visibility: "off" }]
                    },
                    {
                        featureType: "poi.park",
                        elementType: "geometry",
                        stylers: [{ color: "#154043" }]
                    },
                    {
                        featureType: "poi.park",
                        elementType: "labels.text.fill",
                        stylers: [{ color: "#6b9a76" }]
                    },
                    {
                        featureType: "road",
                        elementType: "geometry",
                        stylers: [{ color: "#42566d" }]
                    },
                    {
                        featureType: "road",
                        elementType: "geometry.stroke",
                        stylers: [{ color: "#212a37" }]
                    },
                    {
                        featureType: "road",
                        elementType: "labels.text.fill",
                        stylers: [{ color: "#9ca5b3" }]
                    },
                    {
                        featureType: "road.highway",
                        elementType: "geometry",
                        stylers: [{ color: "#42566d" }]
                    },
                    {
                        featureType: "road.highway",
                        elementType: "geometry.stroke",
                        stylers: [{ color: "#000000" }]
                    },
                    {
                        featureType: "road.highway",
                        elementType: "labels.text.fill",
                        stylers: [{ color: "#ffffff" }]
                    },
                    {
                        featureType: "transit",
                        elementType: "geometry",
                        stylers: [{ color: "#2f3948" }, { visibility: "off" }]
                    },
                    {
                        featureType: "transit.station",
                        elementType: "labels.text.fill",
                        stylers: [{ color: "#d59563" }]
                    },
                    {
                        featureType: "water",
                        elementType: "geometry",
                        stylers: [{ color: "#000000" }]
                    },
                    {
                        featureType: "water",
                        elementType: "labels.text.fill",
                        stylers: [
                            {
                                color: "#8ab4f8"
                            }
                        ]
                    },
                    {
                        featureType: "water",
                        elementType: "labels.text.stroke",
                        stylers: [{ color: "#8ab4f8" }]
                    }
                ],
                gestureHandling: "cooperative"
            }}>
            {children}
        </GoogleMap>
    );
};

const MarkerClustererWrapper = ({ marker, activeStore }) => {
    const [selectedMarker, setSelectedMarker] = useState(null);
    const [infoWindowVisible, setInfoWindowVisible] = useState(false);
    const [infoWindowPosition, setInfoWindowPosition] = useState(null);
    useEffect(() => {
        setSelectedMarker(activeStore);
        const _d = marker.find(_d => _d._id === activeStore);
        setInfoWindowPosition({ lat: _d?.latitude, lng: _d?.longitude });
        setInfoWindowVisible(true);
    }, [activeStore]);
    return (
        <>
            <MarkerClusterer>
                {clusterer =>
                    marker.map((_d, i) => (
                        <Marker
                            key={i}
                            position={{ lat: _d?.latitude, lng: _d?.longitude }}
                            clusterer={clusterer}
                            icon={{
                                url: _d?._id === selectedMarker && infoWindowVisible ? SelectedPin : HomeMapIcon
                            }}
                            onClick={() => {
                                if (_d?._id === selectedMarker && infoWindowVisible) {
                                    setInfoWindowVisible(false);
                                } else {
                                    setSelectedMarker(_d?._id);
                                    setInfoWindowPosition({ lat: _d?.latitude, lng: _d?.longitude });
                                    setInfoWindowVisible(true);
                                }
                            }}
                        />
                    ))
                }
            </MarkerClusterer>

            {infoWindowVisible && selectedMarker && (
                <InfoWindow position={infoWindowPosition} disableAutoPan={true}>
                    <>
                        <div className="store-name">
                            <img src={StoreIcon} alt="" />
                            {marker.find(_d => _d._id === selectedMarker)?.name}
                        </div>
                    </>
                </InfoWindow>
            )}
        </>
    );
};

export default function PhysicalStores() {
    const { isMobile } = React.useContext(LayoutContext);
    const [marker, setMarker] = useState([]);
    const [showFilterPanel, setShowFilterPanel] = useState(false);
    const [filterCityName, setFilterCityName] = useState("All");
    const [defaultCenter, setDefaultCenter] = useState({ lat: 59.89382922, lng: 10.7027179 });
    const [mapRef, setMapRef] = useState(null);
    const [defaultZoom, setDefaultZoom] = useState(5);
    const [activeStore, setActiveStore] = useState(null);
    const [cityOption, setCityOption] = useState([]);
    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: process.env.REACT_APP_MAP_KEY
    });
    const onLoad = map => {
        setMapRef(map);
    };

    const handleResetFormSubmitFunction = () => {
        Library.makePostRequest("getHomeStores", {}, false)
            .then(res => {
                const { data } = res;
                const groupedData = Object.values(
                    data?.data.reduce((acc, current) => {
                        const key = `${current.city}, ${current.country}`;
                        if (!acc[key]) {
                            acc[key] = { optionName: key, data: [] };
                        }
                        acc[key].data.push(current);
                        return acc;
                    }, {})
                ).sort((a, b) => a.optionName.localeCompare(b.optionName));
                setCityOption(groupedData);
                setMarker(data?.data);
            })
            .catch(e => {
                console.log(e);
            });
    };
    const handleStoreFilter = data => {
        if (data === "All") {
            setFilterCityName("All");
            setDefaultZoom(4);
            setDefaultCenter({ lat: 59.89382922, lng: 10.7027179 });
        } else {
            setFilterCityName(`${data?.optionName}`);
            const sumLat = data?.data.reduce((acc, current) => acc + current.latitude, 0);
            const sumLng = data?.data.reduce((acc, current) => acc + current.longitude, 0);
            const centerLat = sumLat / data?.data.length;
            const centerLng = sumLng / data?.data.length;
            setDefaultCenter({
                lat: centerLat,
                lng: centerLng
            });
            setDefaultZoom(11);
        }

        /*if (id) {
            setDefaultZoom(16);
            const cityMarker = marker.find(v => v?._id === id);
            setDefaultCenter({
                lat: cityMarker.latitude,
                lng: cityMarker.longitude
            });
            setActiveStore(id);
        } else {
            setDefaultZoom(4);
            setDefaultCenter({ lat: 59.89382922, lng: 10.7027179 });
            setActiveStore(null);
        }
        if (countryName) {
            setFilterCityName(`${cityName}, ${countryName}`);
        } else {
            setFilterCityName(`${cityName}`);
        }*/

        setShowFilterPanel(false);
    };
    useEffect(() => {
        handleResetFormSubmitFunction();
    }, []);
    useEffect(() => {
        if (mapRef) {
            const animateCenter = () => {
                const newCenter = { ...defaultCenter };
                mapRef.panTo(newCenter, {
                    duration: 5000
                });
            };
            animateCenter();
        }
    }, [defaultCenter, mapRef]);
    return (
        <StyledPage className="frame-295922553">
            <div className={`frame-295922529  ${isMobile ? "pad-text" : ""}`}>
                <div className="frame-313711">
                    <div className="frame-313714">
                        <div className="_50-physical-stores">Many stores and more to come!</div>
                        <div className="main-text-area">
                            Buy products from your favorite brands, directly from physical stores enrolled in HeyBO.
                            <br />
                            Simply select a product on an image, share your location and we will show you the best store to buy from!
                        </div>
                    </div>
                </div>
            </div>
            <div className="group-3707">
                <div>
                    <div className="frame-295922533" onClick={() => setShowFilterPanel(!showFilterPanel)}>
                        <div className="filter-by-city-all">
                            <span>
                                <span className="filter-by-city-all-span">Filter by City:</span>
                                <span className="filter-by-city-all-span2"> {filterCityName}</span>
                            </span>
                        </div>
                        <img className="chevron-right" src={SelectArrow} alt="" />
                    </div>
                    <div className="all-cities-list" style={{ display: !showFilterPanel ? "none" : "" }}>
                        <div className="all-cities-list-child">
                            <div style={{ cursor: "pointer" }} onClick={() => handleStoreFilter("All")}>
                                All Cities
                            </div>
                            {cityOption.map((v, i) => {
                                return (
                                    <div style={{ cursor: "pointer" }} key={i} onClick={() => handleStoreFilter(v)}>
                                        {v?.optionName}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
                {isLoaded && (
                    <GoogleMapWrapper onLoad={onLoad} center={defaultCenter} defaultZoom={defaultZoom} isMobile={isMobile}>
                        {marker.length && <MarkerClustererWrapper marker={marker} activeStore={activeStore} />}
                    </GoogleMapWrapper>
                )}{" "}
            </div>
        </StyledPage>
    );
}
