import { useContext } from "react";
import styled from "styled-components";
import LayoutContext from "../../../contexts/LayoutContext";
import UserContext from "../../../contexts/UserContext";

const SinglePopOverStyles = styled.div`
    .frame-84,
    .frame-84 * {
        box-sizing: border-box;
    }
    .frame-84 {
        background: var(--surface-surface-tertiary, #212121);
        cursor: pointer;
        border-radius: 4px;
        padding: 10px;
        display: flex;
        flex-direction: row;
        gap: 8px;
        row-gap: 0px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313639 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        row-gap: 0px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .delivery {
        color: var(--text-text-primary, #ffffff);
        text-align: left;
        font-family: "OpenSans-Regular", sans-serif;
        font-size: 12px;
        font-weight: 400;
        position: relative;
    }
`;
const SinglePopOver = ({ data, setShowPopOver }) => {
    const { setShowFeedbackPopup } = useContext(LayoutContext);
    const { setProductInfo } = useContext(UserContext);
    return (
        <SinglePopOverStyles>
            <div
                className="frame-84"
                onClick={() => {
                    setProductInfo(data);
                    setShowFeedbackPopup(true);
                    setShowPopOver(false);
                }}>
                <div className="frame-313639">
                    <div className="delivery">Feedback</div>
                </div>
            </div>
        </SinglePopOverStyles>
    );
};
export default SinglePopOver;
