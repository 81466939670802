import React from "react";
import Skeleton from "@mui/material/Skeleton";

/**
 * Renders a simple box skeleton animation component.
 *
 * @param {Object} props - The component props.
 * @param {string} [props.borderRadius="5px"] - The border radius of the skeleton box.
 * @param {string} [props.width="100%"] - The width of the skeleton box.
 * @param {string} [props.height="100%"] - The height of the skeleton box.
 * @param {Object} [props.style] - Additional styles to be applied to the skeleton box.
 * @returns {JSX.Element} The rendered SimpleBoxSkeleton component.
 */
export default function SimpleBoxSkeleton({ borderRadius = "5px", width = "100%", height = "100%", style }) {
    return (
        <Skeleton sx={{ bgcolor: "grey.900", borderRadius: borderRadius, ...style }} variant="rectangular" height={height} width={width} />
    );
}
