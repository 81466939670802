import React from "react";
import Skeleton from "@mui/material/Skeleton";

const OrderDetailsPanelSkeleton = () => {
    return (
        <div style={{ border: "1px solid #212121", borderRadius: "5px 5px 0px 0px", width: "100%" }}>
            <div
                style={{
                    background: "#212121",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "32px",
                    borderRadius: "5px 5px 0px 0px"
                }}>
                <Skeleton
                    sx={{ bgcolor: "#282828", borderRadius: "10px", marginRight: "8px" }}
                    variant="rectangular"
                    height={"10px"}
                    width={"10px"}
                />
                <Skeleton sx={{ bgcolor: "#282828", borderRadius: "5px" }} variant="rectangular" height={"10.5px"} width={"77px"} />
            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    height: "66px",
                    marginBlock: "16px",
                    width: "92%",
                    marginInline: "auto"
                }}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Skeleton sx={{ bgcolor: "grey.900", borderRadius: "5px" }} variant="rectangular" height={"60px"} width={"44px"} />
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        marginInline: "16px"
                    }}>
                    <Skeleton sx={{ bgcolor: "grey.900", borderRadius: "15px" }} variant="rectangular" height={"10.45px"} width={"150px"} />
                    <Skeleton
                        sx={{
                            bgcolor: "grey.900",
                            borderRadius: "17.5px",
                            marginTop: "15.5px",
                            marginBottom: "7.8px"
                        }}
                        variant="rectangular"
                        height={"12.25px"}
                        width={"121px"}
                    />
                    <Skeleton sx={{ bgcolor: "grey.900", borderRadius: "15px" }} variant="rectangular" height={"10.5px"} width={"110px"} />
                </div>
                <div>
                    <Skeleton
                        sx={{
                            bgcolor: "grey.900",
                            borderRadius: "17.5px",
                            marginBottom: "10.5px"
                        }}
                        variant="rectangular"
                        height={"12.5px"}
                        width={"66px"}
                    />
                    <Skeleton sx={{ bgcolor: "grey.900", borderRadius: "17.5px" }} variant="rectangular" height={"0.92px"} width={"8px"} />
                </div>
            </div>
            <div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                    <Skeleton
                        sx={{
                            bgcolor: "#212121",
                            borderRadius: "15px",
                            marginRight: "6px"
                        }}
                        variant="rectangular"
                        height={"10.5px"}
                        width={"43px"}
                    />
                    <Skeleton
                        sx={{
                            bgcolor: "#212121"
                        }}
                        variant="rectangular"
                        height={"0.5px"}
                        width={"291px"}
                    />
                </div>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        height: "45px",
                        width: "92%",
                        marginInline: "auto",
                        marginTop: "17.5px",
                        marginBottom: "16px"
                    }}>
                    <div style={{ marginRight: "16px" }}>
                        <Skeleton
                            sx={{
                                bgcolor: "#212121",
                                borderRadius: "50%"
                            }}
                            variant="circle"
                            height={"45px"}
                            width={"45px"}
                        />
                    </div>
                    <div>
                        <Skeleton
                            sx={{
                                bgcolor: "#212121",
                                borderRadius: "15px",
                                marginBottom: "6px"
                            }}
                            variant="rectangular"
                            height={"10.5px"}
                            width={"126px"}
                        />
                        <Skeleton
                            sx={{
                                bgcolor: "#212121",
                                borderRadius: "15px"
                            }}
                            variant="rectangular"
                            height={"10.5px"}
                            width={"214px"}
                        />
                    </div>
                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginBottom: "17.5px"
                    }}>
                    <Skeleton
                        sx={{
                            bgcolor: "#212121",
                            borderRadius: "15px",
                            marginRight: "6px"
                        }}
                        variant="rectangular"
                        height={"10.5px"}
                        width={"43px"}
                    />
                    <Skeleton
                        sx={{
                            bgcolor: "#212121"
                        }}
                        variant="rectangular"
                        height={"0.5px"}
                        width={"291px"}
                    />
                </div>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        height: "45px",
                        width: "92%",
                        marginInline: "auto",
                        marginBottom: "16px"
                    }}>
                    <div style={{ marginRight: "16px" }}>
                        <Skeleton
                            sx={{
                                bgcolor: "#212121",
                                borderRadius: "50%"
                            }}
                            variant="circle"
                            height={"45px"}
                            width={"45px"}
                        />
                    </div>
                    <div>
                        <Skeleton
                            sx={{
                                bgcolor: "#212121",
                                borderRadius: "15px",
                                marginBottom: "6px"
                            }}
                            variant="rectangular"
                            height={"10.5px"}
                            width={"126px"}
                        />
                        <Skeleton
                            sx={{
                                bgcolor: "#212121",
                                borderRadius: "15px"
                            }}
                            variant="rectangular"
                            height={"10.5px"}
                            width={"214px"}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrderDetailsPanelSkeleton;
