import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import * as Library from "../../../utils/Library";
import more_soon from "../../../assets/images/more_soon.png";
import Solid from "../../../assets/images/Solid.png";
import LabelIcon from "../../../assets/images/label-icon.svg";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import LayoutContext from "../../../contexts/LayoutContext";
import BOImagePage from "../BOImage/BOImagePage";
import BOPicsPage from "../BOPics/Index";
import StandardsContext from "../../../contexts/StandardsContext";
import logGA4Event from "../../../utils/firebase/analytics";
import PageNotFound from "../PageNotFound";
import AppContext from "../../../contexts/AppContext";
import FeedSkeleton from "../../common/skeletons/FeedSkeleton";
import { getCookie, storeImageDetails } from "../../../utils/helperFunctions";
import botagCloseBtn from "../../../assets/images/botagCloseBtn.png";
const StyledPage = styled.div`
    &.FeedPageContainer {
        display: block;
        position: relative;
        width: 100%;
        flex-shrink: 1;
        padding: 14px 24px 0 24px;
        box-sizing: border-box;
    }
    &.mobile-skeleton {
        padding: 0px 16px 0 16px !important;
    }
    .allfeedLogos {
        display: inline-flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
        width: 100%;
        flex-shrink: 0;
        gap: 12px;
        padding-bottom: 14px;
        height: 54px;
    }

    .allfeedLogos::-webkit-scrollbar {
        height: 6px;
    }

    .allfeedLogos .feedIcon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 107px;
        height: 50px;
        border: 2px solid #383838;
        border-radius: 10px;
        font-size: 20px;
        font-weight: 200;
        cursor: pointer;
        padding: 0px 6px;
        flex-shrink: 0;
        position: relative;

        transition: border-color 0.5s;
    }

    .allfeedLogos .feedIcon img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        flex-shrink: 1;
    }

    .allfeedLogos .feedIcon.active {
        border-color: #f7665f;
    }

    .allfeedLogos .feedIcon:hover {
        border-color: #f7665f;
    }

    img.MuiImageListItem-img.sublogoMain {
        height: 40px;
        width: auto;
        position: absolute;
        bottom: 5px;
        right: 5px;
        padding: 5px 5px 1px 10px;
    }
    .product-count {
        height: 34px;
        position: absolute;
        bottom: 10px;
        left: 15px;
        width: 116px;
        background-color: rgba(0, 0, 0, 0.6);
        border-radius: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .image-text {
        display: flex;
        gap: 5px;
        justify-content: space-between;
        align-items: center;
    }
    .product-count-no {
        color: var(--text-text-primary, #ffffff);
        text-align: left;
        font-family: OpenSans-Regular, sans-serif;
        font-size: 14.4px;
        line-height: 24px;
        font-weight: 400;
    }
    .MasonryContainer {
        overflow: scroll;
        height: calc(100vh - 134px);
    }
    .FeedPageContainer.pad-container {
        padding: 0px !important;
    }
    .feed-image-wrapper {
        display: inline-block;
        position: relative;
        flex-direction: column;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        gap: 9px;
        display: flex;
        width: fit-content;
        height: 100%;
    }

    .feed-image {
        width: 100%;
        object-fit: contain;
        max-height: 100%;
        height: auto !important;
    }

    .feed-image-gradient {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 20%; /* adjust the height to your liking */
        background: linear-gradient(to right bottom, rgb(0 0 0 / 0%) 0%, rgb(0 0 0 / 0%) 50%, rgb(0 0 0 / 50%) 100%);
    }
    @keyframes pulse-white {
        0% {
            transform: scale(1.2);
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.3);
        }

        100% {
            transform: scale(0.9);
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
        }
    }
    .beacons {
        display: flex;
        flex-direction: column;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        gap: 10px;
        position: absolute;
        user-select: none;
        touch-action: none;
        cursor: pointer;
    }
    .beacon-main-div {
        position: relative;
        background-color: rgba(0, 0, 0, 0.3);
        height: 40px;
        width: 40px;
        border-radius: 50%;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        animation: 1.5s ease 0s infinite normal none running pulse-white;
    }
    .beacon-product-name {
        background-color: rgba(16, 20, 27, 0.3);
        border-radius: 20px;
        color: rgb(255, 255, 255);
        padding: 8px 15px;
        position: absolute;
        top: 45px;
        white-space: nowrap;
        max-width: 120px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

export default function FeedPage() {
    /* #region STATES */

    const boxRef = useRef(null);
    const targetImageRef = useRef([]);
    const { brandID: paramBrandOurID, BOImageID: paramBOImageID, GTINourID: paramGTINourID } = useParams();
    const {
        setShowRightSidebar,
        setShowRightUserLocationPanel,
        setShowFooter,
        setShowHeader,
        showBOImagePageID,
        setShowBOImagePageID,
        windowWidth,
        isMobile,
        setHomeLogo,
        setFeedLogo,
        showBOPicpageID,
        setShowBOPicpageID,
        setShowYourAccountPannel,
        setShowLoginForm,
        setShowUserLoginRegisterPopup,
        setShowRightMyOrdersDetailsPanel,
        footerLogoClicked,
        setFooterLogoClicked
    } = React.useContext(LayoutContext);
    const { setErrorDialogShow, setErrorDialogTitle, setErrorDialogMessage, setErrorDialogConfirmText, setErrorDialogCancelText } =
        React.useContext(StandardsContext);
    const {
        setSelectedBOImageData,
        setBOTagsList,
        selectedBrandID,
        setSelectedBrandID,
        boImageIsLoading,
        setSelectedBotag,
        setSelectedBotagStoreDetails,
        setSelectedHomePageBotagId
    } = React.useContext(AppContext);

    const PROFILE_VAR = "?profile";
    const [firstTimeLoad, setFirstTimeLoad] = useState(true);
    const [allFeedLoading, setAllFeedLoading] = useState(false);
    const [skeletonLoading, setSkeletonLoding] = useState(true);
    const [allBrandData, setAllBrandData] = useState([]);
    const [allFeedData, setAllFeedData] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(1);
    const [brokenURL, setBrokenURL] = useState(false);
    const storedData = JSON.parse(getCookie("recentlyViewedImages")) || [];
    const [isRecentBtnClicked, setIsRecentBtnClicked] = useState(false);
    const location = useLocation();
    const isProfilePanel = location?.search === PROFILE_VAR;
    const [beacon, setBeacon] = useState({ state: false, id: "" });
    const navigate = useNavigate();
    const query = new URLSearchParams(location.search);
    const showOrder = query.get("showOrder") === "true";
    const orderNumber = query.get("orderNumber");
    const orderID = query.get("orderID");
    const [onHover, setOnHover] = useState({
        state: false,
        id: ""
    });
    const [isShowCloseBtn, setIsShowCloseBtn] = useState({ state: false, id: "" });
    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        if (isProfilePanel) {
            setTimeout(() => {
                if (localStorage.getItem("isLoggedIn")) {
                    setShowYourAccountPannel(true);
                    navigate("/feed");
                } else {
                    setShowLoginForm(true);
                    setShowUserLoginRegisterPopup(true);
                }
            }, 0);
        }
    }, [isProfilePanel]);
    useEffect(() => {
        if (showOrder && orderNumber && orderID) {
            setTimeout(() => {
                setShowRightMyOrdersDetailsPanel({
                    orderID: orderID,
                    isOpen: true
                });
            }, 0);
        }
    }, []);
    useEffect(() => {
        setShowRightSidebar(true);
        setShowRightUserLocationPanel(false);

        setShowHeader(true);
        setHomeLogo(false);
        setFeedLogo(true);
    }, [FeedPage]);

    useEffect(() => {
        if (showBOImagePageID || showBOPicpageID) onPrepareForShowBOImagePage();
        else onPrepareForShowFeedPage();
    }, [showBOImagePageID, showBOPicpageID]);

    useEffect(() => {
        loadMoreIfAtBottom();

        const box = boxRef.current;
        if (box && !allFeedLoading) {
            box.addEventListener("scroll", loadMoreIfAtBottom);
        }

        return () => {
            box?.removeEventListener("scroll", loadMoreIfAtBottom);
        };
    }, [
        allBrandData,
        allFeedData,
        boxRef,
        allFeedLoading,
        currentPage,
        totalPages,
        firstTimeLoad,
        showBOImagePageID,
        boxRef?.current?.clientHeight
    ]);

    useEffect(() => {
        if (isRecentBtnClicked) {
            getFeedRequest(storedData, 1, true);
        } else {
            getFeedRequest(selectedBrandID, 1);
        }
    }, [selectedBrandID]);

    useEffect(() => {
        if (!allBrandData?.length || !paramBrandOurID) return;

        const filteredBrands = allBrandData.filter(item => item.ourID === paramBrandOurID);
        if (!filteredBrands.length) {
            setErrorDialogTitle("Error showing brand BOimages");
            setErrorDialogMessage(`Brand with ID ${paramBrandOurID} not found.`);
            setErrorDialogConfirmText("OK");
            setErrorDialogCancelText("Cancel");
            setErrorDialogShow(true);
            return;
        } else if (allBrandData?.length !== filteredBrands.length) setAllBrandData(filteredBrands);
    }, [paramBrandOurID, allBrandData]);

    useEffect(() => {
        if (paramBOImageID) setShowBOImagePageID(paramBOImageID);
    }, [paramBOImageID]);

    useEffect(() => {
        if (paramGTINourID) {
            setShowBOPicpageID(paramGTINourID);
        } else {
            setShowBOPicpageID("");
        }
    }, [paramGTINourID]);
    /* #endregion */

    /* #region METHODS */
    const loadMoreIfAtBottom = () => {
        if (showBOImagePageID || showBOPicpageID) {
            //console.log(`showBOImagePageID is not null, ignoring loadMoreIfAtBottom. showBOImagePageID: ${showBOImagePageID}`);
            return;
        }
        const box = boxRef.current;
        if (!box) return;

        const { scrollHeight, clientHeight, scrollTop } = box;
        const isAtBottom = Math.floor(scrollHeight - scrollTop) <= clientHeight;

        if (isAtBottom && !allFeedLoading) {
            if (currentPage < totalPages || firstTimeLoad) {
                setFirstTimeLoad(false);
                if (isRecentBtnClicked) {
                    getFeedRequest(storedData, currentPage + 1, true);
                } else {
                    getFeedRequest(selectedBrandID, currentPage + 1);
                }
                setCurrentPage(prev => prev + 1);
            }
        }
    };

    const getFeedRequest = async (selectedBrandID, page, isRecentImages) => {
        logGA4Event("Feed_Loading");
        await Library.makePostRequest(
            "getAllBOImages",
            {
                [isRecentImages ? "labelBrandIds" : "brandID"]: selectedBrandID,
                page: page || 1,
                limit: 5,
                type: (selectedBrandID || isRecentImages) && "2"
            },
            false,
            setAllFeedLoading
        ).then(res => {
            if (res.data.status === 200) {
                setSkeletonLoding(false);
                if (res.data.data.currentPage === 1) {
                    setAllFeedData(res?.data?.data?.getData);
                } else {
                    const newData = [...allFeedData, ...res?.data?.data?.getData];
                    setAllFeedData(newData);
                }

                if (paramBrandOurID) {
                    const id = res?.data?.data?.brandData?.find(item => item.ourID === paramBrandOurID)?._id;

                    if (id && id !== selectedBrandID) {
                        setSelectedBrandID(id);
                        getFeedRequest(id, 1);
                        return;
                    }
                }
                logGA4Event("Feed_Shown");
                setAllBrandData(res?.data?.data?.brandData);
                setCurrentPage(page);
                setTotalPages(res?.data?.data?.totalPages);
            }
        });
    };

    // When we are about to show the BOImage page, we can prepare states for it.
    const onPrepareForShowBOImagePage = () => {
        //console.log("onPrepareForShowBOImagePage");
        setShowFooter(true);
        setHomeLogo(false);
        setFeedLogo(false);

        //Cleaning up states to avoid bugs
        setSelectedBotag(null);
        setSelectedBotagStoreDetails(null);
    };

    // When we are about to enter feed or exit the BOImage page, we can clean up or prepare states.
    const onPrepareForShowFeedPage = () => {
        setShowFooter(false);
        setSelectedBOImageData(null);
        setBOTagsList([]);
        setSelectedBotag(null);
    };
    /* #endregion */
    return (
        <StyledPage
            className={`FeedPageContainer ${isMobile && boImageIsLoading ? "mobile-skeleton" : ""} ${
                (showBOImagePageID || showBOPicpageID) && !boImageIsLoading ? "pad-container" : ""
            }`}>
            {showBOImagePageID && !showBOPicpageID && !brokenURL && <BOImagePage setBrokenURL={setBrokenURL} />}
            {!showBOImagePageID && showBOPicpageID && !brokenURL && <BOPicsPage setBrokenURL={setBrokenURL} />}
            {brokenURL && <PageNotFound />}
            <div style={{ display: showBOImagePageID || showBOPicpageID ? "none" : "block" }}>
                <AllFeedBrands
                    allBrandData={allBrandData}
                    feedCurrentSelectedbrandID={selectedBrandID}
                    paramBrandOurID={paramBrandOurID}
                    setSelectedBrandID={setSelectedBrandID}
                    setSkeletonLoding={setSkeletonLoding}
                    storedData={storedData}
                    getFeedRequest={getFeedRequest}
                    setIsRecentBtnClicked={setIsRecentBtnClicked}
                    footerLogoClicked={footerLogoClicked}
                    setFooterLogoClicked={setFooterLogoClicked}
                />
                {skeletonLoading && <FeedSkeleton />}
                {!skeletonLoading && (
                    <MasonryImageList
                        allFeedData={allFeedData}
                        boxRef={boxRef}
                        width={windowWidth}
                        setShowBOImagePageID={setShowBOImagePageID}
                        setFeedLogo={setFeedLogo}
                        targetImageRef={targetImageRef}
                        setBeacon={setBeacon}
                        beacon={beacon}
                        setSelectedHomePageBotagId={setSelectedHomePageBotagId}
                        isMobile={isMobile}
                        onHover={onHover}
                        setOnHover={setOnHover}
                        isShowCloseBtn={isShowCloseBtn}
                        setIsShowCloseBtn={setIsShowCloseBtn}
                    />
                )}
            </div>
        </StyledPage>
    );
}

/* #region SMALL COMPONENTS */
const AllFeedBrands = ({
    allBrandData,
    feedCurrentSelectedbrandID,
    paramBrandOurID,
    setSelectedBrandID,
    setSkeletonLoding,
    storedData,
    getFeedRequest,
    setIsRecentBtnClicked,
    footerLogoClicked,
    setFooterLogoClicked
}) => {
    const [lastClickedButton, setLastClickedButton] = useState(feedCurrentSelectedbrandID ? "" : "all");
    useEffect(() => {
        if (feedCurrentSelectedbrandID && feedCurrentSelectedbrandID !== "-") {
            setLastClickedButton("");
        } else if (feedCurrentSelectedbrandID === "-") {
            setLastClickedButton("recentlyViewed");
        } else {
            setLastClickedButton("all");
        }
    }, [feedCurrentSelectedbrandID]);
    return (
        <div className="allfeedLogos">
            {allBrandData?.length > 1 && (
                <AllBtn
                    active={lastClickedButton === "all"}
                    setSelectedBrandID={id => {
                        setSelectedBrandID(id);
                        setLastClickedButton("all");
                    }}
                    setSkeletonLoding={setSkeletonLoding}
                    setIsRecentBtnClicked={setIsRecentBtnClicked}
                    isShow={lastClickedButton === "recentlyViewed" || lastClickedButton === "all"}
                />
            )}
            {footerLogoClicked &&
                allBrandData
                    ?.filter(item => feedCurrentSelectedbrandID === item?._id)
                    .map((item, index) => (
                        <div
                            key={index}
                            className={"feedIcon active"}
                            onClick={() => {
                                // no need for the if condition here, as we've already filtered the items
                                setSelectedBrandID(item?._id);
                                setSkeletonLoding(true);
                                setLastClickedButton(""); // Reset last clicked button when selecting a brand
                                setIsRecentBtnClicked(false);
                            }}>
                            <img src={item?.logo} alt={item?.ourID} />
                        </div>
                    ))}
            {storedData?.length ? (
                <RecentlyViewedBtn
                    active={lastClickedButton === "recentlyViewed"}
                    setSelectedBrandID={id => {
                        setSelectedBrandID(id);
                        setLastClickedButton("recentlyViewed");
                    }}
                    setSkeletonLoding={setSkeletonLoding}
                    getFeedRequest={getFeedRequest}
                    storedData={storedData}
                    setIsRecentBtnClicked={setIsRecentBtnClicked}
                    isShow={lastClickedButton === "recentlyViewed" || lastClickedButton === "all"}
                />
            ) : null}
            {allBrandData?.map((item, index) => (
                <div
                    key={index}
                    className={`feedIcon ${feedCurrentSelectedbrandID === item?._id ? "active" : ""}`}
                    onClick={() => {
                        // no need for the if condition here, as we've already filtered the items
                        setSelectedBrandID(item?._id);
                        setSkeletonLoding(true);
                        setLastClickedButton(""); // Reset last clicked button when selecting a brand
                        setIsRecentBtnClicked(false);
                        setFooterLogoClicked(false);
                    }}
                    style={
                        footerLogoClicked && feedCurrentSelectedbrandID === item?._id
                            ? {
                                  display: "none"
                              }
                            : {}
                    }>
                    <img src={item?.logo} alt={item?.ourID} />
                </div>
            ))}

            {!paramBrandOurID && <MoreSoonBtn isShow={lastClickedButton === "recentlyViewed" || lastClickedButton === "all"} />}
        </div>
    );
};

const AllBtn = ({ active, setSelectedBrandID, setSkeletonLoding, setIsRecentBtnClicked, isShow }) => {
    return (
        <div
            className={`feedIcon ${active ? "active" : ""}`}
            style={{ cursor: "pointer", width: "50px" }}
            onClick={() => {
                if (!active) {
                    setSelectedBrandID("");
                    setSkeletonLoding(true);
                    setIsRecentBtnClicked(false);
                }
            }}>
            <div
                style={{
                    fontSize: "14px",
                    fontWeight: "400",
                    color: "#FFFFFF",
                    display: "flex",
                    textAlign: "center"
                }}>
                All
            </div>
        </div>
    );
};

const MoreSoonBtn = ({ isShow }) => {
    return (
        <div className="feedIcon" style={{ cursor: "default", borderColor: "#383838" }}>
            <img src={more_soon} alt={""} style={{ width: "16px", height: "16px" }} />
            &nbsp;
            <div
                style={{
                    fontSize: "14px",
                    fontWeight: "400",
                    color: "#FFFFFF",
                    display: "flex",
                    textAlign: "center"
                }}>
                More soon
            </div>
        </div>
    );
};

const MasonryImageList = ({
    allFeedData,
    boxRef,
    width,
    setShowBOImagePageID,
    setFeedLogo,
    targetImageRef,
    setBeacon,
    beacon,
    setSelectedHomePageBotagId,
    isMobile,
    setOnHover,
    onHover,
    isShowCloseBtn,
    setIsShowCloseBtn
}) => {
    const calculateMasonryCols = () => {
        if (width < 768) return 1;
        else if (width < 1280) return 2;
        else if (width < 1920) return 3;
        else return Math.floor((width * 4) / 1920);
    };

    return (
        <Box className="MasonryContainer" ref={boxRef}>
            <ImageList variant="masonry" cols={calculateMasonryCols()} gap={14} style={{ marginTop: "0" }}>
                {allFeedData.map((item, index) => {
                    if (!targetImageRef.current[index]) {
                        targetImageRef.current[index] = React.createRef();
                    }
                    return (
                        <ImageListItem
                            key={item?.ourID}
                            style={{ cursor: "pointer" }}
                            onMouseEnter={() => {
                                !isMobile &&
                                    setBeacon({
                                        state: true,
                                        id: item?.ourID
                                    });
                            }}
                            onMouseLeave={() => {
                                !isMobile &&
                                    setBeacon({
                                        state: false,
                                        id: item?.ourID
                                    });
                            }}
                            onClick={() => {
                                const timeStamp = new Date().toISOString();
                                storeImageDetails(item?.ourID, timeStamp);
                                setFeedLogo(false);
                                setShowBOImagePageID(item?.ourID);
                                setIsShowCloseBtn({ state: false, id: "" });
                            }}>
                            <div className="feed-image-wrapper">
                                <img
                                    src={`${item?.image}`}
                                    srcSet={`${item?.image}`}
                                    alt={item?.labelBrandID}
                                    loading="lazy"
                                    style={{ minHeight: "200px", borderRadius: "30px" }}
                                    className="feed-image"
                                    ref={targetImageRef.current[index]}
                                />
                                {beacon?.state &&
                                    beacon?.id === item?.ourID &&
                                    item?.BOtags?.map(i => {
                                        return (
                                            <Beacon
                                                i={i}
                                                item={item}
                                                targetImageRef={targetImageRef.current[index]}
                                                setSelectedHomePageBotagId={setSelectedHomePageBotagId}
                                                setIsShowCloseBtn={setIsShowCloseBtn}
                                            />
                                        );
                                    })}
                                <div className="feed-image-gradient" />
                            </div>
                            {isMobile && isShowCloseBtn?.state && isShowCloseBtn?.id === item?.ourID && (
                                <div
                                    style={{
                                        position: "absolute",
                                        zIndex: 5,
                                        top: "25px",
                                        left: "25px"
                                    }}
                                    onClick={e => {
                                        e.stopPropagation();
                                        setBeacon({
                                            state: false,
                                            id: ""
                                        });
                                        setOnHover({
                                            state: false,
                                            id: ""
                                        });

                                        setIsShowCloseBtn(false);
                                    }}>
                                    <img src={botagCloseBtn} alt="" height={"50px"} width={"50px"} />
                                </div>
                            )}
                            {!isShowCloseBtn?.state && (
                                <div
                                    className="product-count"
                                    onClick={e => {
                                        e.stopPropagation();
                                        if (isMobile) {
                                            setBeacon({
                                                state: true,
                                                id: item?.ourID
                                            });

                                            setOnHover({
                                                state: true,
                                                id: item?.ourID
                                            });
                                            setIsShowCloseBtn({ state: true, id: item?.ourID });
                                        } else {
                                            setShowBOImagePageID(item?.ourID);
                                        }
                                    }}>
                                    <div className="image-text">
                                        <img src={LabelIcon} alt="" />
                                        <span className="product-count-no">{`${item?.BOtags?.length ?? 0} products`}</span>
                                    </div>
                                </div>
                            )}
                            <img className="sublogoMain" src={item?.brandLogo} alt="" />
                        </ImageListItem>
                    );
                })}
            </ImageList>
        </Box>
    );
};

const RecentlyViewedBtn = ({
    active,
    setSkeletonLoding,
    getFeedRequest,
    storedData,
    setSelectedBrandID,
    setIsRecentBtnClicked,
    isShow
}) => {
    return (
        <div
            className={`feedIcon ${active ? "active" : ""}`}
            style={{ cursor: "pointer", width: "120px", padding: "0px 10px" }}
            onClick={() => {
                if (!active) {
                    setSkeletonLoding(true);
                    setSelectedBrandID("-");
                    setIsRecentBtnClicked(true);
                }
            }}>
            <span>
                <img src={Solid} alt="" style={{ width: "16px" }} />
            </span>
            &nbsp;
            <div
                style={{
                    fontSize: "14px",
                    fontWeight: "400",
                    color: "#FFFFFF",
                    display: "flex",
                    textAlign: "center"
                }}>
                Recently Viewed
            </div>
        </div>
    );
};

/* #endregion */
/* #region SMALL COMPONENTS */

const Beacon = ({ i, item, targetImageRef, setSelectedHomePageBotagId, setIsShowCloseBtn }) => {
    const navigate = useNavigate();
    const handleBOImagePin = botag => {
        setSelectedHomePageBotagId(botag?.GTINbarcode);
        navigate(`/${item?.ourID}`);
        setIsShowCloseBtn({ state: false, id: "" });
    };
    return (
        <div
            className="beacons"
            style={{
                left: i?.beaconXposition * (targetImageRef?.current?.clientWidth / targetImageRef?.current?.naturalWidth) - 20 + "px",
                top: i?.beaconYposition * (targetImageRef?.current?.clientHeight / targetImageRef?.current?.naturalHeight) - 15 + "px"
            }}
            onClick={() => {
                handleBOImagePin(i);
                //navigate(`/${item?.ourID}`);
            }}>
            <div className="beacon-main-div">
                <div
                    style={{
                        backgroundColor: "#FFFFFF",
                        height: "20px",
                        width: "20px",
                        borderRadius: "50%"
                    }}></div>
            </div>
            {i?.productType && <div className="beacon-product-name">{i?.productType}</div>}
        </div>
    );
};
/* #endregion SMALL COMPONENTS */
