import React, { useContext, useState } from "react";
import styled from "styled-components";
import Chevron from "../../../assets/images/chevron-up0.svg";
import AppContext from "../../../contexts/AppContext";
import LayoutContext from "../../../contexts/LayoutContext";
import * as Library from "../../../utils/Library";
import SimpleBoxSkeleton from "../skeletons/SimpleBoxSkeleton";
import logGA4Event from "../../../utils/firebase/analytics";
import { useNavigate } from "react-router-dom";
import Skeleton from "../skeletons/Skeleton";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
const StyledPage = styled.div`
    &.featured-products-section {
        height: 100vh;
        overflow: scroll;
    }
    &.mobile-scroll {
        overflow: hidden;
        height: auto;
    }
    .frame-29603,
    .frame-29603 * {
        box-sizing: border-box;
    }
    .frame-29603 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        flex: 1;
        position: relative;
        overflow-y: auto;
        width: 100%;
    }
    .item-list-side-bar {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        flex-shrink: 0;
        position: relative;
    }
    .items-in-this-b-oimage {
        color: #ffffff;
        text-align: left;
        font-size: var(--heading-3-font-size, 16px);
        line-height: var(--heading-3-line-height, 24px);
        font-weight: var(--heading-3-font-weight, 600);
        position: relative;
        flex: 1;
    }
    .separator-middle {
        margin-top: -0.5px;
        border-style: solid;
        border-color: #383838;
        border-width: 0.5px 0 0 0;
        flex-shrink: 0;
        width: 100%;
        height: 0px;
        position: relative;
        transform-origin: 0 0;
        transform: rotate(0deg) scale(1, 1);
    }
    .frame-29596 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        flex-shrink: 0;
        position: relative;
        width: 100%;
    }
    .frame-29595 {
        display: flex;
        flex-direction: row;
        /* gap: 40px; */
        align-items: center;
        justify-content: flex-start;

        width: 100%;
        position: relative;
    }
    .frame-29594 {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .luan-j-shirt-cosmic-4-1 {
        border-radius: 5px;
        flex-shrink: 0;
        width: 54px;
        height: 74px;
        position: relative;
        object-fit: cover;
    }
    .frame-29004 {
        display: flex;
        flex-direction: column;
        gap: 6px;
        align-items: flex-start;
        justify-content: flex-start;
        flex-shrink: 0;
        position: relative;
    }
    .brand-product-type {
        color: #8c8c8c;
        text-align: left;
        font-size: var(--heading-4-font-size, 14px);
        line-height: var(--heading-4-line-height, 20px);
        font-weight: var(--heading-4-font-weight, 600);
        position: relative;
        width: 168.18px;
        height: 16px;
    }
    .product-name {
        color: #ffffff;
        text-align: left;
        font-size: var(--heading-3-font-size, 16px);
        line-height: var(--heading-3-line-height, 24px);
        font-weight: var(--heading-3-font-weight, 600);
        position: relative;
        width: 168.18px;
        height: 23px;
        display: block;
        align-items: center;
        justify-content: flex-start;
    }
    .btn-xs-primary {
        background: var(--buttons-button-primary, #f7665f);
        border-radius: 5px;
        padding: 10px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        height: 30px;
        position: relative;
    }
    .button {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-family: var(--button-text-small-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--button-text-small-font-size, 12px);
        line-height: var(--button-text-small-line-height, 16px);
        font-weight: var(--button-text-small-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .frame-29600 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-29601 {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-293362 {
        flex: 1;
        height: 22px;
        position: relative;
    }
    .more-from-brand {
        color: #ffffff;
        text-align: left;
        font-size: var(--heading-3-font-size, 16px);
        line-height: var(--heading-3-line-height, 24px);
        font-weight: var(--heading-3-font-weight, 600);
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
    }
    .chevron-up {
        flex-shrink: 0;
        width: 20px;
        height: 20px;
        position: relative;
        overflow: visible;
    }
    .mini-feed {
        display: flex;
        flex-direction: row;
        gap: 14px;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
        flex-shrink: 0;
        width: 100%;
        position: relative;
    }
    .frame-313373 {
        flex-shrink: 0;
        width: 175px;
        height: 268.57px;
        position: relative;
        object-fit: cover;
        cursor: pointer;
    }
    .frame-313374 {
        flex-shrink: 0;
        width: 179px;
        height: 268.57px;
        position: relative;
    }
    .frame-313375 {
        flex-shrink: 0;
        width: 179px;
        height: 268.57px;
        position: relative;
    }
    .frame-313376 {
        flex-shrink: 0;
        width: 179px;
        height: 268.57px;
        position: relative;
    }
    .frame-313377 {
        flex-shrink: 0;
        width: 179px;
        height: 268.57px;
        position: relative;
    }
    .frame-313378 {
        flex-shrink: 0;
        width: 179px;
        height: 268.57px;
        position: relative;
    }
    .frame-313379 {
        flex-shrink: 0;
        width: 179px;
        height: 268.57px;
        position: relative;
    }
    .frame-313380 {
        flex-shrink: 0;
        width: 179px;
        height: 268.57px;
        position: relative;
    }
    .main-skeleton {
        display: flex;
        flex-direction: row;
        gap: 15px;
        width: 100%;
        align-items: center;
    }
    .main-skeleton-child {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }
    .skeleton-btn {
        position: absolute;
        right: 0px;
    }
`;

export default function FeaturedProductsSection() {
    /* #region VARS */
    const { BOTagsList, setSelectedBotag, selectedBOImageData, setBOTagsList, boImageIsLoading } = useContext(AppContext);
    const { isMobile } = React.useContext(LayoutContext);
    /* #endregion */

    return (
        <StyledPage className={`featured-products-section w-100 ${isMobile ? "mobile-scroll" : ""}`}>
            <ItemsInBOImageSection BOTagsList={BOTagsList} setSelectedBotag={setSelectedBotag} boImageIsLoading={boImageIsLoading} />

            <MoreFromBrandSection selectedBOImageData={selectedBOImageData} setBOTagsList={setBOTagsList} isMobile={isMobile} />
        </StyledPage>
    );
}

/* #region SMALL COMPONENTS */

const ItemsInBOImageSection = ({ BOTagsList, setSelectedBotag, boImageIsLoading }) => {
    return (
        <div className="frame-29603">
            <div className="item-list-side-bar w-100">
                <div className="items-in-this-b-oimage">Items in this BOimage</div>
                <div className="separator-middle"></div>
                {boImageIsLoading ? (
                    <>
                        <div className="main-skeleton">
                            <Skeleton width={54} height={74} type={"product"} />
                            <div className="main-skeleton-child">
                                <Skeleton width={100} height={12.25} type={"product"} />
                                <Skeleton width={100} height={12.25} type={"product"} />
                            </div>
                            <div className="skeleton-btn">
                                <Skeleton width={89} height={30} type={"product"} />
                            </div>
                        </div>
                        <div className="main-skeleton">
                            <Skeleton width={54} height={74} type={"product"} />
                            <div className="main-skeleton-child">
                                <Skeleton width={100} height={12.25} type={"product"} />
                                <Skeleton width={100} height={12.25} type={"product"} />
                            </div>
                            <div className="skeleton-btn">
                                <Skeleton width={89} height={30} type={"product"} />
                            </div>
                        </div>
                    </>
                ) : (
                    BOTagsList.map((botag, index) => (
                        <div className="frame-29596" key={index}>
                            <div className="frame-29595">
                                <div className="frame-29594">
                                    <img className="luan-j-shirt-cosmic-4-1" src={botag?.image} alt="" />
                                    <div className="frame-29004">
                                        <div className="brand-product-type text-one-line">{`${botag?.labelBrandName} / ${botag?.productType}`}</div>
                                        <div className="product-name text-one-line">{botag?.productName}</div>
                                    </div>
                                </div>
                                <div className="btn-xs-primary">
                                    <div className="button cursor-pointer" onClick={() => setSelectedBotag(botag)}>
                                        Open BOtag
                                    </div>
                                </div>
                            </div>
                            <div className="separator-middle"></div>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
};

const MoreFromBrandSection = ({ selectedBOImageData, setBOTagsList, isMobile }) => {
    console.log(selectedBOImageData);
    /* #region VARS */
    const hasBrandName = selectedBOImageData?.brandInfo;
    const labelBrandName = selectedBOImageData?.brandInfo?.name ?? "--";
    const labelBrandID = selectedBOImageData?.labelBrandID ?? selectedBOImageData?.brandInfo?._id;

    const [showMoreFromBrand, setShowMoreFromBrand] = useState(false);
    const [moreItemsData, setMoreItemsData] = useState([]);
    const [isFetchingItems, setIsFetchingItems] = useState(false);
    const navigate = useNavigate();
    /* #endregion */

    /* #region METHODS */
    const fetchMoreItemsClick = () => {
        if (showMoreFromBrand) {
            setShowMoreFromBrand(false);
            return;
        }

        Library.makePostRequest("getRelatedBOimages", { labelBrandID }, false, setIsFetchingItems).then(res => {
            if (res.data.status === 200) {
                setMoreItemsData(res.data.data);
                setShowMoreFromBrand(true);
            }
        });
    };
    const redirectFunction = feedID => {
        navigate(feedID);
        setBOTagsList([]);
        if (isMobile) {
            const element = document.getElementById("mainContent");
            element.scrollIntoView({ behavior: "smooth", block: "center" });
        }
    };
    /* #endregion */

    return (
        hasBrandName && (
            <div className="item-list-side-bar">
                <div className="frame-29600 cursor-pointer" style={{ marginTop: "16px" }} onClick={fetchMoreItemsClick}>
                    <div className="frame-29601">
                        <div className="frame-293362" onClick={() => logGA4Event("More_From_Brand")}>
                            <div className="more-from-brand">More from {labelBrandName}</div>
                        </div>

                        <img
                            className="chevron-up"
                            src={Chevron}
                            alt=""
                            style={{ transform: !showMoreFromBrand ? "rotate(180deg)" : "rotate(0deg)" }}
                        />
                    </div>
                </div>
                {isFetchingItems && (
                    <div className="mini-feed">
                        {Array.from({ length: 6 }).map((_, index) => (
                            <SimpleBoxSkeleton key={index} width="175px" height="268.57px" borderRadius="0px" />
                        ))}
                    </div>
                )}
                {showMoreFromBrand && !isFetchingItems && (
                    <div className="mini-feed">
                        <ImageList variant="masonry" cols={2} gap={14} style={{ marginTop: "0" }}>
                            {moreItemsData.map((item, index) => (
                                <ImageListItem
                                    onClick={() => redirectFunction(`/${item?.ourID}`)}
                                    key={item?.ourID}
                                    style={{ cursor: "pointer" }}>
                                    <img key={index} className="frame-313373" src={item?.image} alt={item?.ourID} />
                                </ImageListItem>
                            ))}
                        </ImageList>
                    </div>
                )}
            </div>
        )
    );
};
/* #endregion */
