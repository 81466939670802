import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import logGA4Event from "../../../utils/firebase/analytics";
import { useContext } from "react";
import LayoutContext from "../../../contexts/LayoutContext";
import Skelton from "../../common/skeletons/Skeleton";

/* #region STYLED CSS */
const StyledPage = styled.div`
    &.ImageBeaconsContainer {
        position: relative;
        /* flex: 1 1 0; */
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 9px;
        display: flex;
        width: fit-content;
        height: 100%;

        /* min-height: 400px !important; */
    }
    &.image-beacons-width {
        width: 100%;
    }
    .BeaconsContainerElement {
        width: 100%;
        height: auto !important;
        object-fit: contain;
        max-height: 100%;
    }

    .BeaconsContainerElement.loading {
        width: 50px;
        height: 50px;
    }
    .overlay-over-image {
        opacity: 0.7;
    }
`;

const StyledBeacon = styled.div`
    @keyframes pulse-white {
        0% {
            transform: scale(1.2);
            box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
        }

        100% {
            transform: scale(0.9);
            box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
        }
    }

    &.BeaconContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        position: absolute;
        user-select: none;
        touch-action: none;
        cursor: pointer;
    }

    .BotagBeacon {
        position: relative;
        background-color: #0000004d;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        animation: pulse-white 1.5s infinite;
    }

    .BotagBeacon .BotagBeaconInner {
        background-color: #ffffff;
        height: 20px;
        width: 20px;
        border-radius: 50%;
    }

    .product-label {
        background-color: rgba(16, 20, 27, 0.3);
        border-radius: 20px;
        color: #fff;
        padding: 8px 15px;
        position: absolute;
        top: 45px;
        white-space: nowrap;
        max-width: 120px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;
/* #endregion */

/**
 * Renders an image with beacons on it.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.className - The class name for the component.
 * @param {Object} props.style - The inline styles for the component.
 * @param {string} props.src - The source URL of the image.
 * @param {Array} props.BOTagsList - The list of tags to be displayed on the image.
 * @param {Function} props.setBOTagsList - The function to set the list of tags.
 * @param {boolean} props.isLoading - A flag to indicate if the image is loading.
 * @param {boolean} props.onBeaconClick - The function to be called when a beacon is clicked.
 * @returns {JSX.Element} The rendered ImageBeacons component.
 */
export default function ImageBeacons(props) {
    const { showBeacon } = useContext(LayoutContext);

    /* #region USE STATES */
    const BOTagsList = props.BOTagsList;
    const imageRef = useRef(null);

    const [imageScale, setImageScale] = useState(1);
    const [imageOffsetTop, setImageOffsetTop] = useState(0);
    /* #endregion */

    /* #region USE EFFECTS */
    useEffect(() => {
        window.addEventListener("resize", reloadImageScale);

        return () => {
            window.removeEventListener("resize", reloadImageScale);
        };
    }, []);

    useEffect(() => {
        const reloadWithDelay = () => {
            setTimeout(() => {
                reloadImageScale();
            }, 100);
        };
        if (imageRef.current) imageRef.current.addEventListener("load", reloadWithDelay);

        return () => {
            if (imageRef.current) imageRef.current.removeEventListener("load", reloadWithDelay);
        };
    }, [imageRef.current?.src, props?.isLoading]);

    useEffect(() => {
        if (!BOTagsList || BOTagsList.length === 0 || props?.isLoading) return;
        reloadBOTagsScaling();
    }, [imageScale, props?.isLoading, imageOffsetTop, imageRef.current?.src]);
    /* #endregion */

    /* #region METHODS */
    const reloadImageScale = () => {
        if (!imageRef.current || !imageRef.current?.getBoundingClientRect()) return;
        const { width } = imageRef.current?.getBoundingClientRect();
        const naturalWidth = imageRef.current.naturalWidth;
        const scale = width / naturalWidth;
        setImageScale(scale);
        setImageOffsetTop(imageRef.current?.offsetTop ?? 0);
    };

    const reloadBOTagsScaling = () => {
        const newBOTagsList = BOTagsList.map(tag => ({
            ...tag,
            x: tag.naturalX * imageScale,
            y: tag.naturalY * imageScale + imageOffsetTop
        }));
        props?.setBOTagsList(newBOTagsList);
    };
    /* #endregion */

    return (
        <StyledPage
            className={` ImageBeaconsContainer ${props?.className ?? ""} ${props?.isLoading ? "image-beacons-width" : ""}`}
            style={props?.style}>
            {props?.isLoading && <Skelton width={"100%"} height={"100vh"} type={"boImage"} />}

            <img
                className={`BeaconsContainerElement ${props?.isLoading ? "hidden" : ""} ${
                    props?.selectedBotag ? "overlay-over-image" : ""
                }`}
                ref={imageRef}
                src={props?.src}
                alt="BOImage Canvas"
                onClick={reloadBOTagsScaling}
                style={{ display: props?.src ? "block" : "none" }}
            />

            {showBeacon &&
                !props?.selectedBotag &&
                !props?.isLoading &&
                BOTagsList.map((tag, index) => (
                    <Beacon
                        key={index}
                        x={tag.x}
                        y={tag.y}
                        onClick={() => {
                            if (props?.onBeaconClick) props?.onBeaconClick(tag);
                            logGA4Event("Location_Shared_Asked");
                        }}
                        label={tag.productType}
                    />
                ))}
        </StyledPage>
    );
}

/* #region SMALL COMPONENTS */
/**
 * Represents a tag element on the image.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {number} props.x - The x-coordinate of the tag element.
 * @param {number} props.y - The y-coordinate of the tag element.
 * @param {number} props.number - The number associated with the tag element.
 * @returns {JSX.Element} The rendered tag element.
 */
const Beacon = ({ x, y, label = "--", onClick }) => {
    const dimensionsNotSet = !x || !y;
    x = x ?? 0;
    y = y ?? 0;

    return (
        <StyledBeacon
            className="BeaconContainer"
            onClick={() => (onClick ? onClick() : null)}
            style={{ top: y - 17, left: x - 17, display: dimensionsNotSet ? "none" : "flex" }}>
            <div className="BotagBeacon">
                <div className="BotagBeaconInner"></div>
            </div>
            <div className="product-label">{label}</div>
        </StyledBeacon>
    );
};

export const tagObject = ({ x, y, number, naturalX, naturalY, selected = false, completed = false, data = {} }) => {
    return { x, y, number, naturalX, naturalY, selected, completed, data };
};
/* #endregion */
