import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import checkCircleBroken from "../../../assets/images/check-circle-broken0.svg";
import frame29057 from "../../../assets/images/frame-290570.svg";
import StandardsContext from "../../../contexts/StandardsContext";
import iconWarning from "../../../assets/images/menu-warning.png";
import iconError from "../../../assets/images/icons-error-48.png";

const StyledPage = styled.div`
    &.show-popup {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 77px;
        backdrop-filter: blur(6px);
        border-radius: 5px;
        background: var(--surface-surface-tertiary, #212121);
        max-width: 458px;
        width: 100%;
        border-radius: 10px;
        left: calc(50% - 229px);
        z-index: 99999;
        box-shadow: 4px 4px 87.4px 0px #000;
        transition: opacity 0.4s linear;
    }
    .notification-card-container {
        //min-height: 330px;
        width: 100%;
        flex-direction: column;
        align-items: center;
        padding: 25.78px 70.65px;
        display: flex;
        position: relative;
    }
    .notification-message {
        color: #f0f0f0;
        text-align: left;
        font-size: 14px;
        line-height: 24px;
        font-weight: 300;
        //position: absolute;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .check-circle-broken {
        width: 20px;
        height: 20px;
        position: absolute;
        left: 27.9px;
        top: 27.5px;
        overflow: visible;
    }
    .frame-29057 {
        display: flex;
        width: 30px;
        height: 30px;
        position: absolute;
        right: 22.65px;
        overflow: visible;
        top: calc(50% - 15px);
    }

    @media (max-width: 488px) {
        &.show-popup {
            width: 100%;
            max-width: 100%;
            left: 16px;
            right: 16px;
            width: calc(100% - 32px);
        }
    }
`;

export const DefaultNotificationCard = () => {
    /* #region VARS */
    const { defaultNotificationCard, setDefaultNotificationCard } = useContext(StandardsContext);
    const { show = true, message = "--", type, autoClose = 5000 } = defaultNotificationCard;

    const [isDisplayNone, setIsDisplayNone] = React.useState(true);
    const [hasOpacity, setHasOpacity] = React.useState(false);
    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        if (!show) return;
        if (autoClose) {
            setTimeout(() => {
                setDefaultNotificationCard({ ...defaultNotificationCard, show: false });
            }, autoClose);
        }
    }, [show, autoClose]);

    useEffect(() => {
        if (show) setIsDisplayNone(false);
        else {
            setHasOpacity(false);
            setTimeout(() => {
                setIsDisplayNone(true);
            }, 300);
        }
    }, [show]);

    useEffect(() => {
        if (!isDisplayNone) {
            setTimeout(() => {
                setHasOpacity(true);
            }, 10);
        }
    }, [isDisplayNone]);
    /* #endregion */

    /* #region METHODS */
    const onCloseClicked = () => {
        setDefaultNotificationCard({ ...defaultNotificationCard, show: false });
    };
    /* #endregion */

    return (
        <StyledPage className="show-popup" style={{ opacity: hasOpacity ? 1 : 0, display: isDisplayNone ? "none" : "flex" }}>
            <div className="notification-card-container">
                <img
                    className="check-circle-broken"
                    src={type === "error" ? iconError : type === "warning" ? iconWarning : checkCircleBroken}
                    alt=""
                />
                <div className="notification-message">{message}</div>
                <img className="frame-29057 cursor-pointer" src={frame29057} alt="" onClick={onCloseClicked} />
            </div>
        </StyledPage>
    );
};
