import React, { useEffect } from "react";
import styled from "styled-components";
import boIkon10 from "../../../assets/images/bo-ikon-10.svg";
import HomeIcon from "../../../assets/images/home-icon.svg";
import HomeIcon2 from "../../../assets/images/home2.png";
import FeedIcon from "../../../assets/images/feed-icon.svg";
import FeedIcon2 from "../../../assets/images/compas2.png";
import ActiveCart from "../../../assets/images/active-cart.svg";
//import InActiveCart from "../../../assets/images/inactive-cart.svg";
import InActiveCart from "../../../assets/images/new-cart.svg";
import MenuLogin from "../../../assets/images/frame-291590.svg";
import { useNavigate } from "react-router-dom";
import LayoutContext from "../../../contexts/LayoutContext";
import UserContext, { userLogout } from "../../../contexts/UserContext";
import MenuPanel from "./MenuPanel";
import * as Library from "../../../utils/Library";
import logGA4Event from "../../../utils/firebase/analytics";
import MenuProfile from "../../../assets/images/menu-profile.svg";
import StandardsContext from "../../../contexts/StandardsContext";
import AppContext from "../../../contexts/AppContext";

/* #region CSS */
const StyledPage = styled.div`
    &.header,
    &.header * {
        box-sizing: border-box;
    }
    &.header {
        background: var(--surface-surface-secondary, #121212);
        border-style: solid;
        border-color: var(--border-border-primary-10, rgba(166, 166, 166, 0.1));
        border-width: 0px 0px 1px 0px;
        padding: 24px;
        display: flex;
        flex-direction: row;
        gap: 32px;
        row-gap: 0px;
        align-items: center;
        justify-content: flex-start;
        height: 53px;
        position: relative;
        width: 100%;
    }
    &.header-mobile {
        position: fixed;
        top: 0px;
        z-index: 9999999;
    }
    .frame-295922451 {
        display: flex;
        flex-direction: column;
        gap: 10px;
        row-gap: 0px;
        align-items: flex-start;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .frame-8 {
        display: flex;
        flex-direction: row;
        gap: 30px;
        row-gap: 0px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .bo-ikon-1 {
        flex-shrink: 0;
        width: 33.78px;
        height: 30px;
        position: relative;
        overflow: visible;
        cursor: pointer;
    }
    .frame-29219 {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        flex: 1;
        position: relative;
        gap: 15px;
    }
    .frame-29521 {
        border-radius: 10px;
        padding: 10px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        row-gap: 0px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 40px;
        height: 40px;
        position: relative;
        cursor: pointer;
    }
    .home-05 {
        flex-shrink: 0;
        width: 24px;
        height: 24px;
        position: relative;
        overflow: visible;
    }
    .frame-29519 {
        border-radius: 10px;
        padding: 10px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        row-gap: 0px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 40px;
        height: 40px;
        position: relative;
        cursor: pointer;
    }
    .compass-03 {
        flex-shrink: 0;
        width: 24px;
        height: 24px;
        position: relative;
        overflow: visible;
    }
    .frame-29517 {
        border-radius: 10px;
        padding: 10px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        row-gap: 0px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 40px;
        height: 40px;
        position: relative;
        cursor: pointer;
    }
    .shopping-bag-03 {
        flex-shrink: 0;
        width: 24px;
        height: 24px;
        position: relative;
        overflow: hidden;
    }
    .cart-counter {
        align-items: center;
        background: #f7665f;
        border-radius: 50%;
        color: #fff;
        display: flex;
        font-size: 9px;
        height: 16px;
        justify-content: center;
        left: 25px;
        position: absolute;
        top: -2px;
        width: 16px;
    }
    .check {
        border-radius: 31px;
        width: 14px;
        height: 58.33%;
        position: absolute;
        left: calc(50% - 1px);
        bottom: 0%;
        top: 41.67%;
        overflow: visible;
    }
    .frame-29159 {
        border-radius: 10px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        row-gap: 0px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 40px;
        height: 40px;
        position: relative;
        overflow: visible;
        cursor: pointer;
    }
    .frame-29159.loggedin {
        width: 24px;
        height: 24px;
        object-fit: contain;
    }

    .frame-29517p {
        background: linear-gradient(270deg, rgba(247, 102, 95, 1) 0%, rgba(18, 18, 18, 1) 60%);
        border-radius: 39px;
        padding: 0px 8px 0px 8px;
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        height: 27px;
        position: relative;
        cursor: pointer;
    }
    .frame-29517p-null {
        background: linear-gradient(270deg, rgba(35, 40, 50, 1) 0%, rgba(18, 18, 18, 1) 100%);
        border-radius: 39px;
        padding: 0px 8px 0px 8px;
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        height: 27px;
        position: relative;
        cursor: pointer;
    }
    .shopping-bag-03p {
        flex-shrink: 0;
        width: 26px;
        height: 26px;
        position: relative;
        overflow: visible;
    }
    ._1 {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-family: var(--subtitle-1-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--subtitle-1-font-size, 16px);
        line-height: var(--subtitle-1-line-height, 24px);
        font-weight: var(--subtitle-1-font-weight, 400);
        position: relative;
        width: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .fireworks {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 100%;
        left: 0;
        pointer-events: none;
        overflow: hidden;
        z-index: 1;
    }

    .firework {
        position: absolute;
        border-radius: 50%;
        transform: scale(0);
        animation: explode 1s forwards;
    }

    @keyframes explode {
        0% {
            transform: scale(0);
            opacity: 1;
        }
        100% {
            transform: scale(1);
            opacity: 0;
        }
    }
`;

/* #endregion CSS */
export default function Header(props) {
    /* #region VARS */
    const navigate = useNavigate();
    const location = window.location.pathname;
    const {
        showRightCheckoutPanel,
        setShowBOImagePageID,
        setShowRightCheckoutPanel,
        setShowRightUserLocationPanel,
        setShowRightSettingsPanel,
        setShowLoginForm,
        setShowRegistration,
        showMenuPanel,
        setShowMenuPanel,
        setShowUserLoginRegisterPopup,
        isMobile,
        setShowRightMyOrdersPanel,
        setShowRightMyOrdersDetailsPanel,
        setShowYourAccountPannel,
        homeLoge,
        feedLogo,
        setHomeLogo,
        setFeedLogo,
        setShowPersonalInformation,
        setShowCardInformation,
        fireworks,
        showFireWorks,
        cartCount,
        setCartCount,
        setStripeCardData
    } = React.useContext(LayoutContext);
    const {
        isUserLoggedIn,
        setIsUserLoggedIn,
        setUserData,
        setUserAddress,
        setUserGPSCoordinates,
        checkoutCartData,
        setCheckoutCartData,
        setCheckoutPaymentData,
        setBOTagAccountID,
        setShippingRecipient
    } = React.useContext(UserContext);
    const { setDefaultNotificationCard } = React.useContext(StandardsContext);
    const { setSelectedBrandID } = React.useContext(AppContext);
    /* #endregion */

    /* #region METHODS */
    const gotoHomeFun = () => {
        navigate("/");
        setShowBOImagePageID("");
        setShowRightUserLocationPanel(false);
        setShowRightCheckoutPanel(false);
        setShowRightMyOrdersPanel(false);
        setShowRightMyOrdersDetailsPanel({
            orderID: null,
            isOpen: false
        });
        setShowRightSettingsPanel(false);
        setShowYourAccountPannel(false);
    };
    const gotoFeedFun = () => {
        setHomeLogo(false);
        setFeedLogo(true);
        navigate("/feed");
        setShowBOImagePageID("");
        setShowRightUserLocationPanel(false);
        setShowRightCheckoutPanel(false);
        setShowRightMyOrdersPanel(false);
        setShowRightMyOrdersDetailsPanel({
            orderID: null,
            isOpen: false
        });
        setShowRightSettingsPanel(false);
        setShowYourAccountPannel(false);
        setSelectedBrandID("");
    };
    const CheckoutClicked = () => {
        logGA4Event("Checkout_Loading");
        setShowRightCheckoutPanel(true);
        setShowRightSettingsPanel(false);
        logGA4Event("Checkout_Shown");
    };
    const profileBtnClicked = () => {
        logGA4Event("MainMenu");
        setShowMenuPanel(true);
    };

    const onLoginClicked = () => {
        logGA4Event("Login");
        setShowUserLoginRegisterPopup(true);
        setShowLoginForm(true);
        setShowRegistration(false);
        setShowPersonalInformation(false);
        setShowCardInformation(false);
    };

    const onLogout = () => {
        userLogout(
            setIsUserLoggedIn,
            setUserData,
            setUserAddress,
            setUserGPSCoordinates,
            setCheckoutCartData,
            setCheckoutPaymentData,
            setBOTagAccountID,
            setShippingRecipient
        );
        setStripeCardData({
            isCardNumber: false,
            isCardExpData: false,
            isCardCvv: false,
            isComplete: false
        });
        if (location === "/feed" || location === "/") {
            setShowRightUserLocationPanel(false);
        } else {
            setShowRightUserLocationPanel(true);
        }
        setShowRightCheckoutPanel(false);
        setShowRightMyOrdersPanel(false);
        setShowRightMyOrdersDetailsPanel({
            orderID: null,
            isOpen: false
        });
        setShowRightSettingsPanel(false);
        setShowYourAccountPannel(false);
        //Library.showSuccessMessage("You have been logged out.");

        Library.showNotification({
            message: "You have been logged out.",
            type: "success",
            setDefaultNotificationCard
        });
    };

    /* #endregion */
    /* region EFFECT*/
    React.useEffect(() => {
        if (showFireWorks) {
            const fireworksContainer = document.getElementById("fireworks");
            fireworks.forEach(firework => {
                const fireworkElement = document.createElement("div");
                fireworkElement.classList.add("firework");
                fireworkElement.style.left = firework.left;
                fireworkElement.style.top = firework.top;
                fireworkElement.style.background = `radial-gradient(circle, rgba(255,255,255,0) 50%, ${firework.color} 100%)`;
                fireworkElement.style.width = `${firework.size}px`;
                fireworkElement.style.height = `${firework.size}px`;
                fireworksContainer.appendChild(fireworkElement);

                fireworkElement.addEventListener("animationend", () => {
                    fireworkElement.remove();
                });
            });
        }
    }, [fireworks, showFireWorks]);
    useEffect(() => {
        if (checkoutCartData?.items && checkoutCartData?.items.length) {
            const sumOfSelectedQuantity = checkoutCartData?.items?.reduce((acc, current) => {
                return acc + current.BOTagData.selectedQuantity;
            }, 0);
            setCartCount(sumOfSelectedQuantity);
        } else {
            setCartCount(0);
        }
    }, [checkoutCartData]);
    /* endregion */
    return (
        <StyledPage className={`header ${isMobile ? "header-mobile" : ""}`}>
            {showMenuPanel && <MenuPanel onLogout={onLogout} />}
            <div className="frame-295922451">
                <div className="frame-8">
                    <img className="bo-ikon-1" src={boIkon10} alt="logo" onClick={gotoHomeFun} />
                </div>
            </div>
            <div className="frame-29219">
                {!isMobile && (
                    <div className="frame-29521">
                        <img className="home-05" src={homeLoge ? HomeIcon : HomeIcon2} alt="home icon" onClick={gotoHomeFun} />
                    </div>
                )}

                <div className="frame-29519">
                    <img className="compass-03" src={feedLogo ? FeedIcon2 : FeedIcon} alt="feed" onClick={gotoFeedFun} />
                </div>
                {/*<div className="frame-29517">
                    {checkoutCartData.items.length > 0 && <div className="cart-counter">{checkoutCartData.items.length}</div>}
                    <div className="shopping-bag-03" onClick={CheckoutClicked}>
                        {showRightCheckoutPanel ? (
                            <img className="active" src={ActiveCart} alt="" />
                        ) : (
                            <>
                                <img className="icons" src={InActiveCart} alt="" />
                            </>
                        )}
                    </div>
                </div>*/}
                <div className={cartCount ? "frame-29517p" : "frame-29517p-null"} onClick={CheckoutClicked}>
                    <img className="shopping-bag-03p" src={InActiveCart} alt="" />
                    <div className="_1">{cartCount}</div>
                    <div className="fireworks" id="fireworks"></div>
                </div>
                <div className="frame-29519">
                    {isUserLoggedIn ? (
                        <img className="frame-29159  loggedin" src={MenuProfile} alt="Profile" onClick={profileBtnClicked} />
                    ) : (
                        <img className="frame-29159" src={MenuLogin} alt="Login" onClick={onLoginClicked} />
                    )}
                </div>
            </div>
        </StyledPage>
    );
}
