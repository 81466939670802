import styled from "styled-components";
import { DefaultPopupCard } from "./DefaultPopupCard";
import { ReactComponent as Frame290570 } from "../../../assets/images/close-btn.svg";
import Courier from "../../../assets/images/courier.png";
import Wolt from "../../../assets/images/vector0.svg";
import * as Library from "../../../utils/Library";
import React, { useEffect, useState } from "react";
import LayoutContext from "../../../contexts/LayoutContext";
import FloatingInput from "../forms/FloatingInput";
import ButtonLoader from "../loaders/ButtonLoader";
import { getLatLngFromAddress } from "../../../utils/LocationLib";
/* #region STYLED CSS */
const CourierInstructionsPopupStyle = styled.div`
    .courier-pop-up,
    .courier-pop-up * {
        box-sizing: border-box;
    }
    .courier-pop-up {
        background: var(--surface-surface-secondary, #121212);
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        position: relative;
        box-shadow: var(--dropshadow-pop-up2-box-shadow, 4px 4px 87.4px 0px rgba(0, 0, 0, 1));
    }
    .frame-29643 {
        padding: 24px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313546 {
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313545 {
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313474 {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .help-courier-find-you {
        color: var(--text-text-primary, #ffffff);
        text-align: left;
        font-family: "OpenSans-SemiBold", sans-serif;
        font-size: 22px;
        line-height: 28px;
        font-weight: 600;
        position: relative;
        flex: 1;
    }
    .frame-29057 {
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 30px;
        height: 30px;
        position: relative;
        overflow: visible;
    }
    .frame-313544 {
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313543 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .info-action-card {
        border-radius: 5px;
        border-style: solid;
        border-color: var(--border-border-primary, rgba(166, 166, 166, 0.2));
        border-width: 1px;
        padding: 10px 16px 10px 16px;
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-29614 {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        flex-shrink: 0;
        width: 332px;
        position: relative;
    }
    .frame-3627 {
        background: var(--buttons-button-tertiary, #232832);
        border-radius: 57px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 45px;
        height: 45px;
        position: relative;
    }
    .group-3495 {
        flex-shrink: 0;
        width: 19.93px;
        height: 15.97px;
        position: relative;
        overflow: visible;
    }
    .frame-313574 {
        display: flex;
        flex-direction: column;
        gap: 6px;
        align-items: flex-start;
        justify-content: center;
        flex: 1;
        position: relative;
    }
    .frame-313451 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-29717 {
        /* display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        position: relative; */
    }
    .frame-313359 {
        display: flex;
        flex-direction: row;
        gap: 3px;
        align-items: center;
        justify-content: flex-start;
        flex-shrink: 0;
        position: relative;
    }
    .product-name {
        color: var(--text-text-primary, #ffffff);
        text-align: left;
        font-family: "OpenSans-Regular", sans-serif;
        font-size: 12px;
        line-height: 14px;
        font-weight: 400;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .product-name-2 {
        line-height: 12px;
        font-weight: 400;
        color: rgb(140, 140, 140);
    }
    .product-name-1 {
        font-size: 12px;
    }
    .product-name-by {
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        gap: 3px;
        font-size: 12px;
    }
    .vector {
        flex-shrink: 0;
        position: absolute;
        left: 24px;
        overflow: visible;
        object-fit: cover;
        width: 30px !important;
        height: 10px !important;
        position: absolute !important;
    }
    .frame-313453 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-3133592 {
        display: flex;
        flex-direction: row;
        gap: 3px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .frame-3135452 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .address {
        color: #ffffff;
        text-align: left;
        font-family: var(--heading-4-font-family, "OpenSans-SemiBold", sans-serif);
        font-size: var(--heading-4-font-size, 14px);
        line-height: var(--heading-4-line-height, 20px);
        font-weight: var(--heading-4-font-weight, 600);
        position: relative;
        align-self: stretch;
    }
    .frame-29737 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-29734 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .input-field {
        background: var(--surface-surface-secondary, #121212);
        border-radius: 5px;
        border-style: solid;
        border-color: var(--border-border-secondary, #8c8f93);
        border-width: 1px;
        padding: 16px;
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        height: 46px;
        position: relative;
        box-shadow: var(--dropdown-input-field-box-shadow, 0px 0px 15px 0px rgba(0, 0, 0, 0.25));
    }
    .frame-313475 {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: flex-start;
        justify-content: center;
        flex: 1;
        height: 14px;
        position: relative;
    }
    .label {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: left;
        font-family: var(--label-xsmall-font-family, "OpenSans-SemiBold", sans-serif);
        font-size: var(--label-xsmall-font-size, 8px);
        line-height: var(--label-xsmall-line-height, 8px);
        font-weight: var(--label-xsmall-font-weight, 600);
        position: relative;
        align-self: stretch;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .label2 {
        color: var(--text-text-primary, #ffffff);
        text-align: left;
        font-family: var(--label-medium-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--label-medium-font-size, 14px);
        line-height: var(--label-medium-line-height, 14px);
        font-weight: var(--label-medium-font-weight, 400);
        position: relative;
        align-self: stretch;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .frame-29733 {
        display: flex;
        flex-direction: row;
        gap: 12px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .input-field2 {
        background: var(--surface-surface-secondary, #121212);
        border-radius: 5px;
        border-style: solid;
        border-color: var(--border-border-secondary, #8c8f93);
        border-width: 1px;
        padding: 16px;
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex: 1;
        position: relative;
        box-shadow: var(--dropdown-input-field-box-shadow, 0px 0px 15px 0px rgba(0, 0, 0, 0.25));
    }
    .input-field3 {
        background: var(--surface-surface-secondary, #121212);
        border-radius: 5px;
        border-style: solid;
        border-color: var(--border-border-primary, rgba(166, 166, 166, 0.2));
        border-width: 1px;
        padding: 16px;
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        position: relative;
        box-shadow: var(--dropdown-input-field-box-shadow, 0px 0px 15px 0px rgba(0, 0, 0, 0.25));
    }
    .label3 {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: left;
        font-family: var(--label-medium-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--label-medium-font-size, 14px);
        line-height: var(--label-medium-line-height, 14px);
        font-weight: var(--label-medium-font-weight, 400);
        position: relative;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .frame-313589 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .please-provide-additional-delivery-info {
        color: var(--text-text-primary, #ffffff);
        text-align: left;
        font-family: var(--heading-4-font-family, "OpenSans-SemiBold", sans-serif);
        font-size: var(--heading-4-font-size, 14px);
        line-height: var(--heading-4-line-height, 20px);
        font-weight: var(--heading-4-font-weight, 600);
        position: relative;
        align-self: stretch;
    }
    .frame-29440 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-29735 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .input-field4 {
        background: var(--surface-surface-secondary, #121212);
        border-radius: 5px;
        border-style: solid;
        border-color: var(--border-border-primary, rgba(166, 166, 166, 0.2));
        border-width: 1px;
        padding: 16px;
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        height: 46px;
        position: relative;
        box-shadow: var(--dropdown-input-field-box-shadow, 0px 0px 15px 0px rgba(0, 0, 0, 0.25));
    }
    .btn-md-solid {
        background: var(--buttons-button-primary, #f7665f);
        border-radius: 5px;
        // padding: 44px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        height: 44px;
        position: relative;
        box-shadow: var(--bt-drop-shadow-box-shadow, 0px 0px 4px 0px rgba(30, 38, 49, 0.35));
    }
    .frame-313497 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313349 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .button-updated {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-family: var(--button-text-medium-regular-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--button-text-medium-regular-font-size, 14px);
        line-height: var(--button-text-medium-regular-line-height, 24px);
        font-weight: var(--button-text-medium-regular-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .floating-input {
        position: relative;
        width: 100%;
    }
    .customer-info-inputs {
        background-color: initial;
        border: 1px solid #373737;
        border-radius: 5px;
        color: #fff;
        display: inline-block;
        height: 0px;
        outline: none;
        padding: 28px 0px 16px 16px;
        width: 100%;
        box-sizing: border-box;
    }
    .floating-input input:focus + label,
    .floating-input input:not(:placeholder-shown) + label {
        color: #8c8c8c;
        font-size: 8px;
        font-weight: 600;
        left: 18px;
        top: 5px;
    }
    .floating-input label {
        color: #8c8c8c;
        font-size: 13px;
        font-weight: 400;
        left: 22px;
        pointer-events: none;
        position: absolute;
        top: 13px;
        transition: all 0.2s ease;
    }
`;
/* #endregion */
const CourierInstructionsPopup = ({ item, itemID }) => {
    /* #region VARS */
    //Contexts
    const {
        setShowCourierInstructionPopup,
        setCourierInstructionsData,
        courierInstructionsData,
        setNotDeliverCourier,
        setShowRightCheckoutPanel
    } = React.useContext(LayoutContext);

    const { city, streetNumber, postCode, street, country } = item?.UserAddress;
    const address = `${street} ${postCode} ${city}, ${country}`;
    const [loading, setLoading] = useState(false);
    const [isAddressTyping, setIsAddressTyping] = useState(false);
    const [isDoorTyping, setIsDoorTyping] = useState(false);
    const [isFloorTyping, setIsFloorTyping] = useState(false);
    const [isPostalTyping, setIsPostalTyping] = useState(false);
    const [isCityTyping, setIsCityTyping] = useState(false);
    const [isInstructionTyping, setIsInstructionTyping] = useState(false);
    const [courierInstructionData, setCourierInstructionData] = useState({
        street: street,
        address: address,
        city: city,
        streetNumber: streetNumber,
        postalCode: postCode,
        optional: "",
        instruction: typeof courierInstructionsData[itemID] !== "undefined" ? courierInstructionsData[itemID] : ""
    });
    const updatedAddress = `${courierInstructionData?.street} ${courierInstructionData?.postalCode} ${courierInstructionData?.city}, ${country}`;
    const IsValid =
        courierInstructionData?.street?.length > 0 &&
        courierInstructionData?.city?.length > 0 &&
        courierInstructionData?.streetNumber?.length > 0 &&
        courierInstructionData?.postalCode?.length > 0
            ? true
            : false;
    /* #endregion */
    /* #region Methods */
    const handleCloseButtonClick = () => {
        setShowCourierInstructionPopup(false);
    };
    const handleAddress = e => {
        setIsAddressTyping(true);
        setCourierInstructionData({ ...courierInstructionData, street: e.target.value });
    };

    const handleStreetNumber = e => {
        setIsDoorTyping(true);
        setCourierInstructionData({ ...courierInstructionData, streetNumber: e.target.value });
    };

    const handleOptional = e => {
        setIsFloorTyping(true);
        setCourierInstructionData({ ...courierInstructionData, optional: e.target.value });
    };

    const handlePostalCode = e => {
        setIsPostalTyping(true);
        setCourierInstructionData({ ...courierInstructionData, postalCode: e.target.value });
    };

    const handleCity = e => {
        setIsCityTyping(true);
        setCourierInstructionData({ ...courierInstructionData, city: e.target.value });
    };

    const handleInstruction = value => {
        setIsInstructionTyping(true);
        setCourierInstructionData({ ...courierInstructionData, instruction: value });
    };

    const handleUpdateButton = async id => {
        setLoading(true);
        setCourierInstructionsData(prevInstructions => ({
            ...prevInstructions,
            [id]: courierInstructionData?.instruction
        }));
        getLatLngFromAddress(updatedAddress).then(async latLng => {
            if (latLng) {
                try {
                    const formData = {
                        COURIER_ID: item?.BOTagAlgorithmData?.cf?._id,
                        extraFields: {
                            isStoreAddress: item?.BOTagAlgorithmData?.storeDetail?.address,
                            isStoreLatitude: item?.BOTagAlgorithmData?.storeDetail?.latitude,
                            isStoreLongitude: item?.BOTagAlgorithmData?.storeDetail?.longitude,
                            isUserAddress: updatedAddress,
                            isUserLatitude: latLng?.lat,
                            isUserLongitude: latLng?.lng
                        }
                    };
                    const res = await Library.makeCommonPostRequest(
                        "couriers",
                        "apiOrderPromise",
                        formData,
                        false,
                        setLoading,
                        error => {
                            console.log("makeCommonPostRequest", error);
                        },
                        undefined,
                        false
                    );
                    if (res?.data?.status === 200) {
                        setShowCourierInstructionPopup(false);
                        item.UserAddress.city = courierInstructionData?.city;
                        item.UserAddress.postCode = courierInstructionData?.postalCode;
                        item.UserAddress.street = courierInstructionData?.street;
                        item.UserAddress.streetNumber = courierInstructionData?.streetNumber;
                    } else {
                        setShowCourierInstructionPopup(false);
                        setNotDeliverCourier(true);
                        setShowRightCheckoutPanel(false);
                    }
                } catch (error) {
                    console.log(error);
                }
            }
        });
    };
    /* #endregion */
    return (
        <DefaultPopupCard mobileUsesFullScreen={false}>
            <CourierInstructionsPopupStyle>
                <div className="frame-313545">
                    <div className="frame-313474">
                        <div className="help-courier-find-you">Help courier find you</div>
                        <div className="frame-313474">
                            <Frame290570 className="frame-29057 cursor-pointer" onClick={handleCloseButtonClick} />
                        </div>
                    </div>
                    <div className="frame-313544">
                        <div className="frame-313543">
                            <div className="info-action-card">
                                <div className="frame-29614">
                                    <div className="frame-3627">
                                        <img className="group-3495" src={Courier} alt="" />
                                    </div>
                                    <div className="frame-313574">
                                        <div className="frame-313451">
                                            <div className="frame-29717">
                                                <div className="frame-313359">
                                                    <div className="product-name-by">
                                                        By:
                                                        <img className="" src={Wolt} alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="frame-313451">
                                            <div className="frame-29717">
                                                <div className="frame-313359">
                                                    <span className="product-name-1">
                                                        To: <span className="product-name-2">{address}</span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="frame-313453">
                                            <div className="frame-29717">
                                                <div className="frame-3133592"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="frame-3135452">
                                <div className="address">Address</div>
                                <div className="frame-29737">
                                    <div className="frame-29734">
                                        <FloatingInput
                                            label="Address"
                                            onChange={handleAddress}
                                            value={courierInstructionData.street}
                                            isTyping={isAddressTyping}
                                        />

                                        <div className="frame-29733">
                                            <FloatingInput
                                                label="Door / Street number"
                                                value={courierInstructionData.streetNumber}
                                                onChange={handleStreetNumber}
                                                isTyping={isDoorTyping}
                                            />

                                            <FloatingInput
                                                label="Apt., floor,etc (optional)"
                                                value={courierInstructionData.optional}
                                                onChange={handleOptional}
                                                isTyping={isFloorTyping}
                                            />
                                        </div>
                                        <div className="frame-29733">
                                            <FloatingInput
                                                label="Post code"
                                                value={courierInstructionData.postalCode}
                                                onChange={handlePostalCode}
                                                isTyping={isPostalTyping}
                                            />
                                            <FloatingInput
                                                label="City"
                                                value={courierInstructionData.city}
                                                onChange={handleCity}
                                                isTyping={isCityTyping}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="frame-313589">
                            <div className="please-provide-additional-delivery-info">Please provide additional delivery info</div>
                            <div className="frame-29440">
                                <div className="frame-29735">
                                    <div className="frame-29734">
                                        <FloatingInput
                                            label="Instructions for courier (Ex. Call me when outside)"
                                            value={courierInstructionData?.instruction}
                                            onChange={e => handleInstruction(e.target.value)}
                                            isTyping={isInstructionTyping}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            style={{
                                cursor: IsValid ? "pointer" : "default",
                                opacity: IsValid ? "1" : "0.2",
                                pointerEvents: !IsValid && "none"
                            }}
                            onClick={() => handleUpdateButton(itemID)}
                            className="btn-md-solid">
                            <div className="frame-313497">
                                <div className="frame-313349">
                                    {loading && <ButtonLoader />}
                                    <div className="button-updated">{loading ? "Updating..." : "Update"}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </CourierInstructionsPopupStyle>
        </DefaultPopupCard>
    );
};
export default CourierInstructionsPopup;
