import React from "react";
import SkeletonMain from "@mui/material/Skeleton";
export default function Skeleton({ style, width, height, type }) {
    return (
        <SkeletonMain
            height={height}
            width={width}
            sx={{ bgcolor: "grey.900", borderRadius: type === "footer" ? "40px" : type === "product" ? "5px" : "0px", ...style }}
            variant="rectangular"
            className="rect"
        />
    );
}
