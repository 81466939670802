import React from "react";
import styled from "styled-components";
import Spinner from "../../../../assets/images/SpinnerRed.svg";
import AlertRedArrow from "../../../../assets/images/AlertRedArrow.svg";
import LayoutContext from "../../../../contexts/LayoutContext";
/* #region CSS */
const StyledPage = styled.div`
    &.frame-29643,
    &.frame-29643 * {
        box-sizing: border-box;
    }
    &.frame-29643 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    &.mobile-frame-29643 {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 95%;
    }
    .frame-313411 {
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: flex-end;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313402 {
        display: flex;
        flex-direction: row;
        gap: 32px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313406 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex: 1;
        position: relative;
    }
    .frame-3134022 {
        display: flex;
        flex-direction: column;
        gap: 32px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313401 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-3590 {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .group-3413 {
        flex-shrink: 0;
        width: 50px;
        height: 50px;
        position: static;
    }
    .ellipse-80 {
        background: var(--wrap-wrap-brand, rgba(247, 102, 95, 0.1));
        border-radius: 50%;
        width: 50px;
        height: 50px;
        position: absolute;
        top: 0px;
    }
    .send-03 {
        width: 5.94%;
        height: 48%;
        position: absolute;
        inset: 26% 47.03%;
        overflow: visible;
    }
    .email-sent {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-family: "OpenSans-SemiBold", sans-serif;
        font-size: 22px;
        line-height: 24px;
        font-weight: 600;
        position: relative;
        align-self: stretch;
    }
    .frame-313418 {
        padding: 0px 24px 0px 24px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .check-your-email-and-open-the-link-we-sent-to-continue-resetting-your-password {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: center;
        font-family: var(--subtitle-2-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 14px);
        line-height: var(--subtitle-2-line-height, 24px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
        flex: 1;
    }
    .btn-md-solid {
        background: var(--buttons-button-secondary, #212121);
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        height: 44px;
        position: relative;
        box-shadow: var(--bt-drop-shadow-box-shadow, 0px 0px 4px 0px rgba(30, 38, 49, 0.35));
    }
    .frame-313498 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .spiner-with-bg {
        flex-shrink: 0;
        width: 20px;
        height: 20px;
        position: relative;
    }
    .spiner {
        width: 100%;
        height: 100%;
        position: absolute;
        right: 0%;
        left: 0%;
        bottom: 0%;
        top: 0%;
    }
    .ellipse-1 {
        background: var(--border-border-brand, #f7665f);
        border-radius: 50%;
        opacity: 0.3;
        width: 100%;
        height: 100%;
        position: absolute;
        right: 0%;
        left: 0%;
        bottom: 0%;
        top: 0%;
    }
    .ellipse-12 {
        background: var(--border-border-brand, #f7665f);
        border-radius: 50%;
        width: 100%;
        height: 100%;
        position: absolute;
        right: 0%;
        left: 0%;
        bottom: 0%;
        top: 0%;
    }
    .frame-313497 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313349 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .button {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-family: var(--button-text-medium-regular-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--button-text-medium-regular-font-size, 14px);
        line-height: var(--button-text-medium-regular-line-height, 24px);
        font-weight: var(--button-text-medium-regular-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .spinner-text {
        display: flex;
        flex-direction: row;
    }
    .spinner-img {
        animation: spin 1s linear infinite;
    }

    @keyframes spin {
        to {
            transform: rotate(360deg);
        }
    }
`;
export default function ResetLinkMailSent() {
    const { isMobile } = React.useContext(LayoutContext);
    return (
        <StyledPage className={`frame-29643 ${isMobile ? "mobile-frame-29643" : ""}`}>
            <div className="frame-313411">
                <div className="frame-313402">
                    <div className="frame-313406">
                        <div className="frame-3134022">
                            <div className="frame-313401">
                                <div className="frame-3590">
                                    <div className="group-3413">
                                        <div className="ellipse-80"></div>
                                        <img className="send-03" src={AlertRedArrow} alt="" />
                                    </div>
                                </div>
                                <div className="email-sent">Email sent!</div>
                                <div className="frame-313418">
                                    <div className="check-your-email-and-open-the-link-we-sent-to-continue-resetting-your-password">
                                        Check your email and open the link we sent to continue{" "}
                                        {localStorage.getItem("isLoggedIn") === "true" ? "changing" : "resetting"} your password
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="btn-md-solid">
                <div className="frame-313498">
                    <span className="spinner-text">
                        <img src={Spinner} alt="" className="spinner-img" />
                        &nbsp;
                        {"  "}Waiting for email verification
                    </span>
                </div>
            </div>
        </StyledPage>
    );
}
