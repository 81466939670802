import React, { useEffect, useState } from "react";
import { DefaultPopupCard } from "./DefaultPopupCard";
import styled from "styled-components";
import Alert from "./../../../assets/images/alert.svg";
import LayoutContext from "./../../../contexts/LayoutContext";
import UserContext, { userLogout } from "./../../../contexts/UserContext";
import Spinner from "../../../assets/images/spinner.svg";
import * as Library from "../../../utils/Library";
import StandardsContext from "../../../contexts/StandardsContext";

const StyledPage = styled.div`
    &.frame-29643,
    &.frame-29643 * {
        box-sizing: border-box;
    }
    &.frame-29643 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-end;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313411 {
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: flex-end;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313402 {
        display: flex;
        flex-direction: row;
        gap: 32px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313406 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex: 1;
        position: relative;
    }
    .frame-313507 {
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-3134022 {
        display: flex;
        flex-direction: column;
        gap: 32px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313401 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-3590 {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .group-3413 {
        flex-shrink: 0;
        width: 50px;
        height: 50px;
        position: static;
    }
    .ellipse-80 {
        background: var(--wrap-wrap-red, rgba(255, 0, 0, 0.1));
        border-radius: 50%;
        width: 50px;
        height: 50px;
        position: absolute;
        top: 0px;
    }
    .alert-triangle {
        width: 5.94%;
        height: 48%;
        position: absolute;
        right: 47.03%;
        left: 47.03%;
        bottom: 26%;
        top: 26%;
        overflow: visible;
    }
    .account-deletion {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-family: "OpenSans-SemiBold", sans-serif;
        font-size: 22px;
        line-height: 24px;
        font-weight: 600;
        position: relative;
        align-self: stretch;
    }
    .are-you-sure-you-want-to-delete-your-b-oaccount-doing-so-will-delete-all-your-personal-information-from-our-database-to-confirm-this-type-delete-below {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: center;
        font-family: var(--subtitle-2-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 14px);
        line-height: var(--subtitle-2-line-height, 24px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
        align-self: stretch;
    }
    .input-field {
        background-color: initial;
        border: 1px solid rgb(55, 55, 55);
        border-radius: 5px;
        color: rgb(255, 255, 255);
        display: inline-block;
        outline: none;
        padding: 16px;
        width: 100%;
    }
    .frame-29729 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313414 {
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313399 {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313412 {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .btn-md-solid {
        background: var(--buttons-button-secondary, #212121);
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex: 1;
        height: 44px;
        position: relative;
        box-shadow: var(--bt-drop-shadow-box-shadow, 0px 0px 4px 0px rgba(30, 38, 49, 0.35));
        cursor: pointer;
    }
    .frame-313497 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313349 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .button {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-family: var(--button-text-medium-regular-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--button-text-medium-regular-font-size, 14px);
        line-height: var(--button-text-medium-regular-line-height, 24px);
        font-weight: var(--button-text-medium-regular-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .btn-md-solid2 {
        background: var(--buttons-button-primary, #f7665f);
        border-radius: 5px;
        opacity: 0.2;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex: 1;
        height: 44px;
        position: relative;
        box-shadow: var(--bt-drop-shadow-box-shadow, 0px 0px 4px 0px rgba(30, 38, 49, 0.35));
        cursor: no-drop;
    }
    .btn-md-solid2.active {
        opacity: 1;
        cursor: pointer;
    }
    .input-field.error {
        border: 1px solid #f7665f;
    }
    .input-field.disabled {
        opacity: 0.2;
    }
    .spinner-text {
        display: flex;
        flex-direction: row;
    }
    .spinner-img {
        animation: spin 1s linear infinite;
    }

    @keyframes spin {
        to {
            transform: rotate(360deg);
        }
    }
`;
export default function DeleteAccountPopup({ visible }) {
    /* #region VARS */
    const { setShowAccountDeletePopup, setShowYourAccountPannel } = React.useContext(LayoutContext);
    const {
        setIsUserLoggedIn,
        setUserData,
        setUserAddress,
        setUserGPSCoordinates,
        setCheckoutCartData,
        setCheckoutPaymentData,
        setBOTagAccountID,
        setShippingRecipient
    } = React.useContext(UserContext);
    const { setDefaultNotificationCard } = React.useContext(StandardsContext);
    const [input, setInput] = useState("");
    const [isButtonActive, setIsButtonActive] = useState(false);
    const [inputError, setInputError] = useState(false);
    const [isFormSubmit, setIsFormSubmit] = useState(false);
    /* #endregion VARS */
    /* #region METHODS */
    const handleInputField = e => {
        setInput(e.target.value);
    };
    const handleClosePopup = () => {
        setShowAccountDeletePopup(false);
    };
    const handleValidation = () => {
        let isFormValid = true;
        if (!input) {
            isFormValid = false;
            setInputError(true);
        }
        return isFormValid;
    };
    const handleDeleteAccountFunction = () => {
        if (handleValidation()) {
            setIsFormSubmit(true);
            Library.makePostRequest(
                "deleteAccount",
                {
                    bothebotAccountID: localStorage.getItem("pid")
                },
                false,
                setIsFormSubmit
            )
                .then(res => {
                    const { data } = res;
                    if (data?.status === 200) {
                        //Library.showSuccessMessage(data?.message);
                        Library.showNotification({ message: data?.message, setDefaultNotificationCard });
                        setShowAccountDeletePopup(false);
                        setShowYourAccountPannel(false);
                        userLogout(
                            setIsUserLoggedIn,
                            setUserData,
                            setUserAddress,
                            setUserGPSCoordinates,
                            setCheckoutCartData,
                            setCheckoutPaymentData,
                            setBOTagAccountID,
                            setShippingRecipient
                        );
                    }
                })
                .catch(e => {
                    console.log(e);
                });
        }
    };
    /* #endregion METHODS */
    /* #region EFFECTS */
    useEffect(() => {
        if (input) {
            if (input === "DELETE") {
                setIsButtonActive(true);
                setInputError(false);
            } else {
                setIsButtonActive(false);
                setInputError(true);
            }
        } else {
            setInputError(false);
        }
    }, [input]);
    /* #endregion EFFECTS */
    return (
        <DefaultPopupCard visible={visible} mobileUsesFullScreen={false}>
            <StyledPage className="frame-29643">
                <div className="frame-313411">
                    <div className="frame-313402">
                        <div className="frame-313406">
                            <div className="frame-313507">
                                <div className="frame-3134022">
                                    <div className="frame-313401">
                                        <div className="frame-3590">
                                            <div className="group-3413">
                                                <div className="ellipse-80"></div>
                                                <img className="alert-triangle" src={Alert} alt="" />
                                            </div>
                                        </div>
                                        <div className="account-deletion">Account deletion</div>
                                        <div className="are-you-sure-you-want-to-delete-your-b-oaccount-doing-so-will-delete-all-your-personal-information-from-our-database-to-confirm-this-type-delete-below">
                                            Are you sure you want to delete your BOaccount? Doing so will delete all your personal
                                            information from our database. To confirm this, type &quot;DELETE&quot; below:
                                        </div>
                                        <input
                                            type="text"
                                            className={`input-field ${inputError ? "error" : ""} ${isFormSubmit ? "disabled" : ""}`}
                                            value={input}
                                            onChange={handleInputField}
                                            disabled={isFormSubmit}
                                        />
                                    </div>
                                </div>
                                <div className="frame-29729">
                                    <div className="frame-313414">
                                        <div className="frame-313399">
                                            <div className="frame-313412">
                                                <div className="btn-md-solid" onClick={handleClosePopup}>
                                                    <div className="frame-313497">
                                                        <div className="frame-313349">
                                                            <div className="button">Cancel</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className={`btn-md-solid2 ${isButtonActive ? "active" : ""}`}
                                                    onClick={handleDeleteAccountFunction}>
                                                    <div className="frame-313497">
                                                        <div className="frame-313349">
                                                            <div className="button">
                                                                {isFormSubmit ? (
                                                                    <span className="spinner-text">
                                                                        {" "}
                                                                        <img src={Spinner} alt="" className="spinner-img" />
                                                                        &nbsp;&nbsp;
                                                                        {"  "}Deleting Account...
                                                                    </span>
                                                                ) : (
                                                                    "Delete account"
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </StyledPage>
        </DefaultPopupCard>
    );
}
