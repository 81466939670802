import styled from "styled-components";
import arrowDown from "../../../../assets/images/arrowDown.png";
import { ReactComponent as MarkerPin020 } from "../../../../assets/images/marker-pin-020.svg";

const DeliveryLocationStyles = styled.div`
    .frame-313523,
    .frame-313523 * {
        box-sizing: border-box;
    }
    .frame-313523 {
        padding: 3px 15px 3px 15px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        height: 94px;
        position: relative;
    }
    .frame-313723 {
        display: flex;
        flex-direction: column;
        gap: 14px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .for-wolt-deliveries-b-otag-needs-a-delivery-address {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-family: "OpenSans-SemiBold", sans-serif;
        font-size: 12px;
        line-height: 16px;
        font-weight: 600;
        position: relative;
        align-self: stretch;
    }
    .icon {
        flex-shrink: 0;
        width: 12px;
        height: 16px;
        position: relative;
        overflow: visible;
    }
`;
const ButtonStyles = styled.div`
    .frame-313501,
    .frame-313501 * {
        box-sizing: border-box;
    }
    .frame-313501 {
        padding: 0px 14px 0px 14px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        height: 33px;
        position: relative;
    }
    .frame-313503 {
        width: 100% !important;
        display: flex;
        flex-direction: row;
        gap: 6px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .btn-fw-botag {
        background: var(--buttons-button-secondary, #1c1c1e) !important;
        border-radius: 5px;
        padding: 0px 44px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex: 1;
        height: 33px;
        position: relative;
        box-shadow: var(--bt-drop-shadow-box-shadow, 0px 0px 4px 0px rgba(30, 38, 49, 0.35));
    }
    .frame-313497 {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313349 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .icon1 {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        position: relative;
        overflow: visible;
    }
    .share-a-location {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-family: "OpenSans-Regular", sans-serif;
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

const BotagDeliveryLocation = ({ deliveryMethod, setShowLocationPopup }) => {
    return (
        <div style={{ display: "flex", flexDirection: "column", gap: "18px" }}>
            <DeliveryLocationStyles>
                <div class="frame-313523">
                    <div class="frame-313723">
                        <div class="for-wolt-deliveries-b-otag-needs-a-delivery-address">
                            For {deliveryMethod === "courier" ? "Wolt" : "Shipping"} deliveries BOtag
                            <br />
                            needs a delivery address.
                        </div>
                        <img class="icon" src={arrowDown} alt="" />
                    </div>
                </div>
            </DeliveryLocationStyles>
            <ButtonStyles>
                <div class="frame-313501">
                    <div class="frame-313503">
                        <div class="btn-fw-botag" onClick={() => setShowLocationPopup(true)}>
                            <div class="frame-313497">
                                <div class="frame-313349">
                                    <MarkerPin020 className="icon1" />

                                    <div class="share-a-location">Share a location</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ButtonStyles>
        </div>
    );
};

export default BotagDeliveryLocation;
