import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { AppProvider } from "./contexts/AppContext";
import { LayoutProvider } from "./contexts/LayoutContext";
import { StandardsProvider } from "./contexts/StandardsContext";
import { UserProvider } from "./contexts/UserContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

root.render(<Elements stripe={stripePromise}>{setContextProviders(<App />)}</Elements>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

function setContextProviders(children) {
    return (
        <AppProvider>
            <LayoutProvider>
                <UserProvider>
                    <StandardsProvider>{children}</StandardsProvider>
                </UserProvider>
            </LayoutProvider>
        </AppProvider>
    );
}
