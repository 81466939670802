import LoaderSvg from "./../../../assets/images/loader.svg";
import styled from "styled-components";
const StyledPage = styled.div`
    &.panel-loading-state,
    &.panel-loading-state * {
        box-sizing: border-box;
    }
    &.panel-loading-state {
        display: flex;
        flex-direction: column;
        gap: 32px;
        align-items: center;
        justify-content: center;
        height: 96vh;
        position: absolute;
        backdrop-filter: blur(5px);
        width: 100%;
    }
    .frame-29779 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .spiner-with-bg {
        flex-shrink: 0;
        width: 55px;
        height: 55px;
        position: relative;
    }
    .spiner-with-bg img {
        width: 55px;
        height: 55px;
    }
    .spiner {
        width: 100%;
        height: 100%;
        position: absolute;
        right: 0%;
        left: 0%;
        bottom: 0%;
        top: 0%;
    }
    .ellipse-1 {
        background: var(--border-border-brand, #f7665f);
        border-radius: 50%;
        opacity: 0.3;
        width: 100%;
        height: 100%;
        position: absolute;
        right: 0%;
        left: 0%;
        bottom: 0%;
        top: 0%;
    }
    .ellipse-12 {
        background: var(--border-border-brand, #f7665f);
        border-radius: 50%;
        width: 100%;
        height: 100%;
        position: absolute;
        right: 0%;
        left: 0%;
        bottom: 0%;
        top: 0%;
    }
    .logging-you-in {
        color: var(--text-text-primary, #ffffff);
        text-align: left;
        font-family: var(--heading-3-font-family, "OpenSans-SemiBold", sans-serif);
        font-size: var(--heading-3-font-size, 16px);
        line-height: var(--heading-3-line-height, 24px);
        font-weight: var(--heading-3-font-weight, 600);
        position: relative;
    }
`;
export default function SubmitLoader(props) {
    return (
        <StyledPage className="panel-loading-state">
            <div className="frame-29779">
                <div className="spiner-with-bg">
                    <img src={LoaderSvg} alt="loader" />
                </div>
                <div className="logging-you-in">Updating...</div>
            </div>
        </StyledPage>
    );
}
