import styled from "styled-components";
import OrderItemCard from "../../sections/OrderItemCard";
import ArrowLeft from "../../../assets/images/arrow-left.png";
import QrCode from "../../../assets/images/qrCode.png";
import * as Library from "../../../utils/Library";
import React, { useEffect, useState } from "react";
import LayoutContext from "../../../contexts/LayoutContext";
import OrderDetailsPanelSkeleton from "../skeletons/OrderDetailsPanelSkeleton";
import logGA4Event from "../../../utils/firebase/analytics";
import StandardsContext from "../../../contexts/StandardsContext";

/* #region STYLED CSS */
const OrderDetailsStyles = styled.div`
    .multi-bag-desktop-panel,
    .multi-bag-desktop-panel * {
        box-sizing: border-box;
    }
    .multi-bag-desktop-panel {
        background: #121212;
        // border-style: solid;
        // border-color: var(--border-border-primary-10, rgba(166, 166, 166, 0.1));
        // border-width: 0px 0px 0px 1px;
        height: 100%;
        position: relative;
        overflow: hidden;
    }
    .panel-headers {
        // padding: 0px 0px 699px 0px;
        width: 372px;
        height: 32px;
        // position: absolute;
        // left: 24px;
        // top: 16px;
    }
    .frame-29001 {
        display: flex;
        flex-direction: row;
        gap: 20px;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        position: absolute;
        left: 0px;
        top: 0px;
    }
    .frame-29757 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        width: 372px;
        // height: 931.99px;
        // position: absolute;
        // left: 24px;
        // top: 80.01px;
        width: 100%;
        height: calc(100% - 204px);
        overflow-y: scroll;
    }
    .back-btn {
        background: var(--buttons-button-quaternary, rgba(166, 166, 166, 0.1));
        border-radius: 5px;
        padding: 4px 8px 4px 8px;
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 45px;
        height: 30px;
        position: relative;
    }
    .arrow-left {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        position: relative;
        overflow: visible;
    }
    .order-nr-321325-j {
        color: var(--text-text-primary, #ffffff);
        text-align: left;
        font-size: var(--heading-3-font-size, 16px);
        line-height: var(--heading-3-line-height, 24px);
        font-weight: var(--heading-3-font-weight, 600);
        position: relative;
        flex: 1;
    }
    .separator {
        width: 100%;
        height: 0.5px;
        background-color: rgb(56, 56, 56);
        margin: 16px 0px 23px 0px;
    }

    .orders-card-item {
        border-radius: 5px;
        border-style: solid;
        border-color: rgb(56, 56, 56);
        border-width: 1.5px;
        padding: 0px 0px 16px 0px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        flex-shrink: 0;
        width: 100%;
        position: relative;
    }
    .info-card-status {
        border-radius: 5px 5px 0px 0px;
        display: flex;
        flex-direction: row;
        gap: 5px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        height: 37px;
        position: relative;
        font-size: 12px;
        box-shadow: var(--bt-drop-shadow-box-shadow, 0px 0px 4px 0px rgba(30, 38, 49, 0.35));
    }
    .frame-29741 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: center;
        flex: 1;
        position: relative;
    }
    .frame-29781 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .please-wait-for-store-confirmation {
        color: var(--text-text-warning, #ff9900);
        text-align: center;
        font-size: var(--caption-font-size, 12px);
        line-height: var(--caption-line-height, 20px);
        font-weight: var(--caption-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .frame-313449 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313447 {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-29715 {
        padding: 0px 16px 0px 16px;
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
        width: 100%;
    }
    .luan-j-shirt-cosmic-4-3 {
        border-radius: 5px;
        flex-shrink: 0;
        width: 44.83px;
        height: 60px;
        position: relative;
        object-fit: cover;
    }
    .frame-29710 {
        display: flex;
        flex-direction: column;
        gap: 6px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex: 1;
        position: relative;
        width: 100%;
        overflow: hidden;
    }
    .brand-product-type {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: left;
        font-size: var(--caption-font-size, 12px);
        line-height: var(--caption-line-height, 20px);
        font-weight: var(--caption-font-weight, 400);
        position: relative;
        align-self: stretch;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
    }
    .product-name {
        color: var(--text-text-primary, #ffffff);
        text-align: left;
        font-size: 14px;
        line-height: 14px;
        font-weight: 600;
        //width: 150px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
    }
    .brand-product-type2 {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: left;
        font-size: var(--caption-font-size, 12px);
        line-height: var(--caption-line-height, 20px);
        font-weight: var(--caption-font-weight, 400);
        position: relative;
    }
    .frame-29538 {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        align-self: stretch;
        gap: 7px;
        flex-shrink: 0;
        position: relative;
    }
    .brand-product-type3 {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        position: relative;
        display: flex;
        align-items: baseline;
        justify-content: center;
        gap: 2px;
    }
    .brand-product-type-3-span {
        color: var(--text-text-primary, #ffffff);
        font-size: 14px;
        line-height: 20px;
        font-weight: 600;
    }
    .brand-product-type-3-span2 {
        color: var(--text-text-primary, #ffffff);
        font-size: var(--caption-font-size, 12px);
        line-height: var(--caption-line-height, 20px);
        font-weight: var(--caption-font-weight, 400);
    }
    .brand-product-type-3-span3 {
        color: var(--text-text-primary, #ffffff);
        font-size: 10px;
        line-height: 20px;
        font-weight: 400;
    }

    .frame-313453 {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313443 {
        padding: 0px 16px 0px 16px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-3134432 {
        display: flex;
        flex-direction: row;
        gap: 7px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .brand-product-type4 {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: left;
        font-size: 12px;
        line-height: 20px;
        font-weight: 400;
        position: relative;
    }

    .frame-313444 {
        padding: 0px 16px 0px 16px;
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: center;
        justify-content: space-between;
        position: relative;
        width: 100%;
    }
    .frame-3627 {
        background: var(--buttons-button-tertiary, #232832);
        border-radius: 57px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 45px;
        height: 45px;
        position: relative;
    }

    .frame-313451 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-29717 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .frame-313359 {
        display: flex;
        flex-direction: row;
        gap: 3px;
        align-items: center;
        justify-content: space-between;
        flex-shrink: 0;
        position: relative;
    }
    .product-name2 {
        color: var(--text-text-primary, #ffffff);
        text-align: left;
        font-size: 12px;
        line-height: 14px;
        font-weight: 400;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        align-self: flex-start;
        top: 2px;
        gap: 6px;
    }
    .vector {
        flex-shrink: 0;
        width: 27px;
        height: 15px;
        position: absolute;
        left: 21px;
        top: -0.01px;
        overflow: visible;
        object-fit: cover;
    }
    .frame-3134522 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-3133592 {
        display: flex;
        flex-direction: row;
        gap: 3px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .frame-3134532 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313456 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313450 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        flex-shrink: 0;
        position: relative;
    }
    .brand-product-type5 {
        color: var(--text-text-primary, #ffffff);
        text-align: right;
        font-size: var(--caption-font-size, 12px);
        line-height: var(--caption-line-height, 20px);
        font-weight: var(--caption-font-weight, 400);
        position: relative;
    }
    .frame-3134523 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        flex-shrink: 0;
        position: relative;
    }
    .frame-3134512 {
        background: var(--buttons-button-tertiary, #232832);
        border-radius: 51px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        flex-shrink: 0;
        height: 20px;
        position: relative;
    }
    .edit-02 {
        flex-shrink: 0;
        width: 10px;
        height: 10px;
        position: relative;
        overflow: visible;
    }
    .frame-313448 {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-3134442 {
        padding: 0px 16px 0px 16px;
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: flex-start;
        position: relative;
    }
    .line-26 {
        margin-top: -0.5px;
        border-style: solid;
        border-color: var(--border-border-tertiary, #373737);
        border-width: 0.5px 0 0 0;
        flex: 1;
        height: 0px;
        position: relative;
        transform-origin: 0 0;
        transform: rotate(0.001deg) scale(1, 1);
    }
    .frame-29714 {
        padding: 0px 16px 0px 16px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-29721 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: flex-end;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-29719 {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .frame-297172 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-29716 {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: center;
        justify-content: flex-end;
        flex: 1;
        position: relative;
    }
    .group-3626 {
        flex-shrink: 0;
        width: 45px;
        height: 45px;
        position: relative;
        overflow: visible;
    }
    .frame-313440 {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: flex-end;
        justify-content: center;
        flex: 1;
        position: relative;
    }
    .frame-313441 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-end;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .product-name3 {
        color: var(--text-text-primary, #ffffff);
        text-align: left;
        font-size: 14px;
        line-height: 20px;
        font-weight: 600;
        position: relative;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .open {
        color: var(--text-text-success, #69c23f);
        text-align: left;
        font-size: var(--regular-text-xxs-font-size, 10px);
        position: relative;
        width: 26.65px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .product-name4 {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: left;
        font-size: var(--caption-font-size, 12px);
        /* line-height: var(--caption-line-height, 20px); */
        font-weight: var(--caption-font-weight, 400);
        position: relative;
        align-self: stretch;
        /* height: 16px; */
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .group-3204 {
        height: auto;
        // position: absolute;
        // left: 24px;
        // top: 553px;
        // transform: translate(0px, -1px);
        // overflow: visible;
    }
    .group-32042 {
        height: auto;
        // position: absolute;
        // left: 24px;
        // top: 553px;
        // overflow: visible;
    }
    .frame-29790 {
        background: #121212;
        padding: 39px 0px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        height: 134px;
        left: calc(50% - 210px);
        bottom: 0px;
    }
    .frame-29755 {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .btn-lg-solid {
        background: var(--buttons-button-secondary, #212121);
        border-radius: 5px;
        // padding: 48px;
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: center;
        flex: 1;
        height: 48px;
        position: relative;
        box-shadow: var(--bt-drop-shadow-box-shadow, 0px 0px 4px 0px rgba(30, 38, 49, 0.35));
    }
    .frame-29590 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .qr-code-01 {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        position: relative;
        overflow: visible;
    }
    .button {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-size: var(--button-text-medium-regular-font-size, 14px);
        line-height: var(--button-text-medium-regular-line-height, 24px);
        font-weight: var(--button-text-medium-regular-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .for-pick-up-orders-click-show-order-code-to-quickly-confirm-your-order-with-the-store-clerk-before-receiving-your-items {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: center;
        font-size: 10px;
        line-height: 16px;
        font-weight: 400;
        position: relative;
        align-self: stretch;
    }
`;
/* #endregion */

const OrderDetailsPanel = () => {
    /* #region VARS */
    //Contexts
    const {
        setShowRightMyOrdersDetailsPanel,
        setShowRightMyOrdersPanel,
        showRightMyOrdersDetailsPanel,
        setOrderID,
        setShowQrPopup,
        setQrData,
        showReturnSubmittedPopup
    } = React.useContext(LayoutContext);
    const { setDefaultNotificationCard } = React.useContext(StandardsContext);
    //States
    const [orderDetailsData, setOrderDetailsData] = React.useState([]);
    const [isLoading, setIsLoading] = useState(false);
    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        getOrdersDetailsRequest();
    }, [OrderDetailsPanel, showReturnSubmittedPopup]);
    /* #endregion */

    /* #region METHODS */
    const onBackFunc = () => {
        if (localStorage.getItem("isLoggedIn") === "true") {
            setShowRightMyOrdersPanel(true);
        } else {
            setShowRightMyOrdersPanel(false);
            setShowRightMyOrdersDetailsPanel({
                orderID: null,
                isOpen: false
            });
            setOrderID(null);
        }
    };

    const getOrdersDetailsRequest = async () => {
        const formData = {
            orderID: showRightMyOrdersDetailsPanel?.orderID
        };

        try {
            const response = await Library.makePostRequest("getOrderDetails", formData, false, setIsLoading, error => {}, false, false);
            if (response?.status === 200) {
                const data = response?.data ?? [];
                setOrderDetailsData(data);
            }
        } catch (error) {
            //Library.showErrorMessage("Something went wrong. Code 400");
            Library.showNotification({
                message: "Something went wrong. Code 400",
                type: "error",
                setDefaultNotificationCard
            });
            console.error("getOrdersDetailsRequest", error);
            setOrderDetailsData([]);
        }
    };
    const showOrderQrFunc = orderDetailsData => {
        logGA4Event("QRcode_PopUp");
        setShowQrPopup(true);
        if (orderDetailsData?.result && orderDetailsData?.result.length) {
            setQrData(orderDetailsData);
        } else {
            setQrData("");
        }
    };
    /* #endregion */

    return (
        <OrderDetailsStyles style={{ height: "100%", width: "100%", overflow: "hidden" }}>
            <div className="multi-bag-desktop-panel">
                <div className="panel-headers">
                    <div className="frame-29001">
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                                alignItems: "center"
                            }}>
                            <div className="back-btn cursor-pointer" onClick={onBackFunc}>
                                <img className="arrow-left" src={ArrowLeft} alt="" />
                            </div>
                            <div className="order-nr-321325-j">&nbsp;&nbsp;Order nr: {orderDetailsData?.orderDetails?.OurID}</div>
                        </div>
                        <div>
                            <div
                                style={{
                                    backgroundColor: "#212121",
                                    borderRadius: "5px",
                                    padding: "8px 12px",
                                    color: "ffffff",
                                    fontSize: "12px",
                                    cursor: "pointer"
                                }}
                                onClick={getOrdersDetailsRequest}>
                                Refresh
                            </div>
                        </div>
                    </div>
                </div>
                <div className="separator"></div>
                <div className="frame-29757">
                    {isLoading && <OrderDetailsPanelSkeleton />}

                    {!isLoading && orderDetailsData?.result?.map(data => <OrderItemCard data={data} />)}
                </div>

                <div className="frame-29790">
                    <div className="frame-29755" onClick={e => (isLoading ? e.preventDefault() : showOrderQrFunc(orderDetailsData))}>
                        <div className="btn-lg-solid">
                            <div className="frame-29590" style={isLoading ? { cursor: "no-drop", opacity: "0.2" } : { cursor: "pointer" }}>
                                <img className="qr-code-01" src={QrCode} alt="" />
                                <div className="button">Show order code</div>
                            </div>
                        </div>
                    </div>
                    <div className="for-pick-up-orders-click-show-order-code-to-quickly-confirm-your-order-with-the-store-clerk-before-receiving-your-items">
                        For pick up orders, click &quot;Show order code&quot; to quickly confirm your order with
                        <br />
                        the store clerk before receiving your items.
                    </div>
                </div>
            </div>
        </OrderDetailsStyles>
    );
};
export default OrderDetailsPanel;
