import moment from "moment/moment";

/**
 * Formats the price with a specified coin symbol and default error message.
 * @param {number|string} price - The price to be formatted.
 * @param {string} [coinSymbol=""] - The symbol of the coin to be appended to the formatted price.
 * @param {string} [defaultError="--"] - The default error message.
 * @returns {string} The formatted price with the coin symbol.
 * @example formatPrice(1000, "USD", "--") // "1,000.00 USD"
 */
export const formatPrice = (price, coinSymbol = "", defaultError = "--") => {
    if (price === null || isNaN(price)) return defaultError;
    price = typeof price === "string" ? price.replace(",", ".") : price.toString().replace(",", ".");

    //split decimal and integer from price
    const priceNonDecimal = price.split(".")[0];
    let priceDecimal = price.split(".")[1];

    //fill the priceDecimal with 0s if it's less than 2 characters
    if (priceDecimal && priceDecimal.length < 2) {
        priceDecimal += "0";
    }

    //format the price to use a dot as a thousand separator
    let formattedPrice = priceNonDecimal.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

    //if there is a decimal, add it to the formatted price
    formattedPrice = priceDecimal ? formattedPrice + "," + priceDecimal : formattedPrice;

    return coinSymbol ? formattedPrice + " " + coinSymbol : formattedPrice;
};

/**
 * Formats the full name of a person with a specified default value.
 * @param {string} firstName - The first name of the person.
 * @param {string} lastName - The last name of the person.
 * @param {string} [defaultValue="--"] - The default value if first name and last name are not provided.
 * @returns {string} The formatted full name.
 * @example formatPersonFullName("John", "Doe", "--") // "John Doe"
 */
export const formatPersonFullName = (firstName, lastName, defaultValue = "--") => {
    let result = defaultValue;
    if (firstName && lastName) result = firstName + " " + lastName;
    else if (firstName) result = firstName;
    else if (lastName) result = lastName;
    else result = defaultValue;

    return result;
};

/**
 * Formats the default date and time with a specified default error message.
 * @param {string} date - The date to be formatted.
 * @param {string} [defaultError="--"] - The default error message.
 * @returns {string} The formatted date and time.
 * @example formatDefaultDateTime("2021-09-01T12:00:00", "--") // "01/09/2021 12:00"
 */
export const formatDefaultDateTime = (date, defaultError = "--") => {
    try {
        if (!date || date === "-") return defaultError;
        const res = moment(date)?.format("DD/MM/YYYY HH:mm") ?? defaultError;
        if (res === "Invalid date") return defaultError;
        return res;
    } catch (error) {
        console.error("formatDefaultDateTime", date);
        return defaultError;
    }
};

/**
 * Formats the default date with a specified default error message.
 * @param {string} date - The date to be formatted.
 * @param {string} [defaultError="--"] - The default error message.
 * @returns {string} The formatted date.
 * @example formatDefaultDate("2021-09-01", "--") // "01/09/2021"
 */
export const formatDefaultDate = (date, defaultError = "--") => {
    try {
        if (!date || date === "-") return defaultError;
        const res = moment(date)?.format("DD/MM/YYYY") ?? defaultError;
        if (res === "Invalid date") return defaultError;
        return res;
    } catch (error) {
        console.error("formatDefaultDateTime", date);
        return defaultError;
    }
};

/**
 * Formats a date into ISO string format.
 *
 * @param {string} date - The date to be formatted.
 * @param {string} [defaultError="--"] - The default error message to be returned if the date is invalid or empty.
 * @returns {string} The formatted date in ISO string format, or the default error message if the date is invalid or empty.
 * @example formatDefaultDateTimeISO("2021-09-01T12:00:00", "--") // "2021-09-01T12:00:00"
 */
export const formatDefaultDateTimeISO = (date, defaultError = "--") => {
    try {
        if (!date || date === "-") return defaultError;
        const res = moment(date)?.toISOString() ?? defaultError;
        if (res === "Invalid date") return defaultError;
        return res;
    } catch (error) {
        console.error("formatDefaultDateTime", date);
        return defaultError;
    }
};

/**
 * Formats a date and time to a human readable format.
 *
 * @param {string} date - The date to be formatted.
 * @param {string} [defaultError="--"] - The default error message to be returned if the date is invalid or empty.
 * @returns {string} The formatted date and time in a human readable format, or the default error message if the date is invalid or empty.
 * @example formatDefaultHumanDateTime("2021-09-01T12:00:00", "--") // "1 September 2021, 12:00"
 */
export const formatDefaultHumanDateTime = (date, defaultError = "--") => {
    try {
        if (!date || date === "-") return defaultError;
        const res = moment(date)?.format("D MMMM YYYY, HH:mm") ?? defaultError;
        if (res === "Invalid date") return defaultError;
        return res;
    } catch (error) {
        console.error("formatDefaultDateTime", date);
        return defaultError;
    }
};

/**
 * Formats a distance value with an optional suffix.
 * @param {number} floatValue - The distance value to be formatted.
 * @param {string} [suffix="km"] - The optional suffix to be appended to the formatted distance.
 * @param {string} [defaultError="--"] - The default error message to be returned if the floatValue is undefined or NaN.
 * @returns {string} The formatted distance value with the suffix.
 * @example formatDistance(14.175, "km", "--") // "14.18 km"
 */
export const formatDistance = (floatValue, suffix = "km", defaultError = "--") => {
    try {
        if (floatValue === undefined || isNaN(floatValue)) return defaultError;
        /*const res = floatValue?.toFixed(2) + (suffix ? " " + suffix : "");
        return res;*/
        if (floatValue < 1) {
            return `${Math.ceil((floatValue * 100).toFixed(2))}m`;
        } else {
            return `${Math.ceil(floatValue?.toFixed(2))} ${suffix ? suffix : ""}`;
        }
    } catch (error) {
        return defaultError;
    }
};

/**
 * Formats a color hex value with a default error message.
 * @param {string} color - The color hex value to be formatted.
 * @param {string} [defaultError="#000000"] - The default error message to be returned if the color is invalid or empty.
 * @returns {string} The formatted color hex value.
 * @example formatColorHex("FF0000", "#000000") // "#FF0000"
 */
export const formatColorHex = (color, defaultError = "") => {
    try {
        if (!color) return defaultError;
        if (color.startsWith("#")) return color;
        return "#" + color;
    } catch (error) {
        console.error(`formatColorHex error for color: ${color}`);
        return defaultError;
    }
};

/**
 * Formats and compares two colors.
 *
 * @param {string} color1 - The first color to format and compare.
 * @param {string} color2 - The second color to format and compare.
 * @returns {boolean} Returns true if the formatted colors are equal, false otherwise.
 */
export const formatAndCompareColors = (color1, color2) => {
    const formattedColor1 = formatColorHex(color1?.toUpperCase());
    const formattedColor2 = formatColorHex(color2?.toUpperCase());

    return formattedColor1 === formattedColor2;
};

/**
 * Capitalizes the first letter of a string.
 *
 * @param {string} string - The input string.
 * @returns {string} The input string with the first letter capitalized.
 */
export const capitalizeFirstLetter = string => {
    try {
        return string.charAt(0).toUpperCase() + string.slice(1);
    } catch (error) {
        console.error("CapitalizeFirstLetter Error:", string);
        return string;
    }
};

/**
 * Formats a phone number with optional spacings and country code.
 *
 * @param {string} phoneNumber - The phone number to format.
 * @param {boolean} [withSpacings=true] - Whether to include spacings in the formatted phone number.
 * @param {string} [countryNr="+47"] - The country code to include in the formatted phone number. (can be empty!)
 * @param {string} [defaultError="--"] - The default error message to return if the phone number is invalid.
 * @returns {string} The formatted phone number.
 * @example formatPhoneNumber("12345678", true, "+47", "--") // "(+47) 123 45 678"
 */
export const formatPhoneNumber = (phoneNumber, withSpacings = true, countryNr = "+47", defaultError = "--") => {
    try {
        if (!phoneNumber) return defaultError;
        //remove spaces:
        phoneNumber = phoneNumber.replace(/\s/g, "");
        if (phoneNumber.length < 8) return phoneNumber;

        if (countryNr) {
            //remove country code if it's already there
            if (phoneNumber.startsWith(countryNr)) phoneNumber = phoneNumber.slice(countryNr.length);
            else if (phoneNumber.startsWith(`(${countryNr})`)) phoneNumber = phoneNumber.slice(`(${countryNr})`.length);

            //format with spaces
            if (withSpacings) phoneNumber = phoneNumber.replace(/(\d{3})(\d{2})(\d{3})/, "$1 $2 $3");

            phoneNumber = `(${countryNr}) ${phoneNumber}`;
        } else if (withSpacings) phoneNumber = phoneNumber.replace(/(\d{3})(\d{2})(\d{3})/, "$1 $2 $3");

        return phoneNumber;
    } catch (error) {
        console.error("FormatPhoneNumber Error:", phoneNumber);
        return defaultError;
    }
};

export const isValidEmail = email => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
};

export const validatePassword = password => {
    const letterCount = (password.match(/[a-zA-Z]/g) || []).length;
    if (letterCount < 7) {
        return false;
    }
    const numberCount = (password.match(/[0-9]/g) || []).length;
    if (numberCount < 1) {
        return false;
    }
    return true;
};
