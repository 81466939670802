import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import LayoutContext from "../../../contexts/LayoutContext";
import IconDownload from "../../../assets/images/icon-download.svg";
import * as Library from "../../../utils/Library";
import * as Formatter from "../../../utils/Formatter";
import UserContext from "../../../contexts/UserContext";
import IconVisa from "../../../assets/images/icon-visa.png";
import OrderPanelSkeleton from "../skeletons/OrderPanelSkeleton";
import logGA4Event from "../../../utils/firebase/analytics";
import StandardsContext from "../../../contexts/StandardsContext";
import useScrollEnd from "../../../utils/hooks/useScrollEnd";

/* #region STYLED CSS */
const StyledPage = styled.div`
    .my-orders,
    .my-orders * {
        box-sizing: border-box;
    }
    .my-orders {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        position: relative;
        width: 100%;
        height: 95dvh;
        overflow: hidden;
    }
    .panel-headers {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        flex-shrink: 0;
        width: 100%;
        position: relative;
    }
    .frame-29055 {
        background: var(--wrap-wrap-gray, rgba(166, 166, 166, 0.1));
        border-radius: 500px;
        padding: 4px 14px 4px 14px;
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .my-orders2 {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-size: var(--heading-3-font-size, 16px);
        line-height: var(--heading-3-line-height, 24px);
        font-weight: var(--heading-3-font-weight, 600);
        position: relative;
    }
    .frame-313365 {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: flex-end;
        flex-shrink: 0;
        width: 159px;
        position: relative;
    }
    .btn-xs-secondary {
        background: var(--buttons-button-secondary, #212121);
        border-radius: 5px;
        padding: 10px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        height: 30px;
        position: relative;
    }
    .button {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-family: var(--button-text-small-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--button-text-small-font-size, 12px);
        line-height: var(--button-text-small-line-height, 16px);
        font-weight: var(--button-text-small-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .separator {
        display: flex;
        flex-direction: row;
        gap: 6px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        height: auto;
        position: relative;
        overflow: visible;
    }
    .separator-middle {
        border-style: solid;
        border-color: var(--border-border-tertiary, #373737);
        border-width: 0.5px 0px 0px;
        flex-shrink: 0;
        width: 100%;
        height: 0px;
        position: relative;
        transform-origin: 0px 0px;
        transform: rotate(0deg) scale(1, 1);
    }
    .tabs-with-indicators {
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .buttons-fixed-width {
        background: var(--buttons-button-secondary, #212121);
        border-radius: 5px 0px 0px 5px;
        padding: 16px;
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: center;
        flex: 1;
        height: 36px;
        position: relative;
        box-shadow: var(--bt-drop-shadow-box-shadow, 0px 0px 4px 0px rgba(30, 38, 49, 0.35));
        cursor: pointer;
    }
    .buttons-fixed-width.enabled {
        background: var(--buttons-button-secondary-active, #373737);
    }
    .frame-313608 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: center;
        flex: 1;
        position: relative;
    }
    .in-progress {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-size: var(--button-text-small-semibold-font-size, 12px);
        line-height: var(--button-text-small-semibold-line-height, 16px);
        font-weight: var(--button-text-small-semibold-font-weight, 600);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .group-3322 {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        position: static;
    }
    .ellipse-52 {
        background: var(--border-border-brand, #f7665f);
        border-radius: 50%;
        width: 16px;
        height: 16px;
        position: absolute;
        right: 32.5px;
        top: 0px;
    }
    .total-items-count {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-size: 9px;
        font-weight: 600;
        position: absolute;
        right: 32px;
        top: 2px;
        width: 16px;
    }
    .buttons-fixed-width2 {
        background: var(--buttons-button-secondary, #212121);
        border-radius: 0px 5px 5px 0px;
        padding: 16px;
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: center;
        flex: 1;
        height: 36px;
        position: relative;
        box-shadow: var(--bt-drop-shadow-box-shadow, 0px 0px 4px 0px rgba(30, 38, 49, 0.35));
        cursor: pointer;
    }
    .buttons-fixed-width2.enabled {
        background: var(--buttons-button-secondary-active, #373737);
    }
    .orders-card-detail {
        border-radius: 5px;
        border-style: solid;
        border-color: var(--border-border-primary, rgba(166, 166, 166, 0.2));
        border-width: 1px;
        padding: 16px 0px 16px 0px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: flex-start;
        justify-content: flex-start;
        flex-shrink: 0;
        width: 100%;
        position: relative;
    }
    .frame-313449 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313447 {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-29715 {
        padding: 0px 16px 0px 16px;
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-29710 {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex: 1;
        position: relative;
    }
    .brand-product-type {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: left;
        font-family: var(--caption-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--caption-font-size, 12px);
        line-height: var(--caption-line-height, 20px);
        font-weight: var(--caption-font-weight, 400);
        position: relative;
        align-self: stretch;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .brand-product-type2 {
        text-align: left;
        font-family: "OpenSans-Regular", sans-serif;
        font-size: 12px;
        line-height: 20px;
        font-weight: 400;
        position: relative;
        align-self: stretch;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .brand-product-type-2-span {
        color: var(--text-text-primary, #ffffff);
        font-family: "OpenSans-Regular", sans-serif;
        font-size: 12px;
        line-height: 20px;
        font-weight: 400;
    }
    .brand-product-type-2-span2 {
        color: var(--text-text-secondary, #8c8c8c);
        font-family: "OpenSans-Regular", sans-serif;
        font-size: 12px;
        line-height: 20px;
        font-weight: 400;
    }
    .brand-product-type3 {
        text-align: left;
        font-family: var(--caption-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--caption-font-size, 12px);
        line-height: var(--caption-line-height, 20px);
        font-weight: var(--caption-font-weight, 400);
        position: relative;
        align-self: stretch;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .brand-product-type-3-span {
        color: var(--text-text-primary, #ffffff);
        font-family: var(--caption-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--caption-font-size, 12px);
        line-height: var(--caption-line-height, 20px);
        font-weight: var(--caption-font-weight, 400);
    }
    .brand-product-type-3-span2 {
        color: var(--text-text-secondary, #8c8c8c);
        font-family: var(--caption-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--caption-font-size, 12px);
        line-height: var(--caption-line-height, 20px);
        font-weight: var(--caption-font-weight, 400);
    }
    .frame-29538 {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313490 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: flex-start;
        justify-content: flex-start;
        flex-shrink: 0;
        position: relative;
    }
    .in-progress2 {
        color: var(--text-text-warning, #ff9900);
        text-align: right;
        font-size: var(--label-small-font-size, 10px);
        line-height: var(--label-small-line-height, 16px);
        font-weight: var(--label-small-font-weight, 600);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    .brand-product-type4 {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .brand-product-type-4-span {
        color: var(--text-text-primary, #ffffff);
        font-size: 14px;
        line-height: 20px;
        font-weight: 600;
    }
    .brand-product-type-4-span2 {
        color: var(--text-text-primary, #ffffff);
        font-family: var(--caption-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--caption-font-size, 12px);
        line-height: var(--caption-line-height, 20px);
        font-weight: var(--caption-font-weight, 400);
    }
    .brand-product-type-4-span3 {
        color: var(--text-text-primary, #ffffff);
        font-family: "OpenSans-Regular", sans-serif;
        font-size: 10px;
        line-height: 20px;
        font-weight: 400;
    }
    .frame-313492 {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-29714 {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313444 {
        padding: 0px 16px 0px 16px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313443 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .brand-product-type5 {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: left;
        font-family: "OpenSans-Regular", sans-serif;
        font-size: 12px;
        line-height: 20px;
        font-weight: 400;
        position: relative;
    }
    .separator2 {
        display: flex;
        flex-direction: row;
        gap: 6px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        height: auto;
        position: relative;
        overflow: visible;
        border-style: solid;
        border-color: var(--border-border-tertiary, #373737);
        border-width: 0px 0px 0.5px;
    }
    .frame-313454 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313398 {
        padding: 0px 16px 0px 16px;
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313396 {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: flex-start;
        justify-content: center;
        flex: 1;
        position: relative;
    }
    .frame-313392 {
        display: flex;
        flex-direction: column;
        gap: 2px;
        align-items: flex-start;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .frame-29719 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-29716 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .product-name {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: left;
        font-family: var(--caption-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--caption-font-size, 12px);
        line-height: var(--caption-line-height, 20px);
        font-weight: var(--caption-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .frame-29720 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .product-name2 {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: left;
        font-family: "OpenSans-Regular", sans-serif;
        font-size: 12px;
        line-height: 20px;
        font-weight: 400;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .frame-29721 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313397 {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: flex-start;
        justify-content: center;
        flex: 1;
        position: relative;
    }
    .frame-3133922 {
        display: flex;
        flex-direction: column;
        gap: 2px;
        align-items: flex-end;
        justify-content: center;
        flex: 1;
        position: relative;
    }
    .image-144 {
        flex-shrink: 0;
        width: 32.87px;
        height: 20px;
        position: relative;
        object-fit: cover;
    }
    .frame-29722 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-end;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-297162 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-end;
        flex: 1;
        position: relative;
    }
    .frame-29723 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }

    .frame-313448 {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-3134442 {
        padding: 0px 16px 0px 16px;
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: flex-end;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313493 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: flex-end;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .btn-sm-fw {
        background: var(--buttons-button-secondary, #212121);
        border-radius: 5px;
        padding: 10px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex: 1;
        height: 30px;
        position: relative;
    }
    .frame-313494 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .download-02 {
        flex-shrink: 0;
        width: 12px;
        height: 12px;
        position: relative;
        overflow: visible;
    }
    .order-info-text {
        align-self: stretch;
        color: #8c8c8c;
        text-align: center;

        /* Caption */
        font-family: "Open Sans";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 166.667% */
    }
    .order-cards-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 16px;
        overflow: auto;
        height: 100%;
    }
`;
/* #endregion */

export default function OrdersPanel() {
    /* #region VARS */
    //Contexts
    const { setShowRightMyOrdersPanel } = React.useContext(LayoutContext);
    const { BOTagAccountID } = React.useContext(UserContext);
    const { setDefaultNotificationCard } = React.useContext(StandardsContext);

    const [isLoading, setIsLoading] = React.useState(false);
    const [totalItemsInProgress, setTotalItemsInProgress] = React.useState(0);
    const [totalItemsHistory, setTotalItemsHistory] = React.useState(0);
    const [isInProgress, setIsInProgress] = React.useState(true);
    const [pageCount, setPageCount] = React.useState(2);

    const scrollRef = useRef(null);
    const isAtEnd = useScrollEnd(scrollRef, 10);

    //States
    const [orders, setOrders] = React.useState([]);
    useEffect(() => {
        if (orders?.length < totalItemsInProgress) {
            if (isAtEnd && !isLoading) {
                setPageCount(pageCount + 1);
                if (isInProgress) {
                    getOrdersRequest("2", pageCount);
                } else {
                    getOrdersRequest("3", pageCount);
                }
            }
        }
    }, [isAtEnd]);

    /* #endregion */

    /* #region EFFECTS */
    React.useEffect(() => {
        getOrdersRequest("2", 1);
    }, []);
    /* #endregion */

    /* #region METHODS */
    const onClose = () => {
        setShowRightMyOrdersPanel(false);
    };

    const getOrdersRequest = async (type, page) => {
        setIsInProgress(type === "2");

        const formData = {
            BOthebotAccountID: BOTagAccountID,
            limit: 10,
            page,
            type
        };

        try {
            const response = await Library.makePostRequest(
                "getOrdersData",
                formData,
                false,
                setIsLoading,
                error => {
                    console.log("getOrdersRequest", error);
                },
                false,
                false
            );
            if (response?.status === 200) {
                const data = response?.data?.result ?? [];
                setOrders(prevData => [...prevData, ...data]);
                /*if (type === "2") setTotalItemsInProgress(response?.data?.totalCount ?? 0);
                else setTotalItemsHistory(response?.data?.totalCount ?? 0);*/
                if (!totalItemsInProgress && !totalItemsHistory) {
                    setTotalItemsInProgress(response?.data?.typeCount ?? 0);
                    setTotalItemsHistory(response?.data?.totalCount - response?.data?.typeCount ?? 0);
                }
            }
        } catch (error) {
            //Library.showErrorMessage("Something went wrong. Code 400");
            Library.showNotification({
                message: "Something went wrong. Code 400",
                type: "error",
                setDefaultNotificationCard
            });
            console.error("getOrdersRequest", error);
            setOrders([]);

            setTotalItemsInProgress(0);
            setTotalItemsHistory(0);
        }
    };

    /* #endregion */

    return (
        <StyledPage className="w-100">
            <div className="my-orders">
                <div className="panel-headers">
                    <div className="frame-29055">
                        <div className="my-orders2">My orders</div>
                    </div>
                    <div className="frame-313365">
                        <div className="btn-xs-secondary cursor-pointer" onClick={onClose}>
                            <div className="button">Close</div>
                        </div>
                    </div>
                </div>
                <div className="separator-middle" />
                <div className="tabs-with-indicators">
                    <div
                        className={`buttons-fixed-width ${isInProgress ? "enabled" : ""}`}
                        onClick={e => {
                            isLoading ? e.preventDefault() : getOrdersRequest("2", 1);
                            logGA4Event("OrdersInProgress");
                            setPageCount(2);
                            setOrders([]);
                        }}>
                        <div className="frame-313608">
                            <div className="in-progress">In progress</div>
                            <div className="group-3322">
                                {totalItemsInProgress > 0 ? (
                                    <>
                                        <div className="ellipse-52"></div>
                                        <div className="total-items-count">{totalItemsInProgress}</div>
                                    </>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                    </div>

                    <div
                        className={`buttons-fixed-width2 ${!isInProgress ? "enabled" : ""}`}
                        onClick={e => {
                            isLoading ? e.preventDefault() : getOrdersRequest("3", 1);
                            logGA4Event("OrdersHistory");
                            setPageCount(2);
                            setOrders([]);
                        }}>
                        <div className="frame-313608">
                            <div className="in-progress">History</div>
                            <div className="group-3322">
                                {totalItemsHistory > 0 ? (
                                    <>
                                        <div className="ellipse-52"></div>
                                        <div className="total-items-count">{totalItemsHistory}</div>
                                    </>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="order-cards-wrapper" ref={scrollRef}>
                    {orders?.length === 0 && !isLoading ? (
                        <div className="order-info-text">
                            {isInProgress ? "You have no orders in progress..." : "You have no orders in history..."}
                        </div>
                    ) : (
                        orders.map((orderData, index) => (
                            <div className="orders-card-detail" key={index}>
                                <OrderCard key={index} orderData={orderData} />
                            </div>
                        ))
                    )}
                    {isLoading && <OrderPanelSkeleton />}
                </div>
            </div>
        </StyledPage>
    );
}

/* #region SMALL COMPONENTS */
const OrderCard = ({ orderData }) => {
    /* #region VARS */
    const {
        createdAt,
        ourID,
        totalItems,
        totalPrice,
        firstName,
        lastName,
        email,
        phoneNumber,
        status,
        cardName,
        cardNumber,
        cardExpireDate,
        pdfReceiptUrl
    } = orderData;
    const { setShowRightMyOrdersDetailsPanel, setShowRightMyOrdersPanel } = React.useContext(LayoutContext);
    const { setDefaultNotificationCard } = React.useContext(StandardsContext);

    const currency = "NOK";
    const customerName = Formatter.formatPersonFullName(firstName, lastName);
    /* #endregion */

    /* #region METHODS */
    const onClickDownloadReceipt = () => {
        if (pdfReceiptUrl) Library.openNewPage(pdfReceiptUrl, setDefaultNotificationCard);
    };

    const onClickSeeOrder = () => {
        setShowRightMyOrdersPanel(false);
        setShowRightMyOrdersDetailsPanel({
            orderID: orderData?._id,
            isOpen: true
        });
    };
    /* #endregion */

    return (
        <div className="frame-313449">
            <div className="frame-313447">
                <div className="frame-29715">
                    <div className="frame-29710">
                        <div className="brand-product-type">{Formatter.formatDefaultHumanDateTime(createdAt)}</div>
                        <div className="brand-product-type2">
                            <span>
                                <span className="brand-product-type-2-span">Order nr:</span>
                                <span className="brand-product-type-2-span2">{" " + ourID}</span>
                            </span>
                        </div>
                        <div className="brand-product-type3">
                            <span>
                                <span className="brand-product-type-3-span">Items:</span>
                                <span className="brand-product-type-3-span2">{" " + totalItems}</span>
                            </span>
                        </div>
                    </div>
                    <div className="frame-29538">
                        <div className="frame-313490">
                            <div
                                className="in-progress2"
                                style={{ color: status === "In Progress" ? "#ff9900" : "All items delivered" ? "#00ff00" : "#ff0000" }}>
                                {status}
                            </div>
                        </div>
                        <div className="brand-product-type4">
                            <span>
                                <span className="brand-product-type-4-span">{Formatter.formatPrice(totalPrice)}</span>
                                <span className="brand-product-type-4-span2">&nbsp;</span>
                                <span className="brand-product-type-4-span3">{currency}</span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="frame-313492">
                <div className="frame-29714">
                    <div className="frame-313444">
                        <div className="frame-313443">
                            <div className="brand-product-type5">CUSTOMER INFO</div>
                            <div className="separator2" />
                        </div>
                    </div>
                </div>
                <div className="frame-313454">
                    <div className="frame-313398">
                        <div className="frame-313396">
                            <div className="frame-313392">
                                <div className="frame-29719">
                                    <div className="frame-29716">
                                        <div className="product-name">{customerName}</div>
                                    </div>
                                </div>
                                <div className="frame-29720">
                                    <div className="frame-29716">
                                        <div className="product-name2">{email}</div>
                                    </div>
                                </div>
                                <div className="frame-29721">
                                    <div className="frame-29716">
                                        <div className="product-name2">{Formatter.formatPhoneNumber(phoneNumber, true)}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="frame-313397">
                            <div className="frame-3133922">
                                <img className="image-144" src={IconVisa} alt="" />
                                <div className="frame-29722">
                                    <div className="frame-297162">
                                        <div className="product-name2">
                                            {cardName} ending *{cardNumber}
                                        </div>
                                    </div>
                                </div>
                                <div className="frame-29723">
                                    <div className="frame-297162">
                                        <div className="product-name2">Expires {cardExpireDate}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="frame-313448">
                        <div className="frame-3134442">
                            <div className="frame-313493">
                                <div
                                    className="btn-sm-fw"
                                    onClick={onClickDownloadReceipt}
                                    style={{ opacity: pdfReceiptUrl ? 1 : 0.2, cursor: pdfReceiptUrl ? "pointer" : "not-allowed" }}>
                                    <div className="frame-313494">
                                        <img className="download-02" src={IconDownload} alt="download" />
                                        <div className="button">Download receipt</div>
                                    </div>
                                </div>
                                <div className="btn-sm-fw cursor-pointer" onClick={onClickSeeOrder}>
                                    <div className="frame-313494">
                                        <div className="button">See order</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
/* #endregion */
