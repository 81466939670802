import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import ProductInfoPanelFAQ from "./ProductInfoPanelFAQ";
import * as Library from "../../../utils/Library";
import AppContext from "../../../contexts/AppContext";
import LayoutContext from "../../../contexts/LayoutContext";
import ProductInfoSkeleton from "../skeletons/ProductInfoSkeleton";

const StyledProductInfo = styled.div`
    &.product-info,
    &.product-info * {
        box-sizing: border-box;
    }
    &.product-info {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        height: 100vh;
        position: relative;
        width: 100%;
        overflow: scroll;
    }
    &.mobile-scroll {
        overflow: hidden;
        height: auto;
    }
    .panel-headers {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        flex-shrink: 0;
        width: 100%;
        position: relative;
    }
    .frame-313366 {
        background: var(--wrap-wrap-gray, rgba(166, 166, 166, 0.1));
        border-radius: 500px;
        padding: 4px 14px 4px 14px;
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .assistive-panel {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-size: var(--heading-3-font-size, 16px);
        line-height: var(--heading-3-line-height, 24px);
        font-weight: var(--heading-3-font-weight, 600);
        position: relative;
    }
    .frame-313365 {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: flex-end;
        flex-shrink: 0;
        width: 159px;
        position: relative;
    }
    .seperator-middle {
        margin-top: -0.5px;
        border-style: solid;
        border-color: var(--border-border-tertiary, #373737);
        border-width: 0.5px 0 0 0;
        flex-shrink: 0;
        width: 100%;
        height: 0px;
        position: relative;
        transform-origin: 0 0;
        transform: rotate(0deg) scale(1, 1);
    }
    .tabs-level-1 {
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .buttons-fixed-width {
        background: #212121;
        border-radius: 5px 0px 0px 5px;
        padding: 16px;
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: center;
        flex: 1;
        height: 36px;
        position: relative;
        box-shadow: var(--bt-drop-shadow-box-shadow, 0px 0px 4px 0px rgba(30, 38, 49, 0.35));
    }
    .label {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-family: var(--button-text-small-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--button-text-small-font-size, 12px);
        line-height: var(--button-text-small-line-height, 16px);
        font-weight: var(--button-text-small-font-weight, 400);
        position: relative;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .buttons-fixed-width2 {
        background: #373737;
        border-radius: 0px 5px 5px 0px;
        padding: 16px;
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: center;
        flex: 1;
        height: 36px;
        position: relative;
        box-shadow: var(--bt-drop-shadow-box-shadow, 0px 0px 4px 0px rgba(30, 38, 49, 0.35));
    }
    .label-active {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-size: var(--button-text-small-semibold-font-size, 12px);
        line-height: var(--button-text-small-semibold-line-height, 16px);
        font-weight: var(--button-text-small-semibold-font-weight, 600);
        position: relative;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .info-action-card {
        border-radius: 5px;
        border-style: solid;
        border-color: rgba(166, 166, 166, 0.2);
        border-width: 1px;
        padding: 16px;
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        flex-shrink: 0;
        width: 100%;
        position: relative;
    }
    .frame-29004 {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .luan-j-shirt-cosmic-4-1 {
        border-radius: 5px;
        flex-shrink: 0;
        width: 40px;
        height: 53px;
        position: relative;
        object-fit: cover;
    }
    .frame-313652 {
        display: flex;
        flex-direction: column;
        gap: 6px;
        align-items: flex-start;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .brand-product-type {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: left;
        font-family: "OpenSans-Regular", sans-serif;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        position: relative;
        align-self: stretch;
        height: 16px;
    }
    .product-name {
        color: var(--text-text-primary, #ffffff);
        text-align: left;
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        position: relative;
        align-self: stretch;
        height: 23px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
`;

const StyledPanelHeaders = styled.div`
    .panel-headers,
    .panel-headers * {
        box-sizing: border-box;
    }
    .panel-headers {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313366 {
        background: var(--wrap-wrap-gray, rgba(166, 166, 166, 0.1));
        border-radius: 500px;
        padding: 4px 14px 4px 14px;
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .assistive-panel {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-size: var(--heading-3-font-size, 16px);
        line-height: var(--heading-3-line-height, 24px);
        font-weight: var(--heading-3-font-weight, 600);
        position: relative;
    }
    .frame-313365 {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: flex-end;
        flex-shrink: 0;
        width: 159px;
        position: relative;
    }
    .btn-xs-secondary {
        background: var(--buttons-button-secondary, #212121);
        border-radius: 5px;
        padding: 10px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        height: 30px;
        position: relative;
    }
    .button {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-family: var(--button-text-small-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--button-text-small-font-size, 12px);
        line-height: var(--button-text-small-line-height, 16px);
        font-weight: var(--button-text-small-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .seperator-middle {
        margin-top: -0.5px;
        border-style: solid;
        border-color: var(--border-border-tertiary, #373737);
        border-width: 0.5px 0 0 0;
        flex-shrink: 0;
        height: 0px;
        position: relative;
        transform-origin: 0 0;
        transform: rotate(0deg) scale(1, 1);
    }
`;

export default function ProductDetailsSection({ showLightboxPanelData = false }) {
    /* #region VARS */
    const { selectedBotag, botagAlgorithmnIsLoading, boimageIsLoading } = useContext(AppContext);
    const { isMobile, setShowProductGalleryLightbox } = React.useContext(LayoutContext);
    const [productDetails, setProductDetails] = useState({});
    const [isLoadingProductInfo, setIsLoadingProductInfo] = useState(false);
    /* #endregion */

    /* #region METHODS */
    const getProductInfo = async () => {
        const productObjID = selectedBotag?.data?.productObjID;

        if (!productObjID) return;
        const res = await Library.makePostRequest("getProductInfo", { productID: productObjID }, false, setIsLoadingProductInfo);

        if (res.status === 200) {
            setProductDetails(res?.data?.productInfo);
        }
    };
    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        getProductInfo();
    }, [selectedBotag]);
    /* #endregion */

    return (
        <StyledProductInfo className={`product-info ${isMobile ? "mobile-scroll" : ""}`}>
            {botagAlgorithmnIsLoading || boimageIsLoading || isLoadingProductInfo ? (
                <ProductInfoSkeleton />
            ) : (
                <>
                    {showLightboxPanelData && <PanelHeaders setShowProductGalleryLightbox={setShowProductGalleryLightbox} />}
                    <div className="info-action-card">
                        <div className="frame-29004">
                            <img className="luan-j-shirt-cosmic-4-1" src={selectedBotag?.image} alt="" />
                            <div className="frame-313652">
                                <div className="brand-product-type">{`${selectedBotag?.labelBrandName} / ${selectedBotag?.productType}`}</div>
                                <div className="product-name">{selectedBotag?.productName}</div>
                            </div>
                        </div>
                    </div>

                    <ProductInfoPanelFAQ details={productDetails} />
                </>
            )}
        </StyledProductInfo>
    );
}

const PanelHeaders = ({ setShowProductGalleryLightbox }) => {
    return (
        <StyledPanelHeaders className="w-100">
            <>
                <div className="panel-headers w-100">
                    <div className="frame-313366">
                        <div className="assistive-panel">Product details</div>
                    </div>
                    <div className="frame-313365">
                        <div
                            className="btn-xs-secondary cursor-pointer"
                            onClick={() => setShowProductGalleryLightbox({ isOpen: false, slides: [] })}>
                            <div className="button">Close</div>
                        </div>
                    </div>
                </div>
                <div className="seperator-middle" style={{ marginTop: "16px" }}></div>
            </>
        </StyledPanelHeaders>
    );
};
