import React, { useEffect } from "react";
import LayoutContext from "../../../contexts/LayoutContext";
import styled from "styled-components";
import * as Library from "../../../utils/Library";
import ImageBeacons from "./../BOImage/ImageBeacons";
import BOTag from "./../BOImage/BOTag";
import AppContext from "../../../contexts/AppContext";
import { UserContext } from "../../../contexts/UserContext";
import ShareLocationPopup from "../../common/dialogs/ShareLocationPopup";
import { fetchAddressFromCoordinates } from "../../../utils/LocationLib";
import { useNavigate } from "react-router-dom";
import logGA4Event from "../../../utils/firebase/analytics";
import { getCoordinates, getLocation, saveCoordinates, saveLocation } from "../../../utils/helperFunctions";

/* #region STYLED CSS */
const StyledPage = styled.div`
    .BOImageContainer {
        width: 100%;
        box-sizing: content-box;
        height: calc(100vh - 140px);
        position: relative;
    }
    .mobile-skeleton {
        height: 404px !important;
    }
    .BoimageCanvas {
        width: 100%;
        height: 100%;
        object-fit: contain;
        flex: 1 1 0;
        //border-radius: 8px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 9px;
        display: flex;
        overflow: hidden;
        position: relative;
        min-height: 350px;
    }

    .TagsContainerElement {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .BOTagContainer {
        align-items: center;
        background-color: #10141b;
        border-radius: 10px;
        bottom: 0;
        /* box-shadow: 0px 0px 0px 100vw #10141b80;  */
        display: flex;
        flex-direction: column;
        gap: 14px;
        left: 0;
        margin: auto;
        position: absolute;
        right: 0;
        top: 0;
        width: fit-content;
        height: fit-content;
    }
    .open-b-otag-button {
        background: var(--buttons-button-tertiary, #232832);
        border-radius: 50px;
        padding: 0px 16px;
        display: flex;
        flex-direction: row;
        gap: 19px;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        height: 48px;
        position: absolute;
        box-shadow: var(--dropshadow-pop-up2-box-shadow, 4px 4px 87.4px 0px rgba(0, 0, 0, 1));
        width: 116px;
        bottom: 10px;
        cursor: pointer;
    }
    .frame-29590 {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .group-3650 {
        flex-shrink: 0;
        width: 20px;
        height: 20px;
        position: relative;
        overflow: visible;
    }
    .button-botag {
        color: var(--dark-text-icons-and-buttons-white-600, #ffffff);
        text-align: center;
        font-family: var(--button-text-medium-regular-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--button-text-medium-regular-font-size, 14px);
        line-height: var(--button-text-medium-regular-line-height, 24px);
        font-weight: var(--button-text-medium-regular-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        right: 6px;
    }
    @keyframes pulse-white {
        0% {
            transform: scale(0.5);
        }
        100% {
            transform: scale(1);
        }
    }

    .BeaconContainer {
        background: #d1d1d1;
        border-radius: 50%;
    }

    .BotagBeacon {
        position: relative;
        background-color: #232832;
        height: 25px;
        width: 25px;
        border-radius: 50%;
        border: 5px solid black;
        display: flex;
        justify-content: center;
        align-items: center;
        animation: pulse-white 1.5s infinite;
    }

    .BotagBeacon .BotagBeaconInner {
        background-color: #ffffff;
        height: 20px;
        width: 20px;
        border-radius: 50%;
    }
`;
/* #endregion */

export default function BOImagePage({ setBrokenURL }) {
    /* #region VARS */
    const { showBOPicpageID, setShowHeader, setShowFooter, setShowRightSidebar, setShowRightUserLocationPanel, isMobile } =
        React.useContext(LayoutContext);
    const { setUserGPSCoordinates, setUserAddress, userAddress } = React.useContext(UserContext);
    const {
        boImageIsLoading,
        setBOImageIsLoading,
        selectedBotag,
        setSelectedBotag,
        setSelectedBotagStoreDetails,
        BOTagsList,
        setBOTagsList,
        setSelectedBOImageData,
        primaryImageData,
        setPrimaryImageData
    } = React.useContext(AppContext);

    const newUserAddress = getLocation();
    const newUserCoords = getCoordinates();

    const navigate = useNavigate();
    const [selectedBotagBeforeLocationPopup, setSelectedBotagBeforeLocationPopup] = React.useState(null);
    const [showLocationPopup, setShowLocationPopup] = React.useState(false);
    const [formFieldImageURL, setFormFieldImageURL] = React.useState("");

    /* #endregion */

    /* #region EFFECTS */
    useEffect(() => {
        if (!showBOPicpageID) return;
        setShowHeader(true);
        setShowFooter(true);
        setShowRightSidebar(true);
        setShowRightUserLocationPanel(true);

        getBOImageData(showBOPicpageID);
        setSelectedBotag(false);
        Library.changeUrlWithoutReloading(`/pic/${showBOPicpageID}`);
    }, [showBOPicpageID]);
    /* #endregion */

    /* #region METHODS */
    const getBOImageData = BOimageID => {
        logGA4Event("BOimage_Loading");
        //setSelectedBOImageData(null);
        Library.makePostRequest(
            "getBOpicData",
            { GTINourID: BOimageID },
            false,
            setBOImageIsLoading,
            onBOImageNotFound,
            undefined,
            false
        ).then(res => {
            if (res.data.status === 200 && res.data.data.length) {
                const BOImageData = res.data.data;
                //console.log(BOImageData);
                setFormFieldImageURL(BOImageData[0]?.images[0]?.URL);

                logGA4Event("BOimage_Shown");
                const BOTags = BOImageData?.map((tag, index) => {
                    return {
                        number: index + 1,
                        selected: false,
                        completed: true,
                        data: {
                            GTINbarcode: tag.barcode,
                            productObjID: tag.product._id,
                            color: tag.colorCode,
                            size: tag.size
                        },
                        GTINbarcode: tag.barcode,
                        selectedColor: tag.colorCode,
                        selectedSize: tag.size,

                        //new
                        images: tag?.images, // [{name, URL, _id}]
                        image: tag?.images[0]?.URL,
                        category: tag?.category,
                        productName: tag?.product?.name,
                        productType: tag?.product?.productType,
                        colors: tag?.availableColorSize?.color,
                        sizes: tag?.availableColorSize?.size,
                        labelBrandID: tag?.labelBrand?._id,
                        labelBrandName: tag?.labelBrand?.name,
                        productID: tag.product._id,
                        stockData: tag?.stockData
                    };
                });
                setBOTagsList(BOTags);
                const result = BOTags.map(item => ({
                    number: item.number,
                    image: item.image,
                    images: item.images
                }));

                setPrimaryImageData(result);
                //Removing BOtags to avoid confusion and circular JSON
                delete BOImageData.BOtags;
                const dynamicKey = "mainLogoURL";
                BOImageData.brandInfo = BOImageData.brandInfo || {};
                BOImageData.brandInfo.name = BOImageData[0]?.labelBrand?.name;
                BOImageData.brandInfo._id = BOImageData[0]?.labelBrand?._id;
                BOImageData.brandInfo.logos = BOImageData.brandInfo.logos || {};
                BOImageData.brandInfo.logos[dynamicKey] = BOImageData[0]?.labelBrand?.logos?.[dynamicKey];
                BOImageData.caption = BOImageData[0]?.product?.name;
                //BOImageData.description = BOImageData[0]?.product?.shortDescription;
                setSelectedBOImageData(BOImageData);
            } else {
                setBrokenURL(true);
            }
        });
    };

    const onBOImageNotFound = error => {
        console.log(error?.message?.toString());
        //Library.showErrorMessage("The BO image was not found. Please try again later.");
        navigate("/404");
    };

    const onBeaconClick = () => {
        logGA4Event("BOimage_NoBeacons");
        var tag = BOTagsList[0];
        const result = primaryImageData.find(item => item.number === tag.number);
        tag.image = result?.image;
        tag.images = result?.images;
        if (!newUserCoords?.latitude || !newUserCoords?.longitude || !newUserAddress) {
            setSelectedBotagBeforeLocationPopup(tag);
            setShowLocationPopup(true);
            return;
        }
        localStorage.setItem("isBotagOpen", true);
        if (tag?.stockData) {
            const updatedData = tag.stockData.map(item => {
                const { barcode, ...rest } = item; // Extract 'barcode' and keep the rest
                return { GTINbarcode: barcode, ...rest }; // Rename 'barcode' to 'GTINbarcode'
            });
            tag.stockData = updatedData;
        }
        setSelectedBotag(tag);
    };

    const onClickOutsideBotag = () => {
        if (selectedBotag) {
            setSelectedBotag(null);
            setSelectedBotagStoreDetails(null);
        }
    };

    const onLocationChanged = (location, isBotagOpen) => {
        const { latitude, longitude, city, country, postalCode } = location;

        setUserGPSCoordinates({ latitude, longitude });
        saveCoordinates({ latitude, longitude });

        fetchAddressFromCoordinates(latitude, longitude, city, country, postalCode).then(newUserAddress => {
            setUserAddress(newUserAddress);
            saveLocation(newUserAddress);

            if (selectedBotagBeforeLocationPopup && isBotagOpen) {
                localStorage.setItem("isBotagOpen", true);
                setSelectedBotag(selectedBotagBeforeLocationPopup);
                setSelectedBotagBeforeLocationPopup(null);
            }
        });
    };

    const openBotag = () => {
        if (selectedBotagBeforeLocationPopup) {
            setSelectedBotag(selectedBotagBeforeLocationPopup);
            setSelectedBotagBeforeLocationPopup(null);
        }
    };
    /* #endregion */

    return (
        <StyledPage onClick={onClickOutsideBotag} className="BOImagePage-container">
            <ShareLocationPopup
                visible={showLocationPopup}
                setVisible={setShowLocationPopup}
                onLocationSelected={onLocationChanged}
                openBotag={openBotag}
            />
            <div className={`BOImageContainer ${isMobile && boImageIsLoading ? "mobile-skeleton" : ""}`}>
                <div className="BoimageCanvas">
                    <ImageBeacons
                        src={formFieldImageURL}
                        BOTagsList={BOTagsList}
                        setBOTagsList={setBOTagsList}
                        isLoading={boImageIsLoading}
                        onBeaconClick={tag => {
                            onBeaconClick(tag);
                            logGA4Event("BOimage_NoBeacons");
                        }}
                        selectedBotag={selectedBotag}
                    />
                    {!selectedBotag && !boImageIsLoading && (
                        <div className="open-b-otag-button" onClick={onBeaconClick}>
                            <div className="frame-29590">
                                <div className="BeaconContainer">
                                    <div className="BotagBeacon">
                                        <div className="BotagBeaconInner"></div>
                                    </div>
                                </div>
                                <div className="button-botag">Open BOtag</div>
                            </div>
                        </div>
                    )}
                </div>

                {selectedBotag && (
                    <div className="BOTagContainer">
                        <BOTag />
                    </div>
                )}
            </div>
        </StyledPage>
    );
}
