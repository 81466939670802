import React, { useState } from "react";
import { DefaultPopupCard } from "./../DefaultPopupCard";
import UserRegistrationAndLogin from "./UserRegistrationAndLogin";
import ForgotPassword from "./ForgotPassword";
import LayoutContext from "../../../../contexts/LayoutContext";
import ResetLinkMailSent from "./ResetLinkMailSent";

export default function RegistrationLoginForgotPassword({ visible }) {
    /* #region VARS */
    const { showForgotPasswordPopup } = React.useContext(LayoutContext);
    const [isMailSent, setIsMailSent] = useState(false);
    /* #endregion VARS */
    return (
        <DefaultPopupCard visible={visible} mobileUsesFullScreen={true} zIndex={9}>
            {!showForgotPasswordPopup && !isMailSent && <UserRegistrationAndLogin />}
            {showForgotPasswordPopup && !isMailSent && <ForgotPassword setIsMailSent={setIsMailSent} />}
            {isMailSent && <ResetLinkMailSent />}
        </DefaultPopupCard>
    );
}
