import React, { useState } from "react";
const AppContext = React.createContext();

export const AppProvider = ({ children }) => {
    /* #region Main App */
    const [appPasswordActive, setAppPasswordActive] = useState(checkPasswordActive()); // Checks if needs app password
    const [appPasswordValidated, setAppPasswordValidated] = useState(false); // Starting app with password validation

    // BOImage States
    const [boImageIsLoading, setBOImageIsLoading] = useState(false); // Botag is loading data
    const [selectedBOImageData, setSelectedBOImageData] = useState(null); // Selected BOImage data

    // BOTag States
    const [BOTagsList, setBOTagsList] = useState([]); // List of botags
    const [botagAlgorithmnIsLoading, setBotagAlgorithmnIsLoading] = useState(false); // Botag algorithmn is loading data
    const [selectedBotag, setSelectedBotag] = useState(null); // Selected botag
    const [selectedBotagStoreDetails, setSelectedBotagStoreDetails] = useState(null); // Selected botag store details
    const [selectedBrandID, setSelectedBrandID] = useState("");
    const [primaryImageData, setPrimaryImageData] = useState("");
    const [selectedHomePageBotagId, setSelectedHomePageBotagId] = useState("");
    /* #endregion */

    /* #region Methods */
    function checkPasswordActive() {
        return parseInt(process.env.REACT_APP_SHOW_PASSWORD_SCREEN) !== 0;
    }
    /* #endregion */

    return (
        <AppContext.Provider
            value={{
                appPasswordActive,
                setAppPasswordActive,
                appPasswordValidated,
                setAppPasswordValidated,

                selectedBOImageData,
                setSelectedBOImageData,

                BOTagsList,
                setBOTagsList,
                boImageIsLoading,
                setBOImageIsLoading,
                botagAlgorithmnIsLoading,
                setBotagAlgorithmnIsLoading,
                selectedBotag,
                setSelectedBotag,
                selectedBotagStoreDetails,
                setSelectedBotagStoreDetails,
                selectedBrandID,
                setSelectedBrandID,
                primaryImageData,
                setPrimaryImageData,
                selectedHomePageBotagId,
                setSelectedHomePageBotagId
            }}>
            {children}
        </AppContext.Provider>
    );
};

export default AppContext;
