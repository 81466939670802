import styled from "styled-components";
import { DefaultPopupCard } from "./DefaultPopupCard";
import { useContext } from "react";
import LayoutContext from "../../../contexts/LayoutContext";
import StoreIcon from "../../../assets/images/store-icon.png";
import UserContext from "../../../contexts/UserContext";

const ReturnPopupStyles = styled.div`
    .claims,
    .claims * {
        box-sizing: border-box;
    }
    .claims {
        background: var(--surface-surface-secondary, #121212);
        border-radius: 10px;
        padding: 24px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        position: relative;
        box-shadow: var(--dropshadow-pop-up2-box-shadow, 4px 4px 87.4px 0px rgba(0, 0, 0, 1));
        overflow: hidden;
    }
    .frame-313622 {
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313508 {
        display: flex;
        flex-direction: row;
        gap: 40px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313608 {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: flex-start;
        justify-content: center;
        flex: 1;
        position: relative;
    }
    .return {
        color: var(--text-text-primary, #ffffff);
        text-align: left;
        font-family: "OpenSans-SemiBold", sans-serif;
        font-size: 22px;
        line-height: 28px;
        font-weight: 600;
        position: relative;
        flex: 1;
    }
    .btn-xs-secondary {
        background: var(--buttons-button-secondary, #212121);
        border-radius: 5px;
        padding: 0px 10px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        height: 30px;
        position: relative;
    }
    .button {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-family: var(--button-text-small-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--button-text-small-font-size, 12px);
        line-height: var(--button-text-small-line-height, 16px);
        font-weight: var(--button-text-small-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .frame-313700 {
        flex-shrink: 0;
        width: 405px;
        height: 188px;
        position: relative;
        margin-bottom: 20px;
    }
    .frame-313668 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        width: 404px;
        position: absolute;
        left: 0.5px;
        top: 0.84px;
    }
    .large-item-picker {
        border-radius: 5px;
        border-style: solid;
        border-color: var(--border-border-primary, rgba(166, 166, 166, 0.2));
        border-width: 1px;
        padding: 16px 0px 16px 0px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313477 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313449 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313447 {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-29715 {
        padding: 0px 16px 0px 16px;
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .luan-j-shirt-cosmic-4-3 {
        border-radius: 5px;
        flex-shrink: 0;
        width: 44.83px;
        height: 60px;
        position: relative;
        object-fit: cover;
    }
    .frame-29710 {
        display: flex;
        flex-direction: column;
        gap: 6px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex: 1;
        position: relative;
    }
    .brand-product-type {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: left;
        font-family: var(--caption-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--caption-font-size, 12px);
        line-height: var(--caption-line-height, 20px);
        font-weight: var(--caption-font-weight, 400);
        position: relative;
        align-self: stretch;
    }
    .product-name {
        color: var(--text-text-primary, #ffffff);
        text-align: left;
        font-family: "OpenSans-SemiBold", sans-serif;
        font-size: 14px;
        line-height: 14px;
        font-weight: 600;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .brand-product-type2 {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: left;
        font-family: var(--caption-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--caption-font-size, 12px);
        line-height: var(--caption-line-height, 20px);
        font-weight: var(--caption-font-weight, 400);
        position: relative;
    }
    .frame-29538 {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .brand-product-type3 {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .brand-product-type-3-span {
        color: var(--text-text-primary, #ffffff);
        font-family: "OpenSans-SemiBold", sans-serif;
        font-size: 14px;
        line-height: 20px;
        font-weight: 600;
    }
    .brand-product-type-3-span2 {
        color: var(--text-text-primary, #ffffff);
        font-family: var(--caption-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--caption-font-size, 12px);
        line-height: var(--caption-line-height, 20px);
        font-weight: var(--caption-font-weight, 400);
    }
    .brand-product-type-3-span3 {
        color: var(--text-text-primary, #ffffff);
        font-family: "OpenSans-Regular", sans-serif;
        font-size: 10px;
        line-height: 20px;
        font-weight: 400;
    }
    .frame-313454 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313448 {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313444 {
        padding: 0px 16px 0px 16px;
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313443 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .brand-product-type4 {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: left;
        font-family: "OpenSans-Regular", sans-serif;
        font-size: 12px;
        line-height: 20px;
        font-weight: 400;
        position: relative;
        width: 37.04px;
    }
    .line-26 {
        margin-top: -0.5px;
        border-style: solid;
        border-color: var(--border-border-tertiary, #373737);
        border-width: 0.5px 0 0 0;
        flex: 1;
        height: 0px;
        position: relative;
        transform-origin: 0 0;
        transform: rotate(0.001deg) scale(1, 1);
    }
    .frame-313445 {
        padding: 0px 16px 0px 16px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-29721 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: flex-end;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-29719 {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
        justify-content: flex-start;
        flex: 1;
        position: relative;
    }
    .frame-29717 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-29716 {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: center;
        justify-content: flex-end;
        flex: 1;
        position: relative;
    }
    .group-3626 {
        flex-shrink: 0;
        width: 45px;
        height: 45px;
        position: relative;
        overflow: visible;
    }
    .frame-313440 {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: flex-end;
        justify-content: center;
        flex: 1;
        position: relative;
    }
    .frame-313441 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-end;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .product-name2 {
        color: var(--text-text-primary, #ffffff);
        text-align: left;
        font-family: "OpenSans-SemiBold", sans-serif;
        font-size: 14px;
        line-height: 20px;
        font-weight: 600;
        position: relative;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .open {
        color: var(--text-text-success, #69c23f);
        text-align: left;
        font-family: var(--regular-text-xxs-font-family, "OpenSans-SemiBold", sans-serif);
        font-size: var(--regular-text-xxs-font-size, 10px);
        font-weight: var(--regular-text-xxs-font-weight, 600);
        position: relative;
        width: 26.65px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .closed {
        color: #ff0000;
        text-align: left;
        font-family: var(--regular-text-xxs-font-family, "OpenSans-SemiBold", sans-serif);
        font-size: var(--regular-text-xxs-font-size, 10px);
        font-weight: var(--regular-text-xxs-font-weight, 600);
        position: relative;
        width: 26.65px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .product-name3 {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: left;
        font-family: var(--caption-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--caption-font-size, 12px);
        line-height: var(--caption-line-height, 20px);
        font-weight: var(--caption-font-weight, 400);
        position: relative;
        align-self: stretch;
        height: 16px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .frame-313619 {
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313617 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313491 {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .important-information-about-returns {
        color: var(--text-text-primary, #ffffff);
        text-align: left;
        font-family: var(--heading-4-font-family, "OpenSans-SemiBold", sans-serif);
        font-size: var(--heading-4-font-size, 14px);
        line-height: var(--heading-4-line-height, 20px);
        font-weight: var(--heading-4-font-weight, 600);
        position: relative;
        flex: 1;
    }
    .to-receive-a-refund-please-return-the-item-to-the-store-who-provided-it-the-store-will-assess-the-item-s-condition-and-notify-us-of-its-eligibility-for-a-return-if-the-item-qualifies-we-ll-initiate-the-refund-process-which-may-take-up-to-three-business-days-read-our-full-terms-for-returns-here {
        text-align: left;
        font-family: var(--subtitle-2-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 14px);
        line-height: var(--subtitle-2-line-height, 24px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
        align-self: stretch;
    }
    .to-receive-a-refund-please-return-the-item-to-the-store-who-provided-it-the-store-will-assess-the-item-s-condition-and-notify-us-of-its-eligibility-for-a-return-if-the-item-qualifies-we-ll-initiate-the-refund-process-which-may-take-up-to-three-business-days-read-our-full-terms-for-returns-here-span {
        color: var(--text-text-secondary, #8c8c8c);
        font-family: var(--subtitle-2-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 14px);
        line-height: var(--subtitle-2-line-height, 24px);
        font-weight: var(--subtitle-2-font-weight, 400);
    }
    .to-receive-a-refund-please-return-the-item-to-the-store-who-provided-it-the-store-will-assess-the-item-s-condition-and-notify-us-of-its-eligibility-for-a-return-if-the-item-qualifies-we-ll-initiate-the-refund-process-which-may-take-up-to-three-business-days-read-our-full-terms-for-returns-here-span2 {
        color: #f7665f;
        font-family: var(--subtitle-2-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 14px);
        line-height: var(--subtitle-2-line-height, 24px);
        font-weight: var(--subtitle-2-font-weight, 400);
        cursor: pointer;
    }
    .to-receive-a-refund-please-return-the-item-to-the-store-who-provided-it-the-store-will-assess-the-item-s-condition-and-notify-us-of-its-eligibility-for-a-return-if-the-item-qualifies-we-ll-initiate-the-refund-process-which-may-take-up-to-three-business-days-read-our-full-terms-for-returns-here-span3 {
        color: var(--text-text-secondary, #8c8c8c);
        font-family: var(--subtitle-2-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 14px);
        line-height: var(--subtitle-2-line-height, 24px);
        font-weight: var(--subtitle-2-font-weight, 400);
    }
    .frame-313629 {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .btn-md-solid {
        background: var(--buttons-button-secondary, #212121);
        border-radius: 5px;
        padding: 0px 44px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex: 1;
        height: 44px;
        position: relative;
        box-shadow: var(--bt-drop-shadow-box-shadow, 0px 0px 4px 0px rgba(30, 38, 49, 0.35));
    }
    .frame-313497 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313349 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .button2 {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-family: var(--button-text-medium-regular-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--button-text-medium-regular-font-size, 14px);
        line-height: var(--button-text-medium-regular-line-height, 24px);
        font-weight: var(--button-text-medium-regular-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .btn-md-solid2 {
        background: var(--buttons-button-primary, #f7665f);
        border-radius: 5px;
        padding: 0px 44px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex: 1;
        height: 44px;
        position: relative;
        box-shadow: var(--bt-drop-shadow-box-shadow, 0px 0px 4px 0px rgba(30, 38, 49, 0.35));
    }
`;
const ReturnPopup = () => {
    const { setShowReturnPopup, setShowReturnAndFeedBackPopup, setShowReturnItemPopup, setShowPolicyPopup } = useContext(LayoutContext);
    const { productInfo } = useContext(UserContext);
    const timeStamp = `${productInfo?.storeInfo?.openHours?.Weekdays?.hourStart}-${productInfo?.storeInfo?.openHours?.Weekdays?.hourEnd}`;
    return (
        <DefaultPopupCard>
            <ReturnPopupStyles>
                <div className="frame-313622">
                    <div className="frame-313508">
                        <div className="frame-313608">
                            <div className="return">Return</div>
                        </div>
                        <div className="btn-xs-secondary" style={{ cursor: "pointer" }} onClick={() => setShowReturnPopup(false)}>
                            <div className="button">Cancel</div>
                        </div>
                    </div>
                    <div className="frame-313700">
                        <div className="frame-313668">
                            <div className="large-item-picker">
                                <div className="frame-313477">
                                    <div className="frame-313449">
                                        <div className="frame-313449">
                                            <div className="frame-313447">
                                                <div className="frame-29715">
                                                    <img
                                                        classNameName="luan-j-shirt-cosmic-4-3"
                                                        src={productInfo?.imageURL || ""}
                                                        alt=""
                                                        width="56px"
                                                        style={{ borderRadius: "3px" }}
                                                    />

                                                    <div className="frame-29710">
                                                        <div className="brand-product-type">
                                                            {productInfo?.brandName || ""} / {productInfo?.productType || ""}
                                                        </div>
                                                        <div className="product-name">{productInfo?.productName || ""}</div>
                                                        <div className="brand-product-type2">
                                                            {productInfo?.color || ""} - {productInfo?.size || ""} -{" "}
                                                            {productInfo?.quantity || ""}
                                                        </div>
                                                    </div>
                                                    <div className="frame-29538">
                                                        <div className="brand-product-type3">
                                                            <span>
                                                                <span className="brand-product-type-3-span">
                                                                    {productInfo?.itemPrice.toLocaleString().replaceAll(",", ".") || ""}{" "}
                                                                </span>
                                                                <span className="brand-product-type-3-span2"></span>
                                                                <span className="brand-product-type-3-span3">NOK</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="frame-313454">
                                                <div className="frame-313448">
                                                    <div className="frame-313444">
                                                        <div className="frame-313443">
                                                            <div className="brand-product-type4">STORE</div>
                                                            <div className="line-26"></div>
                                                        </div>
                                                    </div>
                                                    <div className="frame-313445">
                                                        <div className="frame-29721">
                                                            <div className="frame-29719">
                                                                <div className="frame-29717">
                                                                    <div className="frame-29716">
                                                                        <img className="group-3626" src={StoreIcon} alt="" />
                                                                        <div className="frame-313440">
                                                                            <div className="frame-313441">
                                                                                <div className="product-name2">
                                                                                    {productInfo?.storeName || ""}
                                                                                </div>
                                                                                <div className={timeStamp ? "open" : "closed"}>
                                                                                    {timeStamp ? "Open" : "Closed"}
                                                                                </div>
                                                                            </div>
                                                                            <div className="product-name3">
                                                                                {productInfo?.storeAddress || ""}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="frame-313619">
                    <div className="frame-313617">
                        <div className="frame-313491">
                            <div className="important-information-about-returns">Important information about returns:</div>
                        </div>
                        <div className="to-receive-a-refund-please-return-the-item-to-the-store-who-provided-it-the-store-will-assess-the-item-s-condition-and-notify-us-of-its-eligibility-for-a-return-if-the-item-qualifies-we-ll-initiate-the-refund-process-which-may-take-up-to-three-business-days-read-our-full-terms-for-returns-here">
                            <span>
                                <span className="to-receive-a-refund-please-return-the-item-to-the-store-who-provided-it-the-store-will-assess-the-item-s-condition-and-notify-us-of-its-eligibility-for-a-return-if-the-item-qualifies-we-ll-initiate-the-refund-process-which-may-take-up-to-three-business-days-read-our-full-terms-for-returns-here-span">
                                    To receive a refund, please return the item to the store who provided it. The store will assess the
                                    item&#039;s condition and notify us of its eligibility for a return. If the item qualifies, we&#039;ll
                                    initiate the refund process, which may take up to three business days. Read our full terms for returns
                                </span>
                                <span
                                    onClick={() => setShowPolicyPopup("returns")}
                                    className="to-receive-a-refund-please-return-the-item-to-the-store-who-provided-it-the-store-will-assess-the-item-s-condition-and-notify-us-of-its-eligibility-for-a-return-if-the-item-qualifies-we-ll-initiate-the-refund-process-which-may-take-up-to-three-business-days-read-our-full-terms-for-returns-here-span2">
                                    {" "}
                                    here
                                </span>
                                <span className="to-receive-a-refund-please-return-the-item-to-the-store-who-provided-it-the-store-will-assess-the-item-s-condition-and-notify-us-of-its-eligibility-for-a-return-if-the-item-qualifies-we-ll-initiate-the-refund-process-which-may-take-up-to-three-business-days-read-our-full-terms-for-returns-here-span3">
                                    .
                                </span>
                            </span>
                        </div>
                    </div>
                    <div className="frame-313629">
                        <div
                            className="btn-md-solid"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                setShowReturnAndFeedBackPopup(true);
                                setShowReturnPopup(false);
                            }}>
                            <div className="frame-313497">
                                <div className="frame-313349">
                                    <div className="button2">Back</div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="btn-md-solid2"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                setShowReturnItemPopup(true);
                                setShowReturnPopup(false);
                            }}>
                            <div className="frame-313497">
                                <div className="frame-313349">
                                    <div className="button2">Ok, continue</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ReturnPopupStyles>
        </DefaultPopupCard>
    );
};
export default ReturnPopup;
