import React from "react";
import { useEffect } from "react";
import Faq from "react-faq-component";
import styled from "styled-components";

const StyledFaq = styled.div`
    & {
        width: 100%;
    }
    .icon-wrapper {
        color: white !important;
        top: 12px !important;
        right: 16px !important;
    }
    .faq-row-wrapper .faq-body {
        //max-height: 695px;
        //overflow: auto;
    }
    .faq-body::-webkit-scrollbar {
        width: 0px !important;
    }
    .faq-row-wrapper {
        background-color: transparent !important;
    }
    .faq-body {
        background-color: transparent !important;
    }
    .faq-row {
        border: 1px solid #373737 !important;
        /*margin: 10px 0px;*/
        margin: 0px 0px 16px;
        padding: 10px 16px !important;
        width: 100%;
        border-radius: 5px;
    }

    .htmlStyles,
    .htmlStyles li,
    .htmlStyles p,
    .htmlStyles span,
    .htmlStyles svg,
    .htmlStyles div,
    .htmlStyles a,
    .htmlStyles ul,
    .htmlStyles ol,
    .htmlStyles img,
    .htmlStyles button,
    .htmlStyles input,
    .htmlStyles textarea,
    .htmlStyles label,
    .htmlStyles tr,
    .htmlStyles th,
    .htmlStyles td,
    .htmlStyles caption {
        color: #8c8c8c !important;

        font-family: "Open Sans" !important;
        font-size: 12px !important;
        font-style: normal !important;
    }

    .htmlStyles svg path,
    .htmlStyles svg rect,
    .htmlStyles svg circle,
    .htmlStyles svg line,
    .htmlStyles svg polyline,
    .htmlStyles svg polygon,
    .htmlStyles svg ellipse,
    .htmlStyles svg text,
    .htmlStyles svg tspan,
    .htmlStyles svg textPath,
    .htmlStyles svg textArea {
        fill: #8c8c8c;
    }

    .htmlStyles .transparency-label {
        color: #fff !important;
    }

    .row-title {
        color: white !important;
        display: flex !important;
        align-items: center !important;
    }
    .row-title-text {
        font-size: 12px !important;
        font-weight: 400;
    }
    .arrow-image svg path {
        color: white !important;
    }
    .row-content-text {
        color: #8c8c8c !important;
        font-size: 12px !important;
        font-weight: 400 !important;
    }
    .icon-wrapper {
        color: white !important;
        top: 12px !important;
        right: 16px !important;
    }

    ul {
        margin-bottom: 0;
        margin-top: 0;
    }
`;

const ProductInfoPanelFAQ = ({ details }) => {
    /* #region METHODS */
    const addTitleToHtml = (title, html) => {
        return `<span style="font-weight: 400; color: white !important; vertical-align: top;">${title}</span>${html}`;
    };
    /* #endregion */

    /* #region VARS */
    const config = {
        animate: true,
        arrowIcon: "0",
        openOnload: 0,
        expandIcon: <i className="fa-solid fa-plus" style={{ fontSize: "12px" }}></i>,
        collapseIcon: <i className="fa-solid fa-minus" style={{ fontSize: "12px" }}></i>
    };

    const data = {
        rows: [
            {
                title: "Product description",
                content: (
                    <div className="htmlStyles">
                        <div style={{ paddingBottom: "16px" }} dangerouslySetInnerHTML={{ __html: details?.description }}></div>
                    </div>
                )
            },
            {
                title: "Transparency & Traceability",
                content: (
                    <div className="htmlStyles">
                        <div style={{ paddingBottom: "16px" }} dangerouslySetInnerHTML={{ __html: details?.ecoStatement }}></div>
                        <div style={{ paddingBottom: "16px" }}>
                            <span className="transparency-label">Country of origin:</span>{" "}
                            <span
                                style={{ fontSize: "12px", fontWeight: 400, color: "white" }}
                                dangerouslySetInnerHTML={{ __html: details?.countryOfOrigin || "-" }}></span>
                        </div>
                        <div style={{ paddingBottom: "16px" }}>
                            <span className="transparency-label">Factory:</span> {details?.factory || "-"}
                        </div>
                    </div>
                )
            },
            {
                title: "Composition & care",
                content: (
                    <div className="htmlStyles">
                        <div
                            style={{ paddingBottom: "16px", paddingTop: "12px" }}
                            dangerouslySetInnerHTML={{
                                __html: addTitleToHtml("Composition: ", details?.composition)
                            }}></div>
                        <div
                            style={{ paddingBottom: "16px" }}
                            dangerouslySetInnerHTML={{ __html: addTitleToHtml("Care: ", details?.maintenance) }}></div>
                    </div>
                )
            }
        ]
    };

    /* #endregion */

    /* #region EFFECTS */

    /* #endregion */

    return (
        <StyledFaq>
            <Faq data={data} config={config} style />
        </StyledFaq>
    );
};

export default ProductInfoPanelFAQ;
