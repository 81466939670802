import React from "react";
import Lightbox from "yet-another-react-lightbox";
//import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Inline from "yet-another-react-lightbox/plugins/inline";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import styled from "styled-components";

const StyledPage = styled.div`
    .yarl__toolbar {
        display: none;
    }
`;

export default function LightBoxWrapper({ open, close, slides, index, setOnIndexChange, isMobile }) {
    const onImageIndexChanged = data => {
        const newIndex = data?.index;
        if (newIndex !== index) setOnIndexChange && setOnIndexChange(newIndex);
    };

    const width = "100%"; //isMobile ? "100%" : "calc(100% - 420px)";
    const height = "100dvh"; //isMobile ? "530px" : "100%";

    const handleBackdropClick = event => {
        if (event.target.classList.contains("yarl__slide") || event.target.classList.contains("yarl__slide_wrapper")) {
            close();
        }
    };

    return (
        <StyledPage style={{ width: width, height: height, zIndex: "99999999" }} onClick={handleBackdropClick}>
            <Lightbox
                open={open}
                close={close}
                slides={slides}
                index={index}
                plugins={[
                    //Thumbnails,
                    Zoom,
                    Inline
                ]}
                carousel={{
                    finite: true,
                    preload: slides?.length,
                    padding: isMobile ? "32px" : "150px"
                }}
                /* thumbnails={{
                    position: "bottom",
                    width: 55,
                    height: 80,
                    border: 2,
                    borderRadius: 5,
                    padding: 4,
                    gap: 16,
                    showToggle: false,
                    imageFit: "contain"
                }}*/
                zoom={{
                    scrollToZoom: true,
                    maxZoomPixelRatio: 2
                }}
                render={{
                    iconZoomIn: () => null,
                    iconZoomOut: () => null
                }}
                on={{
                    view: onImageIndexChanged
                }}
            />
        </StyledPage>
    );
}
