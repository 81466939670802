import React, { useEffect } from "react";
import LightBoxWrapper from "../../../common/dialogs/LightBoxWrapper";
import LayoutContext from "../../../../contexts/LayoutContext";
import styled from "styled-components";
import CloseIcon from "../../../../assets/images/close-btn.svg";

const StyledPage = styled.div`
    & {
        position: absolute;
        top: 0;
        left: 0;
        right: 240px;
        bottom: 0;
        width: 100%;
        z-index: 999999999;
    }

    .yarl__container {
        background: rgba(0, 0, 0, 0.8);
        backdrop-filter: blur(5px);
    }

    .yarl__slide {
        //margin: 84px 84px 200px 84px;
    }

    .BOTagLightBox.mobile .yarl__slide {
        //margin: 130px 84px 200px 84px;
    }

    .yarl__thumbnails_thumbnail_placeholder {
        display: none;
    }

    .yarl__thumbnails_thumbnail_fadeout {
        animation: none !important;
    }

    .yarl__thumbnails_thumbnail_fadein {
        animation: none !important;
    }

    .yarl__slide_image {
        border-radius: 10px;
    }
`;

const StyledThumbnails = styled.div`
    &.frame-313648,
    &.frame-313648 * {
        box-sizing: border-box;
    }
    &.frame-313648 {
        display: flex;
        flex-direction: column;
        gap: 40px;
        row-gap: 0px;
        align-items: center;
        justify-content: flex-start;
        position: absolute;
        bottom: 30px;
        width: calc(100% - 420px);
    }
    .luan-j-shirt-cosmic-4-1 {
        border-radius: 10px;
        flex-shrink: 0;
        width: 551px;
        height: 793.44px;
        position: relative;
        object-fit: cover;
    }
    .frame-313647 {
        display: flex;
        flex-direction: row;
        gap: 16px;
        row-gap: 0px;
        align-items: flex-start;
        justify-content: flex-start;
        flex-shrink: 0;
        position: relative;
    }
    .thumb-selected {
        border-radius: 5px;
        border-style: solid;
        border-color: #ffffff;
        border-width: 1px;
        flex-shrink: 0;
        width: 64px;
        height: 89px;
        position: absolute;
        left: -4.5px;
        top: -4.44px;
    }
    .luan-j-shirt-cosmic-4-2 {
        border-radius: 5px;
        border-style: solid;
        border-color: #ffffff;
        border-width: 1px;
        flex-shrink: 0;
        width: 55px;
        height: 79.29px;
        position: relative;
        object-fit: cover;
    }

    .yarl__toolbar {
        display: none;
    }
`;

export default function BOTagLightBox() {
    const { showProductGalleryLightbox, setShowProductGalleryLightbox, isMobile } = React.useContext(LayoutContext);
    const showProductDetails = showProductGalleryLightbox?.showProductDetails;

    return (
        <StyledPage className="BOTagLightBox">
            {showProductGalleryLightbox?.isOpen && (
                <div className={`BOTagLightBox w-100 h-100 flex justify-content-center ${isMobile ? "mobile" : "desktop"}`}>
                    <LightBoxWrapper
                        open={showProductGalleryLightbox.isOpen}
                        close={() => setShowProductGalleryLightbox({ isOpen: false, slides: [], showProductDetails: true })}
                        slides={showProductGalleryLightbox.slides}
                        index={showProductGalleryLightbox?.selectedIndex}
                        isMobile={isMobile}
                        setOnIndexChange={index => {
                            setShowProductGalleryLightbox({
                                isOpen: true,
                                slides: showProductGalleryLightbox.slides,
                                selectedIndex: index,
                                showProductDetails: showProductDetails
                            });
                        }}
                    />
                    {(isMobile || showProductDetails === false) && (
                        <CloseButton
                            onClick={() => setShowProductGalleryLightbox({ isOpen: false, slides: [], showProductDetails: true })}
                        />
                    )}

                    <Thumbnails
                        slides={showProductGalleryLightbox.slides}
                        setSelected={index => {
                            setShowProductGalleryLightbox({
                                isOpen: true,
                                slides: showProductGalleryLightbox.slides,
                                selectedIndex: index,
                                showProductDetails: showProductDetails
                            });
                        }}
                        selectedIndex={showProductGalleryLightbox.selectedIndex}
                        isMobile={isMobile}
                    />
                </div>
            )}
        </StyledPage>
    );
}

const Thumbnails = ({ slides, setSelected, selectedIndex }) => {
    return (
        <StyledThumbnails
            className="frame-313648"
            style={{
                width: "auto",
                zIndex: "99999999"
                //isMobile ? "100%" : "calc(100% - 420px)",
                //bottom: isMobile ? "unset" : "83px",
                //top: isMobile ? "400px" : "unset"
            }}>
            <div className="frame-313647">
                <div className="luan-j-shirt-cosmic-4-6"></div>
                {slides.map((slide, index) => (
                    <div
                        className="thumbnail cursor-pointer"
                        key={index}
                        style={{ position: "relative" }}
                        onClick={() => setSelected(index)}>
                        <img
                            className="luan-j-shirt-cosmic-4-2"
                            src={slide.src}
                            alt=""
                            style={{ opacity: selectedIndex === index ? 1 : 0.5 }}
                        />
                        {selectedIndex === index && <div className="thumb-selected"></div>}
                    </div>
                ))}
            </div>
        </StyledThumbnails>
    );
};

const CloseButton = ({ onClick }) => {
    const StyledCloseButton = {
        borderRadius: "5px",
        display: "flex",
        flexDirection: "row",
        gap: "0px",
        alignItems: "center",
        justifyContent: "center",
        width: "30px",
        height: "30px",
        position: "absolute",
        overflow: "visible",
        top: "16px",
        right: "16px",
        cursor: "pointer"
    };

    return <img className="frame-29057" src={CloseIcon} style={StyledCloseButton} onClick={onClick} alt="" />;
};
