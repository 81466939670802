import React, { useState } from "react";
import styled from "styled-components";
import LayoutContext from "../../../../contexts/LayoutContext";
import { ReactComponent as Frame290570 } from "../../../../assets/images/close-btn.svg";
import ArrowBack from "../../../../assets/images/ArrowBackcher.svg";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import axios from "axios";
import ButtonLoader from "../../loaders/ButtonLoader";
import * as Library from "../../../../utils/Library";
import StandardsContext from "../../../../contexts/StandardsContext";
import UserContext from "../../../../contexts/UserContext";

/* #region STYLED CSS */
const StyledPage = styled.div`
    &.frame-29643,
    &.frame-29643 * {
        box-sizing: border-box;
    }
    &.frame-29643 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313565 {
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313366 {
        display: flex;
        flex-direction: column;
        gap: 40px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313591 {
        display: flex;
        flex-direction: row;
        gap: 40px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313592 {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: flex-start;
        justify-content: center;
        flex: 1;
        position: relative;
    }
    .back-btn-sm {
        background: var(--buttons-button-secondary, #212121);
        border-radius: 5px;
        padding: 4px 8px 4px 8px;
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 30px;
        height: 30px;
        position: relative;
        cursor: pointer;
    }
    .chevron-left {
        flex-shrink: 0;
        width: 20px;
        height: 20px;
        position: relative;
        overflow: visible;
    }
    .payment-method {
        color: var(--text-text-primary, #ffffff);
        text-align: left;
        font-family: "OpenSans-SemiBold", sans-serif;
        font-size: 22px;
        line-height: 28px;
        font-weight: 600;
        position: relative;
        flex: 1;
    }
    .frame-29057 {
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 30px;
        height: 30px;
        position: relative;
        overflow: visible;
    }
    .add-a-card-now-so-that-you-save-time-during-checkout-you-can-also-add-and-save-more-cards-in-settings-later {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: left;
        font-family: var(--subtitle-2-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 14px);
        line-height: var(--subtitle-2-line-height, 24px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
        align-self: stretch;
    }
    .frame-313567 {
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313566 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .add-payment-method {
        color: var(--text-text-primary, #ffffff);
        text-align: left;
        font-family: var(--heading-4-font-family, "OpenSans-SemiBold", sans-serif);
        font-size: var(--heading-4-font-size, 14px);
        line-height: var(--heading-4-line-height, 20px);
        font-weight: var(--heading-4-font-weight, 600);
        position: relative;
        align-self: stretch;
    }
    .frame-313373 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .input-field {
        background: var(--surface-surface-secondary, #121212);
        border-radius: 5px;
        border-style: solid;
        border-color: var(--border-border-primary, rgba(166, 166, 166, 0.2));
        border-width: 1px;
        padding: 16px;
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
        box-shadow: var(--dropdown-input-field-box-shadow, 0px 0px 15px 0px rgba(0, 0, 0, 0.25));
    }
    .label {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: left;
        font-family: var(--label-medium-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--label-medium-font-size, 14px);
        line-height: var(--label-medium-line-height, 14px);
        font-weight: var(--label-medium-font-weight, 400);
        position: relative;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .input-field-credit-card {
        background: var(--surface-surface-secondary, #121212);
        border-radius: 5px;
        border-style: solid;
        border-color: var(--border-border-primary, rgba(166, 166, 166, 0.2));
        border-width: 1px;
        padding: 8px 16px 8px 16px;
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
        box-shadow: var(--dropdown-input-field-box-shadow, 0px 0px 15px 0px rgba(0, 0, 0, 0.25));
    }
    .frame-29438 {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        height: 30px;
        position: relative;
    }
    .credit-card-02 {
        flex-shrink: 0;
        width: 24px;
        height: 24px;
        position: relative;
        overflow: visible;
    }
    .card-number {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: left;
        font-family: var(--label-medium-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--label-medium-font-size, 14px);
        line-height: var(--label-medium-line-height, 14px);
        font-weight: var(--label-medium-font-weight, 400);
        position: relative;
        flex: 1;
    }
    .mm-yy {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: left;
        font-family: var(--subtitle-2-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 14px);
        line-height: var(--subtitle-2-line-height, 24px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
    }
    .cvc {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: left;
        font-family: var(--subtitle-2-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 14px);
        line-height: var(--subtitle-2-line-height, 24px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
    }
    .frame-313572 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313387 {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .separator {
        display: flex;
        flex-direction: row;
        gap: 6px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .line-27 {
        margin-top: -0.5px;
        border-style: solid;
        border-color: var(--border-border-tertiary, #373737);
        border-width: 0.5px 0 0 0;
        flex: 1;
        height: 0px;
        position: relative;
        transform-origin: 0 0;
        transform: rotate(0.001deg) scale(1, 1);
    }
    .text-label-here {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: center;
        font-family: var(--caption-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--caption-font-size, 12px);
        line-height: var(--caption-line-height, 20px);
        font-weight: var(--caption-font-weight, 400);
        position: relative;
    }
    .line-26 {
        margin-top: -0.5px;
        border-style: solid;
        border-color: var(--border-border-tertiary, #373737);
        border-width: 0.5px 0 0 0;
        flex: 1;
        height: 0px;
        position: relative;
        transform-origin: 0 0;
        transform: rotate(0.001deg) scale(1, 1);
    }
    .frame-29637 {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .btn-md-solid {
        background: var(--buttons-button-secondary, #212121);
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex: 1;
        height: 44px;
        position: relative;
        box-shadow: var(--bt-drop-shadow-box-shadow, 0px 0px 4px 0px rgba(30, 38, 49, 0.35));
        cursor: pointer;
    }
    .frame-313497 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313349 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .button {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-family: var(--button-text-medium-regular-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--button-text-medium-regular-font-size, 14px);
        line-height: var(--button-text-medium-regular-line-height, 24px);
        font-weight: var(--button-text-medium-regular-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .btn-md-solid2 {
        background: var(--buttons-button-primary, #f7665f);
        border-radius: 5px;

        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex: 1;
        height: 44px;
        position: relative;
        box-shadow: var(--bt-drop-shadow-box-shadow, 0px 0px 4px 0px rgba(30, 38, 49, 0.35));
        cursor: pointer;
    }
    .floating-input {
        position: relative;
        width: 100%;
    }
    .customer-info-inputs {
        background-color: initial;
        border: 1px solid #373737;
        border-radius: 5px;
        color: #fff;
        display: inline-block;
        height: 0px;
        outline: none;
        padding: 28px 0px 16px 16px;
        width: 100%;
        box-sizing: border-box;
    }
    .floating-input input:focus + label,
    .floating-input input:not(:placeholder-shown) + label {
        color: #8c8c8c;
        font-size: 8px;
        font-weight: 600;
        left: 18px;
        top: 5px;
    }
    .floating-input label {
        color: #8c8c8c;
        font-size: 13px;
        font-weight: 400;
        left: 22px;
        pointer-events: none;
        position: absolute;
        top: 13px;
        transition: all 0.2s ease;
    }
    .element {
        border: 1px solid #373737 !important;
        padding: 16px;
        border-radius: 5px;
        font-size: 14px !important;
        font-weight: 400 !important;
        color: red !important;
    }
`;
/* #endregion  STYLED CSS */

export default function CardInformation() {
    /* #region VARS */
    const { setShowUserLoginRegisterPopup, setShowPersonalInformation, setShowCardInformation } = React.useContext(LayoutContext);
    const { setDefaultNotificationCard } = React.useContext(StandardsContext);
    const { setCheckoutPaymentData, userData } = React.useContext(UserContext);
    const stripe = useStripe();
    const elements = useElements();
    const [cardHolderName, setCardHolderName] = useState("");
    const [complete, setComplete] = useState(false);
    const [saveStripeLoading, setSaveStripeLoading] = useState(false);
    const isValidate = complete && cardHolderName?.length > 0;
    const customStyles = {
        base: {
            "padding": "16px",
            "color": "#fff",
            "fontWeight": "400",
            "fontSize": "14px",
            "fontSmoothing": "antialiased",
            ":-webkit-autofill": {
                color: "#fce883"
            },
            "::placeholder": {
                color: "#8C8C8C"
            }
        },
        invalid: {
            iconColor: "red",
            color: "red"
        }
    };
    /* #endregion VARS */

    /* #region METHODS */
    const handleCloseLogin = () => {
        setShowUserLoginRegisterPopup(false);
        Library.showNotification({
            message: "Account has been created.",
            type: "success",
            setDefaultNotificationCard
        });
    };
    const goBackFunction = () => {
        setShowPersonalInformation(true);
        setShowCardInformation(false);
    };
    const saveCheckoutFunc = async () => {
        setSaveStripeLoading(true);
        const response = await Library.makePostRequest("saveCardPayment", {
            name: cardHolderName,
            email: userData?.email
        });
        const cardElement = elements.getElement(CardElement);
        const res = await stripe.confirmCardSetup(response?.data?.setupIntent?.client_secret, {
            payment_method: {
                card: cardElement,
                billing_details: {
                    name: cardHolderName
                }
            }
        });

        const response2 = await Library.makePostRequest("updateCardDetails", {
            bothebotAccountId: localStorage.getItem("pid"),
            paymentMethod: res?.setupIntent?.payment_method,
            customerId: response?.data?.customerID
        });

        if (response2?.status === 200) {
            handleCloseLogin();
        }

        const cardDetails = response2?.data?.cardDetails;

        if (cardDetails) {
            setCheckoutPaymentData({
                cardNumber: cardDetails?.cardNumber,
                cardHolder: cardDetails?.cardHolderName,
                cardExpiration: cardDetails?.cardExpDate,
                cardCVV: cardDetails?.cardCVV,
                cardName: cardDetails?.cardName,
                customerID: response?.data?.customerID,
                paymentMethodID: cardDetails?.paymentMethodID
            });
        }
        setSaveStripeLoading(false);
    };
    /* #endregion METHODS */

    return (
        <StyledPage className="frame-29643">
            <div className="frame-313565">
                <div className="frame-313366">
                    <div className="frame-313591">
                        <div className="frame-313592">
                            <div className="back-btn-sm">
                                <img className="arrow-left" src={ArrowBack} alt="" onClick={goBackFunction} />
                            </div>
                            <div className="payment-method">Payment method</div>
                        </div>
                        <Frame290570 className="frame-29057 cursor-pointer" onClick={handleCloseLogin} />
                    </div>
                </div>
                <div className="add-a-card-now-so-that-you-save-time-during-checkout-you-can-also-add-and-save-more-cards-in-settings-later">
                    Add a card now, so that you save time during checkout. You can also add and save more cards in settings later.
                </div>
            </div>
            <div className="frame-313567">
                <div className="frame-313566">
                    <div className="add-payment-method">Add payment method</div>
                    <div className="frame-313373">
                        <div className="floating-input">
                            <input
                                type="text"
                                className="customer-info-inputs"
                                placeholder=" "
                                autoComplete="off"
                                required
                                onChange={e => {
                                    setCardHolderName(e.target.value);
                                }}
                                value={cardHolderName}
                                style={{
                                    border:
                                        cardHolderName?.length > 0
                                            ? "1px solid var(--text-text-primary)"
                                            : "1px solid var(--border-border-primary-10)"
                                }}
                            />
                            <label>Name on card</label>
                        </div>
                        <div className="w-100">
                            <CardElement
                                id="card-element"
                                options={{
                                    style: customStyles,
                                    hidePostalCode: true,
                                    disableLink: true
                                }}
                                className="element"
                                onChange={e => {
                                    setComplete(e.complete);
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="frame-313572">
                    <div className="frame-313387">
                        <div className="separator">
                            <div className="line-27"></div>
                            <div className="text-label-here">Step 2 / 2</div>
                            <div className="line-26"></div>
                        </div>
                    </div>
                    <div className="frame-29637">
                        <div className="btn-md-solid" onClick={handleCloseLogin}>
                            <div className="frame-313497">
                                <div className="frame-313349">
                                    <div className="button">Skip step</div>
                                </div>
                            </div>
                        </div>
                        <div className="btn-md-solid2" onClick={isValidate && saveCheckoutFunc} style={{ opacity: isValidate ? 1 : 0.2 }}>
                            <div className="frame-313497">
                                <div className="frame-313349">
                                    <div className="button">
                                        <div style={{ marginTop: "7px" }}>{saveStripeLoading && <ButtonLoader />}</div>

                                        {saveStripeLoading ? <>&nbsp;Finishing up</> : "Finish"}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </StyledPage>
    );
}
