import React, { useState, useEffect } from "react";
import { ReactComponent as Frame290570 } from "../../../../assets/images/close-btn.svg";
import styled from "styled-components";
import LayoutContext from "../../../../contexts/LayoutContext";
import { ReactComponent as PasswordEyeHide } from "../../../../assets/images/password-eye-hide.svg";
import { ReactComponent as PasswordEyeHideActive } from "../../../../assets/images/password-eye-hide-active.svg";
import { ReactComponent as Checkbox } from "../../../../assets/images/terms-check.svg";
import { validatePassword, isValidEmail } from "../../../../utils/Formatter";
import * as Library from "../../../../utils/Library";
import Spinner from "../../../../assets/images/spinner.svg";
import { UserContext, userLogin } from "../../../../contexts/UserContext";
import packageJson from "../../../../../package.json";
import StandardsContext from "../../../../contexts/StandardsContext";

/* #region STYLED CSS */
const StyledPage = styled.div`
    &.frame-29643,
    &.frame-29643 * {
        box-sizing: border-box;
    }
    &.frame-29643 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313562 {
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313474 {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .create-account {
        color: var(--text-text-primary, #ffffff);
        text-align: left;
        font-family: "OpenSans-SemiBold", sans-serif;
        font-size: 22px;
        line-height: 28px;
        font-weight: 600;
        position: relative;
        flex: 1;
    }
    .frame-29057 {
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 30px;
        height: 30px;
        position: relative;
        overflow: visible;
    }
    .experience-all-benefits-by-creating-your-own-b-oaccount {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: left;
        font-family: var(--subtitle-2-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 14px);
        line-height: var(--subtitle-2-line-height, 24px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
        align-self: stretch;
    }
    .tabs-level-1 {
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .buttons-fixed-width {
        background: var(--buttons-button-secondary, #212121);
        border-radius: 5px 0px 0px 5px;
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: center;
        flex: 1;
        height: 36px;
        position: relative;
        box-shadow: var(--bt-drop-shadow-box-shadow, 0px 0px 4px 0px rgba(30, 38, 49, 0.35));
        cursor: pointer;
    }
    .label {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-family: var(--button-text-small-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--button-text-small-font-size, 12px);
        line-height: var(--button-text-small-line-height, 16px);
        font-weight: var(--button-text-small-font-weight, 400);
        position: relative;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .buttons-fixed-width2 {
        background: var(--buttons-button-secondary-active, #373737);
        border-radius: 0px 5px 5px 0px;
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: center;
        flex: 1;
        height: 36px;
        position: relative;
        box-shadow: var(--bt-drop-shadow-box-shadow, 0px 0px 4px 0px rgba(30, 38, 49, 0.35));
        cursor: pointer;
    }
    .label-active {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-family: var(--button-text-small-semibold-font-family, "OpenSans-SemiBold", sans-serif);
        font-size: var(--button-text-small-semibold-font-size, 12px);
        line-height: var(--button-text-small-semibold-line-height, 16px);
        font-weight: var(--button-text-small-semibold-font-weight, 600);
        position: relative;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .frame-313586 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .input-field {
        background: var(--surface-surface-secondary, #121212);
        border-radius: 5px;
        border-style: solid;
        border-color: var(--border-border-primary, rgba(166, 166, 166, 0.2));
        border-width: 1px;
        padding: 16px;
        display: flex;
        flex-direction: row;
        gap: 0px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
        box-shadow: var(--dropdown-input-field-box-shadow, 0px 0px 15px 0px rgba(0, 0, 0, 0.25));
    }
    .label2 {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: left;
        font-family: var(--label-medium-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--label-medium-font-size, 14px);
        line-height: var(--label-medium-line-height, 14px);
        font-weight: var(--label-medium-font-weight, 400);
        position: relative;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .eye {
        flex-shrink: 0;
        width: 14px;
        height: 14px;
        position: relative;
        overflow: visible;
    }
    .eye2 {
        flex-shrink: 0;
        width: 14px;
        height: 14px;
        position: relative;
        overflow: visible;
    }
    .checkbox {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
        cursor: pointer;
    }
    .ellipse-1 {
        border-radius: 50%;
        border-style: solid;
        border-color: var(--border-border-primary, rgba(166, 166, 166, 0.2));
        border-width: 1px;
        flex-shrink: 0;
        width: 20px;
        height: 20px;
        position: relative;
    }
    .text-label {
        text-align: left;
        font-family: var(--subtitle-2-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 14px);
        line-height: var(--subtitle-2-line-height, 24px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
    }
    .text-label-span {
        color: var(--text-text-secondary, #8c8c8c);
        font-family: var(--subtitle-2-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 14px);
        line-height: var(--subtitle-2-line-height, 24px);
        font-weight: var(--subtitle-2-font-weight, 400);
    }
    .text-label-span2 {
        color: var(--text-text-brand, #f7665f);
        font-family: var(--subtitle-2-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 14px);
        line-height: var(--subtitle-2-line-height, 24px);
        font-weight: var(--subtitle-2-font-weight, 400);
    }
    .btn-md-solid {
        background: var(--buttons-button-primary, #f7665f);
        border-radius: 5px;
        opacity: 0.2;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        height: 44px;
        position: relative;
        box-shadow: var(--bt-drop-shadow-box-shadow, 0px 0px 4px 0px rgba(30, 38, 49, 0.35));
        cursor: no-drop;
    }
    .frame-313497 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313349 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .button {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-family: var(--button-text-medium-regular-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--button-text-medium-regular-font-size, 14px);
        line-height: var(--button-text-medium-regular-line-height, 24px);
        font-weight: var(--button-text-medium-regular-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .version-1-2 {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: center;
        font-family: var(--subtitle-2-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 14px);
        line-height: var(--subtitle-2-line-height, 24px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
        align-self: stretch;
    }
    .floating-input {
        position: relative;
        width: 100%;
    }
    .customer-info-inputs {
        background-color: initial;
        border: 1px solid #373737;
        border-radius: 5px;
        color: #fff;
        display: inline-block;
        height: 0px;
        outline: none;
        padding: 28px 16px 16px;
        width: 100%;
        font-size: 14px !important;
    }
    .floating-input input:focus + label,
    .floating-input input:not(:placeholder-shown) + label {
        color: #8c8c8c;
        font-size: 8px;
        font-weight: 600;
        left: 18px;
        top: 5px;
    }
    .floating-input label {
        color: #8c8c8c;
        font-size: 14px;
        font-weight: 400;
        left: 22px;
        pointer-events: none;
        position: absolute;
        top: 13px;
        transition: all 0.2s ease;
    }
    .customer-info-inputs.has-value {
        opacity: 1;
        border: 1px solid #8c8f93;
    }
    .btn-md-solid.active {
        opacity: 1;
        cursor: pointer;
    }
    .toggle-password {
        position: absolute;
        right: 14px;
        top: 16px;
    }
    .floating-input .error {
        border-color: #f7665f !important;
    }
    .error-text {
        font-size: 12px;
        color: #f7665f;
    }
    .spinner-text {
        display: flex;
        flex-direction: row;
    }
    .spinner-img {
        animation: spin 1s linear infinite;
    }

    @keyframes spin {
        to {
            transform: rotate(360deg);
        }
    }
    .password-checker {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        align-items: center;
    }
    .password-checker-main-box {
        width: 156px;
        height: 4px;
        display: flex;
        flex-direction: row;
        gap: 4px;
    }
    .password-strength-box {
        width: 28px;
        background: #212121;
        border-radius: 10px;
    }
    .password-strength-box.weak {
        background: #ff0000;
    }
    .password-strength-box.good {
        background: #ff9900;
    }
    .error-text.good {
        color: #ff9900;
    }
    .password-strength-box.strong {
        background: #69c23f;
    }
    .error-text.strong {
        color: #69c23f;
    }
    .password-checker-label {
        font-size: 14px;
        color: #8c8c8c;
        font-weight: 400;
    }
`;
/* #endregion STYLED CSS */
export default function Registration() {
    /* #region VARS */
    const { setShowUserLoginRegisterPopup, setShowLoginForm, setShowRegistration, setShowPersonalInformation, setShowPolicyPopup } =
        React.useContext(LayoutContext);
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState(false);
    const [emailErrorText, setEmailErrorText] = useState("");
    const [emailHasValue, setEmailHasValue] = useState(false);
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState(false);
    const [passwordErrorText, setPasswordErrorText] = useState("");
    const [togglePassword, setTogglePassword] = useState(false);
    const [passwordHasValue, setPasswordHasValue] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState(false);
    const [confirmPasswordErrorText, setConfirmPasswordErrorText] = useState("");
    const [toggleConfirmPassword, setToggleConfirmPassword] = useState(false);
    const [confirmPasswordHasValue, setConfirmPasswordHasValue] = useState(false);
    const [isFormSubmit, setIsFormSubmit] = useState(false);
    const [enableCheckbox, setEnableCheckbox] = useState(false);
    const [isButtonDisable, setIsButtonDisable] = useState(true);
    const [passwordStrength, setPasswordStrength] = useState(0);
    const {
        setIsUserLoggedIn,
        setUserData,
        setUserAddress,
        setUserGPSCoordinates,
        setBOTagAccountID,
        setCheckoutPaymentData,
        setShippingRecipient,
        setCheckoutCartData,
        checkoutCartData
    } = React.useContext(UserContext);
    const { setDefaultNotificationCard } = React.useContext(StandardsContext);
    /* #endregion VARS */

    /* #region METHODS */
    const handleCloseRegistration = () => {
        setShowUserLoginRegisterPopup(false);
    };
    const handleShowLogin = () => {
        setShowLoginForm(true);
        setShowRegistration(false);
    };
    const handleUserEmail = e => {
        setEmailError(false);
        setEmailErrorText("");
        setEmail(e.target.value);
    };
    const handlePassword = e => {
        setPassword(e.target.value);

        if (e.target.value) {
            const strength = calculateStrength(e.target.value);
            setPasswordStrength(strength);
            if (strength === 1) {
                setPasswordErrorText("Password is weak");
                setPasswordError(true);
            }
            if (strength === 2) {
                setPasswordErrorText("Password is weak");
                setPasswordError(true);
            }
            if (strength === 3) {
                setPasswordErrorText("Password is good");
                setPasswordError(false);
            }
            if (strength === 4) {
                setPasswordErrorText("Password is strong");
                setPasswordError(false);
            }
            if (strength === 5) {
                setPasswordErrorText("Password is very strong");
                setPasswordError(false);
            }
        } else {
            setPasswordError(false);
            setPasswordErrorText("");
            setPasswordStrength(0);
        }
        //console.log(strength);
    };
    const handleTogglePassword = () => {
        setTogglePassword(!togglePassword);
    };
    const handleConfirmPassword = e => {
        setConfirmPassword(e.target.value);
        setConfirmPasswordError(false);
        setConfirmPasswordErrorText("");
    };
    const handleToggleConfirmPassword = () => {
        setToggleConfirmPassword(!toggleConfirmPassword);
    };
    const handleCheckbox = () => {
        setEnableCheckbox(!enableCheckbox);
    };
    const calculateStrength = password => {
        let strength = 0;
        if (password.length < 1) return 0;
        if (/[A-Z]/.test(password)) strength++;
        if (/[a-z]/.test(password)) strength++;
        if (/\d/.test(password)) strength++;
        if (/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(password)) strength++;
        if (password.length >= 10) strength++;
        return strength;
    };
    const handleValidation = () => {
        let isFormValid = true;
        if (!emailHasValue) {
            isFormValid = false;
            setEmailError(true);
            setEmailErrorText("");
        } else if (emailHasValue && !isValidEmail(email)) {
            isFormValid = false;
            setEmailError(true);
            setEmailErrorText("Invalid email");
        }
        if (!password) {
            isFormValid = false;
            setPasswordError(true);
        }
        /*if (password && passwordStrength < 3) {
            isFormValid = false;
            setPasswordError(true);
            //setPasswordErrorText("Password must contain at least 7 letters and 1 number.");
        }*/
        if (!confirmPassword) {
            isFormValid = false;
            setConfirmPasswordError(true);
        }
        if (password && confirmPassword && password !== confirmPassword) {
            isFormValid = false;
            setConfirmPasswordError(true);
            setConfirmPasswordErrorText("Password didn’t match");
        }
        return isFormValid;
    };
    const handleRegistration = e => {
        if (isButtonDisable) {
            e.preventDefault();
            return;
        }
        if (handleValidation()) {
            setEmailHasValue(false);
            setPasswordHasValue(false);
            setIsFormSubmit(true);
            setConfirmPasswordHasValue(false);
            Library.makePostRequest(
                "createAccount",
                {
                    email: email,
                    password: password
                },
                false,
                setIsButtonDisable
            )
                .then(res => {
                    setEmailHasValue(true);
                    setPasswordHasValue(true);
                    setConfirmPasswordHasValue(true);
                    setIsFormSubmit(false);
                    const { data } = res;
                    if (data?.status === 400) {
                        setEmailError(true);
                        setEmailErrorText(data?.message);
                    } else {
                        localStorage.setItem("pid", data?.data?.pid?._id);
                        localStorage.setItem("isLoggedIn", true);
                        const allUserInfo = {
                            _id: data?.data?.pid?._id,
                            firstName: "",
                            lastName: "",
                            email: email,
                            phone: ""
                        };
                        userLogin(
                            allUserInfo,
                            setIsUserLoggedIn,
                            setUserData,
                            setUserAddress,
                            setUserGPSCoordinates,
                            setBOTagAccountID,
                            setCheckoutPaymentData,
                            setShippingRecipient,
                            setCheckoutCartData,
                            checkoutCartData
                        );
                        setShowPersonalInformation(true);
                        setShowLoginForm(false);
                        setShowRegistration(false);
                    }
                })
                .catch(e => {
                    console.log(e);
                });
        }
    };
    /* #endregion METHODS */

    /* #region EFFECTS */
    useEffect(() => {
        if (email) {
            setEmailHasValue(true);
        } else {
            setEmailHasValue(false);
        }
        if (password) {
            setPasswordHasValue(true);
        } else {
            setPasswordHasValue(false);
        }
        if (confirmPassword) {
            setConfirmPasswordHasValue(true);
        } else {
            setConfirmPasswordHasValue(false);
        }
        if (email && password && confirmPassword && enableCheckbox) {
            setIsButtonDisable(false);
        } else {
            setIsButtonDisable(true);
        }
    }, [email, password, confirmPassword, enableCheckbox]);
    /* #endregion EFFECTS */
    return (
        <StyledPage className="frame-29643">
            <div className="frame-313562">
                <div className="frame-313474">
                    <div className="create-account">Create account</div>
                    <Frame290570 className="frame-29057 cursor-pointer" onClick={handleCloseRegistration} />
                </div>
                <div className="experience-all-benefits-by-creating-your-own-b-oaccount">
                    Experience all benefits by creating your own BOaccount!
                </div>
            </div>
            <div className="tabs-level-1">
                <div className="buttons-fixed-width" onClick={handleShowLogin}>
                    <div className="label">Log in</div>
                </div>
                <div className="buttons-fixed-width2">
                    <div className="label-active">Create account</div>
                </div>
            </div>
            <div className="frame-313586">
                <div className="floating-input">
                    <input
                        type="text"
                        className={`customer-info-inputs ${emailHasValue ? "has-value" : ""} ${emailError ? "error" : ""}`}
                        placeholder=" "
                        autoComplete="off"
                        value={email}
                        onChange={handleUserEmail}
                        style={{ opacity: isFormSubmit ? "0.2" : "1" }}
                    />
                    <label>Email</label>
                    <div className="error-text">{emailErrorText}</div>
                </div>
                <div className="floating-input">
                    <input
                        type={!togglePassword ? "password" : "text"}
                        className={`customer-info-inputs ${passwordHasValue ? "has-value" : ""} ${passwordError ? "error" : ""}`}
                        placeholder=" "
                        autoComplete="off"
                        value={password}
                        onChange={handlePassword}
                        style={{ opacity: isFormSubmit ? "0.2" : "1" }}
                    />
                    <label>Password</label>
                    <div className={`error-text ${passwordStrength === 3 ? "good" : passwordStrength >= 4 ? "strong" : ""}`}>
                        {passwordErrorText}
                    </div>
                    <span className="toggle-password">
                        {passwordHasValue ? (
                            <PasswordEyeHideActive
                                className="frame-29057 cursor-pointer"
                                style={{
                                    height: "15px",
                                    width: "15px"
                                }}
                                onClick={handleTogglePassword}
                            />
                        ) : (
                            <PasswordEyeHide
                                className="frame-29057 cursor-pointer"
                                style={{
                                    height: "15px",
                                    width: "15px"
                                }}
                                onClick={handleTogglePassword}
                            />
                        )}
                    </span>
                </div>
                <div className="password-checker">
                    <div className="password-checker-label">Password strength</div>
                    <div className="password-checker-main-box">
                        {Array(5)
                            .fill(0)
                            .map((_, i) => (
                                <div
                                    className={`password-strength-box ${
                                        i < passwordStrength && passwordStrength === 1
                                            ? "weak"
                                            : i < passwordStrength && passwordStrength === 2
                                            ? "weak"
                                            : i < passwordStrength && passwordStrength === 3
                                            ? "good"
                                            : i < passwordStrength && passwordStrength >= 4
                                            ? "strong"
                                            : ""
                                    }`}></div>
                            ))}
                    </div>
                </div>
                <div className="floating-input">
                    <input
                        type={!toggleConfirmPassword ? "password" : "text"}
                        className={`customer-info-inputs ${confirmPasswordHasValue ? "has-value" : ""} ${
                            confirmPasswordError ? "error" : ""
                        }`}
                        placeholder=" "
                        autoComplete="off"
                        value={confirmPassword}
                        onChange={handleConfirmPassword}
                        style={{ opacity: isFormSubmit ? "0.2" : "1" }}
                    />
                    <label>Re-enter password</label>
                    <div className="error-text">{confirmPasswordErrorText}</div>
                    <span className="toggle-password">
                        {confirmPasswordHasValue ? (
                            <PasswordEyeHideActive
                                className="frame-29057 cursor-pointer"
                                style={{
                                    height: "15px",
                                    width: "15px"
                                }}
                                onClick={handleToggleConfirmPassword}
                            />
                        ) : (
                            <PasswordEyeHide
                                className="frame-29057 cursor-pointer"
                                style={{
                                    height: "15px",
                                    width: "15px"
                                }}
                                onClick={handleToggleConfirmPassword}
                            />
                        )}
                    </span>
                </div>
            </div>
            <div className="checkbox">
                <div className="text-label">
                    <div className="checkbox" onClick={handleCheckbox}>
                        {enableCheckbox ? (
                            <Checkbox
                                className="frame-29057 cursor-pointer"
                                style={{
                                    height: "20px",
                                    width: "20px",
                                    opacity: isFormSubmit ? 0.2 : 1
                                }}
                            />
                        ) : (
                            <div className="ellipse-1"></div>
                        )}
                        <span className="text-label-span">
                            I agree with{" "}
                            <span className="text-label-span2" onClick={() => setShowPolicyPopup("privacy")}>
                                privacy policy
                            </span>
                        </span>
                    </div>
                </div>
            </div>
            <div className={`btn-md-solid  ${!isButtonDisable ? "active" : ""}`} onClick={handleRegistration}>
                <div className="frame-313497">
                    <div className="frame-313349">
                        <div className="button">
                            {isFormSubmit ? (
                                <span className="spinner-text">
                                    <img src={Spinner} alt="" className="spinner-img" />
                                    &nbsp;
                                    {"  "}Creating account...
                                </span>
                            ) : (
                                "Create account"
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="version-1-2">Version {packageJson.version}</div>
        </StyledPage>
    );
}
