import React, { useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import checkMark from "./../../../../assets/images/green-check0.svg";
import LayoutContext from "../../../../contexts/LayoutContext";
import styled from "styled-components";
/* #region CSS */
const StyledPage = styled.div`
    &.frame-29643,
    &.frame-29643 * {
        box-sizing: border-box;
    }
    &.frame-29643 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    &.mobile-frame-29643 {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 95%;
    }
    .frame-313411 {
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: flex-end;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313402 {
        display: flex;
        flex-direction: row;
        gap: 32px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313406 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: center;
        align-self: stretch;
        flex: 1;
        position: relative;
    }
    .frame-3134022 {
        display: flex;
        flex-direction: column;
        gap: 32px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313401 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .frame-3590 {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .group-3631 {
        flex-shrink: 0;
        width: 50px;
        height: 50px;
        position: static;
    }
    .ellipse-80 {
        background: var(--wrap-wrap-green, rgba(105, 194, 63, 0.1));
        border-radius: 50%;
        width: 50px;
        height: 50px;
        position: absolute;
        top: 0px;
    }
    .check {
        width: 5.94%;
        height: 48%;
        position: absolute;
        right: 47.03%;
        left: 47.03%;
        bottom: 24%;
        top: 28%;
        overflow: visible;
    }
    .password-changed {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-family: "OpenSans-SemiBold", sans-serif;
        font-size: 22px;
        line-height: 24px;
        font-weight: 600;
        position: relative;
        align-self: stretch;
    }
    .frame-313418 {
        padding: 0px 24px 0px 24px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: flex-start;
        align-self: stretch;
        flex-shrink: 0;
        position: relative;
    }
    .your-password-has-been-successfully-changed {
        color: var(--text-text-secondary, #8c8c8c);
        text-align: center;
        font-family: var(--subtitle-2-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--subtitle-2-font-size, 14px);
        line-height: var(--subtitle-2-line-height, 24px);
        font-weight: var(--subtitle-2-font-weight, 400);
        position: relative;
        flex: 1;
    }
    .btn-md-solid {
        background: var(--buttons-button-secondary, #212121);
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        height: 44px;
        position: relative;
        box-shadow: var(--bt-drop-shadow-box-shadow, 0px 0px 4px 0px rgba(30, 38, 49, 0.35));
        cursor: pointer;
    }
    .frame-313497 {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .frame-313349 {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
    }
    .button {
        color: var(--text-text-primary, #ffffff);
        text-align: center;
        font-family: var(--button-text-medium-regular-font-family, "OpenSans-Regular", sans-serif);
        font-size: var(--button-text-medium-regular-font-size, 14px);
        line-height: var(--button-text-medium-regular-line-height, 24px);
        font-weight: var(--button-text-medium-regular-font-weight, 400);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;
/* #endregion CSS */
export default function PasswordChangedSuccess() {
    /* #region VARS */
    const { setShowUserLoginRegisterPopup, setShowForgotPasswordPopup, setPasswordIsChanged, setValidatingPasswordToken, isMobile } =
        React.useContext(LayoutContext);
    const navigate = useNavigate();
    const changePasswordRef = useRef();
    /* #endregion VARS */
    /* #region METHODS */
    const handleGoToLogin = () => {
        setShowUserLoginRegisterPopup(true);
        setShowForgotPasswordPopup(false);
        setPasswordIsChanged(false);
        setValidatingPasswordToken(false);
        navigate("/feed");
    };
    useEffect(() => {
        function handleClickOutside(event) {
            if (changePasswordRef.current && !changePasswordRef.current.contains(event.target)) {
                if (localStorage.getItem("isLoggedIn") === "true") {
                    setShowUserLoginRegisterPopup(false);
                    setShowForgotPasswordPopup(false);
                    setPasswordIsChanged(false);
                    setValidatingPasswordToken(false);
                    navigate("/feed");
                }
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [changePasswordRef]);
    /* #endregion METHODS */
    return (
        <StyledPage className={`frame-29643 ${isMobile ? "mobile-frame-29643" : ""}`}>
            <div className="frame-313411" ref={changePasswordRef}>
                <div className="frame-313402">
                    <div className="frame-313406">
                        <div className="frame-3134022">
                            <div className="frame-313401">
                                <div className="frame-3590">
                                    <div className="group-3631">
                                        <div className="ellipse-80"></div>
                                        <img className="check" src={checkMark} alt="" />
                                    </div>
                                </div>
                                <div className="password-changed">Password changed!</div>
                                <div className="frame-313418">
                                    <div className="your-password-has-been-successfully-changed">
                                        Your password has been successfully changed
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {localStorage.getItem("isLoggedIn") === "true" ? (
                ""
            ) : (
                <div className="btn-md-solid" onClick={handleGoToLogin}>
                    <div className="frame-313497">
                        <div className="frame-313349">
                            <div className="button">Log in now</div>
                        </div>
                    </div>
                </div>
            )}
        </StyledPage>
    );
}
